import styled from "@emotion/styled";


export const Container = styled.div`
// border: 3px solid red;
display: flex;
flex-wrap: wrap;
transition all 1s;
@media(max-width: 1800px) {
    text-align: center;
    justify-content: center;
    
}
`;

export const Title = styled.h2`
    font-size: 30px;
    margin-bottom: 2%;
    font-weight: bold;
`;

export const Description = styled.p`
    margin-bottom: 1%;
    
`;

export const Row = styled.div`
// border: 5px solid red;
display: flex;width: 100%;
justify-content: space-between;                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                              

@media(max-width: 1800px) {
    display: flex;
    flex-wrap:wrap;
    margin-left: 5%;
    margin-bottom: 2%;
    // border: 2px solid red;
    justify-content: center;
}
`;


export const Label = styled.h4`
    font-weight: 300;
    margin-bottom: 1%;
`;

export const Input = styled.input`
    width: 30rem;
    border-radius: 1rem;
    height: 50px;
    padding: 10px;
    margin-bottom: 2%;
    font-size: 20px;
    border: 1px solid;
    @media(max-width: 1000px) {
        width: 25rem;
    }
`;

export const ImageUpload = styled.div`
    height: 300px;
    width:700px;
    border-radius: 1rem;
    border: 3px solid black;
    margin-bottom: 2%;
    @media(max-width: 768px) {
        width: 29rem;
    }
`;

export const MiniRow = styled.div`
    display: flex;
    flex-wrap: wrap;
// border: 3px solid blue;
justify-content: space-between;                                                                                    
width: 100%;
    @media(max-width: 1800px) {
        display: block
    }
`
export const GameDescription = styled.textarea`
    width: 100%;
    border-radius: 1rem;
    height: 15rem;
    padding: 10px;
    font-size: 20px;
    font-family:Montserrat;
    margin-botttom: 6%;
    border: 2px solid;
    @media(max-width: 768px) {
        width: 25rem;
        height: 15rem;
    }
`
export const ButtonGroup = styled.div`
    border-radius: 1rem;
    width: 39rem;
     
    @media(max-width: 1800px) {
        width: 40rem;
        margin-left: 20%;
    }
    @media(max-width: 800px) {
        width: 30rem;
        margin-left: 5%;
    }
`;

export const Buttons = styled.div`
    text-align: right;
    justify-content: right;
    display: flex;
    margin-top: 5%;
    width: 100%;
    @media(max-width: 1800px) {
        display: flex;
        margin-bottom: 3%;
    }
}
`;
export const LowerContainer = styled.div`
    @media(max-width: 1800px) {
        display: block;
        justify-content: center;
    }
`;