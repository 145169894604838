import { useContext } from "react";
import {
  useGetProfileQuery,
  useUpdateProfileMutation,
  useUpdateUserMutation,
} from "@graphql";
import { Field, Form, Formik } from "formik";
import { RegularButton } from "../buttons/button";
import {
  Buttons,
  Container,
  Label,
  ProfileContainer,
  Details,
} from "./components";
import { LoggedInUserContext } from "contexts/user";
import * as toast from "../jiwewallet/toast";
import ProfileHeader from "./ProfileHeader";
import { Box, LinearProgress } from "@material-ui/core";

export default function Profile() {
  const loggedInUser = useContext(LoggedInUserContext);

  const { data, loading: loadingUser } = useGetProfileQuery({
    variables: { profileId: loggedInUser?.profile?.id! },
  });

  const [updateProfileMutation] = useUpdateProfileMutation();
  const [updateUserMutation] = useUpdateUserMutation();

  if (loadingUser || !data) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );

  }

  const user = data?.profiles_by_pk;
  const onSubmit = async (values: any, helpers: any) => {
    const profileValues = values;
    delete profileValues.email;
    const { data: updatedData } = await updateProfileMutation({
      variables: {
        id: user!.id,
        input: {
          ...profileValues,
        },
      },
    });
    const userValues = values;
    delete userValues.nickname;
    await updateUserMutation({
      variables: {
        id: loggedInUser?.id,
        input: {
          ...values,
          email: values.email,
          username: values.nickname,
        },
      },
    });
    toast.success("Profile successfully updated");
    if (updatedData) {
      helpers.setSubmitting(false);
    }
  };

  const initialValues = {
    nickname: user!.nickname || "",
    bio: user!.bio || "",
    avatar: user!.avatar || "",
    name: user!.name || "",
    email: loggedInUser?.email || "",
    phone_number: loggedInUser?.phone_number || "",
  };

  return (
    <ProfileContainer>
      <ProfileHeader />
      <Container>
        <Formik onSubmit={onSubmit} initialValues={initialValues}>
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "3%", display: "none" }}>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Ducimus eius natus magnam aspernatur iusto officia sint ipsum
                accusantium quaerat consequatur aliquam quos, harum dolorum
                labore, adipisci quo repudiandae eos nihil? Lorem ipsum dolor,
                sit amet consectetur adipisicing elit. Fugiat, quo esse magni
                nam accusantium molestias consequatur nulla voluptate facilis
                labore id dolores. Eum cumque vero eius minima ducimus ad
                accusamus.
              </div>
              <Details>
                <div>
                  <Label>Username</Label>
                  <Field compact name='nickname' type='text' />
                </div>
                <div>
                  <Label>Email</Label>
                  <Field compact name='email' type='text' required />
                </div>
                <div>
                  <Label>Name</Label>
                  <Field compact name='name' type='text' required />
                </div>
                <div>
                  <Label>Phone number</Label>
                  <Field compact name='phone_number' type='text' required />
                </div>
              </Details>
              <div>
                <Label>Bio</Label>
                {/* <Bio /> */}
                {/* <Field compact name='name' type='textarea' /> */}
                <Field name='bio' as='textarea' />
              </div>
              <Buttons style={{ marginTop: "3%", marginBottom: " 3%" }}>
                <RegularButton disabled={isSubmitting}>
                  Update Profile
                </RegularButton>
              </Buttons>
            </Form>
          )}
        </Formik>

        {/* <DeleteContainer>
          <DeleteTitle>Delete Account</DeleteTitle>
          <DeleteDesc>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores
            iure, quo dolorum, inventore harum ipsa perferendis deserunt nobis
            ipsum illum ut ipsam esse consectetur nesciunt sunt, illo sed!
            Rerum, illo. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Blanditiis adipisci minima, ea quia quae, tempora accusamus
            optio perferendis et doloremque reprehenderit unde error tempore
            placeat quo nemo! Ab, quos explicabo.
          </DeleteDesc>
          <OutlineButton
            style={{ border: "2px solid #BA4646", marginTop: "2%" }}
          >
            Delete Account
          </OutlineButton>
        </DeleteContainer> */}
      </Container>
    </ProfileContainer>
  );
}
