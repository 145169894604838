import styled from "@emotion/styled";

interface IFlexWrapper {
  direction?: string;
  align?: string;
  justify?: string;
}

const FlexWrapper = styled.div<IFlexWrapper>`
  display: flex;
  flex-direction: ${({ direction = "row" }) => direction};
  align-items: ${({ align = "center" }) => align};
  justify-content: ${({ justify = "center" }) => justify};
`;

export default FlexWrapper;
