import React, { useContext } from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "@emotion/styled";

import Footer from "./Footer";
import GamesSection from "./GamesSection";
import Header from "./Header";
import FeaturedGames from "./FeaturedGames";
import Button from "./Button";
import Container from "./Container";
import FlexWrapper from "./FlexWrapper";
import useAuth from "pages/auth/useAuth";
import { LoggedInUserContext } from "contexts/user";
import { Helmet } from "react-helmet";

const ActionsWrapper = styled(Container)`
  display: flex;
  flex-direction: column;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 1rem;
  padding-bottom: 1rem;

  @media (max-width: 480px) {
    margin-top: -5em;

    > div > div {
      margin-left: 0 !important;
    }
  }

  > div {
    > div {
      text-align: center;
    }
    h3 {
      color: ${(props) => props.theme.colors.dark};
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 1rem;
      text-align: center;
    }
    @media (max-width: 720px) {
      display: block !important;
      width: 100%;
      div {
        display: block;
        margin-bottom: 1em;
      }
    }
  }
`;


const Landing: React.FC<RouteComponentProps> = ({ history }) => {
  const { isAuthenticated } = useAuth();
  const loggedInUser = useContext(LoggedInUserContext);

  return (
    <>
      <Helmet>
        <title>Homepage | Jiwe IO</title>
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='og:image'
          content='https://jiwe.studio/assets/images/v3_2.webp'
        />
        <meta
          name='twitter:image'
          content='https://jiwe.studio/assets/images/v3_2.webp'
        />
      </Helmet>
      <Header
        isAuthenticated={isAuthenticated}
        userId={loggedInUser?.id}
        floated
        isHomeHeader={true}
      />
      <FeaturedGames />
      <ActionsWrapper>
        <FlexWrapper
          justify='center'
          style={{
            flex: "0 0 100%",
            flexWrap: "wrap",
          }}
        >
          <div>
            {/* <h3 style={{ color: "#F6DC72" }}>&nbsp;</h3> */}
            <Button
              heavy
              padding='wide'
              color='dark'
              // outline
              onClick={() =>
                history.push("/games/61d2a9df-2209-49bd-82c0-6d6b4aa28c1b")
              }
            >
              {/* Play like never before */}
              Download the new Usoni beta
            </Button>
          </div>
          <div style={{ marginLeft: "4rem" }}>
            {/* <h3>Are you a developer?</h3> */}
            <Button
              heavy
              padding='wide'
              color='dark'
              onClick={() => history.push("/web3-gaming")}
            >
              Web 3 Gaming
            </Button>
          </div>
          <div style={{ marginLeft: "4rem" }}>
            {/* <h3>Are you a developer?</h3> */}
            <Button
              heavy
              padding='wide'
              color='dark'
              onClick={() => history.push("/faq")}
            >
              Create &amp; distribute games with us
            </Button>
          </div>
        </FlexWrapper>
      </ActionsWrapper>

      <GamesSection />
      <Footer />
    </>
  );
};

export default Landing;
