export default function CategoryList({
  id,
  title,
  active,
  setSelected,
}: {
  id: string;
  title: string;
  active: boolean;
  setSelected: any;
}) {
  return (
    <li
      className={active ? "categoryList active" : "categoryList"}
      onClick={() => setSelected(id)}
    >
      <p style={{cursor:'pointer'}}>{title}</p>
    </li>
  );
}
