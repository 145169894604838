import React, { ButtonHTMLAttributes } from "react";
import { css } from "@emotion/react";

import styled from "@emotion/styled";
import { buttonColor, buttonPadding } from "theme";

import { ReactComponent as Loader } from "assets/loader.svg";

export interface IButtonProps {
  outline?: boolean;
  width?: string;
  color?: VariantType;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  heavy?: boolean;
  padding?: "compact" | "wide";
  loading?: boolean;
}

const baseBtnStyles = css`
  outline: none;
  border: none;
  font-size: 1rem;
  text-align: center;
  position: relative;
  border-radius: 4px;
  cursor: pointer;
  svg {
    height: 18px;
    vertical-align: middle;
  }
  .icon-start {
    margin-right: 8px;
  }
  .icon-end {
    margin-left: 8px;
  }
  :disabled {
    opacity: 0.33;
    cursor: not-allowed;
  }
`;

const ButtonWrapper = styled.button<Partial<IButtonProps>>`
  ${baseBtnStyles}
  font-weight: ${(props) => (props.heavy ? "700" : "500")};
  width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
  border: solid 2px
    ${(props) => props!.color && props.theme.colors[props!.color]};
  background: ${(props) =>
    props.outline
      ? "transparent"
      : props!.color && props.theme.colors[props!.color]};
  color: ${(props) => buttonColor(props)};
  padding: ${(props) => buttonPadding(props.padding)};
`;

export const IconButton = styled.button<Partial<IButtonProps>>`
  ${baseBtnStyles}
  padding: 4px !important;
  color: ${(props) => props!.color && props.theme.colors[props!.color]};
  svg {
    height: 18px;
    vertical-align: middle;
  }
`;

const Button: React.FC<
  IButtonProps & ButtonHTMLAttributes<HTMLButtonElement>
> = ({ children, loading, type, iconStart, iconEnd, ...props }) => {
  return (
    <ButtonWrapper type={type || "button"} {...props}>
      {loading ? (
        <Loader />
      ) : (
        <>
          {iconStart && <span className="icon-start">{iconStart}</span>}
          {children}
          {iconEnd && <span className="icon-end">{iconEnd}</span>}
        </>
      )}
    </ButtonWrapper>
  );
};

Button.defaultProps = {
  color: "primary",
};

export const LinkButton = styled(Button, {
  shouldForwardProp: () => true,
})`
  color: ${(props) => props!.color && props.theme.colors[props!.color]};
  background: transparent;
  text-decoration: none;
  padding-left: 0 !important;
  padding-right: 0 !important;
  border: none;
`;

export const ButtonGroup = styled("div")`
  button {
    margin-right: 1.5rem;
    :last-of-type {
      margin-right: 0rem;
    }
  }
`;

export default Button;
