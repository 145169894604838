import styled from "@emotion/styled";
import useAuth from "pages/auth/useAuth";
import Header from "pages/landing/Header";
import { Helmet } from "react-helmet";

export const FAQWrapper = styled.div`
  background-color: #fff;
  margin-top: 3rem !important;
  padding: 3rem;

  p {
    margin-bottom: 1em;
    line-height: 1.4;
  }

  h2,
  h3,
  h4 {
    margin-top: 2em;
  }

  h3 {
    margin-bottom: 1em;
  }

  ul,
  ol {
    padding-inline-start: 2em;
    margin-bottom: 2em;
  }

  li {
  }
`;

const FAQContent = () => {
  const { isAuthenticated } = useAuth();
  return (
    <>
      <Helmet>
        <title>FAQ | Jiwe IO</title>
      </Helmet>
      <Header
        isAuthenticated={isAuthenticated}
        userId={""}
        floated
        isHomeHeader={true}
      />
      <FAQWrapper style={{ margin: "auto", maxWidth: 1280 }}>
        <h1>Frequently asked questions</h1>
        <h2>Developer Publishing and Distribution</h2>
        <h3>Publishing and Distribution</h3>
        <p>
          By definition, <b>publishers</b> invest in the development process,
          marketing and quality assurance of the game (e.g. Electronic Arts,
          Ubisoft, Square Enix, Nintendo), At Jiwe Studio we would like to do
          the same for a handful of games. In this case we would like to work
          with the game developers and provide available studio resources to
          bring their game to life.
        </p>
        <p>
          Distributors on the other hand, only offer a platform for you to sell
          your game (e.g. steam, Google Play, Jiwe IO).
        </p>
        <p>
          <b>We do both!</b> Our mission is to help you create and showcase
          unforgettable player experiences, and we believe each project is
          unique and deserves to be played by as many players as possible.
        </p>
        <hr />
        <h3>What we are looking for</h3>
        <p>
          <b>African games</b> — We are looking for games developed by African
          developers or by developers living in Africa.
        </p>
        <p>
          <b>Platform</b> — We are platform agnostic so feel free to share your
          PC / Android / Web game with us
        </p>
        <p>
          <b>Genre</b> — We are interested in having as wide a genre of games as
          possible, so feel free to submit yours, as unique and as common as it
          may be.
        </p>
        <hr />
        <h3>Why Publish and Distribute with Jiwe</h3>
        <p>
          <b>Africa Centric</b> — We are based in Africa, from Nairobi, Kenya.
          Hence, we understand the challenges and opportunities in making games
          in Africa. We are also focused on distributing to Africa and to the
          world.
        </p>
        <p>
          <b>Community Focus</b> — We focus on community engagement to help
          collect and catalog great feedback for your game, from expert
          developers to Esports players and casual gamers. This is to help you
          develop better quality experiences.
        </p>
        <p>
          <b>Free</b> — We host your game for free.
        </p>
        <p>
          <b>Wallet</b> — [Coming Soon]
          <ul>
            <li>
              To help Developers earn revenue on games or help raise capital for
              developing games.
            </li>
            <li>
              For gamers to purchase games and to support development of more
              games
            </li>
          </ul>
        </p>
        <hr />
        <h3>How to submit your game</h3>
        <p>
          If you want to tell us about your game and find out how to add it onto
          our platform, please email our team.
        </p>
        <p>
          Email us - <a href='mailto:rock@jiwe.io'>rock@jiwe.io</a>
        </p>
        <p>
          Your email should include:
          <ol>
            <li>
              Who you are and what you’ve done before Game overview with 1-2
              paragraphs of elevator pitch, the platforms you’re aiming for,
              what state the game is in now and when you think the game would
              launch.
            </li>
            <li>
              Links to the game website, videos and a playable build if you have
              one. What you are looking for e.g. funding, marketing support etc.
            </li>
          </ol>
        </p>
      </FAQWrapper>
    </>
  );
};

export default FAQContent;
