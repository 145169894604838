import { useReducer } from "react";
import { filter } from "lodash";
import styled from "@emotion/styled";
import { useGetAllGamesQuery } from "@graphql";


import FlexWrapper from './FlexWrapper'
import Container from "./Container";
import GamesView from "./GamesView";

export const Label = styled("span")<{ color: VariantType }>`
  border-radius: 4px;
  background: transparent;
  color: ${(props) => props.theme.colors[props.color]};
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 600;
`;

export const TagLabel = styled(Label)<{ active?: boolean }>`
  cursor: pointer;
  background: ${(props) =>
    props.active ? props.theme.colors.greyish : "transparent"};
  border-radius: 1rem;
  margin-left: 1rem;
  padding-left: 16px;
  padding-right: 16px;
  font-size: 0.9rem;
  :first-of-type {
    margin-left: 1rem;
  }
`;

const Divider = styled.div<{ color?: VariantType; hidden?: boolean }>`
  margin: 1.5em 0;
  display: block;
  ${({ hidden, color = "grey", theme }) =>
    `
    border-bottom: solid 1px ${!hidden ? theme.colors[color] : "transparent"}`};
`;


const SectionWrapper = styled.section`
  padding: 4em 0;
  background: ${(props) => props.theme.colors.bg1};
`;

type ActionType = "SET_TAGS" | "TOGGLE_FILTER" | "SET_FILTERS";

interface IState {
  selectedTags: Array<string>;
  showFilters: boolean;
  filters: any;
}

const tags: Array<string> = ["#post-apocalyptic", "#strategy"];

const stateReducer = (
  state: IState,
  action: { payload: any; type: ActionType }
): IState => {
  switch (action.type) {
    case "SET_TAGS":
      return { ...state, selectedTags: action.payload };
    case "TOGGLE_FILTER":
      return { ...state, showFilters: action.payload };
    case "SET_FILTERS":
      return { ...state, filters: action.payload };
    default:
      return state;
  }
};

const initialState: IState = {
  selectedTags: [],
  showFilters: false,
  filters: null,
};

const GamesSection = () => {
  const [state, dispatch] = useReducer(stateReducer, initialState);
  const { data, loading } = useGetAllGamesQuery();

  return loading ? null : (
    <SectionWrapper>
      <Container>
        <FlexWrapper justify="space-between">
          <FlexWrapper>
            <div style={{ display: "none" }}>
              {tags.map((tag) => (
                <TagLabel
                  key={tag}
                  color="secondary"
                  active={state.selectedTags.includes(tag)}
                  onClick={() => {
                    let newTags;
                    if (state.selectedTags.includes(tag)) {
                      newTags = filter(state.selectedTags, (t) => t !== tag);
                    } else {
                      newTags = [...state.selectedTags, tag];
                    }
                    dispatch({ payload: newTags, type: "SET_TAGS" });
                  }}
                >
                  {tag}
                </TagLabel>
              ))}
            </div>
          </FlexWrapper>
        </FlexWrapper>
        <Divider hidden />

        {!loading && (
          <GamesView
            // @ts-ignore
            games={data?.games?.filter(
              (game) => game.game_posters?.length! > 0
            )}
          />
        )}
        <Divider hidden />
      </Container>
    </SectionWrapper>
  );
};

export default GamesSection;
