import React from "react";

import styled from "@emotion/styled";

const MessageWrapper = styled("div")<{ success: boolean }>`
  padding: 0.75rem 1rem;
  font-size: 0.875rem;
  font-weight: 700;
  align-items: center;
  display: flex;
  --text-opacity: 1;
  color: ${(props) => (props.success ? "#2F855A" : "#c53030")};
  background-color: ${(props) => (props.success ? "#F0FFF4" : "#fff5f5")};
  border-radius: 4px;
  border: solid 1px;
  margin-bottom: 1rem;
  svg {
    margin-right: 1rem;
  }
  p {
    padding: 0;
    color: ${(props) => (props.success ? "#2F855A" : "#c53030")};
    // color: rgba(197, 48, 48, var(--text-opacity));
  }
`;

const Message: React.FC<{ message: string; success?: boolean }> = ({
  message,
  success = false,
}) => {
  return (
    <MessageWrapper success={success}>
      <svg viewBox='0 0 20 20' width='16' height='16'>
        <path
          fill='currentColor'
          d='M12.432 0c1.34 0 2.01.912 2.01 1.957 0 1.305-1.164 2.512-2.679 2.512-1.269 0-2.009-.75-1.974-1.99C9.789 1.436 10.67 0 12.432 0zM8.309 20c-1.058 0-1.833-.652-1.093-3.524l1.214-5.092c.211-.814.246-1.141 0-1.141-.317 0-1.689.562-2.502 1.117l-.528-.88c2.572-2.186 5.531-3.467 6.801-3.467 1.057 0 1.233 1.273.705 3.23l-1.391 5.352c-.246.945-.141 1.271.106 1.271.317 0 1.357-.392 2.379-1.207l.6.814C12.098 19.02 9.365 20 8.309 20z'
        />
      </svg>
      <p>{message}</p>
    </MessageWrapper>
  );
};

export default Message;
