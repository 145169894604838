import { useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import Modal from "react-modal";
import { useGetGameQuery } from "@graphql";
import ModalContent from "../../components/modal/Modal";
import GameDetails from "components/game/GameDetails";
import GameReviews from "components/game/GameReviews";
import { GAModalViewEvent } from "lib/google-analytics";
import { Helmet } from "react-helmet";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "950px",
    height: "550px",
    marginRight: "-50%",
    borderRadius: "1rem",

    transform: "translate(-50%, -50%)",
  },
};

const GameView: React.FC<
  RouteComponentProps<{
    gameId: string;
  }>
> = ({ match: { params } }) => {
  const { gameId } = params;
  const [modalIsOpen, setIsOpen] = useState(false);

  const { data, loading } = useGetGameQuery({
    variables: {
      id: gameId,
    },
    fetchPolicy: "cache-first",
  });

  function openModal() {
    GAModalViewEvent(document.location.pathname + "/review");
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  function handleDropdown() {}

  return loading ? (
    <div>...</div>
  ) : (
    <>
      <Helmet>
        <title>{data?.game?.title} - Game | Jiwe IO</title>
        <meta
          name='description'
          content={`${data?.game?.title} a game on Jiwe IO`}
        />
        <meta property='og:site_name' content='Jiwe IO' />
        <meta property='og:type' content='website' />
        <meta
          property='og:title'
          content={`${data?.game?.title} a game on Jiwe IO`}
        />
        <meta
          property='og:description'
          content={`${data?.game?.description}`}
        />
        <meta
          property='og:url'
          content={`https://beta.jiwe.io/games/${data?.game?.id}`}
        />
        <meta
          property='og:image'
          content={`https://beta.jiwe.io/games/${data?.game?.game_posters[0].file.path}`}
        />
        {/* <meta property="og:image:width" content="1280">
    <meta property="og:image:height" content="640"> */}
        <meta
          name='twitter:title'
          content={`${data?.game?.title} a game on Jiwe IO`}
        />
        <meta
          name='twitter:description'
          content={`${data?.game?.description}`}
        />
        <meta
          name='twitter:url'
          content={`https://beta.jiwe.io/games/${data?.game?.id}`}
        />
        <meta name='twitter:site' content='@jiwe_io' />
        <meta name='twitter:creator' content='@jiwe_io' />
        <meta name='twitter:card' content='summary_large_image' />
        <meta
          name='twitter:image'
          content={`https://beta.jiwe.io/games/${data?.game?.game_posters[0].file.path}`}
        />
      </Helmet>
      <GameDetails game={data?.game} />
      <GameReviews
        game={data?.game}
        handleDropDown={handleDropdown}
        openModal={openModal}
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel='Example Modal'
      >
        <ModalContent
          gameId={data?.game!.id}
          gameName={data?.game?.title!}
          closeModal={closeModal}
        />
      </Modal>
    </>
  );
};

export default GameView;
