import useAuth from "pages/auth/useAuth";
import Header from "pages/landing/Header";
import { Helmet } from "react-helmet";
import { FAQWrapper } from ".";

const TermsOfServicePage = () => {
  const { isAuthenticated } = useAuth();

  return (
    <>
      <Helmet>
        <title>Terms of service | Jiwe IO</title>
      </Helmet>

      <Header
        isAuthenticated={isAuthenticated}
        userId={""}
        floated
        isHomeHeader={true}
      />
      <FAQWrapper style={{ margin: "auto", maxWidth: 1280 }}>
        <p className='c3'>
          <span className='c1'>Terms of service:</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            This Terms of Service Agreement (&ldquo;Agreement&rdquo;) is a legal
            document that outlines and explains your rights and obligations as a
            gamer or game developer from Jiwe Interactive.
            (&ldquo;Company&rdquo;). While you are encouraged to read through
            the Agreement carefully, a brief summary is provided above each
            section as a guide.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>Terms of Service:</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>Table of contents</span>
        </p>
        <p className='c3'>
          <span className='c1'>1. Service</span>
        </p>
        <p className='c3'>
          <span className='c1'>2. Users and Developers</span>
        </p>
        <p className='c3'>
          <span className='c1'>3. Acceptable Use</span>
        </p>
        <p className='c3'>
          <span className='c1'>4. Developer Content</span>
        </p>
        <p className='c3'>
          <span className='c1'>5. User Generated Content</span>
        </p>
        <p className='c3'>
          <span className='c1'>6. Digital Millennium Copyright Act</span>
        </p>
        <p className='c3'>
          <span className='c1'>Counter Notice</span>
        </p>
        <p className='c3'>
          <span className='c1'>7. Acceptable Payment Forms</span>
        </p>
        <p className='c3'>
          <span className='c1'>8. Transactions and Fees</span>
        </p>
        <p className='c3'>
          <span className='c1'>9. Refunds</span>
        </p>
        <p className='c3'>
          <span className='c1'>
            10. Unclaimed Earnings In Developer Accounts
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>11. Warranty Disclaimer</span>
        </p>
        <p className='c3'>
          <span className='c1'>12. Limitation of Liability</span>
        </p>
        <p className='c3'>
          <span className='c1'>13. Term and Termination.</span>
        </p>
        <p className='c3'>
          <span className='c1'>14. Governing Law/Jurisdiction</span>
        </p>
        <p className='c3'>
          <span className='c1'>15. Class Action Waiver</span>
        </p>
        <p className='c3'>
          <span className='c1'>16. Indemnity</span>
        </p>
        <p className='c3'>
          <span className='c1'>17. Assignment</span>
        </p>
        <p className='c3'>
          <span className='c1'>18. Miscellaneous</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>1.Service</span>
        </p>
        <p className='c2'>
          <span className='c4'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Jiwe IO is a website and digital software and media distribution
            platform owned and operated by Jiwe Interactive. (&ldquo;Jiwe
            IO&rdquo; or &ldquo;Service&rdquo;). By registering an account and
            using the Service, you agree to be bound by the terms of this
            Agreement.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>2. Users and Developers</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c6'>Users:</span>
          <span className='c1'>
            &nbsp;If you register an account to purchase, download, or play
            games or other content from Jiwe IO, you agree to be bound to the
            terms of this Agreement as a platform user (&ldquo;User&rdquo;).
            Users affirm that they are over the age of 13, as the Service is not
            intended for children under 13.
          </span>
        </p>
        <p className='c3'>
          <span className='c6'>Developers:</span>
          <span className='c1'>
            &nbsp;If you register an account to sell, distribute, or publish
            games or other content on Jiwe IO, you agree to be bound to the
            terms of this Agreement as a platform developer
            (&ldquo;Developer&rdquo;). Developers affirm that they are either
            more than 18 years of age, or possess legal parental or guardian
            consent, and are fully able and competent to enter into the terms,
            conditions, obligations, affirmations, representations and
            warranties set forth in this Agreement.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>3. Acceptable Use</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Jiwe IO aims to create a safe environment for users of the site and
            service. This requires a community that is built on goodwill and
            responsible behavior by its members. The posting of content or other
            actions that, in the Company&rsquo;s sole discretion, degrades the
            experience of others may result in account termination without prior
            notice. Prohibited actions include but are not limited to:
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <ol className='c5 lst-kix_8genmdshvopq-0 start' start={1}>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Uploading viruses or malicious code or acting in any manner to
              restrict or inhibit any other user from using and enjoying the
              Service;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Spamming or sending repeated messages, junk email, contests,
              surveys or any unsolicited messages;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Posting unlawful, misleading, malicious, or discriminatory
              content;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Bullying, intimidating, harassing, defaming, threatening others,
              or violating the legal rights (such as rights of privacy and
              publicity) of others;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Posting content that promotes or participates in racial
              intolerance, sexism, hate crimes, hate speech, or intolerance to
              any group of individuals;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Soliciting, harvesting or collecting information about others;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Violating copyright, trademark or other intellectual property or
              other legal rights of others by posting content without permission
              to distribute such content;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Hacking, maliciously manipulating, or misrepresenting Jiwe
              IO&rsquo;s interface in any way;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Creating a false identity for the purpose or effect of misleading
              others; or
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Violating any applicable laws or regulations.
            </span>
          </li>
        </ol>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>4. Developers Content</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Developers are solely responsible for the content they upload and
            distribute on Jiwe IO. Developers affirm, represent, and warrant
            that they own or have the rights, licenses, permissions and consents
            necessary to publish, duplicate, and distribute the submitted
            content. By submitting content to the Service for distribution,
            Developers also grant a license to the Company for all patent,
            trademark, trade secret, copyright or other proprietary rights in
            and to the Content for publication on the Service, pursuant to this
            Agreement. The Company does not endorse copyright infringing
            activities or other intellectual property infringing activities and
            violations of may result in the removal of content if the Company is
            notified of such violations. Removal and termination of accounts may
            occur without prior notice.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Developers retain all ownership rights to the submitted content, and
            by submitting content to the Service, Developers hereby grant the
            following:
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <ol className='c5 lst-kix_6qdt4823d2g4-0 start' start={1}>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              To the Company, a worldwide, non-exclusive, royalty-free,
              sublicensable and transferable license to use, reproduce,
              distribute, prepare derivative works of, display, and perform the
              content in connection with the Service, including without
              limitation for promoting, redistributing in any and all media
              formats. If you choose to remove your content from the Service,
              this license shall terminate within a commercially reasonable time
              after you remove your content from the Service.
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              To Users, a non-exclusive, perpetual license to access the content
              and to use, reproduce, distribute, display and perform such
              content as permitted through the functionality of the Service.
              Users shall retain a license to this content even after the
              content is removed from the Service.
            </span>
          </li>
        </ol>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>5. User Generated Content</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Jiwe IO provides interfaces and tools for Users to generate content
            and make it available to other users, including ratings, reviews,
            profile images, banners, and others (&ldquo;User Generated
            Content&rdquo; or &ldquo;UGC&rdquo;). By uploading or creating such
            UGC, you grant to Company the worldwide, non-exclusive, perpetual,
            royalty free license to use, reproduce, create derivative works,
            display, perform and distribute for the UGC.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>6. Digital Millennium Copyright Act</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Copyright owners or authorized agents who believe that any content
            on the Service infringes upon their copyrights may submit a
            notification pursuant to the Digital Millennium Copyright Act
            (&ldquo;DMCA&rdquo;) by providing us with the following information
            in writing (for further detail, please see 17 U.S.C. 512&copy;(3)):
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <ol className='c5 lst-kix_klmy60i9q25d-0 start' start={1}>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              A physical or electronic signature of a person authorized to act
              on behalf of the owner of an exclusive right that is allegedly
              infringed.
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Identification of the copyrighted work claimed to have been
              infringed, or, if multiple copyrighted works at a single online
              site are covered by a single notification, a representative list
              of such works at that site.
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Identification of the material that is claimed to be infringing or
              to be the subject of infringing activity and that is to be removed
              or access to which is to be disabled, and information reasonably
              sufficient to permit the service provider to locate the material.
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Information reasonably sufficient to permit the service provider
              to contact the complaining party, such as an address, telephone
              number, and, if available, an electronic mail address at which the
              complaining party may be contacted.
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              A statement that the complaining party has a good faith belief
              that use of the material in the manner complained of is not
              authorized by the copyright owner, its agent, or the law.
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              A statement that the information in the notification is accurate,
              and under penalty of perjury, that the complaining party is
              authorized to act on behalf of the owner of an exclusive right
              that is allegedly infringed
            </span>
          </li>
        </ol>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            DMCA notices may be directed to rock@jiwe.io. You acknowledge that
            failure to comply with the requirements of this section may
            invalidate your DMCA notice.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>Counter Notice</span>
        </p>
        <p className='c3'>
          <span className='c1'>
            If you believe your removed content does not infringe, or if you
            have authorization from the copyright holder, the holder&rsquo;s
            agent, or pursuant to law, you may send a counter-notice containing
            the following information:
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>Your physical or electronic signature:</span>
        </p>
        <ol className='c5 lst-kix_ef9607o2xcik-0 start' start={1}>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Identification of the Content that has been removed or to which
              access has been disabled and the location at which the Content
              appeared before it was removed or disabled;
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              A statement that you have a good faith belief that the Content was
              removed or disabled as a result of mistake or a misidentification
              of the Content; and
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              Your name, address, telephone number, and e-mail address, a
              statement that you consent to the jurisdiction of the court of the
              Republic of Kenya, and a statement that you will accept service of
              process from the person who provided notification of the alleged
              infringement.
            </span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>
              If a counter-notice is received, Company may send a copy to the
              original complaining party informing them the content may be
              replaced or removed in 10 business days. Unless the copyright
              holder files an action seeking a court order against the Developer
              or User, the removed content may be replaced in 10 to 14 business
              days or after receipt of the counter-notice, at Company&rsquo;s
              sole discretion.
            </span>
          </li>
        </ol>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>7. Acceptable Payment Forms</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Developers who distribute content on the Service for a fee may be
            subject to the acceptable use policies of the Company&rsquo;s
            payment providers, Stripe and PayPal. You can review the acceptable
            use policies for our payment providers:
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <ol className='c5 lst-kix_1ob8vtj0o7tg-0 start' start={1}>
          <li className='c0 li-bullet-0'>
            <span className='c1'>Celo</span>
          </li>
          <li className='c0 li-bullet-0'>
            <span className='c1'>Mpesa</span>
          </li>
        </ol>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            When you provide payment information to the Company or one of its
            payment providers, you represent that you are the authorized user of
            the card, PIN, key or account associated with that payment, and you
            authorize the Company to charge your credit card or process your
            payment with the chosen payment provider.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>8. Transactions and Fees</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Developers may set the prices, at their own discretion, for their
            content and products to be sold or in game sales or rewards through
            the Service (each sale, a &ldquo;Transaction&rdquo;). The Company
            shall be entitled to a share of the revenue Developers receive from
            Transactions which shall be calculated on the gross revenue from the
            Transactions, not including any Transactions for which Developer or
            Company provides a refund in accordance with the transaction
            configuration on Developer&rsquo;s account (the &ldquo;Revenue
            Share&rdquo;). Developers may also configure transactions to provide
            for a portion of revenue to be directed towards a selected charity
            under the Developer&rsquo;s transaction configuration. For
            Transactions, Company will collect the purchase price and any
            applicable fees and taxes through its payment providers, and will
            pay to Developer the proceeds net of the applicable Revenue Share,
            payment provider fees, and applicable taxes, VAT, duties, charges or
            levies. The Company may withhold any taxes, duties, charges or
            levies on payments by Company to Developer pursuant to this
            Agreement, as may be required by applicable law, rule or regulation,
            and submit such withheld taxes, duties, charges or levies to the
            appropriate tax authority. Use of IP proxies or other methods to
            disguise your place of residence, whether to circumvent geographic
            restrictions on content, to purchase at a price not applicable to
            your geography, or for any other purpose, is a violation of this
            Agreement.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>9. Refunds</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Users may request, and Company may process, refund requests if the
            purchased content cannot run, some other issue prevents access to
            the content, or the product does not accurately represent what was
            advertised. For technical issues, Company will direct users to the
            Developer to first try to resolve such issues. To request a refund,
            please contact support with your purchase information.{" "}
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>
            10. Unclaimed Earnings In Developer Accounts
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Developer accounts receiving Payouts from the Company are subject to
            a maintenance fee for unclaimed transactions. Payouts from the
            Company are issued when the Company is the Merchant of Record.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Accrued revenue from transactions initiated over twelve (12) months
            ago that has not been claimed for withdrawal may be subject to a
            maintenance fee. Company reserves the right to debit your Developer
            account balance per month:
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Ten percent (10%) of the original revenue earned per purchase older
            than twelve (12) months
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            until your balance reaches zero or you initiate a withdrawal for the
            remainder of the balance. Developer accounts may request to delay
            this fee by contacting support and expressing their intent to claim
            at a determined time in the future.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            In simple English, this means that after 1 year, if you
            haven&rsquo;t initiated a payout for the earnings of a purchase, 10%
            of it will be debited from your account per month. After 10 months
            you will no longer be able to claim any of that purchase.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            The maintenance charge will not cause your account balance to become
            negative and will not cause you to owe money to the Company.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>11. Warranty Disclaimer</span>
        </p>
        <p className='c2'>
          <span className='c4'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            YOU AGREE THAT USE OF THE SERVICE SHALL BE AT YOUR OWN RISK. THE
            COMPANY, ITS OFFICERS, DIRECTORS, EMPLOYEES AND AGENTS
            (&ldquo;AFFILIATES&rdquo;) DISCLAIM ALL WARRANTIES, EXPRESS OR
            IMPLIED, IN CONNECTION WITH THE SERVICES AND THE USE THEREOF TO THE
            FULLEST EXTENT PERMITTED BY LAW. COMPANY MAKES NO WARRANTIES OR
            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS OF THE SERVICE,
            THE SITE&rsquo;S CONTENT, OR THE CONTENT OF ANY DEVELOPER LINKED TO
            THIS SITE AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (A)
            ERRORS, MISTAKES OR INACCURACIES OF CONTENT, (B) PERSONAL INJURY OR
            PROPERTY DAMAGE OF ANY NATURE WHATSOEVER RESULTING FROM YOUR ACCESS
            TO AND USE OF THE SERVICES, &copy; ANY UNAUTHORIZED ACCESS TO OR USE
            OF OUR SERVICES, SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION
            AND/OR FINANCIAL INFORMATION STORED THEREIN, (D) ANY INTERRUPTION OR
            CESSATION OF ACCESS TO OUR SERVICES, (E) ANY BUGS, VIRUSES, TROJAN
            HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH OUR
            SERVICES BY ANY THIRD PARTY, AND/OR (F) ANY ERRORS OR OMISSIONS IN
            ANY CONTENT OR FOR ANY LOSS OR DAMAGE OF ANY KIND INCURRED AS A
            RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED, TRANSMITTED, OR
            OTHERWISE MADE AVAILABLE VIA THE SERVICES. THE COMPANY DOES NOT
            WARRANT, ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY
            PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH
            THE SERVICES OR ANY HYPERLINKED SERVICES OR FEATURED IN ANY BANNER
            OR OTHER ADVERTISING, AND THE COMPANY WILL NOT BE A PARTY TO OR IN
            ANY WAY BE RESPONSIBLE FOR MONITORING ANY TRANSACTION BETWEEN YOU
            AND THIRD-PARTY PROVIDERS OF PRODUCTS OR SERVICES.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>12. Limitation of Liability</span>
        </p>
        <p className='c3'>
          <span className='c1'>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, NEITHER COMPANY
            OR ITS AFFILIATES SHALL BE LIABLE IN ANY WAY FOR YOUR LOSS OR DAMAGE
            OF ANY KIND RESULTING FROM THE USE OR INABILITY TO USE THE SERVICE,
            YOUR ACCOUNT, OR YOUR PURCHASED/SUBMITTED CONTENT, INCLUDING BUT NOT
            LIMITED TO, LOSS OF GOODWILL, WORK STOPPAGE, COMPUTER FAILURE OR
            MALFUNCTION, OR ANY AND ALL OTHER COMMERCIAL DAMAGES OR LOSSES. IN
            NO EVENT WILL THE COMPANY OR ITS AFFILIATES BE LIABLE FOR ANY
            INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, PUNITIVE OR EXEMPLARY
            DAMAGES, OR OTHER DAMAGES ARISING OUT OF OR IN ANY WAY CONNECTED
            WITH JIWE IO, THE SERVICES, OR THE CONTENT, EVEN IN THE EVENT OF THE
            COMPANY&rsquo;S OR ITS AFFILIATES&rsquo; FAULT, TORT (EXCLUDING
            NEGLIGENCE), STRICT LIABILITY, OR BREACH OF COMPANY&rsquo;S WARRANTY
            AND EVEN IF IT HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES.
            THESE LIMITATIONS AND LIABILITY EXCLUSIONS APPLY EVEN IF ANY REMEDY
            FAILS TO PROVIDE ADEQUATE RECOMPENSE. AS SOME STATES OR
            JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR THE LIMITATION OF
            LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, IN SUCH STATES OR
            JURISDICTIONS, EACH OF THE COMPANY AND ITS AFFILIATES&rsquo;
            LIABILITY SHALL BE LIMITED TO THE FULL EXTENT PERMITTED BY LAW.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>13. Term and Termination.</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Term. The term of this Agreement commences on the date you first
            register an account and will continue in effect unless otherwise
            terminated in accordance with this Agreement.
          </span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Termination by Users. Users may terminate their account at any time.
            You may cease to use your account, or if you so choose, may request
            that the Company terminate your access to your account. User
            accounts may not be transferred, sold, or assigned to a third party,
            and such actions may result in account termination. User account
            termination does not entitle the user to a refund.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Termination by Developers. Developers may terminate their account at
            any time. Developers may request the removal of content and final
            payment of outstanding Transaction balances, and the Company will
            comply within a commercially reasonable time. The Company may retain
            a percentage of the outstanding Transaction balance at
            Company&rsquo;s sole discretion in case of refunds, fees, or other
            payment issues.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            Survival. Sections 4, 5, 8, 9, 11, 12, 14, 15, 16, and 18 will
            survive any expiration or termination of this Agreement.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>14. Governing Law/Jurisdiction</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            The internal laws of the Republic of Kenya shall govern this
            Agreement without giving effect to the choice of law rules thereof.
            Each party irrevocably and unconditionally submits to non-exclusive
            jurisdiction of the Republic of Kenya, and the appropriate courts of
            appeal from such courts for any other matter concerning this
            Agreement. You agree that the Service shall be deemed solely based
            in Kenya, is a passive website, and does not give rise to personal
            jurisdiction over Company, either specific or general, in
            jurisdictions other than Kenya.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>15. Class Action Waiver</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            YOU AND COMPANY AGREE NOT TO BRING OR PARTICIPATE IN A CLASS,
            COLLECTIVE, OR REPRESENTATIVE ACTION, PRIVATE ATTORNEY GENERAL
            ACTION OR COLLECTIVE ARBITRATION, EVEN IF ARBITRATION PROCEDURES OR
            RULES WOULD OTHERWISE ALLOW ONE. You and Company also agree not to
            seek to combine any action with any other action without the consent
            of all parties to this Agreement and all other actions.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            If the agreement in this Section not to bring or participate in a
            class, collective or representative action, private attorney general
            action or collective arbitration should be found illegal or
            unenforceable, you and Company agree that it shall not be severable,
            that this entire Section shall be unenforceable and any claim or
            dispute would be resolved in court and not in a class or collective
            action.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>16. Indemnity</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            To the extent permitted by applicable law, you agree to defend,
            indemnify and hold harmless Company and its Affiliates, from and
            against all claims, damages, obligations, losses, liabilities,
            costs, debt, or expenses (including but not limited to
            attorneys&rsquo; fees) arising from: (a) your use and access of the
            service; (b) your violation of any term of this Agreement; &copy;
            your violation of any third party right, including without
            limitation any copyright, trademark, property or privacy right; (d)
            any claim that your submitted content caused damage to a third
            party.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>17. Assignment</span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c1'>
            This Agreement, and any rights and licenses granted hereunder, may
            not be transferred or assigned by you, but may be assigned by
            Company without restriction.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
        <p className='c3'>
          <span className='c4'>18. Miscellaneous</span>
        </p>
        <p className='c3'>
          <span className='c1'>
            These Terms of Service, together with the Privacy Policy and any
            other legal notices published by Company on the Service, shall
            constitute the entire agreement between you and Company concerning
            the Service. If any provision of this Agreement is deemed invalid by
            a court of competent jurisdiction, the invalidity of such provision
            shall not affect the validity of the remaining provisions of these
            Terms of Service, which shall remain in full force and effect. No
            waiver of any term of this Agreement shall be deemed a further or
            continuing waiver of such term or any other term, and
            Company&rsquo;s failure to assert any right or provision under this
            Agreement shall not constitute a waiver of such right or provision.
            Company reserves the right to modify and amend these Terms of
            Service at any time and without notice, and it is your
            responsibility to review these Terms of Service for any changes.
            Your use of the Service following any amendment of these Terms of
            Service will signify your assent to and acceptance of its revised
            terms. Headings and the simplified description of sections are
            provided for convenience and shall not affect the interpretation of
            this Agreement. YOU AND COMPANY AGREE THAT ANY CAUSE OF ACTION
            ARISING OUT OF OR RELATED TO THE SERVICES MUST COMMENCE WITHIN ONE
            (1) YEAR AFTER THE CAUSE OF ACTION ACCRUES. OTHERWISE, SUCH CAUSE OF
            ACTION IS PERMANENTLY BARRED.
          </span>
        </p>
        <p className='c2'>
          <span className='c1'></span>
        </p>
      </FAQWrapper>
    </>
  );
};

export default TermsOfServicePage;
