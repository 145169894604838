import { GroupButton, OutlineButton, RegularButton } from 'components/buttons/button';
import FilesDragAndDrop from 'components/filesDragAndDrop/FilesDragAndDrop';
import { useRef, useState, useEffect } from 'react';
import { ButtonGroup, Buttons, Container,LowerContainer, Description, GameDescription, ImageUpload, Input, Label, MiniRow, Row, Title } from './components';
 

 
export default function Upload() {
  
    const screenshotRef = useRef(null);

    const [fileName, setFileName] = useState('');


    const onScreenshotChange = async (e: any) => {
        const file = e.target.files?.item(0);
        console.log(file)
    }

    const screenshotHandler = async () => {
        // screenshotRef.current.click()
    }

    const onUpload = (files: File | null) => {
        console.log(files);
      };
        
    return (
        <Container>
            <Title>Upload Game</Title>
            <Description style={{marginBottom: '2%'}}>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Blanditiis obcaecati iusto quas minima quis voluptate, accusantium at odit. Minus quis harum debitis dolore necessitatibus iusto impedit quae voluptates optio laudantium.
                Lorem ipsum dolor sit amet consectetur adipisicing
            </Description>
            <Row>
                <div>
                    <Label>Game Title</Label>
                    <Input />
                    <Label>Tag Line</Label>
                    <Input />
                    <Label>Game Tags</Label>
                    <Input />
                    <Label>Game Genre</Label>
                    <Input />
                </div>
                <div>
                    <FilesDragAndDrop
                         onUpload={onUpload}
                    />
                    <Label>Game Trailer</Label>
                    <Input style={{ width: "700px" }} id='mobileremove' />
                    <input ref={screenshotRef} type="file" hidden onChange={onScreenshotChange} />
                    <Label>Game Screenshots</Label>
                    <OutlineButton style={{ border: "2px solid #B12F2F", marginBottom: "3%" }} onClick={screenshotHandler}>Browse Files</OutlineButton>
                    <Label>Upload Game</Label>
                    <OutlineButton style={{ border: "2px solid #B12F2F" }}>Browse Files</OutlineButton>
                </div>
            </Row>
            <LowerContainer>
            <Title>Game Description</Title>
            <GameDescription style={{ marginBottom:"2%"}}>
                Lorem, ipsum dolor sit amet consectetur adipisicing elit. Veniam nulla pariatur ducimus saepe, voluptatibus, culpa dolor itaque est beatae sequi provident sed. Molestiae deleniti necessitatibus repellendus ipsa, ex ad veritatis!
            </GameDescription>

            <Title>Pricing</Title>
            <Description>Lorem ipsum dolor sit amet consectetur adipisicing elit. Nemo animi aut voluptates voluptas cum consectetur, magnam, laborum quas ipsa repellat asperiores consequuntur! Harum optio assumenda tempora ratione reprehenderit ipsa. Magnam.</Description>

            <MiniRow>
                <ButtonGroup style={{ marginRight: "10%" }}>
                    <GroupButton>Paid</GroupButton>
                    <GroupButton>Free</GroupButton>
                    <GroupButton>Donations</GroupButton>
                </ButtonGroup>

                <Label style={{ marginRight: "1%", marginTop: "1%" }}>Price</Label>
                <Input   />
            </MiniRow>
            </LowerContainer>
            <Buttons>
                <OutlineButton style={{ marginRight: "2%" }}>Cancel</OutlineButton>
                <RegularButton>Upload Game</RegularButton>
            </Buttons>
        </Container>
    )
}
