import styled from "@emotion/styled";
import { useGetFeaturedGamesQuery } from "@graphql";
import Container from "./Container";
import FlexWrapper from "./FlexWrapper";

const MainWrapper = styled.main<{ image?: string; "data-srcset"?: any }>`
  background: url(${(props) => props.image}) no-repeat center;
  background-color: #fff;
  background-color: #2d0b30;
  background-size: contain;
  background-size: cover;
`;

const OverlayWrapper = styled.div`
  background: rgba(0, 0, 0, 0.125);
  height: 100%;

  @media (max-width: 720px) {
    height: auto;
    position: absolute;
    top: 66vh;
    width: 100%;
  }

  @media (max-width: 480px) {
    height: auto;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const ContainerCustom = styled(Container)`
  width: 100%;
  padding: 0 1rem;
  font-size: 0.85em;
  height: 100%;
  display: block;
`;

const GameLogoWrapper = styled.div`
  align-items: center;
  display: flex;
  color: #fff;
  flex-direction: column;
  height: 80vh;
  justify-content: center;
  min-height: 450px;
  max-height: 1000px;
  max-width: 500px;
  margin-left: 60px;
  padding-top: 150px;
  position: relative;
  width: 100%;
  transform: translate3d(0, 0, 0);
  z-index: 5;

  // @media (max-width: 1024px) {
  max-width: 80%;
  max-height: 78vh;
  margin-left: auto;
  margin-right: auto;
  // top: -50px;
  // }
`;

const FeaturedGames = () => {
  // const { data, loading } = useGetFeaturedGamesQuery();
  // return loading ? (
  //   <div className="loader__wide">
  //     <>...</>
  //   </div>
  // ) : (
    return (
      <MainWrapper image='/Gaming-Against-Slavery-2.png'>
        <OverlayWrapper>
          <ContainerCustom>
            <FlexWrapper direction='column' align='flex-end'>
              {/* {data?.featured_games[0] && null} */}
            </FlexWrapper>
            <GameLogoWrapper />
          </ContainerCustom>
        </OverlayWrapper>
      </MainWrapper>
    );
  // );
};

export default FeaturedGames;
