/**
 * Event - Add custom tracking event.
 * @param {string} category
 * @param {string} action
 * @param {string} label
 */
import ReactGA from "react-ga";

export const GAEvent = (category: string, action: string, label: string) => {
  ReactGA.event({
    category,
    action,
    label,
  });
};

export const GAModalViewEvent = (location: string) => ReactGA.modalview(location)