import jwtDecode from "jwt-decode";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";

type AccessToken = {
  partyId?: string | null;
  accessToken?: string | null;
  next?: string
};

type AccessTokenPayload = {
  id?: string;
  iat?: string;
  exp?: string;
};

type UseAuthHook = () => {
  isAuthenticated: boolean;
  partyId: string;

  login: (payload: AccessToken) => void;
  logout: () => void;
};

export const storageKey = "neue-ui";

const useAuth: UseAuthHook = () => {
  let history = useHistory();

  const [authState, setAuthState] = useState<
    (AccessTokenPayload & AccessToken) | null
  >(null);

  useEffect(() => {
    if (window) {
      let accessToken = localStorage.getItem(storageKey);

      if (accessToken) {
        const token: AccessToken | null = JSON.parse(accessToken);
        refreshState(token);
      }
    }
  }, []);

  const refreshState = (token: AccessToken | null) => {
    const payload: AccessTokenPayload | null = decryptToken(token?.accessToken);
    setAuthState({
      partyId: token?.partyId || "",
      accessToken: token?.accessToken,
      id: payload?.id,
      iat: payload?.iat,
      exp: payload?.exp,
    });
  };

  const login = (token: AccessToken) => {
    localStorage.setItem(storageKey, JSON.stringify(token));
    refreshState(token);
    window.location.href = token.next || '/';
  };

  const logout = () => {
    localStorage.removeItem(storageKey);
    history.push("/auth/login");
  };

  const decryptToken = (
    accessToken?: string | null
  ): AccessTokenPayload | null => {
    if (accessToken) return jwtDecode(accessToken);

    return null;
  };

  const isAuthenticated = (): boolean => {
    const token = localStorage.getItem(storageKey);

    return Boolean(token);
  };

  return {
    isAuthenticated: isAuthenticated(),
    partyId: authState?.partyId || "",
    logout,
    login,
  };
};

export default useAuth;
