import styled from "@emotion/styled";

export const ProfileContainer = styled.div`
  color: #828282;
  width: 100%;
  display: flex-start;
`;

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-start;
  height: 70px;
  border-bottom: 2px solid #9e9e9e;
  font-size: 30px;
  column-gap: 5rem;
  @media (max-width: 1000px) {
    font-size: 25px;
  }
`;

export const YourProfile = styled.div<{ active: boolean }>`
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: ${(props) => (props.active ? "black" : "inherit")};
  cursor: pointer;
`;

export const Security = styled.div<{ active: boolean }>`
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: ${(props) => (props.active ? "black" : "inherit")};
  cursor: pointer;
`;

export const Payments = styled.div<{ active: boolean }>`
  font-weight: ${(props) => (props.active ? "bold" : "normal")};
  color: ${(props) => (props.active ? "black" : "inherit")};
  cursor: pointer;
`;

export const Line = styled.div`
  width: 100%;
  border-top: 1px solid #e7e7e7;
`;
export const Container = styled.div`
  font-size: 20px;
  width: 100%;
  height: 100%;
  margin-top: 1%;
  // border: 5px solid red;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  background-color: #FFFFF;

  textarea {
    width: 60%;
    border-radius: 1rem;
    height: 10rem;
    padding: 20px;
    font-size: 20px;
    border: 2px solid;
    @media (max-width: 1000px) {
      margin-left: 5%;
    }
  }
`;
export const Details = styled.div`
  margin-top: 1rem;
  margin-bottom: 2%;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  column-gap: 2rem;
  row-gap: 2rem;
  // justify-content: space-between;
  // @media (max-width: 1000px) {
  //   display: flex;
  //   text-align: center;
  //   justify-content: center;
  // }

  input {
    width: 35rem;
    border-radius: 1rem;
    height: 4rem;
    padding: 10px;
    margin-bottom: 2%;
    font-size: 20px;
    border: 2px solid;
  }
`;
export const Label = styled.h4`
  font-weight: 300;
  margin-bottom: 2%;
`;

export const Input = styled.input`
  width: 35rem;
  border-radius: 1rem;
  height: 4rem;
  padding: 10px;
  margin-bottom: 2%;
  font-size: 20px;
  border: 2px solid;
`;

export const Buttons = styled.div`
  width: 100%;
  text-align: left;

  @media (max-width: 1000px) {
    display: flex;
  }
`;

export const Bio = styled.textarea`
  width: 75vw;
  border-radius: 1rem;
  height: 15rem;
  padding: 20px;
  font-size: 20px;
  border: 2px solid;
  @media (max-width: 1000px) {
    margin-left: 5%;
  }
`;
export const DeleteContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10%;
`;
export const DeleteTitle = styled.h2`
  width: 100rem;
  font-weight: bold;
  color: black;
`;

export const DeleteDesc = styled.span`
  width: 100%;
  font-size: 20px;
`;
