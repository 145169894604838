import { useState, useRef } from "react";
import {
  HeaderContainer,
  HeaderToggle,
  Item,
  ItemContainer,
  Logo,
  Mobilebar,
  Wrapper,
} from "./components";
import { SearchBar } from "../forms/form";
import { BellIcon, Close, Menubar } from "../icons/icons";
// import { RedButton } from '../game/components';
import { RegularButton } from "../buttons/button";
import { Link, BrowserRouter as Router } from "react-router-dom";
import useAuth from "pages/auth/useAuth";

function Header() {
  const linkRef = useRef()
  const [sidebar, setSidebar] = useState(false);
  const [filtereddata, setFilteredData] = useState();
  const { logout } = useAuth();

  const showSidebar = () => setSidebar(!sidebar);
  const handleChange = (e: any) => {
    setFilteredData(e.target.value);
  };

  function Button({ children = "" }) {
    return <Link to="/uploadgame"> <RegularButton id='mobileremove'>{children}</RegularButton></Link>;
  }


  return (
    <div>
      <HeaderContainer>
        <nav className={sidebar ? "nav-menu active" : "nav-menu"}>
          <HeaderToggle>
            <Close
              className='fa-solid fa-xmark'
              onClick={() => {
                setSidebar(false);
              }}
            />
          </HeaderToggle>
          <div style={{ marginBottom: "30%" }}>
            <ItemContainer>
              <Item style={{ width: 50 }}>
                <Link to='/games'>
                  <i className='fa-solid fa-gamepad' />
                </Link>
              </Item>
              <Item style={{ marginLeft: 0 }}>
                <a href='/games'>Games</a>
              </Item>
            </ItemContainer>
            {/* <ItemContainer>
              <Item>
                <i className='fa-solid fa-cloud-arrow-down' />
                <p style={{ marginLeft: "30px" }}>Downloads</p>
              </Item>
            </ItemContainer> */}
            <ItemContainer>
              <Item style={{ width: 50 }}>
                <Link to='/profile/wallet'>
                  <i className='fa-solid fa-wallet' />
                </Link>
              </Item>
              <Item style={{ marginLeft: 0 }}>
                <Link to='/profile/wallet'>Wallet</Link>
              </Item>
            </ItemContainer>
            {/* <ItemContainer>
              <Item>
                <i className='fa-solid fa-chart-line' />
                <p style={{ marginLeft: "30px" }}>Statistics</p>
              </Item>
            </ItemContainer> */}
          </div>
          {/* <ItemContainer>
            <Item>
              <i className='fa-solid fa-gear' />
              <p style={{ marginLeft: "30px" }}>Settings</p>
            </Item>
          </ItemContainer> */}
          <ItemContainer onClick={() => logout()}>
            <Item>
              <i className='fa-solid fa-arrow-right-from-bracket' />
              <p style={{ marginLeft: "30px" }}>Logout</p>
            </Item>
          </ItemContainer>
        </nav>
        <Wrapper>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "40%",
              justifyContent: "space-between",
            }}
          >
            <Link to='/'>
              <Logo
                alt='logo'
                src={require("assets/logo/logo-white.png").default}
              />
            </Link>
            <form>
              <SearchBar
                style={{ marginLeft: "5%" }}
                type='search'
                placeholder='Search Games'
                required
              />
            </form>
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginRight: "3%" }}
          >
            {/* <RegularButton id='mobileremove'>Upload a Game</RegularButton> */}
            {/* <BellIcon id='mobileremove' className='fa-solid fa-bell' /> */}
          </div>
          <Mobilebar style={{ marginRight: "3%" }}>
            <Menubar className='fa-solid fa-bars' onClick={showSidebar} />
          </Mobilebar>
        </Wrapper>
      </HeaderContainer>
    </div>
  );
}

export default Header;
