import styled from "@emotion/styled";


export const ModalTitle = styled.div`
    font-size: 30px;
    font-weight: bold;
    text-transform: bold;
    margin-bottom: 1%;
`;

export const Review = styled.div`
    background-color: #FFFFFF;
    border-radius: 1rem;
    width: 90%;
    height: 90px;
    padding: 20px;
    border: 1px solid #D2D2D2;
`;

export const Flex = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    font-size: 15px;
    font-weight: 300;
    color: #1E3948;
    align-items: center;
    justify-content: space-between;
`

export const Line = styled.div`
    width: 100%;
    height: 10px;
    border-top: 1px solid #E7E7E7;
`

export const Detail = styled.div`
    // border: 3px solid red;
    width: 420px;
    padding: 10px;
    background-color: #FFFFFF;
    border-radius: 1rem; 
    margin-top: 2%;

`;

export const Question = styled.div`
    font-size: 15px;
`;

export const Details = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-between;
    // border: 3px solid black;
`;

export const Ratings = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    font-size: 10px;
`
export const Rate = styled.div`
    // border: 5px solid red;
    width: 130px;
`;

export const ModalFooter = styled.div`
    width: 100%;
    text-align: right;
    margin-top: 2%;
`
export const OutlineButton = styled.button`
    padding: 15px;
    color: #7B7B7B;
    background-color: inherit;
    border-radius: 1rem;
    border: 0px;
    font-size: 20px;
    width: 300px;
    height: 60px;
    align-items: center;
    text-align: center;
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
`;