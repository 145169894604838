import styled from '@emotion/styled';


export const Top = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    // border: 5px solid;
    margin-bottom: 3%;
    @media(max-width: 1100px) {
        display: block;
    }
`;


export const Title = styled.h2`
  font-size: 30px;
  font-weight: bold;
  text-transform: capitalize;

  span {
    border-bottom: 1px solid #00000008;
  }
`;

export const Wrapper = styled.div`
  @media (max-width: 1100px) {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
  }

  @media (max-width: 480px) {
    .wallet-wrapper {
      width: 100% !important;

      .wallet-card {
        margin-left: 5% !important;
        width: 90% !important;
        height: unset;
      }

      .balance-container {
        justify-content: center;
        > div {
          justify-content: center;
        }
      }

      .cowrie-balance-container {
        margin-top: .5rem !important;
        margin-left: unset;

        > div {
          justify-content: center;
        }
      }
    }
  }
`;

export const Container = styled.div`
    width: 90%;
    height: 20rem;
    padding: 3px;
    border: 3px solid #CECECE;
    border-radius: 1rem;
    margin-bottom: 1%;
    margin-bottom: 3.5%;
    @media(max-width: 1100px) {
        width:60%;
        margin-left: 20%;
    }
`;

export const Flex = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: black;
    margin-top: 5%;
    margin-bottom: 1%;
    align-items: center;
    font-size: 20px;
`;

export const Tiny = styled.div`
    display: block;
    font-size: 16px;
    // border: 3px solid #E7E7E7;
    color: black;
    // font-weight: bold;
    text-align: right;
`;

export const Medium = styled.div`
    display: block;
    font-size: 30px;
    // border: 3px solid #E7E7E7;
    color: black;
    font-weight: bold;
    text-align: right;
`;

export const Large = styled.div`
    display: block;
    font-size: 70px;
    // border: 3px solid #E7E7E7;
    color: black;
    font-weight: bold;
`;

export const Graph = styled.div`
    width: 50rem;
    height: 25rem;
    padding: 3;
    border: 3px solid #CECECE;
    border-radius: 1rem;
`;

export const Buttons = styled.div`
margin-left: 2%;
@media(max-width: 1100px) {
    margin: 5%;
}
`;