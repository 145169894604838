import styled from "@emotion/styled";

const Container = styled.div`
  width: 90%;
  max-width: 1440px;
  padding: 0 15px;
  margin: 0 auto;
`;

export default Container;
