import { differenceInDays, format, startOfMonth, startOfWeek, startOfYear } from "date-fns";
import { groupBy, isEmpty } from "lodash";
import shortUUID from "short-uuid";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { GetWalletTransactionsQuery } from "@graphql";
import { Title } from "./components";

const short = shortUUID();

type WalletTransactionsTableProps = {
  loadingCeloBalance: boolean;
  walletTransactions: GetWalletTransactionsQuery;
  activePeriod: "thisWeek" | "thisMonth" | "thisYear";
};

function WalletTransactionsTable({
  loadingCeloBalance,
  walletTransactions,
  activePeriod,
}: WalletTransactionsTableProps) {
  const excludeAccounts = [
    "Income:Jiwe",
    "Assets:Jiwe:MPESA",
    // "Test:Liabilities:Jiwe:TransactionFee",
    "Liabilities:Jiwe:TransactionFee",
  ];
  const transactions =
    walletTransactions?.fetchWalletTransactions?.transactions.filter(
      (t: any) => !excludeAccounts.includes(t.account_path)
    );

  const periodMap = {
    thisWeek: startOfWeek(new Date()),
    thisMonth: startOfMonth(new Date()),
    thisYear: startOfYear(new Date()),
  };
  const grouped = groupBy(transactions, "journal_id");
  // @ts-ignore
  let groupedTxns = Object.keys(grouped)
    .map((journalId) => {
      if (grouped && grouped[journalId][0] && grouped[journalId][0].metadata) {
        const { transactionType } = grouped[journalId][0].metadata;
        return grouped[journalId].filter((t) => t[transactionType] > 0);
      }
      return undefined;
    })
    .filter((a) => !isEmpty(a))
    .reduce((acc: any, val: any) => [...acc, ...val], [])
    .filter(
      (entry) =>
        differenceInDays(new Date(entry.entry_date), periodMap[activePeriod]) >=
        0
    );
  return (
    <>
      <Title style={{ marginBottom: "2%" }}>Wallet Transactions [This {activePeriod.replace('this', '')}]</Title>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell align='center'>Transaction ID</TableCell>
              <TableCell align='center'>Amount</TableCell>
              <TableCell align='center'>Description</TableCell>
              <TableCell align='center'>Transaction Timestamp </TableCell>
              <TableCell align='center'>Transaction Type</TableCell>
              <TableCell align='center'>Metadata</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!loadingCeloBalance &&
              !isEmpty(groupedTxns) &&
              groupedTxns?.map((row: any) => (
                <TableRow
                  key={row.transaction_id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell align='center' component='th' scope='row'>
                    {short.fromUUID(row.transaction_id)}
                  </TableCell>
                  <TableCell align='center'>
                    {row.metadata.transactionType === "credit"
                      ? row.credit
                      : row.debit}
                  </TableCell>
                  <TableCell align='center'>{row.transaction_memo}</TableCell>
                  <TableCell align='center'>
                    {format(new Date(row.entry_date), "d MMMM yyyy")}
                  </TableCell>
                  <TableCell align='center'>
                    {row.metadata.transactionType === "debit"
                      ? "wallet deposit"
                      : "wallet deduction"}
                  </TableCell>
                  <TableCell align='center'>
                    {row.metadata.orderId}
                    {row.metadata.gameId && <> [{row.metadata.gameId}]</>}
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default WalletTransactionsTable;
