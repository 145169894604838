import numeral from "numeral";
import { useCelo, Mainnet, Alfajores } from "@celo/react-celo";
import { useHistory, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { Box, LinearProgress } from "@material-ui/core";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import {
  GetWalletTransactionsQuery,
  useGetCeloWalletBalanceQuery,
  useTopUpCowrieMutation,
  useUpdateProfileMetadataMutation,
  MeOutput,
  GetProfileQuery,
  useOffRampCeloMutation,
} from "@graphql";

import {
  DropdownButton,
  RegularButton,
  OutlineButton,
} from "components/buttons/button";
import {
  Title,
  Top,
  Container,
  Flex,
  Medium,
  Large,
  Graph,
  Wrapper,
  Buttons,
} from "./components";

import * as toast from "./toast";
import useQueryParam from "hooks/use-query-param";
import { sendToCelo, topUpWallet } from "./functions";
import WalletTransactionsTable from "./WalletTransactionsTable";
import ConnectWalletInstructions from "./ConnectWalletInstructions";

const jiweWalletAddress = {
  test: "0x09f4826a172cf746c09f44322783f70c09e946d8",
  live: "0xe54f481140870da1da5da1ebaa82647a6d450851",
};

const localStorageKeys = [
  "walletconnect",
  "react-celo/last-used-wallet",
  "react-celo/last-used-network",
  "react-celo/last-used-wallet-arguments",
  "react-celo/last-used-address",
];

export default function JiweWallet({
  celoWalletAddress,
  loggedInUser,
  walletTransactions,
}: {
  celoWalletAddress: string;
  loggedInUser: MeOutput & { profile: GetProfileQuery["profiles_by_pk"] };
  walletTransactions: GetWalletTransactionsQuery;
}) {
  const query = useQueryParam();
  // @ts-ignore
  const defaultWalletMode: "test" | "live" = query.get("walletMode") || "test";

  const [activePeriod, setActivePeriod] = useState<
    "thisWeek" | "thisMonth" | "thisYear"
  >("thisWeek");
  const [walletMode, setWalletMode] = useState<"test" | "live">(
    defaultWalletMode
  );

  const history = useHistory();
  const location = useLocation();
  const onChange = (walletMode: string) => {
    const params = new URLSearchParams({ walletMode });
    history.replace({
      pathname: location.pathname,
      search: params.toString(),
    });
  };

  const { connect, address, destroy, performActions, updateNetwork } =
    useCelo();

  const [topUpJiweWallet] = useTopUpCowrieMutation();
  const [offRamp] = useOffRampCeloMutation();

  const { data: celoWallet, loading: loadingCeloBalance } =
    useGetCeloWalletBalanceQuery({
      variables: { params: { celoWalletAddress, testMode: walletMode === 'test' } },
    });

  const [updateProfileMetadata] = useUpdateProfileMetadataMutation();

  useEffect(() => {
    if (
      localStorage.walletconnect &&
      loggedInUser.profile?.metadata[walletMode] &&
      !loggedInUser.profile?.metadata[walletMode].valoraWalletConfigs
    ) {
      const walletModeMatches =
        localStorage.getItem("walletMode") === walletMode;
      if (walletModeMatches) {
        const valoraWalletConfigs = localStorageKeys
          .map((k) => {
            if (
              k === "walletconnect" ||
              k === "react-celo/last-used-wallet-arguments"
            ) {
              return {
                [k]: JSON.parse(localStorage[k]),
              };
            }
            return { [k]: localStorage[k] };
          })
          .reduce((acc, val) => ({ ...acc, ...val }), {});
        updateProfileMetadata({
          variables: {
            profileId: loggedInUser.profile_id,
            metadata: {
              ...loggedInUser.profile?.metadata,
              [walletMode]: {
                ...loggedInUser.profile?.metadata[walletMode],
                celoWalletAddress: address,
                valoraWalletConfigs,
              },
            },
          },
        })
          .then(console.log)
          .catch(console.error);
      }
    }
  }, [loggedInUser, updateProfileMetadata, address, walletMode]);

  useEffect(() => {
    if (walletMode === "live") {
      // const network = walletMode === "test" ? Alfajores : ;
      updateNetwork(Mainnet);
    } else {
      updateNetwork(Alfajores);
    }

    // Hydrate state
    if (loggedInUser.profile?.metadata[walletMode]) {
      const {
        [walletMode]: { valoraWalletConfigs },
      } = loggedInUser.profile?.metadata;

      if (valoraWalletConfigs) {
        localStorageKeys.forEach((key) => {
          if (
            key === "walletconnect" ||
            key === "react-celo/last-used-wallet-arguments"
          ) {
            localStorage.setItem(key, JSON.stringify(valoraWalletConfigs[key]));
          } else {
            localStorage.setItem(key, valoraWalletConfigs[key]);
          }
        });
      } else {
        localStorageKeys.forEach((key) => localStorage.removeItem(key));
      }

      const walletModeMatches =
        localStorage.getItem("walletMode") === walletMode;
      if (!walletModeMatches) {
        localStorage.setItem("walletMode", walletMode);
        setTimeout(() => {
          document.location.reload();
        }, 300);
      }
    }
  }, [walletMode]);

  if (loadingCeloBalance) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  // <DataGrid
  //   rows={rows}
  //   columns={columns}
  //   pageSize={5}
  //   rowsPerPageOptions={[5]}
  //   checkboxSelection
  // />;

  return (
    <Wrapper>
      {address && (
        <Top>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              columnGap: "0.5rem",
              alignItems: "center",
            }}
          >
            <Title>
              Your Jiwe <span>{walletMode}</span> Wallet
            </Title>
            {/* <Icon className='fa-solid fa-thumbs-up' /> */}
            <SwapHorizIcon
              style={{ opacity: 0.7, cursor: "pointer" }}
              onClick={() => {
                const newMode = walletMode === "test" ? "live" : "test";
                setWalletMode(newMode);
                onChange(newMode);
              }}
            />
            <Title
              style={{ opacity: 0.5, cursor: "pointer" }}
              onClick={() => {
                const newMode = walletMode === "test" ? "live" : "test";
                setWalletMode(newMode);
                onChange(newMode);
              }}
            >
              {walletMode === "test" ? "live" : "test"} Wallet
            </Title>
          </div>
          <DropdownButton
            style={{ width: "200px" }}
            placeholder='This Week'
            value={activePeriod}
            // @ts-ignore
            onChange={(e) => setActivePeriod(e.target.value)}
          >
            <option value='thisWeek'>This Week</option>
            <option value='thisMonth'>This Month</option>
            <option value='thisYear'>This Year</option>
          </DropdownButton>
        </Top>
      )}
      <div
        style={{
          display: "flex",
          marginBottom: "3%",
          gap: "1rem",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            width: address ? "700px" : "unset",
            display: "flex-start",
            flexWrap: "wrap",
          }}
          className="wallet-wrapper"
        >
          {!loadingCeloBalance && address && (
            <Container className="wallet-card">
              <Flex style={{ alignItems: "flex-start" }} className="balance-container">
                <div style={{ marginLeft: "2%" }}>
                  <p>Name</p>
                  <Medium>{loggedInUser?.name}</Medium>
                </div>
                <div style={{ marginRight: "2%" }}>
                  Celo Wallet
                  <Medium>
                    <small>
                      <span>
                        {celoWalletAddress &&
                          `${celoWalletAddress.slice(
                            0,
                            2
                          )}${celoWalletAddress.slice(
                            2,
                            6
                          )}...${celoWalletAddress.slice(-4)}`}
                      </span>
                    </small>
                  </Medium>
                  {celoWalletAddress && (
                    <>
                      <Medium>
                        cUSD{" "}
                        {numeral(
                          parseInt(
                            celoWallet?.getCeloBalance?.cUSDBalance!,
                            10
                          ) / 1e18
                        ).format("0,00.00")}
                      </Medium>
                      <Medium>
                        cEUR{" "}
                        {numeral(
                          parseInt(
                            celoWallet?.getCeloBalance?.cEURBalance!,
                            10
                          ) / 1e18
                        ).format("0,00.00")}
                      </Medium>
                      <Medium>
                        CELO{" "}
                        {numeral(
                          parseInt(
                            celoWallet?.getCeloBalance?.celoBalance!,
                            10
                          ) / 1e18
                        ).format("0,00.00")}
                      </Medium>
                    </>
                  )}
                </div>
              </Flex>

              <Flex style={{ marginLeft: "2%", marginTop: '-7rem' }} className="cowrie-balance-container">
                <p style={{ width: "100%" }}>
                  Cowrie Balance {walletMode === "test" ? "[TEST]" : ""}
                </p>
                <div
                  style={{
                    display: "flex",
                    width: "300px",
                    alignItems: "center",
                  }}
                >
                  <img
                    src='/cowrieshell.png'
                    width='70px'
                    height='80px'
                    alt=''
                  />
                  <Large style={{ marginLeft: "1%" }}>
                    {numeral(
                      walletTransactions?.getWalletBalance?.debitBalance
                    ).format("0,00.00")}
                  </Large>
                </div>
              </Flex>
            </Container>
          )}

          <div
            style={
              !address
                ? {
                    width: "650px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "normal",
                    columnGap: "1rem",
                    rowGap: "1rem",
                  }
                : {
                    // width: "650px",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "normal",
                    columnGap: "1rem",
                    rowGap: "1rem",
                  }
            }
          >
            {!loadingCeloBalance && address && (
              <Buttons>
                <RegularButton
                  style={{
                    width: "200px",
                    marginRight: "2%",
                    marginBottom: "2%",
                  }}
                  onClick={() =>
                    topUpWallet({
                      toast,
                      performActions,
                      jiweWalletAddress,
                      topUpJiweWallet,
                      walletMode,
                      loggedInUser,
                    })
                  }
                >
                  Top up from Celo
                </RegularButton>
                <OutlineButton
                  style={{ width: "210px" }}
                  onClick={() =>
                    sendToCelo({
                      address,
                      toast,
                      offRamp,
                      walletMode,
                      loggedInUser,
                    })
                  }
                >
                  Send to my Celo
                </OutlineButton>
                {/* <OutlineButton style={{ width: "210px" }}>
                  Withdraw to MPESA
                </OutlineButton> */}
                <OutlineButton
                  onClick={async () => {
                    destroy().then(async () =>
                      updateProfileMetadata({
                        variables: {
                          profileId: loggedInUser.profile_id,
                          metadata: {
                            ...loggedInUser.profile?.metadata,
                            test: {
                              walletId:
                                loggedInUser.profile?.metadata.test
                                  .walletId,
                              celoWalletAddress: undefined,
                              valoraWalletConfigs: {},
                            },
                            live: {
                              walletId:
                                loggedInUser.profile?.metadata.live
                                  .walletId,
                              celoWalletAddress: undefined,
                              valoraWalletConfigs: {},
                            },
                          },
                        },
                        onCompleted() {
                          toast.warning("Celo wallet disconnected");
                        },
                      })
                    );
                  }}
                >
                  Disconnect Celo Wallet
                </OutlineButton>
              </Buttons>
            )}
            {!loadingCeloBalance && !address && (
              <>
                <Title
                  style={{ opacity: 0.5, cursor: "pointer", fontSize: 18 }}
                  onClick={() => {
                    const newMode = walletMode === "test" ? "live" : "test";
                    setWalletMode(newMode);
                    onChange(newMode);
                  }}
                >
                  View {walletMode === "test" ? "live" : "test"} Wallet
                </Title>
                <Container>
                  <Flex style={{ alignItems: "flex-start" }}>
                    <div style={{ marginLeft: "2%" }}>
                      <p>Name</p>
                      <Medium>{loggedInUser?.name}</Medium>
                    </div>
                    <div style={{ marginRight: "2%" }}>
                      Celo Wallet
                      <Medium>
                        <small>
                          <span style={{ color: "crimson" }}>Not linked</span>
                        </small>
                      </Medium>
                    </div>
                  </Flex>

                  <Flex style={{ marginLeft: "2%" }}>
                    <p style={{ width: "100%" }}>Cowrie Balance</p>
                    <div
                      style={{
                        display: "flex",
                        width: "300px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src='/cowrieshell.png'
                        width='70px'
                        height='80px'
                        alt=''
                      />
                      <Large style={{ marginLeft: "1%" }}>
                        {numeral(0).format("0,00.00")}
                      </Large>
                    </div>
                  </Flex>
                </Container>
              </>
            )}
            {/* <RegularButton style={{ width: "210px", marginTop: "2%" }}>
              Top up from MPESA
            </RegularButton>
            <OutlineButton style={{ width: "210px", marginTop: "2%" }}>
              Fund a Game
            </OutlineButton> */}
          </div>
        </div>
        {!loadingCeloBalance && !address && (
          <ConnectWalletInstructions
            walletMode={walletMode}
            connect={connect}
          />
        )}
        {/* {
          /*!loadingCeloBalance && !address && (
            <div
              style={{
                display: "block",
                visibility: "hidden",
                marginTop: "-5%",
              }}
            >
              <Title style={{ marginBottom: "5%" }}>Your Expenditure</Title>
              <Graph />
            </div>
          )
        } */}
      </div>
      {address && (
        <WalletTransactionsTable
          walletTransactions={walletTransactions}
          loadingCeloBalance={loadingCeloBalance}
          activePeriod={activePeriod}
        />
      )}
    </Wrapper>
  );
}
