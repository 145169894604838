import MarkdownView from "react-showdown";
import {
  Description,
  DevContainer,
  Developer,
  GameAvatar,
  GameCategory,
  GameContainer,
  GameName,
  Spaced,
} from "../../components/game/components";
import { RegularButton } from "../../components/buttons/button";
import { GetGameQuery } from "@graphql";
import { GAEvent } from "lib/google-analytics";
// @ts-ignore
// import { ReactComponent as CloudDownloadIcon } from "assets/icons/cloud-download.svg";

const GameDetails: React.FC<{ game: GetGameQuery["game"] }> = ({ game }) => {
  return (
    <>
      <GameName>{game?.title}</GameName>
      <GameContainer style={{ marginTop: "2%" }}>
        <div>
          <GameAvatar src={game!.game_posters[0].file.path} alt='gameAvatar' />
        </div>
        <div>
          {game!.game_genres.length > 0 &&
            game!.game_genres.map(({ tag }) => (
              <GameCategory style={{ marginBottom: "5%" }} key={tag.id}>
                #{tag.value}
              </GameCategory>
            ))}

          <Description>
            <MarkdownView
              markdown={game!.description}
              options={{ tables: true, emoji: true }}
            />
          </Description>
          <br />
          <Spaced>
            {game!.game_downloads!.map(({ file }) => (
              <RegularButton
                as='a'
                key={file.id}
                // @ts-ignore
                href={file.path}
                download
                // target="_blank"
                rel='noopener noreferrer'
                onClick={() => {
                  GAEvent(
                    "GAME_DOWNLOAD",
                    "Download link clicked",
                    `${game!.title} - ${file.file_type}`
                  );
                  document.location.href = file.path;
                }}
                style={{ height: "unset" }}
              >
                Download for {file.file_type === "apk" ? "Android" : "PC"}
              </RegularButton>
            ))}
            <br />
          </Spaced>
          <DevContainer>
            <Developer>
              <span>Developer</span>
              <h3>{game?.author?.name}</h3>
            </Developer>
          </DevContainer>
        </div>
      </GameContainer>
    </>
  );
};

export default GameDetails;
