import { useState } from "react";
import { useGetMyAppsQuery } from "@graphql";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import {
  Buttons,
  Container,
  ProfileContainer,
} from "../profile/components";
import ProfileHeader from "components/profile/ProfileHeader";
import { RegularButton } from "components/buttons/button";
import AddNewApp from "./AddNewApp";
import { Box, LinearProgress } from "@mui/material";

export default function Apps() {
  const [modalStatus, openModal] = useState<boolean>(false);

  const { data, loading } = useGetMyAppsQuery({ fetchPolicy: "cache-first" });

  if (loading) {
    return (
      <Box sx={{ width: "100%" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <ProfileContainer>
      <ProfileHeader activeSection='apps' />
      <Container>
        <div style={{ marginBottom: "3%" }}>
          <b>The Jiwe Wallet</b>
          <br />
          To use the Jiwe wallet in your game, you require a few things,
          including creating an app that will manage your API Key and API
          Secret. The keys are added to each game you create to perform wallet
          transactions. <br />
          Read more on wallet transactions here
          <br /> For security purposes, we recommend generating one API key and
          secret per game.
        </div>
        <br />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size='small' aria-label='a dense table'>
            <TableHead>
              <TableRow>
                <TableCell>Game Name</TableCell>
                <TableCell width='150'>Client ID</TableCell>
                <TableCell>Api Key</TableCell>
                <TableCell>Api Secret</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.third_party_apps.map((app) => (
                <TableRow
                  key={app.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component='th' scope='row'>
                    {app.client_name}
                  </TableCell>
                  <TableCell width='150'>{app.id}</TableCell>
                  <TableCell>{app.metadata.client_key}</TableCell>
                  <TableCell>{app.client_secret}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {data?.third_party_apps.length === 0 && <p>No game configs found</p>}
        </TableContainer>
        <Buttons style={{ marginTop: "3%", marginBottom: " 3%" }}>
          <RegularButton onClick={() => openModal(true)}>
            + Add New Game
          </RegularButton>
        </Buttons>
      </Container>
      <AddNewApp isOpen={modalStatus} setIsOpen={openModal} />
    </ProfileContainer>
  );
}
