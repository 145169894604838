import { css } from "@emotion/react";

export const globalStyles = css`
        
    body  {
      background: #143040;
      height: 100%;
      font-family: "Montserrat";
      scroll-behavior:smooth;
      resize:horizontal;

      &.bg-black {
        background-color: #000;
      }
    }
    
    * {
      margin: 0;
      padding: 0;
    }
    .rating{
      color:black;
    }
    .nav-menu {
      background-color: #143040;
      width: 250px;
      height: 100vh;
      display: block;
      justify-content: center;
      position: fixed;
      top: 0;
      left: -100%;
      transition: 850ms;
      color: #FFF;
  }
    
    .nav-menu.active {
        display:none;
        left: 0;
        transition: 350ms;
        @media(max-width: 768px) {
            display: block;
        }
    }
    #mobileremove {
        @media(max-width: 1100px) {
            display: none;
        }
    }
    
    .categoryList {
      color: #797979;
      padding: 7px;
      border-radius: 10px;
      list-display: none;
    }
    #img {  
      background: url('/login-bg.jpg') no-repeat;
      background-size: 100%;
  }
  
  @media(max-width: 768px) {
    transition: 350ms;
      .categoryList {
          margin-right: 20px
      }
  }
  
  .categoryList.active {
      font-weight: 900;
      color: #1A1A1A;
      text-decoration: underline;
  }  
                                     
}      
`;
