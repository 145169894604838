import styled from '@emotion/styled';

export const DragAndDropArea = styled.div`
    width: 700px;
    height: 300px;
    padding: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    font-size: 24px;
    color: #555555;
    border: 2px #c3c3c3 dashed;
    border-radius: 12px;
    margin-bottom: 2%;
    @media(max-width: 768px) {
        width: 25rem;
    }
`;