import { useLoginMutation, useSignUpMutation } from "@graphql";
import useAuth from "pages/auth/useAuth";
import { useState } from "react";
import { Link } from "react-router-dom";
import { DropdownButton, RegularButton } from "../../components/buttons/button";
import {
  Input,
  Label,
  Left,
  Line,
  Login,
  Right,
  SignupContainer,
  SignupTitle,
  Text,
} from "./components";
import * as toast from "../../components/jiwewallet/toast";
import { Helmet } from "react-helmet";

export default function Signup() {
  const [name, setName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [username, setUsername] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [phoneNumber] = useState<string>();
  const [acceptedTOS, setAcceptedTOS] = useState<number>(0);
  const [signupType, setSignupType] = useState<string>("Gamer");
  const [signUpMutation] = useSignUpMutation();
  const [loginMutation] = useLoginMutation();
  const { login } = useAuth();

  const handleSubmit = async () => {
    try {
    if (name && email && username && password) {
        await signUpMutation({
          variables: {
            input: {
              email,
              enableMFA: true,
              name,
              locale: "en",
              password,
              phoneNumber,
              username,
              metadata: JSON.stringify({
                registeredAs: signupType.toLowerCase(),
              }),
              // socialSignupData: JSON.stringify({
              //   tokenId: values.tokenId,
              //   avatar: values.avatar,
              // }),
            },
          },
          onCompleted: async () => {
            await loginMutation({
              variables: {
                input: {
                  usernameOrEmail: email,
                  password: password,
                },
              },
              onCompleted: (res) => {
                if (res?.login?.type === "SUCCESS") {
                  login({
                    partyId: res?.login?.id,
                    accessToken: res?.login?.accessToken,
                  });
                }
              },
              onError: (err) => {
                toast.error(err.message);
              },
            });
          },
        });
      } else {
        toast.error("Name, email or phone number cannot be empty");
      }
    } catch (err) {
      // @ts-ignore
      toast.error(err.message);
    }
  };
  return (
    <SignupContainer>
      <Helmet>
        <title>Signup | Jiwe IO</title>
      </Helmet>

      <Left>
        <Text>Join our community of Gamers and web developers</Text>
      </Left>
      <Right style={{ overflowX: "scroll" }}>
        <SignupTitle>Signup</SignupTitle>
        <Login>
          Already have an account?{" "}
          <Link to='/auth/login' style={{ color: "blue", cursor: "pointer" }}>
            Log in
          </Link>
        </Login>
        <form style={{ marginTop: "2%", justifyContent: "space-between" }}>
          <Label>Name</Label>
          <Input
            onChange={(e) => setName(e.target.value)}
            value={name}
            required
          />
          <Label>Email</Label>
          <Input
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            required
          />
          <Label>Username</Label>
          <Input
            onChange={(e) => setUsername(e.target.value)}
            value={username}
            required
          />
          <Label>Password</Label>
          <Input
            type='password'
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            required
          />
          <Label>Register as</Label>
          <DropdownButton
            onChange={(e) => setSignupType(e.target.value)}
            value={signupType}
          >
            <option value='Gamer'>Gamer</option>
            <option value='Developer'>Developer</option>
          </DropdownButton>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "3%",
            }}
          >
            <input
              type='checkbox'
              name='tos'
              value={acceptedTOS}
              onChange={() => setAcceptedTOS(acceptedTOS === 0 ? 1 : 0)}
            />
            <h2 style={{ fontSize: "15px", fontWeight: "300", marginLeft: 5 }}>
              I agree to{" "}
              <a href='https://jiwe.io/privacy-policy'>terms of service</a>
            </h2>
          </div>
          <div style={{ textAlign: "center" }}>
            <RegularButton
              style={{ width: "100%" }}
              onClick={(e) => {
                try {
                  e.preventDefault();
                  if (name && email && username && password) {
                    handleSubmit();
                  } else {
                    toast.error("Name, email, username and password are all");
                  }
                } catch (err) {
                  console.log(err)
                  // @ts-ignore
                  toast.error(err.message);
                }
              }}
            >
              Create account
            </RegularButton>
            <Line />
            {/* <OutlineButton style={{ width: "100%" }}>
              SIGN UP WITH GOOGLE
            </OutlineButton> */}
          </div>
        </form>
      </Right>
    </SignupContainer>
  );
}
