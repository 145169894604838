import { useContext } from "react";
import isEmpty from "lodash/isEmpty";
import { LoggedInUserContext } from "contexts/user";
import JiweWallet from "components/jiwewallet/JiweWallet";
import NoJiweWallet from "components/jiwewallet/NoJiweWallet";
import { useGetWalletTransactionsQuery } from "@graphql";
import useQueryParam from "hooks/use-query-param";

const WalletPage = () => {
  const query = useQueryParam();
  const walletMode = query.get("walletMode") || "test";
  const loggedInUser = useContext(LoggedInUserContext);
  const { test = {}, live = {} } = loggedInUser?.profile?.metadata || {};
  const { walletId } = walletMode === "test" ? test : live;
  const { data, loading } = useGetWalletTransactionsQuery({
    variables: {
      params: {
        walletId,
        testMode: walletMode.toLowerCase() === 'test'
      },
    },
  });

  if (loading || isEmpty(loggedInUser!.profile)) {
    return null;
  }

  return (
    <>
      {walletId && data ? (
        <JiweWallet
          loggedInUser={loggedInUser!}
          celoWalletAddress={
            walletMode === "test"
              ? test.celoWalletAddress
              : live.celoWalletAddress
          }
          walletTransactions={data}
        />
      ) : (
        <NoJiweWallet
          profileId={loggedInUser!.profile_id}
        />
      )}
    </>
  );
};

export default WalletPage;
