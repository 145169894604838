import React, { useState } from "react";

import styled from "@emotion/styled";

import { useGenerateResetPasswordTokenMutation } from "@graphql";
import { Input } from "pages/login/components";
import { RegularButton } from "components/buttons/button";
import Message from "components/shared/Message";

const EmailWrapper = styled.div`
  max-width: 420px;
  width: 100%;
  margin: 5rem 0 0;
`;

export const FormFieldWrapper = styled("div")<{ compact?: boolean }>`
  width: 100%;
  ${(props) => props.compact && `max-width: 420px`};
  position: relative;
  padding: 0;
  margin-bottom: 0.75rem;
  > div.error {
    color: #fc0408;
  }
  > div.label {
    text-align: left;
    margin-bottom: 0.65em;
    font-size: 0.9em;
    > small {
      font-size: 10px;
      display: block;
      padding: 5px 0;
    }
  }
  > label {
    display: block;
    text-align: left;
    margin-bottom: 10px;
    font-size: 0.9rem;
    font-weight: 700;
    color: ${(props) => props.theme.colors.primary};
  }
`;

const GenerateAuthTokenForm = () => {
  const [username, setUsername] = useState("");
  const [state, setState] = useState<"pending" | "complete">("pending");

  const [generateResetPasswordTokenMutation, { loading }] =
    useGenerateResetPasswordTokenMutation();

  const handleSubmit = () => {
    if (username !== "") {
      generateResetPasswordTokenMutation({
        variables: {
          input: {
            usernameOrEmail: username,
          },
        },
      })
        .catch(console.error)
        .finally(() => {
          setState("complete");
          setUsername("");
        });
    }
  };

  return (
    <EmailWrapper>
      {state === "complete" && (
        <Message success message='Please check your email to for reset instructions' />
      )}
      {state === "pending" && (
      <FormFieldWrapper style={{ display: "flex", gap: "1rem", flexDirection: 'column' }}>
        <Input
          type='text'
          value={username}
          onChange={(e) => setUsername(e.currentTarget.value)}
          name='username'
          placeholder='Enter your email address'
        />
        <RegularButton disabled={loading} onClick={handleSubmit}>
          Reset Password
        </RegularButton>
      </FormFieldWrapper>)}
    </EmailWrapper>
  );
};

export default GenerateAuthTokenForm;
