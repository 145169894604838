import { Redirect, Route, Switch } from "react-router-dom";
import { useGetMeQuery, useGetProfileQuery } from "@graphql";
import { LoggedInUserContext } from "./contexts/user";

import { AppContainer, Main } from "./components";
import Header from "./components/header/Header";
import Sidebar from "./components/sidebar/Sidebar";
import Password from "./components/password/Password";

import Game from "./pages/games/GameView";
import GamesPage from "./pages/games/GamesList";
import Login from "./pages/login/Login";
import Signup from "./pages/signup/Signup";
import JiweWalletPage from "pages/wallet";
import Profile from "./components/profile/Profile";
import Upload from "./components/uploadGame/Upload";
import GameStatistics from "components/gameStatistics/GameStatistics";
import ProtectedRoute from "pages/auth/ProtectedRoute";
import LandingPage from "pages/landing";
import FAQPage from "pages/faq";
import PrivacyPolicyPage from "pages/faq/privacy-policy";
import TermsOfSerivcePage from "pages/faq/terms-of-service";
import Apps from "components/apps/Apps";
import EarlyAccessPage from "pages/landing/EarlyAccess";
import SlugPage from "pages/[slug]/SlugPage";
import ResetPassword from "pages/reset-password/ResetPassword";

const AppRoutes = () => {
  const { data } = useGetMeQuery({
    fetchPolicy: "cache-first",
  });
  const { data: profileData, error: profileError } = useGetProfileQuery({
    variables: { profileId: data?.me?.profile_id },
    fetchPolicy: "cache-first",
  });

  return (
    <LoggedInUserContext.Provider
      value={{ ...data?.me!, profile: profileData?.profiles_by_pk }}
    >
      <AppContainer>
        <Header />
        <div style={{ display: "flex", marginTop: "2%" }}>
          <Sidebar />
          <Main>
            <Switch>
              <Route exact path='/games' component={GamesPage} />
              <Route exact path='/games/:gameId' component={Game} />
              <Route exact path='/profile' component={Profile} />
              <Route exact path='/profile/password' component={Password} />
              <Route exact path='/profile/apps' component={Apps} />
              <Route exact path='/profile/wallet' component={JiweWalletPage} />
              <Route exact path='/uploadgame' component={Upload} />
              <Route exact path='/gamestatistics' component={GameStatistics} />
              <Route exact path='/:slug' component={SlugPage} />
            </Switch>
          </Main>
        </div>
      </AppContainer>
    </LoggedInUserContext.Provider>
  );
};

function App() {
  // const [menuOpen, setMenuOpen] = useState(false);
  return (
    <Switch>
      <Route path='/usoni' exact>
        <Redirect to='/games/61d2a9df-2209-49bd-82c0-6d6b4aa28c1b' />
      </Route>
      <Route path='/auth/login' component={Login} />
      <Route path='/auth/reset-password' component={ResetPassword} />
      <Route path='/signup' component={Signup} />
      <Route exact path='/' component={LandingPage} />
      <Route exact path='/web3-gaming' component={EarlyAccessPage} />
      <Route exact path='/faq' component={FAQPage} />
      <Route exact path='/privacy-policy' component={PrivacyPolicyPage} />
      <Route exact path='/terms-of-service' component={TermsOfSerivcePage} />
      <ProtectedRoute path='/' component={AppRoutes} />
    </Switch>
  );
}

export default App;
