import styled from "@emotion/styled";


export const GameContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5%;

  /* first breakpoint*/
  --w1: 800px;
  --n: 2;
  /* second breakpoint*/
  --w2: 400px;
  --m: 2;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 2rem;
  @media (max-width: 768px) {
    display: flex;
    justify-content: center;
    text-align: center;
  }
`;

export const GameName = styled.h2`
    font-size: 50px;
    font-weight: bold;
    margin: 0px;
    @media(max-width: 1100px) {
        font-size: 25px;
    }
`;
 
export const GameAvatar = styled.img`
    width: 600px;
    // height: 650px;
    @media(max-width: 768px) {
        border-radius: 1rem;
        height: 290px;
        width: 320px;
        // margin-left: 1%;
    }
`;
 

export const Spaced = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    @media(max-width: 1000px) {
        display: flex;
        margin: 10%;
    }
 
`;

export const GameCategory = styled.span`
    font-size: 20px;
    font-weight: 300;
    margin-right: 10px;
    color: #7F7F7F;
`;

export const Developer = styled.div`
    // margin-left: 25%;
    @media(max-width: 1100px) {
        margin-left: 37%;
    }
`;

export const Description = styled.div`
    // border: 4px solid;
    display: flex;
    flex-wrap: wrap;
    @media(max-width: 1000px) {
        width: 400px;
        // margin-left: 25%;
        // padding: 30px;
    }
`;

export const DevContainer = styled.div`
    display: flex;
    margin-top: 5%;
    font-size: 20px;
    @media(max-width: 768px) {
        margin-top: 10%;
    }
`;

export const Flex = styled.div`
    height: 80px;
    display: flex;
    margin-bottom: 3%;
    flex-wrap: wrap;
    align-items: center;
    @media(max-width: 768px) {
        display: flex;
    }
`;
export const LeftFlex = styled.div`
    display: flex;
    width: 180px;
    align-items: center;
    margin-right: 10%;
    justify-content: space-between;
    @media(max-width: 768px) {
        margin-left: 30%;
        margin-top: 3%;
    }
`;

export const Stars = styled.div`
    margin-right: 1%;
    font-size: 20px;
`
export const Rate = styled.h3`
    font-size: 20px;
`;

export const DropdownIcon = styled.img`
    background: transparent;
    height: 20px;
    margin-left: 0%;
`;

export const GameVersion = styled.button`
    color: #7B7B7B;
    background-color: #FFF;
    border-radius: 1rem;
    border: 1px solid;
    font-size: 20px;
    width: 200px;
    height: 40px;
    align-items: center;
    &:hover {
        transform: translateY(-3px);
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
    @media(max-width: 768px) {
        margin-left: 10%
        width: 100px;
        height:40px;
    }
`;


export const ReviewButtons = styled.div`
    // width: 1200px;
    display: flex;
    align-items: center;
    text-align: center;
    height: 70px;
    justify-content: space-between;

    @media(max-width: 768px) {
        margin-left: 10%;
    }
`;
export const CommentBlock = styled.div`
    background-color: #F2EFEF;
    // height: 100%;
    border-radius: 1rem;
    padding: 10px;
    margin-bottom: 3%;
    max-width: 100%;
    @media(max-width: 768px) {
        margin: 10px;
        font-size: 12px;
        display: flex-start;
    }
`;

export const Avatar = styled.img`
    width: 60px;
    height: 60px;
    border-radius: 50%;
    @media(max-width: 768px) {
        margin-left: 0%;
    }
`;

export const Block = styled.div`
    display: block;
    
`;

export const CommentProfile = styled.div`
    font-weight: bold;
    @media(max-width: 768px) {
        // margin-left: 1%;
        width: 100%;
        font-size: 15px;
        font-weight: 300;
    }
    
`;
export const CommentRate = styled.div`
    margin-left: 68%;
    display: flex;
    width: 50px;
    justify-content: space-between;
    @media(max-width: 1000px) {
        display: block;
        margin-top: -20%;
    }
`;

export const CommentActions = styled.div`
    width: 350px;
    margin-top: 1rem;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    text-align: right;
    display: flex;
    justify-content: space-between;
    margin-left: 60%;
    margin-top: 4%;
    @media(max-width: 1000px) {
        margin-left: 0%;
        // width: 50%;
    }
    
`;

export const Action = styled.div`
    width: 60px;
    display: flex;
    margin-right: 2%;
    justify-content: space-between;
    cursor: pointer;
`;
export const Icon = styled.i`
    height: 20px;
`;

export const Comment = styled.span`
    
    display: flex;
    @media(max-width: 768px) {
        width: 23rem;
        margin-left: -20%;
        margin-top: 5%;
   }
`;

export const Center = styled.div`
    color: #72ADF2;
    margin-left: 50%;
    @media(max-width: 768px) {
         margin-left: 10%;
    }
`;

export const Specs = styled.div`
    display: flex;
    font-size: 30px;
    width: 100%;
    margin-top: 3%;
    @media(max-width: 768px) {
        width: 360px;
    }
`;

export const BottomFlex = styled.div`
    display: flex;
    flex-wrap: wrap; 
    margin-top: 3%;
    margin-bottom: 3%;
    width: 100%;
    justify-content: space-between;
    
`;

export const Subtitle = styled.span`
    text-decoration: underline;  
    font-weight: bold; 
    @media(max-width: 768px) {
        margin-top: 20%;
        margin-left: -30%;
    }
`;
export const Ratings = styled.div`
    border-left: 5px solid black; 
    margin-left: 10%;

`;
export const Question = styled.h2`
    // border-left: 5px solid; 
    display: flex;
    Font-size: 20px;
    font-weight: bold;
    margin-left: 1%;
    @media(max-width: 1000px) {
        font-size: 12px;
    }
`;

export const SubTitle = styled.h2`
    font-weight: bold;
    font-size: 20px;
    @media(max-width: 1000px) {
        display: flex;
        font-size: 12px;
        margin-right: 3%;
    }
`;

export const BottomContainer = styled.div`
    display: flex;
    align-items: center;
    @media(max-width: 1000px) {
        margin-left: -20%;
        margin-bottom: 4%;
    }
`;