import React, { useEffect, useState } from "react";
import { chunk } from "lodash";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

import { Games } from "@graphql";

import GameCard from "./GameCard";

const useWidth = () => {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    setWidth(window!.innerWidth);

    window.addEventListener("resize", (event: UIEvent) => {
      // @ts-ignore
      setWidth(event.target!.innerWidth);
    });
    return;
  }, []);

  return [width];
};

const GamesWrapperRow = styled("ul")<{ small?: boolean }>`
  // display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: no-wrap;
  overflow: hidden;
  width: 100%;
  height: ${(props) => (props.small ? "220px" : "260px")};

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-row-gap: 1em;
  // gap: 1.5rem;
  @media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
    grid-template-columns: repeat(3, 1fr);
    // height: auto;
    margin-bottom: 1rem;
  }
  @media (min-width: 481px) and (max-width: 767px) {
    grid-template-columns: repeat(2, 1fr);
    // height: auto;
    margin-bottom: 1rem;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    grid-template-columns: repeat(1, 1fr);
    height: auto;
    margin-bottom: 1rem;
  }
`;

export const GameColumn = styled("li")<{ small?: boolean }>`
  vertical-align: middle;
  list-style: none;
  // width: 25%;
  min-width: ${(props) => (props.small ? "180px" : "250px")};
  height: ${(props) => (props.small ? "180px" : "200px")};
  margin: 0 ${(props) => (props.small ? ".5rem" : "1rem")};
  :first-of-type {
    margin-left: 0;
  }
  :last-of-type {
    margin-right: 0;
  }
  transition-duration: 0.5s;
  overflow: hidden;
  cursor: pointer;
  :hover {
    margin: 0rem 0;
    transition-delay: 0.25s;
    min-width: ${(props) => (props.small ? "350px" : "430px")};
    height: ${(props) => (props.small ? "220px" : "260px")};
  }
  a {
    text-decoration: none;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin-left: 0;
    margin-right: 0;
  }
`;

const GamesView: React.FC<{
  games?: Array<Partial<Games>>;
  small?: boolean;
}> = ({ small, games }) => {
  const [width] = useWidth();
  let columnsWidth = 4;

  if (width > 768 && width < 1024) {
    columnsWidth = 3;
  }

  if (width > 480 && width < 767) {
    columnsWidth = 2;
  }

  return (
    <div>
      {chunk(games, columnsWidth).map((row, key) => (
        <GamesWrapperRow key={key.toString()} small={small}>
          {row.map((game) => (
            <GameColumn key={game.title} small={small}>
              <NavLink to={`/games/${game.id}`}>
                <GameCard game={game} />
              </NavLink>
            </GameColumn>
          ))}
        </GamesWrapperRow>
      ))}
    </div>
  );
};

export default GamesView;
