import styled from "@emotion/styled";

export const AppContainer = styled.div`
    height: 100%;
    width: 100%;
    overflow: hidden;
    // border: 5px solid red;
    display:flex-start;
    transition: 500ms;
`;

export const Sections = styled.div`
    margin-top: 2%;
    margin-bottom: 5%;
    display: flex;
    font-family: "Montserrat";
    @media(max-width: 768px) {
        text-align: center;
    }
`;

export const Main = styled.div`
    background-color: #FFF;
    // width: 100%;
    width: calc(83.3% - 4rem);
    padding: 1%;
    border-radius: 1rem;
    margin-right: 4rem;
    display: flex-start;
    transition: 800ms;
    @media(max-width: 1100px) {
        text-align: center;
        margin:0rem;
        width: 100%;
        padding: 2%;
        transition: 800ms;
        align-items: center;
    }
`;
