import { Field, Form, Formik, FormikHelpers } from "formik";
import styled from "@emotion/styled";
import Modal from "react-modal";
import { OutlineButton, RegularButton } from "components/buttons/button";
import { Details, Label } from "components/profile/components";
import { GameEngine, ThirdPartyAppType, useAddThirdPartyAppMutation } from "@graphql";
import * as toast from "../jiwewallet/toast";

const ContentWrapper = styled.div`
  padding: 3rem 0;
  width: 90%;
  margin: auto;

  h1 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  button {
    display: block;
    margin: 1rem auto;
    cursor: pointer;
  }
`;

const FormFields = styled(Details)`
  width: 100%;

  input {
    width: 100%;
    min-width: 350px;
  }

  select {
    padding: 15px;
    color: #7b7b7b;
    background-color: #fff;
    border-radius: 1rem;
    border: 2px solid;
    font-size: 20px;
    width: 100%;
    height: 60px;
    align-items: center;
    text-align: center;
    text-decoration: none;
    &:hover {
      transform: translateY(-3px);
      transition: 1s;
      box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    }
  }
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    borderRadius: "1rem",
    transform: "translate(-50%, -50%)",
  },
};

const AddNewApp: React.FC<{ isOpen: boolean; setIsOpen: Function }> = ({
  isOpen,
  setIsOpen,
}) => {
  const [addThirdPartyApp] = useAddThirdPartyAppMutation();
  const onSubmit = async (values: any, actions: FormikHelpers<{}>) => {
    const port = 51772
    await addThirdPartyApp({
      variables: {
        params: {
          clientName: values.clientName,
          contacts: values.contacts.split(","),
          applicationType: ThirdPartyAppType.Native,
          // @ts-ignore
          scope: "email,profile,openid,rewards,in-app-purchases",
          clientUri: values.website || "http://localhost/",
          // @ts-ignore
          grantTypes: "implicit,authorization_code",
          redirectUri: [`http://127.0.0.1:${port}`, "http://localhost"],
          gameEngine: values.gameEngine === 'unity' ? GameEngine.Unity : GameEngine.Unreal,
        },
      },
      onCompleted() {
        toast.success("App added successfully");
        setIsOpen(false);
      },
      onError(err) {
        toast.error(err.message);
      },
      refetchQueries: ["GetMyApps"],
    });
    actions.setSubmitting(false);
  };
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <ContentWrapper style={{ padding: "1rem 0", width: "unset" }}>
        <h1>Setup your game</h1>
        <Formik onSubmit={onSubmit} initialValues={{}}>
          {({ handleSubmit, isSubmitting }) => (
            <Form onSubmit={handleSubmit}>
              <FormFields>
                <div>
                  <Label>Game name</Label>
                  <Field compact name='clientName' type='text' required />
                  <Label>Contact email</Label>
                  <Field compact name='contacts' type='email' required />
                  <Label>Game website</Label>
                  <Field compact name='website' type='text' />
                  <Label>Game engine used</Label>
                  <Field as='select' name='gameEngine' required>
                    <option value='unity'>Unity</option>
                    <option value='unreal'>Unreal</option>
                  </Field>
                </div>
              </FormFields>
              <RegularButton disabled={isSubmitting} type='submit'>
                Submit
              </RegularButton>
              <OutlineButton
                disabled={isSubmitting}
                type='button'
                onClick={() => setIsOpen(false)}
              >
                Cancel
              </OutlineButton>
            </Form>
          )}
        </Formik>
      </ContentWrapper>
    </Modal>
  );
};

export default AddNewApp;
