import { useFormik } from "formik";
import CircleChecked from "@material-ui/icons/CheckCircleOutline";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import { Radio } from "@mui/material";
import Rating from "@mui/material/Rating";
import { RegularButton } from "../buttons/button";
import { Textarea } from "pages/login/components";
import {
  Details,
  Flex,
  Line,
  ModalTitle,
  Question,
  Detail,
  Ratings,
  Rate,
  ModalFooter,
  OutlineButton,
} from "./components";
import { useCreateGameRatingMutation, GetGameRatingsQueryDocument } from "@graphql";
import { GAEvent } from "lib/google-analytics";

const initialFormState = {
  game_art_style_rating: "",
  story_context_rating: "",
  music_sfx_rating: "",
  game_mechanic_rating: "",
  overall_rating: "0",
  comment: "",
};

const handleSubmit: (
  createGameRatingMutation: Function,
  gameId: string,
  closeModal: Function,
  gameName: string,
) => (values: any, helpers: any) => Promise<void> =
  (createGameRatingMutation, gameId, closeModal, gameName) =>
    async (values, helpers) => {
      const result = await createGameRatingMutation({
        variables: {
          input: {
            game_id: gameId,
            rating: values.overall_rating,
            additional_ratings: {
              game_art_style_rating: values.game_art_style_rating,
              game_mechanic_rating: values.game_mechanic_rating,
              story_context_rating: values.story_context_rating,
              music_sfx_rating: values.music_sfx_rating,
            },
            comment: {
              data: {
                content: values.comment,
                target_id: gameId,
              },
            },
          },
        },
        onCompleted() {
          GAEvent(
            "GAME_REVIEW",
            "Review posted",
            `${gameName} - /games/${gameId}`
          );
        }
      });
      if (result.data) {
        closeModal();
        helpers.resetForm();
      }
    };

export default function ModalContent({
  gameName,
  gameId,
  closeModal,
}: {
  gameName: string;
  gameId: string;
  closeModal: Function;
}) {
  const [createGameRatingMutation] = useCreateGameRatingMutation({
    refetchQueries: [
      { query: GetGameRatingsQueryDocument },
      "GetGameRatingsQuery", // Query name
    ],
  });
  const formik = useFormik({
    initialValues: initialFormState,
    onSubmit: handleSubmit(
      createGameRatingMutation,
      gameId,
      closeModal,
      gameName
    ),
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalTitle>{gameName} Rating and Review</ModalTitle>
      <Textarea
        name='comment'
        value={formik.values.comment}
        onChange={formik.handleChange}
      />
      <Flex>
        <h3>How will you rate the game?</h3>
        <Rating
          color='#143040'
          className='rating'
          name='overall_rating'
          value={parseInt(formik.values.overall_rating, 10)}
          onChange={formik.handleChange}
          style={{ fontSize: 50 }}
        />
      </Flex>
      <Line></Line>
      <Flex>
        <h3>Any more details on the game? (Optional)</h3>
      </Flex>
      <Details>
        <Detail>
          <Question>How was the game art style?</Question>
          <Ratings>
            <Rate>
              <Radio
                color='error'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='Beautiful'
                checked={formik.values.game_art_style_rating === "Beautiful"}
                onChange={formik.handleChange}
                name='game_art_style_rating'
              />
              Beautiful
            </Rate>
            <Rate>
              <Radio
                color='error'
                name='game_art_style_rating'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='Decent'
                checked={formik.values.game_art_style_rating === "Decent"}
                onChange={formik.handleChange}
              />
              Decent
            </Rate>
            <Rate>
              <Radio
                color='error'
                name='game_art_style_rating'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='Below Average'
                checked={
                  formik.values.game_art_style_rating === "Below Average"
                }
                onChange={formik.handleChange}
              />
              Below Average
            </Rate>
          </Ratings>
        </Detail>
        <Detail>
          <Question>How was the game mechanic?</Question>
          <Ratings>
            <Rate>
              <Radio
                color='error'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='beautiful'
                checked={formik.values.game_mechanic_rating === "beautiful"}
                onChange={formik.handleChange}
                name='game_mechanic_rating'
              />
              Beautiful
            </Rate>
            <Rate>
              <Radio
                color='error'
                name='game_mechanic_rating'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='decent'
                checked={formik.values.game_mechanic_rating === "decent"}
                onChange={formik.handleChange}
              />
              Decent
            </Rate>
            <Rate>
              <Radio
                color='error'
                name='game_mechanic_rating'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='below_average'
                checked={formik.values.game_mechanic_rating === "below_average"}
                onChange={formik.handleChange}
              />
              Below Average
            </Rate>
          </Ratings>
        </Detail>
        <Detail>
          <Question>How was the story or context?</Question>
          <Ratings>
            <Rate>
              <Radio
                color='error'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='beautiful'
                checked={formik.values.story_context_rating === "beautiful"}
                onChange={formik.handleChange}
                name='story_context_rating'
              />
              Beautiful
            </Rate>
            <Rate>
              <Radio
                color='error'
                name='story_context_rating'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='decent'
                checked={formik.values.story_context_rating === "decent"}
                onChange={formik.handleChange}
              />
              Decent
            </Rate>
            <Rate>
              <Radio
                color='error'
                name='story_context_rating'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='below_average'
                checked={formik.values.story_context_rating === "below_average"}
                onChange={formik.handleChange}
              />
              Below Average
            </Rate>
          </Ratings>
        </Detail>
        <Detail>
          <Question>How was the music and sound effects?</Question>
          <Ratings>
            <Rate>
              <Radio
                color='error'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='beautiful'
                checked={formik.values.music_sfx_rating === "beautiful"}
                onChange={formik.handleChange}
                name='music_sfx_rating'
              />
              Beautiful
            </Rate>
            <Rate>
              <Radio
                color='error'
                name='music_sfx_rating'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='decent'
                checked={formik.values.music_sfx_rating === "decent"}
                onChange={formik.handleChange}
              />
              Decent
            </Rate>
            <Rate>
              <Radio
                color='error'
                name='music_sfx_rating'
                icon={<CircleUnchecked />}
                checkedIcon={<CircleChecked />}
                value='below_average'
                checked={formik.values.music_sfx_rating === "below_average"}
                onChange={formik.handleChange}
              />
              Below Average
            </Rate>
          </Ratings>
        </Detail>
      </Details>
      <ModalFooter>
        <OutlineButton onClick={() => closeModal()} type='button'>
          Cancel
        </OutlineButton>{' '}
        <RegularButton>Finish</RegularButton>
      </ModalFooter>
    </form>
  );
}
