import styled from "@emotion/styled";

export const CategorylistContainer = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  cursor: pointer;
`;
export const _CategorylistContainer = styled.ul`
  display: grid;
  list-style: none;
  text-align: center;
  font-size: 15px;
  /* first breakpoint*/
  --w1: 80px;
  --n: 10;
  /* second breakpoint*/
  --w2: 400px;
  --m: 2;

  grid-template-columns: repeat(
    auto-fill,
    minmax(
      clamp(
        clamp(
          100%/ (var(--n) + 1) + 0.1%,
          (var(--w1) - 100vw) * 1000,
          100%/ (var(--m) + 1) + 0.1%
        ),
        (var(--w2) - 100vw) * 1000,
        100%
      ),
      1fr
    )
  );
  gap: 10px;

  @media (max-width: 1000px) {
    /* first breakpoint*/
    --w1: 80px;
    --n: 5;
    /* second breakpoint*/
    --w2: 400px;
    --m: 5;

    grid-template-columns: repeat(
      auto-fill,
      minmax(
        clamp(
          clamp(
            100%/ (var(--n) + 1) + 0.1%,
            (var(--w1) - 100vw) * 1000,
            100%/ (var(--m) + 1) + 0.1%
          ),
          (var(--w2) - 100vw) * 1000,
          100%
        ),
        1fr
      )
    );
    gap: 10px;
    --w2:400px;
    --m:5;
  
    grid-template-columns:
      repeat(auto-fill,
        minmax(clamp(clamp(
            100%/(var(--n) + 1) + 0.1%, 
          (var(--w1) - 100vw)*1000,
            100%/(var(--m) + 1) + 0.1%), 
          (var(--w2) - 100vw)*1000,
            100%), 1fr));
    gap:10px;
  }
`;

export const GamesContainer = styled.div`
  margin-right: 5%;
  margin-bottom: 5%;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  min-width: 400px;
  @media(max-width: 1000px) {
    transition: 200ms;
    margin:0px;
    width: 100%;
    justify-content: center;
  }
`;
export const GameDetail = styled.div`
  background-color: #f7f7f7;
  width: 25rem;
  height: 25vw;
  // box-shadow: 0 0 5rem rgba(0,0,0,1);
  cursor: pointer;
  margin-top: 5%;

  a {
    text-decoration: none;
    &:hover {
      opacity: 0.8;
    }
  }
  @media (max-width: 1000px) {
    width: 25rem;
    height: 90vw;
  }
`;
export const Image = styled.img`
  margin: 0px;
  width: 100%;
  height: 70%;
`;
export const GameName = styled.h3`
  color: black;
  font-size: 20px;
  margin: 30px;
  font-family: "Montserrat";
`;

export const GameCategory = styled.span`
  color: #747474;
  font-size: 20px;
  margin: 30px;
  width: 100%;
`;
