import React, { createRef, RefObject, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled";

import { ReactComponent as CloseMenuIcon } from "assets/icons/x.svg";
import { ReactComponent as MenuIcon } from "assets/icons/menu-alt-2.svg";
import AuthProfilePill from "./AuthProfilePill";
import { useGetMeQuery, useGetProfileQuery } from "@graphql";


const useWidth = () => {
  const [width, setWidth] = useState<number>(0);

  useEffect(() => {
    setWidth(window!.innerWidth);

    window.addEventListener("resize", (event: UIEvent) => {
      // @ts-ignore
      setWidth(event.target!.innerWidth);
    });
    return;
  }, []);

  return [width];
};

const useMobileMenuToggle = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return {
    isOpen,
    toggle,
    open: () => setIsOpen(true),
    close: () => setIsOpen(false),
  };
};

const HeaderWrapper = styled("header")<{
  floated?: boolean;
  isHomeHeader?: boolean;
}>`
  padding: 0.5em 0;
  background: ${(props) => (props.isHomeHeader ? "#000" : "transparent")};
  border-bottom: solid 1px #e2e8f0;
  ${(props) =>
    props.floated &&
    `
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 10;
    border-bottom: none;
  `}
  nav {
    position: relative;
    padding: 0 16px;
    max-width: 1280px;
    margin: 0 auto;
  }
`;

export const ContainerWrapper = styled("div")`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding: 0;
  max-width: 1440px;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

const NavItems = styled("div")<{ isOpen: boolean; light?: boolean }>`
  align-items: center;
  display: flex;
  transition: all 0.2s ease 0s;

  @media (max-width: 480px) {
    position: absolute;
    display: block;
    width: calc(100% - 30px);
    top: 100%;
    right: 15px;
    background: ${(props) => props.theme.colors.primary};
    border-radius: 4px;
    opacity: ${(props) => (props.isOpen ? "1" : "0")};
    transform: translateY(${(props) => (props.isOpen ? "0" : "-185%")});
    z-index: 11;
  }
  > a {
    color: ${(props) =>
      props.light ? props.theme.colors.dark : props.theme.colors.light};
  }
`;

const NavItem = styled(NavLink)<{ color?: VariantType }>`
  color: ${(props) =>
    props.color ? props.theme.colors[props.color] : props.theme.colors.dark};
  background: ${(props) =>
    props.color ? props.theme.colors.dark : "transparent"};
  :hover,
  &.active {
    color: #ae2424;
  }
  text-decoration: none;
  padding: 9px 15px;
  font-size: 14px;
  border-radius: 3px;
  font-weight: 700;
  margin-right: 1.5rem;
  :last-of-type {
    margin-right: 0;
    color: ${(props) => props.theme.colors.light};
  }
  @media (max-width: 480px) {
    display: block;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    :first-of-type {
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
    }
    :last-of-type {
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    padding: 15px;
    &.active {
      color: ${(props) => props.theme.colors.greyish};
      background: ${(props) => props.theme.colors.dark};
      font-weight: bolder;
    }
  }
`;

const MobileNavToggle = styled("button")`
  @media (min-width: 481px) {
    display: none;
  }
  background: ${(props) => props.theme.colors.primary};
  color: ${(props) => props.theme.colors.white};
  padding: 6px;
  border-radius: 4px;
  svg {
    vertical-align: middle;
  }
`;

const Logo = styled("img")<{ height?: number }>`
  height: ${(props) => props.height || "50"}px;
  @media (max-width: 480px) {
    height: 40px;
  } 
`;

const Header: React.FC<{
  isAuthenticated: boolean;
  userId?: string;
  floated?: boolean;
  isHomeHeader?: boolean;
  light?: boolean;
}> = ({ userId, isAuthenticated, floated, isHomeHeader, light }) => {
  const { data, error } = useGetMeQuery({
    fetchPolicy: "cache-first",
  });
  const { isOpen, toggle, open, close } = useMobileMenuToggle();
  const [width] = useWidth();

  const node: RefObject<HTMLDivElement> = createRef();

  useEffect(() => {
    const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
      // @ts-ignore
      if (node.current && node.current.contains(e.target)) {
        open();
      } else {
        close();
      }
    };
    // @ts-ignore
    if (width > 480) document.addEventListener("mousedown", handleClick, false);
    return () => {
      if (width > 480)
        // @ts-ignore
        document.removeEventListener("mousedown", handleClick, false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <HeaderWrapper floated={floated} isHomeHeader={isHomeHeader}>
      <nav>
        <ContainerWrapper>
          <NavLink to='/'>
            {isHomeHeader ? (
              <Logo src={require("assets/logo/logo-white.png").default} />
            ) : (
              <Logo src={require("assets/logo/logo-black.png").default} />
            )}
          </NavLink>
          <Logo
            src={
              require("assets/images/africa-comicade-gamathon-2021/AfricaComicadeLogo.png")
                .default
            }
            height={50}
          />
          <Logo
            src={
              require("assets/images/africa-comicade-gamathon-2021/LudiqueworksLogo.png")
                .default
            }
            height={32}
          />
          <Logo
            src={
              require("assets/images/africa-comicade-gamathon-2021/ADMI_White_logo-01.png")
                .default
            }
            height={50}
          />

          <NavItems light={light} isOpen={isOpen} ref={node}>
            <NavItem to='/games'>Browse Games</NavItem>
            {/* {isAuthenticated && !error && data?.me?.id ? (
              <AuthProfilePill profileId={data?.me?.profile_id!} />
            ) : (
              <>
                <NavItem to='/auth/login'>Login</NavItem>
                <NavItem color='primary' to='/signup'>
                  Sign Up
                </NavItem>
              </>
            )} */}
          </NavItems>
          {/* <MobileNavToggle onClick={toggle}>
            {isOpen ? <CloseMenuIcon /> : <MenuIcon />}
          </MobileNavToggle> */}
        </ContainerWrapper>
      </nav>
    </HeaderWrapper>
  );
};

export default Header;
