import { useState } from "react";
import { Link, RouteComponentProps } from "react-router-dom";
import { Helmet } from "react-helmet";
import {
  useGetGamesByGenreLazyQuery,
  useGetGamesByGenreQuery,
  useGetPageQuery,
} from "@graphql";
import {
  CategorylistContainer,
  GameCategory,
  GameDetail,
  GameName,
  GamesContainer,
  Image,
} from "../../components/gameslist/components";
import CategoryList from "../../components/CategoryList/CategoryList";
import styled from "@emotion/styled";
import MarkdownView from "react-showdown";
import useAuth from "pages/auth/useAuth";
import { AppContainer, Main } from "components";
import Header from "components/header/Header";
import Sidebar from "components/sidebar/Sidebar";

const Poster = styled.img`
  margin: 0px;
  width: 100%;
  height: auto;
`;

const GenreGamesComponent = ({ genreId }: { genreId: string }) => {
  const { data, loading, error } = useGetGamesByGenreQuery({
    fetchPolicy: "cache-first",
    variables: { genreId },
  });

  if (loading || error) {
    return null;
  }

  const games = data?.game_genres
    .map(({ game }) => game)
    .filter((game) => game.game_posters?.length > 0);

  return (
    <GamesContainer>
      {games &&
        games.map((game: any) => (
          <GameDetail key={game.id}>
            <Link to={`/games/${game.id}`}>
              <Image src={game.game_posters[0].file.path} />
              <div style={{ marginBottom: "40px" }}>
                <GameName>{game.title}</GameName>
                <GameCategory>
                  {game.game_genres.length > 0 &&
                    `#${game.game_genres
                      .map(({ tag }: any) => tag.value)
                      .join(" #")}`}
                </GameCategory>
              </div>
            </Link>
          </GameDetail>
        ))}
    </GamesContainer>
  );
};

export default function SlugPage({
  match: { params },
}: RouteComponentProps<{ slug: string }>) {
  const { logout } = useAuth();
  const { slug } = params;
  const [selected, setSelected] = useState("description");

  const { data, loading, error } = useGetPageQuery({
    fetchPolicy: "cache-first",
    variables: { slug },
  });

  if (loading) {
    return <div>...</div>;
  }

  if (error) {
    console.log(error.message);
    if (error?.message === "Authentication hook unauthorized this request") {
      logout();
    }
    return null;
    document.location = "/games";
  }

  const page = data?.pages[0];

  const tabs: any = page?.metadata.tabs.map((tab: any) => ({
    id: tab.title,
    title: tab.title,
    type: tab.type,
    data: tab.data,
  }));

  const tabsMap = tabs
    .map((tab: any) => ({ [tab.id]: tab }))
    .reduce((acc: any, val: any) => ({ ...acc, ...val }));

  console.log(tabsMap);

  return (
    <>
      <Helmet>
        <title>{page!.title} | Jiwe IO</title>
      </Helmet>

      {page?.poster && <Poster src={page?.poster} />}
      <CategorylistContainer
        style={{
          borderBottom: " 3px solid #EFEFEF",
          padding: "1rem 0",
        }}
      >
        {[{ id: "description", title: "Description" }, ...tabs].map((item) => (
          <CategoryList
            title={item.title!}
            active={selected === item.id}
            setSelected={setSelected}
            id={item.id}
            key={item.id}
          />
        ))}
      </CategorylistContainer>
      <div
        style={{
          paddingLeft: 7,
          textAlign: "left",
          marginTop: "2rem",
          minHeight: 350,
        }}
      >
        {selected === "description" && (
          <MarkdownView
            markdown={page!.description || ""}
            options={{ tables: true, emoji: true }}
          />
        )}
        {selected !== "description" &&
          tabsMap[selected] &&
          selected.toLowerCase() === "games" && (
            <GenreGamesComponent genreId={tabsMap[selected].data.genreId} />
          )}
      </div>
    </>
  );
}
