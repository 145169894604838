import { useHistory } from "react-router-dom";
import { Payments, Security, Title, YourProfile } from "./components";

const ProfileHeader = ({
  activeSection = 'profile',
}: {
  activeSection?: "profile" | "password" | "apps" | "wallet";
}) => {
  let history = useHistory();

  return (
    <Title>
      <YourProfile
        active={activeSection === "profile"}
        onClick={() => history.push("/profile")}
      >
        <i className='fa-solid fa-user' /> Your Profile
      </YourProfile>
      <Security
        active={activeSection === "password"}
        onClick={() => history.push("/profile/password")}
      >
        <i className='fa-solid fa-user-lock'></i>Password
      </Security>
      <Payments
        active={activeSection === "apps"}
        onClick={() => history.push("/profile/apps")}
      >
        <i className='fa-solid fa-building-lock' />
        Apps
      </Payments>
      <Payments
        active={activeSection === "wallet"}
        onClick={() => history.push("/profile/wallet")}
      >
        <i className='fa-solid fa-wallet' />
        Wallet
      </Payments>
    </Title>
  );
};

export default ProfileHeader