import { GetGameQuery, useGetGameRatingsQueryQuery } from "@graphql";
import {
  Avatar,
  Flex,
  Rate,
  CommentBlock,
  Block,
  CommentActions,
  Icon,
  Comment,
  Action,
  CommentProfile,
  LeftFlex,
  ReviewButtons,
  Subtitle,
  Ratings,
  Question,
  SubTitle,
  BottomContainer,
} from "../../components/game/components";
import Rating from "@material-ui/lab/Rating";
import { OutlineButton } from "../../components/buttons/button";

const GameReviews: React.FC<{
  game: GetGameQuery["game"];
  openModal: Function;
  handleDropDown: Function;
}> = ({ game, openModal }) => {
  const { data } = useGetGameRatingsQueryQuery({
    variables: {
      game_id: game!.id,
    },
    fetchPolicy: "cache-first",
  });

  const getRatingsMean = (ratings?: number[]) => {
    if (ratings) {
      const length = ratings.length || 1;
      const sum = ratings.reduce((acc, val) => acc + val, 0);
      return parseFloat((sum / length).toFixed(1));
    }
    return 0;
  };

  const overallRating = getRatingsMean(
    data?.game_ratings.map((rating) => rating.rating || 0)
  );

  return (
    <div>
      <h2>Reviews</h2>
      <Flex style={{ justifyContent: "space-between" }}>
        <LeftFlex style={{width: 'fit-content'}}>
          {overallRating > 0 && (
            <Rating
              value={getRatingsMean(
                data?.game_ratings.map((rating) => rating.rating || 0)
              )}
              readOnly
            />
          )}
          <Rate style={{marginLeft: '.5rem'}}>
            {overallRating === 0 ? (
              <small>No Ratings</small>
            ) : (
              `(${overallRating})`
            )}
          </Rate>
        </LeftFlex>
        <ReviewButtons>
          {/* <OutlineButton onClick={() => handleDropDown()}>
            Game Version
            <DropdownIcon src={dropdownImage} alt='' />
          </OutlineButton> */}
          <OutlineButton onClick={() => openModal()}>
            Add your Review
          </OutlineButton>
        </ReviewButtons>
      </Flex>
      <br />
      {data?.game_ratings &&
        data?.game_ratings?.map((game_rating) => (
          <CommentBlock key={game_rating.id}>
            <Flex style={{ marginBottom: 0, height: "unset" }}>
              <Avatar
                src={
                  game_rating.comment.author?.avatar ||
                  "https://www.gravatar.com/avatar/38076cfd91be7ba0da88071dac73636b"
                }
                style={{ marginRight: "1rem" }}
              />
              <Block>
                <CommentProfile>
                  <h3>{game_rating.comment?.author!.name}</h3>
                </CommentProfile>
              </Block>
            </Flex>
            <div style={{ marginLeft: "calc(60px + 1rem)" }}>
              <Subtitle>Review</Subtitle>
              <Comment style={{ marginBottom: "5%" }}>
                {game_rating.comment.content}
              </Comment>
              <BottomContainer>
                <SubTitle>
                  How {game_rating.comment.author?.name} rated the game:{" "}
                </SubTitle>
                <Rating
                  id='rate'
                  color='#143040'
                  className='rating'
                  name='overall_rating'
                  value={game_rating.rating}
                  // onChange={formik.handleChange}
                  // style={{ fontSize: 50, marginLeft: '2%' }}
                />
              </BottomContainer>
            </div>
            {game_rating.additional_ratings && (
              <Ratings>
                <Question>
                  Art Style:
                  <p style={{ fontWeight: "300", marginLeft: "2%" }}>
                    {game_rating.additional_ratings.game_art_style_rating}
                  </p>
                </Question>
                <Question>
                  Story and Context:
                  <p style={{ fontWeight: "300", marginLeft: "2%" }}>
                    {game_rating.additional_ratings.story_context_rating}
                  </p>
                </Question>
                <Question>
                  Game Mechanics:
                  <p style={{ fontWeight: "300", marginLeft: "2%" }}>
                    {game_rating.additional_ratings.game_mechanic_rating}
                  </p>
                </Question>
                <Question>
                  Music and Sound Effects:
                  <p style={{ fontWeight: "300", marginLeft: "2%" }}>
                    {game_rating.additional_ratings.music_sfx_rating}
                  </p>
                </Question>
              </Ratings>
            )}

            <CommentActions>
              <Action>
                <Icon className='fa-solid fa-thumbs-up' />
                <span>Like</span>
              </Action>
              <Action>
                <Icon className='fa-solid fa-reply-all' />
                <span>Reply</span>
              </Action>
            </CommentActions>
          </CommentBlock>
        ))}
    </div>
  );
};

export default GameReviews;
