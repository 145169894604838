import { DropdownButton } from 'components/buttons/button'
import { Graph, Label, SubContainer, Sections, Title, Top, Flex, Chartline, Large, Medium, GamerImage, Vertical, Row, CowrieShell } from './components'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';


const data = [
  {
    name: "January",
    pv: 30,
    amt: 2400,
    x: 100, y: 200, z: 200 
  },
  {
    name: "February",
    pv: 60,
    amt: 2210
  },
  {
    name: "March",
    pv: 10,
    amt: 2290
  },
  {
    name: "April",
    pv: 40,
    amt: 2000
  },
  {
    name: "May",
    pv: 100,
    amt: 2181
  },
  {
    name: "June",
    pv: 60,
    amt: 2500
  },
  {
    name: "July",
    pv: 60,
    amt: 2100
  },
  {
    name: "August",
    pv: 60,
    amt: 2100
  },
  {
    name: "September",
    pv: 90,
    amt: 2100
  },
  {
    name: "October",
    pv: 20,
    amt: 2100
  },
  {
    name: "November",
    pv: 40,
    amt: 2100
  },
  {
    name: "December",
    pv: 100,
    amt: 2100
  }

];

export default function GameStatistics() {

  function createData(
    gamerId: string,
    invoiceNumber: string,
    transaction: string,
    date: string,
    transactionType: string,
    amount: string,
  ) {
    return { gamerId, invoiceNumber, transaction, date, transactionType, amount };
  }

  const rows = [
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),
    createData('JIDA3476', 'A754UH23', 'Bought Usoni', '10 November 2020', 'Credit', `$ ${-1232.00}`),

  ];

  return (
    <>
      <Top>
        <Title>Game Statistics</Title>
        <DropdownButton style={{ width: '400px' }} placeholder="This Week">
          <option value="thisWeek">This Week</option>
          <option value="thisMonth">This Month</option>
          <option value="thisYear">This Year</option>
        </DropdownButton>
      </Top>
      <Row>
        <Graph>
          <ResponsiveContainer width="100%" height="100%">
            <LineChart
              width={500}
              height={300}
              data={data}
              margin={{
                top: 5,
                right: 30,
                left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Line type="monotone" dataKey="pv" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </Graph>
        <Sections style={{marginLeft: '3%'}}>
          <SubContainer>
            <Flex style={{ marginBottom: '10%' }}>
              <p style={{ marginLeft: '10%', width: '200px' }}>Your Game Downloads</p>
              <Chartline className='fa-solid fa-chart-line' style={{ marginRight: '5%' }} />
            </Flex>
            <Large style={{ textAlign: 'center' }}>0843</Large>
          </SubContainer>
          <SubContainer>
            <Flex style={{ marginBottom: '15%' }}>
              <p style={{ marginLeft: '10%', width: '120px' }}>Game Page Visits</p>
              <Chartline className='fa-solid fa-chart-line' style={{ marginRight: '10%' }} />
            </Flex>
            <Large style={{ marginLeft: '10%' }}>21</Large>
          </SubContainer>
          <SubContainer>
            <Flex style={{ marginBottom: '1%' }}>
              <p style={{ marginLeft: '10%' }}>Jewel Wallet Summary</p>
            </Flex>
            <Flex>
              <CowrieShell src="cowrieshell.png"/>
              <Medium style={{ marginRight: '15%' }}>+12,040.99</Medium>
            </Flex>
            <Flex style={{ justifyContent: 'center' }}>Purchases:<Medium style={{ fontSize: '18px', marginLeft: '2%' }}> +18,041.77</Medium></Flex>
            <Flex style={{ justifyContent: 'center' }}>Rewards:<Medium style={{ fontSize: '18px', marginLeft: '2%', }}>-6000.78</Medium></Flex>
          </SubContainer>
          <SubContainer>
            <Title style={{ marginBottom: '10%', fontWeight: '300' }}>Top Gamers</Title>
            <Flex>
              <GamerImage src="topgamersample.png" />
              <GamerImage src="topgamersample.png" />
              <GamerImage src="topgamersample.png" />
              <GamerImage src="topgamersample.png" />
              <GamerImage src="topgamersample.png" />
              <GamerImage src="topgamersample.png" />
              <GamerImage src="topgamersample.png" />
              <GamerImage src="topgamersample.png" />
            </Flex>
          </SubContainer>
        </Sections>
      </Row>
      <div style={{ width: '100%', display: 'flex', marginBottom: '3%' }}>
        <Label style={{ marginRight: '2%', marginTop: '10px' }}>Select Your Game: </Label>{' '}
        <DropdownButton style={{ width: '300px' }} placeholder="Jewel">
          <option value="jewel">JEWEL</option>
          <option value="usoniu">USONI</option>
          <option value="othergame">othergame</option>
        </DropdownButton>
      </div>
      <Title>Transactions</Title>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell  style={{fontWeight: 'bold'}} align="center">Gamer ID</TableCell>
              <TableCell  style={{fontWeight: 'bold'}} align="center">Invoice Number</TableCell>
              <TableCell  style={{fontWeight: 'bold'}} align="center">Transaction</TableCell>
              <TableCell  style={{fontWeight: 'bold'}}align="center">Date </TableCell>
              <TableCell  style={{fontWeight: 'bold'}} align="center">Transaction Type</TableCell>
              <TableCell style={{fontWeight: 'bold'}} align="center">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow
                key={row.gamerId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell align="center" component="th" scope="row">
                  {row.gamerId}
                </TableCell>
                <TableCell align="center">{row.invoiceNumber}</TableCell>
                <TableCell align="center">{row.transaction}</TableCell>
                <TableCell align="center">{row.date}</TableCell>
                <TableCell align="center">{row.transactionType}</TableCell>
                <TableCell align="center">{row.amount}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}
