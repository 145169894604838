import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSON: any;
  _text: any;
  bigint: any;
  date: any;
  float8: any;
  ftype: any;
  group_type: any;
  json: any;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

export type AddGameInput = {
  authorId: Scalars['uuid'];
  gameDescription: Scalars['String'];
  gameFiles: Array<InputMaybe<Scalars['uuid']>>;
  gameLanguage: Scalars['String'];
  gameTitle: Scalars['String'];
  genre: Scalars['String'];
};

export type AddGameOutput = {
  __typename?: 'AddGameOutput';
  game?: Maybe<Games>;
  gameId?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AddGoogleAccountInput = {
  googleTokenId: Scalars['String'];
};

export type AddGoogleAccountOutput = {
  __typename?: 'AddGoogleAccountOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type AddThirdPartyAppInput = {
  applicationType: ThirdPartyAppType;
  clientName: Scalars['String'];
  clientUri: Scalars['String'];
  contacts: Array<InputMaybe<Scalars['String']>>;
  gameEngine?: InputMaybe<GameEngine>;
  grantTypes: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  redirectUri: Array<InputMaybe<Scalars['String']>>;
  scope: Scalars['String'];
};

export type AddThirdPartyAppOutput = {
  __typename?: 'AddThirdPartyAppOutput';
  authClientSecret?: Maybe<Scalars['String']>;
  message: Scalars['String'];
  type: Scalars['String'];
  walletClientSecret?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
  walletName?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

export type CeloWalletBalanceInput = {
  celoWalletAddress: Scalars['String'];
  testMode?: InputMaybe<Scalars['Boolean']>;
};

export type CeloWalletBalanceOutput = {
  __typename?: 'CeloWalletBalanceOutput';
  cEURBalance: Scalars['String'];
  cUSDBalance: Scalars['String'];
  celoBalance: Scalars['String'];
  celoWalletAddress: Scalars['String'];
  testMode: Scalars['Boolean'];
};

export type ChangePasswordInput = {
  newPassword: Scalars['String'];
  oldPassword: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};

export type ChangePasswordOutput = {
  __typename?: 'ChangePasswordOutput';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type ConfirmPaymentInput = {
  externalRef: Scalars['String'];
};

export type ConfirmPaymentOutput = {
  __typename?: 'ConfirmPaymentOutput';
  code?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

export type CreateWalletInput = {
  name?: InputMaybe<Scalars['String']>;
};

export type CreateWalletOutput = {
  __typename?: 'CreateWalletOutput';
  message: Scalars['String'];
  testWalletId?: Maybe<Scalars['String']>;
  walletId?: Maybe<Scalars['String']>;
};

export type DeleteAccountInput = {
  password: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};

export type DeleteAccountOutput = {
  __typename?: 'DeleteAccountOutput';
  message?: Maybe<Scalars['String']>;
};

export type FetchWalletTransactionsInput = {
  account?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  populateTransactions?: InputMaybe<Scalars['Boolean']>;
  startDate?: InputMaybe<Scalars['String']>;
  testMode?: InputMaybe<Scalars['Boolean']>;
  walletId: Scalars['String'];
};

export type FetchWalletTransactionsOutput = {
  __typename?: 'FetchWalletTransactionsOutput';
  transactions: Scalars['JSON'];
};

export type GaGamesReportInput = {
  dateRanges?: InputMaybe<Scalars['JSON']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type GaGamesReportOutput = {
  __typename?: 'GAGamesReportOutput';
  reports?: Maybe<Scalars['String']>;
};

export type GaSessionsReportInput = {
  dateRanges?: InputMaybe<Scalars['JSON']>;
  endDate?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type GaSessionsReportOutput = {
  __typename?: 'GASessionsReportOutput';
  reports?: Maybe<Scalars['String']>;
};

export enum GameEngine {
  Unity = 'Unity',
  Unreal = 'Unreal'
}

export type GenerateOtpOutput = {
  __typename?: 'GenerateOTPOutput';
  message?: Maybe<Scalars['String']>;
  otp?: Maybe<Scalars['String']>;
};

export type GenerateResetPasswordTokenInput = {
  usernameOrEmail: Scalars['String'];
};

export type GenerateResetPasswordTokenOutput = {
  __typename?: 'GenerateResetPasswordTokenOutput';
  resetToken?: Maybe<Scalars['String']>;
};

export type GetWalletBalanceOutput = {
  __typename?: 'GetWalletBalanceOutput';
  balance?: Maybe<Scalars['JSON']>;
  creditBalance?: Maybe<Scalars['JSON']>;
  creditTotal?: Maybe<Scalars['JSON']>;
  debitBalance?: Maybe<Scalars['JSON']>;
  debitTotal?: Maybe<Scalars['JSON']>;
  transactionCount?: Maybe<Scalars['JSON']>;
};

export type GoogleLoginInput = {
  googleTokenId: Scalars['String'];
};

export type GoogleLoginOutput = {
  __typename?: 'GoogleLoginOutput';
  accessToken: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

export type LoginInput = {
  password: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};

export type LoginOutput = {
  __typename?: 'LoginOutput';
  accessToken: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type MeOutput = {
  __typename?: 'MeOutput';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  locale?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  me?: Maybe<Users>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['uuid']>;
  username?: Maybe<Scalars['String']>;
};

export type OffRampCeloInput = {
  amount: Scalars['Float'];
  celoWalletAddress: Scalars['String'];
  description: Scalars['String'];
  idempotencyKey: Scalars['String'];
  metadata?: InputMaybe<Scalars['JSON']>;
  testMode: Scalars['Boolean'];
  walletId: Scalars['String'];
};

export type OffRampCeloOutput = {
  __typename?: 'OffRampCeloOutput';
  message: Scalars['String'];
  transactionHash?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export enum PaymentMethod {
  C2B = 'C2B',
  StkPush = 'STK_PUSH'
}

export type PurchaseInput = {
  gameId: Scalars['String'];
  paymentMethod: PaymentMethod;
  paymentMethodData?: InputMaybe<Scalars['String']>;
  uploadId: Scalars['String'];
};

export type PurchaseOutput = {
  __typename?: 'PurchaseOutput';
  code?: Maybe<Scalars['String']>;
  purchaseReference: Scalars['String'];
  status: Scalars['String'];
};

export type QrAuthenticatorOutput = {
  __typename?: 'QRAuthenticatorOutput';
  message?: Maybe<Scalars['String']>;
  qrCodeURL: Scalars['String'];
};

export type ResetPasswordInput = {
  newPassword: Scalars['String'];
  token: Scalars['String'];
  usernameOrEmail: Scalars['String'];
};

export type ResetPasswordOutput = {
  __typename?: 'ResetPasswordOutput';
  code?: Maybe<Scalars['String']>;
  message: Scalars['String'];
};

export type SendNotificationInput = {
  message: Scalars['String'];
  recipientChannels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  recipientUsernames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  type: Scalars['String'];
};

export type SendNotificationOutput = {
  __typename?: 'SendNotificationOutput';
  id?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  notification?: Maybe<Notifications>;
};

export type SendToCeloInput = {
  amount: Scalars['Float'];
  celoWalletAddress: Scalars['String'];
  description: Scalars['String'];
  idempotencyKey: Scalars['String'];
  testMode: Scalars['Boolean'];
  walletId: Scalars['uuid'];
};

export type SendToCeloOutput = {
  __typename?: 'SendToCeloOutput';
  celoResponse?: Maybe<Scalars['JSON']>;
  message: Scalars['String'];
  walletBalance?: Maybe<GetWalletBalanceOutput>;
};

export type SetupMfaInput = {
  mfaMethod: Scalars['String'];
};

export type SetupMfaOutput = {
  __typename?: 'SetupMFAOutput';
  message?: Maybe<Scalars['String']>;
  qrCodeURL?: Maybe<Scalars['String']>;
};

export type SignupInput = {
  email: Scalars['String'];
  enableMFA: Scalars['Boolean'];
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['JSON']>;
  name: Scalars['String'];
  password: Scalars['String'];
  phoneNumber?: InputMaybe<Scalars['String']>;
  socialSignupData?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
};

export type SignupOutput = {
  __typename?: 'SignupOutput';
  data?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  signedUpUser?: Maybe<Users>;
  type?: Maybe<Scalars['String']>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']>;
};

export type TestTopupWalletInput = {
  walletId: Scalars['String'];
};

export type TestTopupWalletOutput = {
  __typename?: 'TestTopupWalletOutput';
  message?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  walletBalance?: Maybe<Scalars['String']>;
};

export enum ThirdPartyAppType {
  Native = 'native',
  Web = 'web'
}

export type TopUpJiweWalletInput = {
  amount: Scalars['Float'];
  celoTxnData: Scalars['JSON'];
  testMode: Scalars['Boolean'];
  walletId: Scalars['uuid'];
};

export type TopUpJiweWalletOutput = {
  __typename?: 'TopUpJiweWalletOutput';
  celoResponse?: Maybe<Scalars['JSON']>;
  message: Scalars['String'];
  walletBalance?: Maybe<GetWalletBalanceOutput>;
};

export type VerifyMfaInput = {
  mfaMethod: Scalars['String'];
  otp: Scalars['String'];
};

export type VerifyMfaOutput = {
  __typename?: 'VerifyMFAOutput';
  message?: Maybe<Scalars['String']>;
  response: Scalars['Boolean'];
};

/** Boolean expression to compare columns of type "_text". All fields are combined with logical 'AND'. */
export type _Text_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['_text']>;
  _gt?: InputMaybe<Scalars['_text']>;
  _gte?: InputMaybe<Scalars['_text']>;
  _in?: InputMaybe<Array<Scalars['_text']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['_text']>;
  _lte?: InputMaybe<Scalars['_text']>;
  _neq?: InputMaybe<Scalars['_text']>;
  _nin?: InputMaybe<Array<Scalars['_text']>>;
};

/** columns and relationships of "audit_log_entries" */
export type Audit_Log_Entries = {
  __typename?: 'audit_log_entries';
  change: Scalars['json'];
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  initiator_id?: Maybe<Scalars['uuid']>;
  reason: Scalars['String'];
  target_id: Scalars['String'];
  target_type: Scalars['String'];
};


/** columns and relationships of "audit_log_entries" */
export type Audit_Log_EntriesChangeArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "audit_log_entries" */
export type Audit_Log_Entries_Aggregate = {
  __typename?: 'audit_log_entries_aggregate';
  aggregate?: Maybe<Audit_Log_Entries_Aggregate_Fields>;
  nodes: Array<Audit_Log_Entries>;
};

/** aggregate fields of "audit_log_entries" */
export type Audit_Log_Entries_Aggregate_Fields = {
  __typename?: 'audit_log_entries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Audit_Log_Entries_Max_Fields>;
  min?: Maybe<Audit_Log_Entries_Min_Fields>;
};


/** aggregate fields of "audit_log_entries" */
export type Audit_Log_Entries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Audit_Log_Entries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "audit_log_entries". All fields are combined with a logical 'AND'. */
export type Audit_Log_Entries_Bool_Exp = {
  _and?: InputMaybe<Array<Audit_Log_Entries_Bool_Exp>>;
  _not?: InputMaybe<Audit_Log_Entries_Bool_Exp>;
  _or?: InputMaybe<Array<Audit_Log_Entries_Bool_Exp>>;
  change?: InputMaybe<Json_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  initiator_id?: InputMaybe<Uuid_Comparison_Exp>;
  reason?: InputMaybe<String_Comparison_Exp>;
  target_id?: InputMaybe<String_Comparison_Exp>;
  target_type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "audit_log_entries" */
export enum Audit_Log_Entries_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogEntriesPkey = 'log_entries_pkey'
}

/** input type for inserting data into table "audit_log_entries" */
export type Audit_Log_Entries_Insert_Input = {
  change?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiator_id?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Scalars['String']>;
  target_type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Audit_Log_Entries_Max_Fields = {
  __typename?: 'audit_log_entries_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  initiator_id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Audit_Log_Entries_Min_Fields = {
  __typename?: 'audit_log_entries_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  event_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  initiator_id?: Maybe<Scalars['uuid']>;
  reason?: Maybe<Scalars['String']>;
  target_id?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "audit_log_entries" */
export type Audit_Log_Entries_Mutation_Response = {
  __typename?: 'audit_log_entries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Audit_Log_Entries>;
};

/** on_conflict condition type for table "audit_log_entries" */
export type Audit_Log_Entries_On_Conflict = {
  constraint: Audit_Log_Entries_Constraint;
  update_columns?: Array<Audit_Log_Entries_Update_Column>;
  where?: InputMaybe<Audit_Log_Entries_Bool_Exp>;
};

/** Ordering options when selecting data from "audit_log_entries". */
export type Audit_Log_Entries_Order_By = {
  change?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  initiator_id?: InputMaybe<Order_By>;
  reason?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: audit_log_entries */
export type Audit_Log_Entries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "audit_log_entries" */
export enum Audit_Log_Entries_Select_Column {
  /** column name */
  Change = 'change',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  InitiatorId = 'initiator_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type'
}

/** input type for updating data in table "audit_log_entries" */
export type Audit_Log_Entries_Set_Input = {
  change?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiator_id?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Scalars['String']>;
  target_type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "audit_log_entries" */
export type Audit_Log_Entries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Audit_Log_Entries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Audit_Log_Entries_Stream_Cursor_Value_Input = {
  change?: InputMaybe<Scalars['json']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  event_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  initiator_id?: InputMaybe<Scalars['uuid']>;
  reason?: InputMaybe<Scalars['String']>;
  target_id?: InputMaybe<Scalars['String']>;
  target_type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "audit_log_entries" */
export enum Audit_Log_Entries_Update_Column {
  /** column name */
  Change = 'change',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventId = 'event_id',
  /** column name */
  Id = 'id',
  /** column name */
  InitiatorId = 'initiator_id',
  /** column name */
  Reason = 'reason',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type'
}

export type Audit_Log_Entries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Audit_Log_Entries_Set_Input>;
  where: Audit_Log_Entries_Bool_Exp;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "blog_flags" */
export type Blog_Flags = {
  __typename?: 'blog_flags';
  /** An object relationship */
  blog?: Maybe<Blogs>;
  blog_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  flag?: Maybe<Flags>;
  flag_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
};

/** aggregated selection of "blog_flags" */
export type Blog_Flags_Aggregate = {
  __typename?: 'blog_flags_aggregate';
  aggregate?: Maybe<Blog_Flags_Aggregate_Fields>;
  nodes: Array<Blog_Flags>;
};

/** aggregate fields of "blog_flags" */
export type Blog_Flags_Aggregate_Fields = {
  __typename?: 'blog_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Blog_Flags_Max_Fields>;
  min?: Maybe<Blog_Flags_Min_Fields>;
};


/** aggregate fields of "blog_flags" */
export type Blog_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "blog_flags" */
export type Blog_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Blog_Flags_Max_Order_By>;
  min?: InputMaybe<Blog_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "blog_flags" */
export type Blog_Flags_Arr_Rel_Insert_Input = {
  data: Array<Blog_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Blog_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "blog_flags". All fields are combined with a logical 'AND'. */
export type Blog_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Blog_Flags_Bool_Exp>>;
  _not?: InputMaybe<Blog_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Blog_Flags_Bool_Exp>>;
  blog?: InputMaybe<Blogs_Bool_Exp>;
  blog_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "blog_flags" */
export enum Blog_Flags_Constraint {
  /** unique or primary key constraint on columns "blog_id", "flag_id" */
  BlogFlagsBlogIdFlagIdUnique = 'blog_flags_blog_id_flag_id_unique',
  /** unique or primary key constraint on columns "id" */
  BlogFlagsPkey = 'blog_flags_pkey'
}

/** input type for inserting data into table "blog_flags" */
export type Blog_Flags_Insert_Input = {
  blog?: InputMaybe<Blogs_Obj_Rel_Insert_Input>;
  blog_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Blog_Flags_Max_Fields = {
  __typename?: 'blog_flags_max_fields';
  blog_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "blog_flags" */
export type Blog_Flags_Max_Order_By = {
  blog_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Blog_Flags_Min_Fields = {
  __typename?: 'blog_flags_min_fields';
  blog_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "blog_flags" */
export type Blog_Flags_Min_Order_By = {
  blog_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "blog_flags" */
export type Blog_Flags_Mutation_Response = {
  __typename?: 'blog_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blog_Flags>;
};

/** on_conflict condition type for table "blog_flags" */
export type Blog_Flags_On_Conflict = {
  constraint: Blog_Flags_Constraint;
  update_columns?: Array<Blog_Flags_Update_Column>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "blog_flags". */
export type Blog_Flags_Order_By = {
  blog?: InputMaybe<Blogs_Order_By>;
  blog_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blog_flags */
export type Blog_Flags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "blog_flags" */
export enum Blog_Flags_Select_Column {
  /** column name */
  BlogId = 'blog_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "blog_flags" */
export type Blog_Flags_Set_Input = {
  blog_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "blog_flags" */
export type Blog_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blog_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Blog_Flags_Stream_Cursor_Value_Input = {
  blog_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "blog_flags" */
export enum Blog_Flags_Update_Column {
  /** column name */
  BlogId = 'blog_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id'
}

export type Blog_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Blog_Flags_Set_Input>;
  where: Blog_Flags_Bool_Exp;
};

/** columns and relationships of "blogs" */
export type Blogs = {
  __typename?: 'blogs';
  /** An array relationship */
  blog_flags: Array<Blog_Flags>;
  /** An aggregate relationship */
  blog_flags_aggregate: Blog_Flags_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  name: Scalars['String'];
  owner_id: Scalars['uuid'];
  owner_type: Scalars['uuid'];
  path: Scalars['String'];
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate;
  type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "blogs" */
export type BlogsBlog_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Flags_Order_By>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


/** columns and relationships of "blogs" */
export type BlogsBlog_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Flags_Order_By>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


/** columns and relationships of "blogs" */
export type BlogsPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


/** columns and relationships of "blogs" */
export type BlogsPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

/** aggregated selection of "blogs" */
export type Blogs_Aggregate = {
  __typename?: 'blogs_aggregate';
  aggregate?: Maybe<Blogs_Aggregate_Fields>;
  nodes: Array<Blogs>;
};

/** aggregate fields of "blogs" */
export type Blogs_Aggregate_Fields = {
  __typename?: 'blogs_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Blogs_Max_Fields>;
  min?: Maybe<Blogs_Min_Fields>;
};


/** aggregate fields of "blogs" */
export type Blogs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Blogs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "blogs". All fields are combined with a logical 'AND'. */
export type Blogs_Bool_Exp = {
  _and?: InputMaybe<Array<Blogs_Bool_Exp>>;
  _not?: InputMaybe<Blogs_Bool_Exp>;
  _or?: InputMaybe<Array<Blogs_Bool_Exp>>;
  blog_flags?: InputMaybe<Blog_Flags_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  owner_id?: InputMaybe<Uuid_Comparison_Exp>;
  owner_type?: InputMaybe<Uuid_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  posts?: InputMaybe<Posts_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "blogs" */
export enum Blogs_Constraint {
  /** unique or primary key constraint on columns "owner_id", "path", "owner_type" */
  BlogsOwnerIdOwnerTypePathUnique = 'blogs_owner_id_owner_type_path_unique',
  /** unique or primary key constraint on columns "id" */
  BlogsPkey = 'blogs_pkey'
}

/** input type for inserting data into table "blogs" */
export type Blogs_Insert_Input = {
  blog_flags?: InputMaybe<Blog_Flags_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  owner_type?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
  posts?: InputMaybe<Posts_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Blogs_Max_Fields = {
  __typename?: 'blogs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  owner_type?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Blogs_Min_Fields = {
  __typename?: 'blogs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  owner_id?: Maybe<Scalars['uuid']>;
  owner_type?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "blogs" */
export type Blogs_Mutation_Response = {
  __typename?: 'blogs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Blogs>;
};

/** input type for inserting object relation for remote table "blogs" */
export type Blogs_Obj_Rel_Insert_Input = {
  data: Blogs_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Blogs_On_Conflict>;
};

/** on_conflict condition type for table "blogs" */
export type Blogs_On_Conflict = {
  constraint: Blogs_Constraint;
  update_columns?: Array<Blogs_Update_Column>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};

/** Ordering options when selecting data from "blogs". */
export type Blogs_Order_By = {
  blog_flags_aggregate?: InputMaybe<Blog_Flags_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  owner_id?: InputMaybe<Order_By>;
  owner_type?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  posts_aggregate?: InputMaybe<Posts_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: blogs */
export type Blogs_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "blogs" */
export enum Blogs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  OwnerType = 'owner_type',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "blogs" */
export type Blogs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  owner_type?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "blogs" */
export type Blogs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Blogs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Blogs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  owner_id?: InputMaybe<Scalars['uuid']>;
  owner_type?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "blogs" */
export enum Blogs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  OwnerType = 'owner_type',
  /** column name */
  Path = 'path',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Blogs_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Blogs_Set_Input>;
  where: Blogs_Bool_Exp;
};

/** columns and relationships of "books" */
export type Books = {
  __typename?: 'books';
  created_at: Scalars['timestamp'];
  id: Scalars['uuid'];
  metadata?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
};


/** columns and relationships of "books" */
export type BooksMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "books" */
export type Books_Aggregate = {
  __typename?: 'books_aggregate';
  aggregate?: Maybe<Books_Aggregate_Fields>;
  nodes: Array<Books>;
};

/** aggregate fields of "books" */
export type Books_Aggregate_Fields = {
  __typename?: 'books_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Books_Max_Fields>;
  min?: Maybe<Books_Min_Fields>;
};


/** aggregate fields of "books" */
export type Books_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Books_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Books_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "books". All fields are combined with a logical 'AND'. */
export type Books_Bool_Exp = {
  _and?: InputMaybe<Array<Books_Bool_Exp>>;
  _not?: InputMaybe<Books_Bool_Exp>;
  _or?: InputMaybe<Array<Books_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "books" */
export enum Books_Constraint {
  /** unique or primary key constraint on columns "id" */
  BooksIdIdx = 'books_id_idx',
  /** unique or primary key constraint on columns "id" */
  BooksPkey = 'books_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Books_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Books_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Books_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "books" */
export type Books_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Books_Max_Fields = {
  __typename?: 'books_max_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Books_Min_Fields = {
  __typename?: 'books_min_fields';
  created_at?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "books" */
export type Books_Mutation_Response = {
  __typename?: 'books_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Books>;
};

/** on_conflict condition type for table "books" */
export type Books_On_Conflict = {
  constraint: Books_Constraint;
  update_columns?: Array<Books_Update_Column>;
  where?: InputMaybe<Books_Bool_Exp>;
};

/** Ordering options when selecting data from "books". */
export type Books_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: books */
export type Books_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Books_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "books" */
export enum Books_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "books" */
export type Books_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "books" */
export type Books_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Books_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Books_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "books" */
export enum Books_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name'
}

export type Books_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Books_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Books_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Books_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Books_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Books_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Books_Set_Input>;
  where: Books_Bool_Exp;
};

/** columns and relationships of "channel_members" */
export type Channel_Members = {
  __typename?: 'channel_members';
  /** An object relationship */
  channel?: Maybe<Channels>;
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  group?: Maybe<Groups>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "channel_members" */
export type Channel_Members_Aggregate = {
  __typename?: 'channel_members_aggregate';
  aggregate?: Maybe<Channel_Members_Aggregate_Fields>;
  nodes: Array<Channel_Members>;
};

/** aggregate fields of "channel_members" */
export type Channel_Members_Aggregate_Fields = {
  __typename?: 'channel_members_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Channel_Members_Max_Fields>;
  min?: Maybe<Channel_Members_Min_Fields>;
};


/** aggregate fields of "channel_members" */
export type Channel_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channel_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "channel_members" */
export type Channel_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Channel_Members_Max_Order_By>;
  min?: InputMaybe<Channel_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "channel_members" */
export type Channel_Members_Arr_Rel_Insert_Input = {
  data: Array<Channel_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Channel_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "channel_members". All fields are combined with a logical 'AND'. */
export type Channel_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Channel_Members_Bool_Exp>>;
  _not?: InputMaybe<Channel_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Channel_Members_Bool_Exp>>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "channel_members" */
export enum Channel_Members_Constraint {
  /** unique or primary key constraint on columns "group_id", "channel_id" */
  ChannelMembersGroupIdChannelIdUnique = 'channel_members_group_id_channel_id_unique',
  /** unique or primary key constraint on columns "id" */
  ChannelMembersPkey = 'channel_members_pkey',
  /** unique or primary key constraint on columns "user_id", "channel_id" */
  ChannelMembersUserIdChannelIdUnique = 'channel_members_user_id_channel_id_unique'
}

/** input type for inserting data into table "channel_members" */
export type Channel_Members_Insert_Input = {
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Channel_Members_Max_Fields = {
  __typename?: 'channel_members_max_fields';
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "channel_members" */
export type Channel_Members_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Channel_Members_Min_Fields = {
  __typename?: 'channel_members_min_fields';
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "channel_members" */
export type Channel_Members_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "channel_members" */
export type Channel_Members_Mutation_Response = {
  __typename?: 'channel_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Channel_Members>;
};

/** on_conflict condition type for table "channel_members" */
export type Channel_Members_On_Conflict = {
  constraint: Channel_Members_Constraint;
  update_columns?: Array<Channel_Members_Update_Column>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "channel_members". */
export type Channel_Members_Order_By = {
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channel_members */
export type Channel_Members_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "channel_members" */
export enum Channel_Members_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "channel_members" */
export type Channel_Members_Set_Input = {
  channel_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "channel_members" */
export type Channel_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channel_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channel_Members_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "channel_members" */
export enum Channel_Members_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Channel_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channel_Members_Set_Input>;
  where: Channel_Members_Bool_Exp;
};

/** columns and relationships of "channels" */
export type Channels = {
  __typename?: 'channels';
  /** An array relationship */
  channel_members: Array<Channel_Members>;
  /** An aggregate relationship */
  channel_members_aggregate: Channel_Members_Aggregate;
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  identifier: Scalars['String'];
  name: Scalars['String'];
  /** An array relationship */
  notification_recipients: Array<Notification_Recipients>;
  /** An aggregate relationship */
  notification_recipients_aggregate: Notification_Recipients_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "channels" */
export type ChannelsChannel_MembersArgs = {
  distinct_on?: InputMaybe<Array<Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Members_Order_By>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsChannel_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Members_Order_By>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsNotification_RecipientsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Recipients_Order_By>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};


/** columns and relationships of "channels" */
export type ChannelsNotification_Recipients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Recipients_Order_By>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};

/** aggregated selection of "channels" */
export type Channels_Aggregate = {
  __typename?: 'channels_aggregate';
  aggregate?: Maybe<Channels_Aggregate_Fields>;
  nodes: Array<Channels>;
};

/** aggregate fields of "channels" */
export type Channels_Aggregate_Fields = {
  __typename?: 'channels_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Channels_Max_Fields>;
  min?: Maybe<Channels_Min_Fields>;
};


/** aggregate fields of "channels" */
export type Channels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Channels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "channels". All fields are combined with a logical 'AND'. */
export type Channels_Bool_Exp = {
  _and?: InputMaybe<Array<Channels_Bool_Exp>>;
  _not?: InputMaybe<Channels_Bool_Exp>;
  _or?: InputMaybe<Array<Channels_Bool_Exp>>;
  channel_members?: InputMaybe<Channel_Members_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  identifier?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notification_recipients?: InputMaybe<Notification_Recipients_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "channels" */
export enum Channels_Constraint {
  /** unique or primary key constraint on columns "name" */
  ChannelsNameKey = 'channels_name_key',
  /** unique or primary key constraint on columns "name" */
  ChannelsNameUnique = 'channels_name_unique',
  /** unique or primary key constraint on columns "id" */
  ChannelsPkey = 'channels_pkey'
}

/** input type for inserting data into table "channels" */
export type Channels_Insert_Input = {
  channel_members?: InputMaybe<Channel_Members_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  notification_recipients?: InputMaybe<Notification_Recipients_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Channels_Max_Fields = {
  __typename?: 'channels_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Channels_Min_Fields = {
  __typename?: 'channels_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  identifier?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "channels" */
export type Channels_Mutation_Response = {
  __typename?: 'channels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Channels>;
};

/** input type for inserting object relation for remote table "channels" */
export type Channels_Obj_Rel_Insert_Input = {
  data: Channels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};

/** on_conflict condition type for table "channels" */
export type Channels_On_Conflict = {
  constraint: Channels_Constraint;
  update_columns?: Array<Channels_Update_Column>;
  where?: InputMaybe<Channels_Bool_Exp>;
};

/** Ordering options when selecting data from "channels". */
export type Channels_Order_By = {
  channel_members_aggregate?: InputMaybe<Channel_Members_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  identifier?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notification_recipients_aggregate?: InputMaybe<Notification_Recipients_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: channels */
export type Channels_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "channels" */
export enum Channels_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "channels" */
export type Channels_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "channels" */
export type Channels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Channels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Channels_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  identifier?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "channels" */
export enum Channels_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Identifier = 'identifier',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Channels_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Channels_Set_Input>;
  where: Channels_Bool_Exp;
};

/** columns and relationships of "clients" */
export type Clients = {
  __typename?: 'clients';
  callback_url: Scalars['String'];
  client_key: Scalars['String'];
  client_secret?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  metadata?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  oidc_client_id?: Maybe<Scalars['uuid']>;
  registered_email: Scalars['String'];
  salt: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "clients" */
export type ClientsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "clients" */
export type Clients_Aggregate = {
  __typename?: 'clients_aggregate';
  aggregate?: Maybe<Clients_Aggregate_Fields>;
  nodes: Array<Clients>;
};

/** aggregate fields of "clients" */
export type Clients_Aggregate_Fields = {
  __typename?: 'clients_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Clients_Max_Fields>;
  min?: Maybe<Clients_Min_Fields>;
};


/** aggregate fields of "clients" */
export type Clients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Clients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Clients_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "clients". All fields are combined with a logical 'AND'. */
export type Clients_Bool_Exp = {
  _and?: InputMaybe<Array<Clients_Bool_Exp>>;
  _not?: InputMaybe<Clients_Bool_Exp>;
  _or?: InputMaybe<Array<Clients_Bool_Exp>>;
  callback_url?: InputMaybe<String_Comparison_Exp>;
  client_key?: InputMaybe<String_Comparison_Exp>;
  client_secret?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  oidc_client_id?: InputMaybe<Uuid_Comparison_Exp>;
  registered_email?: InputMaybe<String_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "clients" */
export enum Clients_Constraint {
  /** unique or primary key constraint on columns "client_key" */
  ClientsClientKeyUnique = 'clients_client_key_unique',
  /** unique or primary key constraint on columns "client_secret" */
  ClientsClientSecretUnique = 'clients_client_secret_unique',
  /** unique or primary key constraint on columns "id" */
  ClientsPkey = 'clients_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Clients_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Clients_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Clients_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "clients" */
export type Clients_Insert_Input = {
  callback_url?: InputMaybe<Scalars['String']>;
  client_key?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  oidc_client_id?: InputMaybe<Scalars['uuid']>;
  registered_email?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Clients_Max_Fields = {
  __typename?: 'clients_max_fields';
  callback_url?: Maybe<Scalars['String']>;
  client_key?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  oidc_client_id?: Maybe<Scalars['uuid']>;
  registered_email?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Clients_Min_Fields = {
  __typename?: 'clients_min_fields';
  callback_url?: Maybe<Scalars['String']>;
  client_key?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  oidc_client_id?: Maybe<Scalars['uuid']>;
  registered_email?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "clients" */
export type Clients_Mutation_Response = {
  __typename?: 'clients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Clients>;
};

/** on_conflict condition type for table "clients" */
export type Clients_On_Conflict = {
  constraint: Clients_Constraint;
  update_columns?: Array<Clients_Update_Column>;
  where?: InputMaybe<Clients_Bool_Exp>;
};

/** Ordering options when selecting data from "clients". */
export type Clients_Order_By = {
  callback_url?: InputMaybe<Order_By>;
  client_key?: InputMaybe<Order_By>;
  client_secret?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  oidc_client_id?: InputMaybe<Order_By>;
  registered_email?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: clients */
export type Clients_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Clients_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "clients" */
export enum Clients_Select_Column {
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  ClientKey = 'client_key',
  /** column name */
  ClientSecret = 'client_secret',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OidcClientId = 'oidc_client_id',
  /** column name */
  RegisteredEmail = 'registered_email',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "clients" */
export type Clients_Set_Input = {
  callback_url?: InputMaybe<Scalars['String']>;
  client_key?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  oidc_client_id?: InputMaybe<Scalars['uuid']>;
  registered_email?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "clients" */
export type Clients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Clients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Clients_Stream_Cursor_Value_Input = {
  callback_url?: InputMaybe<Scalars['String']>;
  client_key?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  oidc_client_id?: InputMaybe<Scalars['uuid']>;
  registered_email?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "clients" */
export enum Clients_Update_Column {
  /** column name */
  CallbackUrl = 'callback_url',
  /** column name */
  ClientKey = 'client_key',
  /** column name */
  ClientSecret = 'client_secret',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  OidcClientId = 'oidc_client_id',
  /** column name */
  RegisteredEmail = 'registered_email',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Clients_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Clients_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Clients_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Clients_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Clients_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Clients_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Clients_Set_Input>;
  where: Clients_Bool_Exp;
};

/** columns and relationships of "comment_mentions" */
export type Comment_Mentions = {
  __typename?: 'comment_mentions';
  /** An object relationship */
  comment?: Maybe<Comments>;
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "comment_mentions" */
export type Comment_Mentions_Aggregate = {
  __typename?: 'comment_mentions_aggregate';
  aggregate?: Maybe<Comment_Mentions_Aggregate_Fields>;
  nodes: Array<Comment_Mentions>;
};

/** aggregate fields of "comment_mentions" */
export type Comment_Mentions_Aggregate_Fields = {
  __typename?: 'comment_mentions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Comment_Mentions_Max_Fields>;
  min?: Maybe<Comment_Mentions_Min_Fields>;
};


/** aggregate fields of "comment_mentions" */
export type Comment_Mentions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "comment_mentions" */
export type Comment_Mentions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comment_Mentions_Max_Order_By>;
  min?: InputMaybe<Comment_Mentions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comment_mentions" */
export type Comment_Mentions_Arr_Rel_Insert_Input = {
  data: Array<Comment_Mentions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comment_Mentions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comment_mentions". All fields are combined with a logical 'AND'. */
export type Comment_Mentions_Bool_Exp = {
  _and?: InputMaybe<Array<Comment_Mentions_Bool_Exp>>;
  _not?: InputMaybe<Comment_Mentions_Bool_Exp>;
  _or?: InputMaybe<Array<Comment_Mentions_Bool_Exp>>;
  comment?: InputMaybe<Comments_Bool_Exp>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "comment_mentions" */
export enum Comment_Mentions_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentMentionsPkey = 'comment_mentions_pkey'
}

/** input type for inserting data into table "comment_mentions" */
export type Comment_Mentions_Insert_Input = {
  comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Comment_Mentions_Max_Fields = {
  __typename?: 'comment_mentions_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "comment_mentions" */
export type Comment_Mentions_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comment_Mentions_Min_Fields = {
  __typename?: 'comment_mentions_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "comment_mentions" */
export type Comment_Mentions_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comment_mentions" */
export type Comment_Mentions_Mutation_Response = {
  __typename?: 'comment_mentions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Comment_Mentions>;
};

/** on_conflict condition type for table "comment_mentions" */
export type Comment_Mentions_On_Conflict = {
  constraint: Comment_Mentions_Constraint;
  update_columns?: Array<Comment_Mentions_Update_Column>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};

/** Ordering options when selecting data from "comment_mentions". */
export type Comment_Mentions_Order_By = {
  comment?: InputMaybe<Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: comment_mentions */
export type Comment_Mentions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "comment_mentions" */
export enum Comment_Mentions_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "comment_mentions" */
export type Comment_Mentions_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "comment_mentions" */
export type Comment_Mentions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comment_Mentions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comment_Mentions_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "comment_mentions" */
export enum Comment_Mentions_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Comment_Mentions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comment_Mentions_Set_Input>;
  where: Comment_Mentions_Bool_Exp;
};

/** columns and relationships of "comments" */
export type Comments = {
  __typename?: 'comments';
  author_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  comment_mentions: Array<Comment_Mentions>;
  /** An aggregate relationship */
  comment_mentions_aggregate: Comment_Mentions_Aggregate;
  content: Scalars['String'];
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  game_comments: Array<Game_Comments>;
  /** An aggregate relationship */
  game_comments_aggregate: Game_Comments_Aggregate;
  /** An array relationship */
  game_ratings: Array<Game_Ratings>;
  /** An aggregate relationship */
  game_ratings_aggregate: Game_Ratings_Aggregate;
  id: Scalars['uuid'];
  target_id: Scalars['String'];
  target_type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user?: Maybe<Users>;
};


/** columns and relationships of "comments" */
export type CommentsComment_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Mentions_Order_By>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsComment_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Mentions_Order_By>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsGame_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Game_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Comments_Order_By>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsGame_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Comments_Order_By>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsGame_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Ratings_Order_By>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};


/** columns and relationships of "comments" */
export type CommentsGame_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Ratings_Order_By>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};

/** aggregated selection of "comments" */
export type Comments_Aggregate = {
  __typename?: 'comments_aggregate';
  aggregate?: Maybe<Comments_Aggregate_Fields>;
  nodes: Array<Comments>;
};

/** aggregate fields of "comments" */
export type Comments_Aggregate_Fields = {
  __typename?: 'comments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Comments_Max_Fields>;
  min?: Maybe<Comments_Min_Fields>;
};


/** aggregate fields of "comments" */
export type Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "comments" */
export type Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Comments_Max_Order_By>;
  min?: InputMaybe<Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "comments" */
export type Comments_Arr_Rel_Insert_Input = {
  data: Array<Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "comments". All fields are combined with a logical 'AND'. */
export type Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Comments_Bool_Exp>>;
  _not?: InputMaybe<Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Comments_Bool_Exp>>;
  author_id?: InputMaybe<Uuid_Comparison_Exp>;
  comment_mentions?: InputMaybe<Comment_Mentions_Bool_Exp>;
  content?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  game_comments?: InputMaybe<Game_Comments_Bool_Exp>;
  game_ratings?: InputMaybe<Game_Ratings_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  target_id?: InputMaybe<String_Comparison_Exp>;
  target_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "comments" */
export enum Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  CommentsPkey = 'comments_pkey'
}

/** input type for inserting data into table "comments" */
export type Comments_Insert_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  comment_mentions?: InputMaybe<Comment_Mentions_Arr_Rel_Insert_Input>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_comments?: InputMaybe<Game_Comments_Arr_Rel_Insert_Input>;
  game_ratings?: InputMaybe<Game_Ratings_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['String']>;
  target_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Comments_Max_Fields = {
  __typename?: 'comments_max_fields';
  author_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "comments" */
export type Comments_Max_Order_By = {
  author_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Comments_Min_Fields = {
  __typename?: 'comments_min_fields';
  author_id?: Maybe<Scalars['uuid']>;
  content?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['String']>;
  target_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "comments" */
export type Comments_Min_Order_By = {
  author_id?: InputMaybe<Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "comments" */
export type Comments_Mutation_Response = {
  __typename?: 'comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Comments>;
};

/** input type for inserting object relation for remote table "comments" */
export type Comments_Obj_Rel_Insert_Input = {
  data: Comments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};

/** on_conflict condition type for table "comments" */
export type Comments_On_Conflict = {
  constraint: Comments_Constraint;
  update_columns?: Array<Comments_Update_Column>;
  where?: InputMaybe<Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "comments". */
export type Comments_Order_By = {
  author_id?: InputMaybe<Order_By>;
  comment_mentions_aggregate?: InputMaybe<Comment_Mentions_Aggregate_Order_By>;
  content?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  game_comments_aggregate?: InputMaybe<Game_Comments_Aggregate_Order_By>;
  game_ratings_aggregate?: InputMaybe<Game_Ratings_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: comments */
export type Comments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "comments" */
export enum Comments_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "comments" */
export type Comments_Set_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['String']>;
  target_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "comments" */
export type Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Comments_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  content?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['String']>;
  target_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "comments" */
export enum Comments_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  Content = 'content',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "daily_comments_count" */
export type Daily_Comments_Count = {
  __typename?: 'daily_comments_count';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** aggregated selection of "daily_comments_count" */
export type Daily_Comments_Count_Aggregate = {
  __typename?: 'daily_comments_count_aggregate';
  aggregate?: Maybe<Daily_Comments_Count_Aggregate_Fields>;
  nodes: Array<Daily_Comments_Count>;
};

/** aggregate fields of "daily_comments_count" */
export type Daily_Comments_Count_Aggregate_Fields = {
  __typename?: 'daily_comments_count_aggregate_fields';
  avg?: Maybe<Daily_Comments_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Daily_Comments_Count_Max_Fields>;
  min?: Maybe<Daily_Comments_Count_Min_Fields>;
  stddev?: Maybe<Daily_Comments_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_Comments_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_Comments_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_Comments_Count_Sum_Fields>;
  var_pop?: Maybe<Daily_Comments_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_Comments_Count_Var_Samp_Fields>;
  variance?: Maybe<Daily_Comments_Count_Variance_Fields>;
};


/** aggregate fields of "daily_comments_count" */
export type Daily_Comments_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_Comments_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Daily_Comments_Count_Avg_Fields = {
  __typename?: 'daily_comments_count_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "daily_comments_count". All fields are combined with a logical 'AND'. */
export type Daily_Comments_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Comments_Count_Bool_Exp>>;
  _not?: InputMaybe<Daily_Comments_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Comments_Count_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
};

/** aggregate max on columns */
export type Daily_Comments_Count_Max_Fields = {
  __typename?: 'daily_comments_count_max_fields';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Daily_Comments_Count_Min_Fields = {
  __typename?: 'daily_comments_count_min_fields';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** Ordering options when selecting data from "daily_comments_count". */
export type Daily_Comments_Count_Order_By = {
  count?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
};

/** select columns of table "daily_comments_count" */
export enum Daily_Comments_Count_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Day = 'day'
}

/** aggregate stddev on columns */
export type Daily_Comments_Count_Stddev_Fields = {
  __typename?: 'daily_comments_count_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Daily_Comments_Count_Stddev_Pop_Fields = {
  __typename?: 'daily_comments_count_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Daily_Comments_Count_Stddev_Samp_Fields = {
  __typename?: 'daily_comments_count_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "daily_comments_count" */
export type Daily_Comments_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Comments_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Comments_Count_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  day?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Daily_Comments_Count_Sum_Fields = {
  __typename?: 'daily_comments_count_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Daily_Comments_Count_Var_Pop_Fields = {
  __typename?: 'daily_comments_count_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Daily_Comments_Count_Var_Samp_Fields = {
  __typename?: 'daily_comments_count_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Daily_Comments_Count_Variance_Fields = {
  __typename?: 'daily_comments_count_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "daily_likes_count" */
export type Daily_Likes_Count = {
  __typename?: 'daily_likes_count';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** aggregated selection of "daily_likes_count" */
export type Daily_Likes_Count_Aggregate = {
  __typename?: 'daily_likes_count_aggregate';
  aggregate?: Maybe<Daily_Likes_Count_Aggregate_Fields>;
  nodes: Array<Daily_Likes_Count>;
};

/** aggregate fields of "daily_likes_count" */
export type Daily_Likes_Count_Aggregate_Fields = {
  __typename?: 'daily_likes_count_aggregate_fields';
  avg?: Maybe<Daily_Likes_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Daily_Likes_Count_Max_Fields>;
  min?: Maybe<Daily_Likes_Count_Min_Fields>;
  stddev?: Maybe<Daily_Likes_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_Likes_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_Likes_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_Likes_Count_Sum_Fields>;
  var_pop?: Maybe<Daily_Likes_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_Likes_Count_Var_Samp_Fields>;
  variance?: Maybe<Daily_Likes_Count_Variance_Fields>;
};


/** aggregate fields of "daily_likes_count" */
export type Daily_Likes_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_Likes_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Daily_Likes_Count_Avg_Fields = {
  __typename?: 'daily_likes_count_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "daily_likes_count". All fields are combined with a logical 'AND'. */
export type Daily_Likes_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_Likes_Count_Bool_Exp>>;
  _not?: InputMaybe<Daily_Likes_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_Likes_Count_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
};

/** aggregate max on columns */
export type Daily_Likes_Count_Max_Fields = {
  __typename?: 'daily_likes_count_max_fields';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Daily_Likes_Count_Min_Fields = {
  __typename?: 'daily_likes_count_min_fields';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** Ordering options when selecting data from "daily_likes_count". */
export type Daily_Likes_Count_Order_By = {
  count?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
};

/** select columns of table "daily_likes_count" */
export enum Daily_Likes_Count_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Day = 'day'
}

/** aggregate stddev on columns */
export type Daily_Likes_Count_Stddev_Fields = {
  __typename?: 'daily_likes_count_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Daily_Likes_Count_Stddev_Pop_Fields = {
  __typename?: 'daily_likes_count_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Daily_Likes_Count_Stddev_Samp_Fields = {
  __typename?: 'daily_likes_count_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "daily_likes_count" */
export type Daily_Likes_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_Likes_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_Likes_Count_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  day?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Daily_Likes_Count_Sum_Fields = {
  __typename?: 'daily_likes_count_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Daily_Likes_Count_Var_Pop_Fields = {
  __typename?: 'daily_likes_count_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Daily_Likes_Count_Var_Samp_Fields = {
  __typename?: 'daily_likes_count_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Daily_Likes_Count_Variance_Fields = {
  __typename?: 'daily_likes_count_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "daily_user_count" */
export type Daily_User_Count = {
  __typename?: 'daily_user_count';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** aggregated selection of "daily_user_count" */
export type Daily_User_Count_Aggregate = {
  __typename?: 'daily_user_count_aggregate';
  aggregate?: Maybe<Daily_User_Count_Aggregate_Fields>;
  nodes: Array<Daily_User_Count>;
};

/** aggregate fields of "daily_user_count" */
export type Daily_User_Count_Aggregate_Fields = {
  __typename?: 'daily_user_count_aggregate_fields';
  avg?: Maybe<Daily_User_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Daily_User_Count_Max_Fields>;
  min?: Maybe<Daily_User_Count_Min_Fields>;
  stddev?: Maybe<Daily_User_Count_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_User_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_User_Count_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_User_Count_Sum_Fields>;
  var_pop?: Maybe<Daily_User_Count_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_User_Count_Var_Samp_Fields>;
  variance?: Maybe<Daily_User_Count_Variance_Fields>;
};


/** aggregate fields of "daily_user_count" */
export type Daily_User_Count_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_User_Count_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Daily_User_Count_Avg_Fields = {
  __typename?: 'daily_user_count_avg_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "daily_user_count". All fields are combined with a logical 'AND'. */
export type Daily_User_Count_Bool_Exp = {
  _and?: InputMaybe<Array<Daily_User_Count_Bool_Exp>>;
  _not?: InputMaybe<Daily_User_Count_Bool_Exp>;
  _or?: InputMaybe<Array<Daily_User_Count_Bool_Exp>>;
  count?: InputMaybe<Bigint_Comparison_Exp>;
  day?: InputMaybe<Date_Comparison_Exp>;
};

/** aggregate max on columns */
export type Daily_User_Count_Max_Fields = {
  __typename?: 'daily_user_count_max_fields';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** aggregate min on columns */
export type Daily_User_Count_Min_Fields = {
  __typename?: 'daily_user_count_min_fields';
  count?: Maybe<Scalars['bigint']>;
  day?: Maybe<Scalars['date']>;
};

/** Ordering options when selecting data from "daily_user_count". */
export type Daily_User_Count_Order_By = {
  count?: InputMaybe<Order_By>;
  day?: InputMaybe<Order_By>;
};

/** select columns of table "daily_user_count" */
export enum Daily_User_Count_Select_Column {
  /** column name */
  Count = 'count',
  /** column name */
  Day = 'day'
}

/** aggregate stddev on columns */
export type Daily_User_Count_Stddev_Fields = {
  __typename?: 'daily_user_count_stddev_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Daily_User_Count_Stddev_Pop_Fields = {
  __typename?: 'daily_user_count_stddev_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Daily_User_Count_Stddev_Samp_Fields = {
  __typename?: 'daily_user_count_stddev_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "daily_user_count" */
export type Daily_User_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Daily_User_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Daily_User_Count_Stream_Cursor_Value_Input = {
  count?: InputMaybe<Scalars['bigint']>;
  day?: InputMaybe<Scalars['date']>;
};

/** aggregate sum on columns */
export type Daily_User_Count_Sum_Fields = {
  __typename?: 'daily_user_count_sum_fields';
  count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type Daily_User_Count_Var_Pop_Fields = {
  __typename?: 'daily_user_count_var_pop_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Daily_User_Count_Var_Samp_Fields = {
  __typename?: 'daily_user_count_var_samp_fields';
  count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Daily_User_Count_Variance_Fields = {
  __typename?: 'daily_user_count_variance_fields';
  count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "events" */
export type Events = {
  __typename?: 'events';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  power: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  value: Scalars['String'];
};

/** aggregated selection of "events" */
export type Events_Aggregate = {
  __typename?: 'events_aggregate';
  aggregate?: Maybe<Events_Aggregate_Fields>;
  nodes: Array<Events>;
};

/** aggregate fields of "events" */
export type Events_Aggregate_Fields = {
  __typename?: 'events_aggregate_fields';
  avg?: Maybe<Events_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Events_Max_Fields>;
  min?: Maybe<Events_Min_Fields>;
  stddev?: Maybe<Events_Stddev_Fields>;
  stddev_pop?: Maybe<Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Events_Stddev_Samp_Fields>;
  sum?: Maybe<Events_Sum_Fields>;
  var_pop?: Maybe<Events_Var_Pop_Fields>;
  var_samp?: Maybe<Events_Var_Samp_Fields>;
  variance?: Maybe<Events_Variance_Fields>;
};


/** aggregate fields of "events" */
export type Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Events_Avg_Fields = {
  __typename?: 'events_avg_fields';
  power?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "events". All fields are combined with a logical 'AND'. */
export type Events_Bool_Exp = {
  _and?: InputMaybe<Array<Events_Bool_Exp>>;
  _not?: InputMaybe<Events_Bool_Exp>;
  _or?: InputMaybe<Array<Events_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  power?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "events" */
export enum Events_Constraint {
  /** unique or primary key constraint on columns "name" */
  EventsNameUnique = 'events_name_unique',
  /** unique or primary key constraint on columns "id" */
  EventsPkey = 'events_pkey',
  /** unique or primary key constraint on columns "value" */
  EventsValueUnique = 'events_value_unique'
}

/** input type for incrementing numeric columns in table "events" */
export type Events_Inc_Input = {
  power?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "events" */
export type Events_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Events_Max_Fields = {
  __typename?: 'events_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Events_Min_Fields = {
  __typename?: 'events_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "events" */
export type Events_Mutation_Response = {
  __typename?: 'events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Events>;
};

/** on_conflict condition type for table "events" */
export type Events_On_Conflict = {
  constraint: Events_Constraint;
  update_columns?: Array<Events_Update_Column>;
  where?: InputMaybe<Events_Bool_Exp>;
};

/** Ordering options when selecting data from "events". */
export type Events_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: events */
export type Events_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "events" */
export enum Events_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Power = 'power',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "events" */
export type Events_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Events_Stddev_Fields = {
  __typename?: 'events_stddev_fields';
  power?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Events_Stddev_Pop_Fields = {
  __typename?: 'events_stddev_pop_fields';
  power?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Events_Stddev_Samp_Fields = {
  __typename?: 'events_stddev_samp_fields';
  power?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "events" */
export type Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Events_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Events_Sum_Fields = {
  __typename?: 'events_sum_fields';
  power?: Maybe<Scalars['Int']>;
};

/** update columns of table "events" */
export enum Events_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Power = 'power',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Events_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Events_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Events_Var_Pop_Fields = {
  __typename?: 'events_var_pop_fields';
  power?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Events_Var_Samp_Fields = {
  __typename?: 'events_var_samp_fields';
  power?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Events_Variance_Fields = {
  __typename?: 'events_variance_fields';
  power?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "featured_games" */
export type Featured_Games = {
  __typename?: 'featured_games';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "featured_games" */
export type Featured_Games_Aggregate = {
  __typename?: 'featured_games_aggregate';
  aggregate?: Maybe<Featured_Games_Aggregate_Fields>;
  nodes: Array<Featured_Games>;
};

/** aggregate fields of "featured_games" */
export type Featured_Games_Aggregate_Fields = {
  __typename?: 'featured_games_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Featured_Games_Max_Fields>;
  min?: Maybe<Featured_Games_Min_Fields>;
};


/** aggregate fields of "featured_games" */
export type Featured_Games_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Featured_Games_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "featured_games" */
export type Featured_Games_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Featured_Games_Max_Order_By>;
  min?: InputMaybe<Featured_Games_Min_Order_By>;
};

/** input type for inserting array relation for remote table "featured_games" */
export type Featured_Games_Arr_Rel_Insert_Input = {
  data: Array<Featured_Games_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Featured_Games_On_Conflict>;
};

/** Boolean expression to filter rows from the table "featured_games". All fields are combined with a logical 'AND'. */
export type Featured_Games_Bool_Exp = {
  _and?: InputMaybe<Array<Featured_Games_Bool_Exp>>;
  _not?: InputMaybe<Featured_Games_Bool_Exp>;
  _or?: InputMaybe<Array<Featured_Games_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "featured_games" */
export enum Featured_Games_Constraint {
  /** unique or primary key constraint on columns "id" */
  FeaturedGamesPkey = 'featured_games_pkey'
}

/** input type for inserting data into table "featured_games" */
export type Featured_Games_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Featured_Games_Max_Fields = {
  __typename?: 'featured_games_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "featured_games" */
export type Featured_Games_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Featured_Games_Min_Fields = {
  __typename?: 'featured_games_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "featured_games" */
export type Featured_Games_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "featured_games" */
export type Featured_Games_Mutation_Response = {
  __typename?: 'featured_games_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Featured_Games>;
};

/** on_conflict condition type for table "featured_games" */
export type Featured_Games_On_Conflict = {
  constraint: Featured_Games_Constraint;
  update_columns?: Array<Featured_Games_Update_Column>;
  where?: InputMaybe<Featured_Games_Bool_Exp>;
};

/** Ordering options when selecting data from "featured_games". */
export type Featured_Games_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: featured_games */
export type Featured_Games_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "featured_games" */
export enum Featured_Games_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "featured_games" */
export type Featured_Games_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "featured_games" */
export type Featured_Games_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Featured_Games_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Featured_Games_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "featured_games" */
export enum Featured_Games_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Featured_Games_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Featured_Games_Set_Input>;
  where: Featured_Games_Bool_Exp;
};

/** columns and relationships of "file_storage_providers" */
export type File_Storage_Providers = {
  __typename?: 'file_storage_providers';
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  username: Scalars['String'];
};


/** columns and relationships of "file_storage_providers" */
export type File_Storage_ProvidersFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


/** columns and relationships of "file_storage_providers" */
export type File_Storage_ProvidersFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** aggregated selection of "file_storage_providers" */
export type File_Storage_Providers_Aggregate = {
  __typename?: 'file_storage_providers_aggregate';
  aggregate?: Maybe<File_Storage_Providers_Aggregate_Fields>;
  nodes: Array<File_Storage_Providers>;
};

/** aggregate fields of "file_storage_providers" */
export type File_Storage_Providers_Aggregate_Fields = {
  __typename?: 'file_storage_providers_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<File_Storage_Providers_Max_Fields>;
  min?: Maybe<File_Storage_Providers_Min_Fields>;
};


/** aggregate fields of "file_storage_providers" */
export type File_Storage_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<File_Storage_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "file_storage_providers". All fields are combined with a logical 'AND'. */
export type File_Storage_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<File_Storage_Providers_Bool_Exp>>;
  _not?: InputMaybe<File_Storage_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<File_Storage_Providers_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  files?: InputMaybe<Files_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_storage_providers" */
export enum File_Storage_Providers_Constraint {
  /** unique or primary key constraint on columns "id" */
  FileStorageProvidersPkey = 'file_storage_providers_pkey'
}

/** input type for inserting data into table "file_storage_providers" */
export type File_Storage_Providers_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  files?: InputMaybe<Files_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type File_Storage_Providers_Max_Fields = {
  __typename?: 'file_storage_providers_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type File_Storage_Providers_Min_Fields = {
  __typename?: 'file_storage_providers_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "file_storage_providers" */
export type File_Storage_Providers_Mutation_Response = {
  __typename?: 'file_storage_providers_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<File_Storage_Providers>;
};

/** input type for inserting object relation for remote table "file_storage_providers" */
export type File_Storage_Providers_Obj_Rel_Insert_Input = {
  data: File_Storage_Providers_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<File_Storage_Providers_On_Conflict>;
};

/** on_conflict condition type for table "file_storage_providers" */
export type File_Storage_Providers_On_Conflict = {
  constraint: File_Storage_Providers_Constraint;
  update_columns?: Array<File_Storage_Providers_Update_Column>;
  where?: InputMaybe<File_Storage_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "file_storage_providers". */
export type File_Storage_Providers_Order_By = {
  created_at?: InputMaybe<Order_By>;
  files_aggregate?: InputMaybe<Files_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
};

/** primary key columns input for table: file_storage_providers */
export type File_Storage_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "file_storage_providers" */
export enum File_Storage_Providers_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "file_storage_providers" */
export type File_Storage_Providers_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "file_storage_providers" */
export type File_Storage_Providers_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Storage_Providers_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Storage_Providers_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "file_storage_providers" */
export enum File_Storage_Providers_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type File_Storage_Providers_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<File_Storage_Providers_Set_Input>;
  where: File_Storage_Providers_Bool_Exp;
};

/** columns and relationships of "files" */
export type Files = {
  __typename?: 'files';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file_storage_provider?: Maybe<File_Storage_Providers>;
  file_storage_provider_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['ftype']>;
  /** An array relationship */
  game_files: Array<Game_Files>;
  /** An aggregate relationship */
  game_files_aggregate: Game_Files_Aggregate;
  /** An array relationship */
  games: Array<Games>;
  /** An aggregate relationship */
  games_aggregate: Games_Aggregate;
  id: Scalars['uuid'];
  path: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  uploads: Array<Uploads>;
  /** An aggregate relationship */
  uploads_aggregate: Uploads_Aggregate;
};


/** columns and relationships of "files" */
export type FilesGame_FilesArgs = {
  distinct_on?: InputMaybe<Array<Game_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Files_Order_By>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


/** columns and relationships of "files" */
export type FilesGame_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Files_Order_By>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


/** columns and relationships of "files" */
export type FilesGamesArgs = {
  distinct_on?: InputMaybe<Array<Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Games_Order_By>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


/** columns and relationships of "files" */
export type FilesGames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Games_Order_By>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


/** columns and relationships of "files" */
export type FilesUploadsArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};


/** columns and relationships of "files" */
export type FilesUploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};

/** aggregated selection of "files" */
export type Files_Aggregate = {
  __typename?: 'files_aggregate';
  aggregate?: Maybe<Files_Aggregate_Fields>;
  nodes: Array<Files>;
};

/** aggregate fields of "files" */
export type Files_Aggregate_Fields = {
  __typename?: 'files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Files_Max_Fields>;
  min?: Maybe<Files_Min_Fields>;
};


/** aggregate fields of "files" */
export type Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "files" */
export type Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Files_Max_Order_By>;
  min?: InputMaybe<Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "files" */
export type Files_Arr_Rel_Insert_Input = {
  data: Array<Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "files". All fields are combined with a logical 'AND'. */
export type Files_Bool_Exp = {
  _and?: InputMaybe<Array<Files_Bool_Exp>>;
  _not?: InputMaybe<Files_Bool_Exp>;
  _or?: InputMaybe<Array<Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file_storage_provider?: InputMaybe<File_Storage_Providers_Bool_Exp>;
  file_storage_provider_id?: InputMaybe<Uuid_Comparison_Exp>;
  file_type?: InputMaybe<Ftype_Comparison_Exp>;
  game_files?: InputMaybe<Game_Files_Bool_Exp>;
  games?: InputMaybe<Games_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  path?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploads?: InputMaybe<Uploads_Bool_Exp>;
};

/** unique or primary key constraints on table "files" */
export enum Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  FilesPkey = 'files_pkey'
}

/** input type for inserting data into table "files" */
export type Files_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_storage_provider?: InputMaybe<File_Storage_Providers_Obj_Rel_Insert_Input>;
  file_storage_provider_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['ftype']>;
  game_files?: InputMaybe<Game_Files_Arr_Rel_Insert_Input>;
  games?: InputMaybe<Games_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploads?: InputMaybe<Uploads_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Files_Max_Fields = {
  __typename?: 'files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_storage_provider_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['ftype']>;
  id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "files" */
export type Files_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_storage_provider_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Files_Min_Fields = {
  __typename?: 'files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_storage_provider_id?: Maybe<Scalars['uuid']>;
  file_type?: Maybe<Scalars['ftype']>;
  id?: Maybe<Scalars['uuid']>;
  path?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "files" */
export type Files_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_storage_provider_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "files" */
export type Files_Mutation_Response = {
  __typename?: 'files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Files>;
};

/** input type for inserting object relation for remote table "files" */
export type Files_Obj_Rel_Insert_Input = {
  data: Files_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Files_On_Conflict>;
};

/** on_conflict condition type for table "files" */
export type Files_On_Conflict = {
  constraint: Files_Constraint;
  update_columns?: Array<Files_Update_Column>;
  where?: InputMaybe<Files_Bool_Exp>;
};

/** Ordering options when selecting data from "files". */
export type Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_storage_provider?: InputMaybe<File_Storage_Providers_Order_By>;
  file_storage_provider_id?: InputMaybe<Order_By>;
  file_type?: InputMaybe<Order_By>;
  game_files_aggregate?: InputMaybe<Game_Files_Aggregate_Order_By>;
  games_aggregate?: InputMaybe<Games_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  path?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploads_aggregate?: InputMaybe<Uploads_Aggregate_Order_By>;
};

/** primary key columns input for table: files */
export type Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "files" */
export enum Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileStorageProviderId = 'file_storage_provider_id',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "files" */
export type Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_storage_provider_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['ftype']>;
  id?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "files" */
export type Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Files_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_storage_provider_id?: InputMaybe<Scalars['uuid']>;
  file_type?: InputMaybe<Scalars['ftype']>;
  id?: InputMaybe<Scalars['uuid']>;
  path?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "files" */
export enum Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileStorageProviderId = 'file_storage_provider_id',
  /** column name */
  FileType = 'file_type',
  /** column name */
  Id = 'id',
  /** column name */
  Path = 'path',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};

/** columns and relationships of "flags" */
export type Flags = {
  __typename?: 'flags';
  /** An array relationship */
  blog_flags: Array<Blog_Flags>;
  /** An aggregate relationship */
  blog_flags_aggregate: Blog_Flags_Aggregate;
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  game_flags: Array<Game_Flags>;
  /** An aggregate relationship */
  game_flags_aggregate: Game_Flags_Aggregate;
  /** An array relationship */
  group_flags: Array<Group_Flags>;
  /** An aggregate relationship */
  group_flags_aggregate: Group_Flags_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  post_flags: Array<Post_Flags>;
  /** An aggregate relationship */
  post_flags_aggregate: Post_Flags_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_flags: Array<User_Flags>;
  /** An aggregate relationship */
  user_flags_aggregate: User_Flags_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "flags" */
export type FlagsBlog_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Flags_Order_By>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsBlog_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Flags_Order_By>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsGame_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Game_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Flags_Order_By>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsGame_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Flags_Order_By>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsGroup_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Flags_Order_By>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsGroup_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Flags_Order_By>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsPost_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Flags_Order_By>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsPost_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Flags_Order_By>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsUser_FlagsArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};


/** columns and relationships of "flags" */
export type FlagsUser_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

/** aggregated selection of "flags" */
export type Flags_Aggregate = {
  __typename?: 'flags_aggregate';
  aggregate?: Maybe<Flags_Aggregate_Fields>;
  nodes: Array<Flags>;
};

/** aggregate fields of "flags" */
export type Flags_Aggregate_Fields = {
  __typename?: 'flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Flags_Max_Fields>;
  min?: Maybe<Flags_Min_Fields>;
};


/** aggregate fields of "flags" */
export type Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "flags". All fields are combined with a logical 'AND'. */
export type Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Flags_Bool_Exp>>;
  _not?: InputMaybe<Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Flags_Bool_Exp>>;
  blog_flags?: InputMaybe<Blog_Flags_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  game_flags?: InputMaybe<Game_Flags_Bool_Exp>;
  group_flags?: InputMaybe<Group_Flags_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  post_flags?: InputMaybe<Post_Flags_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_flags?: InputMaybe<User_Flags_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "flags" */
export enum Flags_Constraint {
  /** unique or primary key constraint on columns "name" */
  FlagsNameUnique = 'flags_name_unique',
  /** unique or primary key constraint on columns "id" */
  FlagsPkey = 'flags_pkey',
  /** unique or primary key constraint on columns "value" */
  FlagsValueUnique = 'flags_value_unique'
}

/** input type for inserting data into table "flags" */
export type Flags_Insert_Input = {
  blog_flags?: InputMaybe<Blog_Flags_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  game_flags?: InputMaybe<Game_Flags_Arr_Rel_Insert_Input>;
  group_flags?: InputMaybe<Group_Flags_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  post_flags?: InputMaybe<Post_Flags_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_flags?: InputMaybe<User_Flags_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Flags_Max_Fields = {
  __typename?: 'flags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Flags_Min_Fields = {
  __typename?: 'flags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "flags" */
export type Flags_Mutation_Response = {
  __typename?: 'flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Flags>;
};

/** input type for inserting object relation for remote table "flags" */
export type Flags_Obj_Rel_Insert_Input = {
  data: Flags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};

/** on_conflict condition type for table "flags" */
export type Flags_On_Conflict = {
  constraint: Flags_Constraint;
  update_columns?: Array<Flags_Update_Column>;
  where?: InputMaybe<Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "flags". */
export type Flags_Order_By = {
  blog_flags_aggregate?: InputMaybe<Blog_Flags_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  game_flags_aggregate?: InputMaybe<Game_Flags_Aggregate_Order_By>;
  group_flags_aggregate?: InputMaybe<Group_Flags_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  post_flags_aggregate?: InputMaybe<Post_Flags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_flags_aggregate?: InputMaybe<User_Flags_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: flags */
export type Flags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "flags" */
export enum Flags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "flags" */
export type Flags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "flags" */
export type Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "flags" */
export enum Flags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Flags_Set_Input>;
  where: Flags_Bool_Exp;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']>;
  _gt?: InputMaybe<Scalars['float8']>;
  _gte?: InputMaybe<Scalars['float8']>;
  _in?: InputMaybe<Array<Scalars['float8']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['float8']>;
  _lte?: InputMaybe<Scalars['float8']>;
  _neq?: InputMaybe<Scalars['float8']>;
  _nin?: InputMaybe<Array<Scalars['float8']>>;
};

/** Boolean expression to compare columns of type "ftype". All fields are combined with logical 'AND'. */
export type Ftype_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['ftype']>;
  _gt?: InputMaybe<Scalars['ftype']>;
  _gte?: InputMaybe<Scalars['ftype']>;
  _in?: InputMaybe<Array<Scalars['ftype']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['ftype']>;
  _lte?: InputMaybe<Scalars['ftype']>;
  _neq?: InputMaybe<Scalars['ftype']>;
  _nin?: InputMaybe<Array<Scalars['ftype']>>;
};

/** columns and relationships of "game_comments" */
export type Game_Comments = {
  __typename?: 'game_comments';
  /** An object relationship */
  comment: Comments;
  comment_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "game_comments" */
export type Game_Comments_Aggregate = {
  __typename?: 'game_comments_aggregate';
  aggregate?: Maybe<Game_Comments_Aggregate_Fields>;
  nodes: Array<Game_Comments>;
};

/** aggregate fields of "game_comments" */
export type Game_Comments_Aggregate_Fields = {
  __typename?: 'game_comments_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Game_Comments_Max_Fields>;
  min?: Maybe<Game_Comments_Min_Fields>;
};


/** aggregate fields of "game_comments" */
export type Game_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "game_comments" */
export type Game_Comments_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Game_Comments_Max_Order_By>;
  min?: InputMaybe<Game_Comments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "game_comments" */
export type Game_Comments_Arr_Rel_Insert_Input = {
  data: Array<Game_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Game_Comments_On_Conflict>;
};

/** Boolean expression to filter rows from the table "game_comments". All fields are combined with a logical 'AND'. */
export type Game_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Comments_Bool_Exp>>;
  _not?: InputMaybe<Game_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Comments_Bool_Exp>>;
  comment?: InputMaybe<Comments_Bool_Exp>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_comments" */
export enum Game_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  GameCommentsPkey = 'game_comments_pkey'
}

/** input type for inserting data into table "game_comments" */
export type Game_Comments_Insert_Input = {
  comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Game_Comments_Max_Fields = {
  __typename?: 'game_comments_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "game_comments" */
export type Game_Comments_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Game_Comments_Min_Fields = {
  __typename?: 'game_comments_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "game_comments" */
export type Game_Comments_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "game_comments" */
export type Game_Comments_Mutation_Response = {
  __typename?: 'game_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Comments>;
};

/** on_conflict condition type for table "game_comments" */
export type Game_Comments_On_Conflict = {
  constraint: Game_Comments_Constraint;
  update_columns?: Array<Game_Comments_Update_Column>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "game_comments". */
export type Game_Comments_Order_By = {
  comment?: InputMaybe<Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_comments */
export type Game_Comments_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "game_comments" */
export enum Game_Comments_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "game_comments" */
export type Game_Comments_Set_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "game_comments" */
export type Game_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Game_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Game_Comments_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "game_comments" */
export enum Game_Comments_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Game_Comments_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Game_Comments_Set_Input>;
  where: Game_Comments_Bool_Exp;
};

/** columns and relationships of "game_files" */
export type Game_Files = {
  __typename?: 'game_files';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file: Files;
  file_id: Scalars['uuid'];
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "game_files" */
export type Game_Files_Aggregate = {
  __typename?: 'game_files_aggregate';
  aggregate?: Maybe<Game_Files_Aggregate_Fields>;
  nodes: Array<Game_Files>;
};

/** aggregate fields of "game_files" */
export type Game_Files_Aggregate_Fields = {
  __typename?: 'game_files_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Game_Files_Max_Fields>;
  min?: Maybe<Game_Files_Min_Fields>;
};


/** aggregate fields of "game_files" */
export type Game_Files_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Files_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "game_files" */
export type Game_Files_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Game_Files_Max_Order_By>;
  min?: InputMaybe<Game_Files_Min_Order_By>;
};

/** input type for inserting array relation for remote table "game_files" */
export type Game_Files_Arr_Rel_Insert_Input = {
  data: Array<Game_Files_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Game_Files_On_Conflict>;
};

/** Boolean expression to filter rows from the table "game_files". All fields are combined with a logical 'AND'. */
export type Game_Files_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Files_Bool_Exp>>;
  _not?: InputMaybe<Game_Files_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Files_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_files" */
export enum Game_Files_Constraint {
  /** unique or primary key constraint on columns "id" */
  GameFilesPkey = 'game_files_pkey'
}

/** input type for inserting data into table "game_files" */
export type Game_Files_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Game_Files_Max_Fields = {
  __typename?: 'game_files_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "game_files" */
export type Game_Files_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Game_Files_Min_Fields = {
  __typename?: 'game_files_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "game_files" */
export type Game_Files_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "game_files" */
export type Game_Files_Mutation_Response = {
  __typename?: 'game_files_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Files>;
};

/** on_conflict condition type for table "game_files" */
export type Game_Files_On_Conflict = {
  constraint: Game_Files_Constraint;
  update_columns?: Array<Game_Files_Update_Column>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};

/** Ordering options when selecting data from "game_files". */
export type Game_Files_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_files */
export type Game_Files_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "game_files" */
export enum Game_Files_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "game_files" */
export type Game_Files_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "game_files" */
export type Game_Files_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Game_Files_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Game_Files_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "game_files" */
export enum Game_Files_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Game_Files_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Game_Files_Set_Input>;
  where: Game_Files_Bool_Exp;
};

/** columns and relationships of "game_flags" */
export type Game_Flags = {
  __typename?: 'game_flags';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  flag?: Maybe<Flags>;
  flag_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  game?: Maybe<Games>;
  game_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
};

/** aggregated selection of "game_flags" */
export type Game_Flags_Aggregate = {
  __typename?: 'game_flags_aggregate';
  aggregate?: Maybe<Game_Flags_Aggregate_Fields>;
  nodes: Array<Game_Flags>;
};

/** aggregate fields of "game_flags" */
export type Game_Flags_Aggregate_Fields = {
  __typename?: 'game_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Game_Flags_Max_Fields>;
  min?: Maybe<Game_Flags_Min_Fields>;
};


/** aggregate fields of "game_flags" */
export type Game_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "game_flags" */
export type Game_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Game_Flags_Max_Order_By>;
  min?: InputMaybe<Game_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "game_flags" */
export type Game_Flags_Arr_Rel_Insert_Input = {
  data: Array<Game_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Game_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "game_flags". All fields are combined with a logical 'AND'. */
export type Game_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Flags_Bool_Exp>>;
  _not?: InputMaybe<Game_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_flags" */
export enum Game_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  GameFlagsPkey = 'game_flags_pkey'
}

/** input type for inserting data into table "game_flags" */
export type Game_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Game_Flags_Max_Fields = {
  __typename?: 'game_flags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "game_flags" */
export type Game_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Game_Flags_Min_Fields = {
  __typename?: 'game_flags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "game_flags" */
export type Game_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "game_flags" */
export type Game_Flags_Mutation_Response = {
  __typename?: 'game_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Flags>;
};

/** on_conflict condition type for table "game_flags" */
export type Game_Flags_On_Conflict = {
  constraint: Game_Flags_Constraint;
  update_columns?: Array<Game_Flags_Update_Column>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "game_flags". */
export type Game_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_flags */
export type Game_Flags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "game_flags" */
export enum Game_Flags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "game_flags" */
export type Game_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "game_flags" */
export type Game_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Game_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Game_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "game_flags" */
export enum Game_Flags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id'
}

export type Game_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Game_Flags_Set_Input>;
  where: Game_Flags_Bool_Exp;
};

/** columns and relationships of "game_genres" */
export type Game_Genres = {
  __typename?: 'game_genres';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "game_genres" */
export type Game_Genres_Aggregate = {
  __typename?: 'game_genres_aggregate';
  aggregate?: Maybe<Game_Genres_Aggregate_Fields>;
  nodes: Array<Game_Genres>;
};

/** aggregate fields of "game_genres" */
export type Game_Genres_Aggregate_Fields = {
  __typename?: 'game_genres_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Game_Genres_Max_Fields>;
  min?: Maybe<Game_Genres_Min_Fields>;
};


/** aggregate fields of "game_genres" */
export type Game_Genres_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Genres_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "game_genres" */
export type Game_Genres_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Game_Genres_Max_Order_By>;
  min?: InputMaybe<Game_Genres_Min_Order_By>;
};

/** input type for inserting array relation for remote table "game_genres" */
export type Game_Genres_Arr_Rel_Insert_Input = {
  data: Array<Game_Genres_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Game_Genres_On_Conflict>;
};

/** Boolean expression to filter rows from the table "game_genres". All fields are combined with a logical 'AND'. */
export type Game_Genres_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Genres_Bool_Exp>>;
  _not?: InputMaybe<Game_Genres_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Genres_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_genres" */
export enum Game_Genres_Constraint {
  /** unique or primary key constraint on columns "id" */
  GameGenresPkey = 'game_genres_pkey'
}

/** input type for inserting data into table "game_genres" */
export type Game_Genres_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Game_Genres_Max_Fields = {
  __typename?: 'game_genres_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "game_genres" */
export type Game_Genres_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Game_Genres_Min_Fields = {
  __typename?: 'game_genres_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "game_genres" */
export type Game_Genres_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "game_genres" */
export type Game_Genres_Mutation_Response = {
  __typename?: 'game_genres_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Genres>;
};

/** on_conflict condition type for table "game_genres" */
export type Game_Genres_On_Conflict = {
  constraint: Game_Genres_Constraint;
  update_columns?: Array<Game_Genres_Update_Column>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};

/** Ordering options when selecting data from "game_genres". */
export type Game_Genres_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_genres */
export type Game_Genres_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "game_genres" */
export enum Game_Genres_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "game_genres" */
export type Game_Genres_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "game_genres" */
export type Game_Genres_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Game_Genres_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Game_Genres_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "game_genres" */
export enum Game_Genres_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Game_Genres_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Game_Genres_Set_Input>;
  where: Game_Genres_Bool_Exp;
};

/** columns and relationships of "game_ratings" */
export type Game_Ratings = {
  __typename?: 'game_ratings';
  additional_ratings?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  comment: Comments;
  comment_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  rating: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "game_ratings" */
export type Game_RatingsAdditional_RatingsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "game_ratings" */
export type Game_Ratings_Aggregate = {
  __typename?: 'game_ratings_aggregate';
  aggregate?: Maybe<Game_Ratings_Aggregate_Fields>;
  nodes: Array<Game_Ratings>;
};

/** aggregate fields of "game_ratings" */
export type Game_Ratings_Aggregate_Fields = {
  __typename?: 'game_ratings_aggregate_fields';
  avg?: Maybe<Game_Ratings_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Game_Ratings_Max_Fields>;
  min?: Maybe<Game_Ratings_Min_Fields>;
  stddev?: Maybe<Game_Ratings_Stddev_Fields>;
  stddev_pop?: Maybe<Game_Ratings_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Game_Ratings_Stddev_Samp_Fields>;
  sum?: Maybe<Game_Ratings_Sum_Fields>;
  var_pop?: Maybe<Game_Ratings_Var_Pop_Fields>;
  var_samp?: Maybe<Game_Ratings_Var_Samp_Fields>;
  variance?: Maybe<Game_Ratings_Variance_Fields>;
};


/** aggregate fields of "game_ratings" */
export type Game_Ratings_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "game_ratings" */
export type Game_Ratings_Aggregate_Order_By = {
  avg?: InputMaybe<Game_Ratings_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Game_Ratings_Max_Order_By>;
  min?: InputMaybe<Game_Ratings_Min_Order_By>;
  stddev?: InputMaybe<Game_Ratings_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Game_Ratings_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Game_Ratings_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Game_Ratings_Sum_Order_By>;
  var_pop?: InputMaybe<Game_Ratings_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Game_Ratings_Var_Samp_Order_By>;
  variance?: InputMaybe<Game_Ratings_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Game_Ratings_Append_Input = {
  additional_ratings?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "game_ratings" */
export type Game_Ratings_Arr_Rel_Insert_Input = {
  data: Array<Game_Ratings_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Game_Ratings_On_Conflict>;
};

/** aggregate avg on columns */
export type Game_Ratings_Avg_Fields = {
  __typename?: 'game_ratings_avg_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "game_ratings" */
export type Game_Ratings_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "game_ratings". All fields are combined with a logical 'AND'. */
export type Game_Ratings_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Ratings_Bool_Exp>>;
  _not?: InputMaybe<Game_Ratings_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Ratings_Bool_Exp>>;
  additional_ratings?: InputMaybe<Jsonb_Comparison_Exp>;
  comment?: InputMaybe<Comments_Bool_Exp>;
  comment_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_ratings" */
export enum Game_Ratings_Constraint {
  /** unique or primary key constraint on columns "id" */
  GameRatingsPkey = 'game_ratings_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Game_Ratings_Delete_At_Path_Input = {
  additional_ratings?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Game_Ratings_Delete_Elem_Input = {
  additional_ratings?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Game_Ratings_Delete_Key_Input = {
  additional_ratings?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "game_ratings" */
export type Game_Ratings_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "game_ratings" */
export type Game_Ratings_Insert_Input = {
  additional_ratings?: InputMaybe<Scalars['jsonb']>;
  comment?: InputMaybe<Comments_Obj_Rel_Insert_Input>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Game_Ratings_Max_Fields = {
  __typename?: 'game_ratings_max_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "game_ratings" */
export type Game_Ratings_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Game_Ratings_Min_Fields = {
  __typename?: 'game_ratings_min_fields';
  comment_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  rating?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "game_ratings" */
export type Game_Ratings_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "game_ratings" */
export type Game_Ratings_Mutation_Response = {
  __typename?: 'game_ratings_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Ratings>;
};

/** on_conflict condition type for table "game_ratings" */
export type Game_Ratings_On_Conflict = {
  constraint: Game_Ratings_Constraint;
  update_columns?: Array<Game_Ratings_Update_Column>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};

/** Ordering options when selecting data from "game_ratings". */
export type Game_Ratings_Order_By = {
  additional_ratings?: InputMaybe<Order_By>;
  comment?: InputMaybe<Comments_Order_By>;
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_ratings */
export type Game_Ratings_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Game_Ratings_Prepend_Input = {
  additional_ratings?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "game_ratings" */
export enum Game_Ratings_Select_Column {
  /** column name */
  AdditionalRatings = 'additional_ratings',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "game_ratings" */
export type Game_Ratings_Set_Input = {
  additional_ratings?: InputMaybe<Scalars['jsonb']>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Game_Ratings_Stddev_Fields = {
  __typename?: 'game_ratings_stddev_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "game_ratings" */
export type Game_Ratings_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Game_Ratings_Stddev_Pop_Fields = {
  __typename?: 'game_ratings_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "game_ratings" */
export type Game_Ratings_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Game_Ratings_Stddev_Samp_Fields = {
  __typename?: 'game_ratings_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "game_ratings" */
export type Game_Ratings_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "game_ratings" */
export type Game_Ratings_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Game_Ratings_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Game_Ratings_Stream_Cursor_Value_Input = {
  additional_ratings?: InputMaybe<Scalars['jsonb']>;
  comment_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  rating?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Game_Ratings_Sum_Fields = {
  __typename?: 'game_ratings_sum_fields';
  rating?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "game_ratings" */
export type Game_Ratings_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "game_ratings" */
export enum Game_Ratings_Update_Column {
  /** column name */
  AdditionalRatings = 'additional_ratings',
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Game_Ratings_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Game_Ratings_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Game_Ratings_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Game_Ratings_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Game_Ratings_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Game_Ratings_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Game_Ratings_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Game_Ratings_Set_Input>;
  where: Game_Ratings_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Game_Ratings_Var_Pop_Fields = {
  __typename?: 'game_ratings_var_pop_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "game_ratings" */
export type Game_Ratings_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Game_Ratings_Var_Samp_Fields = {
  __typename?: 'game_ratings_var_samp_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "game_ratings" */
export type Game_Ratings_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Game_Ratings_Variance_Fields = {
  __typename?: 'game_ratings_variance_fields';
  rating?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "game_ratings" */
export type Game_Ratings_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "game_serving_points" */
export type Game_Serving_Points = {
  __typename?: 'game_serving_points';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  game?: Maybe<Games>;
  game_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  serving_point?: Maybe<Serving_Points>;
  serving_point_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "game_serving_points" */
export type Game_Serving_Points_Aggregate = {
  __typename?: 'game_serving_points_aggregate';
  aggregate?: Maybe<Game_Serving_Points_Aggregate_Fields>;
  nodes: Array<Game_Serving_Points>;
};

/** aggregate fields of "game_serving_points" */
export type Game_Serving_Points_Aggregate_Fields = {
  __typename?: 'game_serving_points_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Game_Serving_Points_Max_Fields>;
  min?: Maybe<Game_Serving_Points_Min_Fields>;
};


/** aggregate fields of "game_serving_points" */
export type Game_Serving_Points_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "game_serving_points" */
export type Game_Serving_Points_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Game_Serving_Points_Max_Order_By>;
  min?: InputMaybe<Game_Serving_Points_Min_Order_By>;
};

/** input type for inserting array relation for remote table "game_serving_points" */
export type Game_Serving_Points_Arr_Rel_Insert_Input = {
  data: Array<Game_Serving_Points_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Game_Serving_Points_On_Conflict>;
};

/** Boolean expression to filter rows from the table "game_serving_points". All fields are combined with a logical 'AND'. */
export type Game_Serving_Points_Bool_Exp = {
  _and?: InputMaybe<Array<Game_Serving_Points_Bool_Exp>>;
  _not?: InputMaybe<Game_Serving_Points_Bool_Exp>;
  _or?: InputMaybe<Array<Game_Serving_Points_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  serving_point?: InputMaybe<Serving_Points_Bool_Exp>;
  serving_point_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "game_serving_points" */
export enum Game_Serving_Points_Constraint {
  /** unique or primary key constraint on columns "id" */
  GameServingPointsPkey = 'game_serving_points_pkey'
}

/** input type for inserting data into table "game_serving_points" */
export type Game_Serving_Points_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serving_point?: InputMaybe<Serving_Points_Obj_Rel_Insert_Input>;
  serving_point_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Game_Serving_Points_Max_Fields = {
  __typename?: 'game_serving_points_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  serving_point_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "game_serving_points" */
export type Game_Serving_Points_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serving_point_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Game_Serving_Points_Min_Fields = {
  __typename?: 'game_serving_points_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  serving_point_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "game_serving_points" */
export type Game_Serving_Points_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serving_point_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "game_serving_points" */
export type Game_Serving_Points_Mutation_Response = {
  __typename?: 'game_serving_points_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Game_Serving_Points>;
};

/** on_conflict condition type for table "game_serving_points" */
export type Game_Serving_Points_On_Conflict = {
  constraint: Game_Serving_Points_Constraint;
  update_columns?: Array<Game_Serving_Points_Update_Column>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};

/** Ordering options when selecting data from "game_serving_points". */
export type Game_Serving_Points_Order_By = {
  created_at?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serving_point?: InputMaybe<Serving_Points_Order_By>;
  serving_point_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: game_serving_points */
export type Game_Serving_Points_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "game_serving_points" */
export enum Game_Serving_Points_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServingPointId = 'serving_point_id'
}

/** input type for updating data in table "game_serving_points" */
export type Game_Serving_Points_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serving_point_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "game_serving_points" */
export type Game_Serving_Points_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Game_Serving_Points_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Game_Serving_Points_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serving_point_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "game_serving_points" */
export enum Game_Serving_Points_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServingPointId = 'serving_point_id'
}

export type Game_Serving_Points_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Game_Serving_Points_Set_Input>;
  where: Game_Serving_Points_Bool_Exp;
};

/** columns and relationships of "games" */
export type Games = {
  __typename?: 'games';
  /** An object relationship */
  author?: Maybe<Users>;
  author_id?: Maybe<Scalars['uuid']>;
  /** Author could be a user or group */
  author_type?: Maybe<Scalars['uuid']>;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  /** An array relationship */
  featured_games: Array<Featured_Games>;
  /** An aggregate relationship */
  featured_games_aggregate: Featured_Games_Aggregate;
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  game_comments: Array<Game_Comments>;
  /** An aggregate relationship */
  game_comments_aggregate: Game_Comments_Aggregate;
  /** An array relationship */
  game_files: Array<Game_Files>;
  /** An aggregate relationship */
  game_files_aggregate: Game_Files_Aggregate;
  /** An array relationship */
  game_flags: Array<Game_Flags>;
  /** An aggregate relationship */
  game_flags_aggregate: Game_Flags_Aggregate;
  /** An array relationship */
  game_genres: Array<Game_Genres>;
  /** An aggregate relationship */
  game_genres_aggregate: Game_Genres_Aggregate;
  /** An array relationship */
  game_ratings: Array<Game_Ratings>;
  /** An aggregate relationship */
  game_ratings_aggregate: Game_Ratings_Aggregate;
  /** An array relationship */
  game_serving_points: Array<Game_Serving_Points>;
  /** An aggregate relationship */
  game_serving_points_aggregate: Game_Serving_Points_Aggregate;
  genre: Scalars['String'];
  id: Scalars['uuid'];
  language?: Maybe<Scalars['String']>;
  /** An array relationship */
  purchases: Array<Purchases>;
  /** An aggregate relationship */
  purchases_aggregate: Purchases_Aggregate;
  /** An object relationship */
  studio?: Maybe<Groups>;
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  uploads: Array<Uploads>;
  /** An aggregate relationship */
  uploads_aggregate: Uploads_Aggregate;
};


/** columns and relationships of "games" */
export type GamesFeatured_GamesArgs = {
  distinct_on?: InputMaybe<Array<Featured_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Featured_Games_Order_By>>;
  where?: InputMaybe<Featured_Games_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesFeatured_Games_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Featured_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Featured_Games_Order_By>>;
  where?: InputMaybe<Featured_Games_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Game_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Comments_Order_By>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Comments_Order_By>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_FilesArgs = {
  distinct_on?: InputMaybe<Array<Game_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Files_Order_By>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Files_Order_By>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Game_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Flags_Order_By>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Flags_Order_By>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_GenresArgs = {
  distinct_on?: InputMaybe<Array<Game_Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Genres_Order_By>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_Genres_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Genres_Order_By>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Ratings_Order_By>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Ratings_Order_By>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_Serving_PointsArgs = {
  distinct_on?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Serving_Points_Order_By>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesGame_Serving_Points_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Serving_Points_Order_By>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesPurchasesArgs = {
  distinct_on?: InputMaybe<Array<Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchases_Order_By>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesPurchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchases_Order_By>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesUploadsArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};


/** columns and relationships of "games" */
export type GamesUploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};

/** aggregated selection of "games" */
export type Games_Aggregate = {
  __typename?: 'games_aggregate';
  aggregate?: Maybe<Games_Aggregate_Fields>;
  nodes: Array<Games>;
};

/** aggregate fields of "games" */
export type Games_Aggregate_Fields = {
  __typename?: 'games_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Games_Max_Fields>;
  min?: Maybe<Games_Min_Fields>;
};


/** aggregate fields of "games" */
export type Games_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Games_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "games" */
export type Games_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Games_Max_Order_By>;
  min?: InputMaybe<Games_Min_Order_By>;
};

/** input type for inserting array relation for remote table "games" */
export type Games_Arr_Rel_Insert_Input = {
  data: Array<Games_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Games_On_Conflict>;
};

/** Boolean expression to filter rows from the table "games". All fields are combined with a logical 'AND'. */
export type Games_Bool_Exp = {
  _and?: InputMaybe<Array<Games_Bool_Exp>>;
  _not?: InputMaybe<Games_Bool_Exp>;
  _or?: InputMaybe<Array<Games_Bool_Exp>>;
  author?: InputMaybe<Users_Bool_Exp>;
  author_id?: InputMaybe<Uuid_Comparison_Exp>;
  author_type?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  featured_games?: InputMaybe<Featured_Games_Bool_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  game_comments?: InputMaybe<Game_Comments_Bool_Exp>;
  game_files?: InputMaybe<Game_Files_Bool_Exp>;
  game_flags?: InputMaybe<Game_Flags_Bool_Exp>;
  game_genres?: InputMaybe<Game_Genres_Bool_Exp>;
  game_ratings?: InputMaybe<Game_Ratings_Bool_Exp>;
  game_serving_points?: InputMaybe<Game_Serving_Points_Bool_Exp>;
  genre?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  purchases?: InputMaybe<Purchases_Bool_Exp>;
  studio?: InputMaybe<Groups_Bool_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploads?: InputMaybe<Uploads_Bool_Exp>;
};

/** unique or primary key constraints on table "games" */
export enum Games_Constraint {
  /** unique or primary key constraint on columns "id" */
  GamesPkey = 'games_pkey',
  /** unique or primary key constraint on columns "title" */
  GamesTitleUnique = 'games_title_unique'
}

/** input type for inserting data into table "games" */
export type Games_Insert_Input = {
  author?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  author_id?: InputMaybe<Scalars['uuid']>;
  /** Author could be a user or group */
  author_type?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  featured_games?: InputMaybe<Featured_Games_Arr_Rel_Insert_Input>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  game_comments?: InputMaybe<Game_Comments_Arr_Rel_Insert_Input>;
  game_files?: InputMaybe<Game_Files_Arr_Rel_Insert_Input>;
  game_flags?: InputMaybe<Game_Flags_Arr_Rel_Insert_Input>;
  game_genres?: InputMaybe<Game_Genres_Arr_Rel_Insert_Input>;
  game_ratings?: InputMaybe<Game_Ratings_Arr_Rel_Insert_Input>;
  game_serving_points?: InputMaybe<Game_Serving_Points_Arr_Rel_Insert_Input>;
  genre?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  purchases?: InputMaybe<Purchases_Arr_Rel_Insert_Input>;
  studio?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploads?: InputMaybe<Uploads_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Games_Max_Fields = {
  __typename?: 'games_max_fields';
  author_id?: Maybe<Scalars['uuid']>;
  /** Author could be a user or group */
  author_type?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  genre?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "games" */
export type Games_Max_Order_By = {
  author_id?: InputMaybe<Order_By>;
  /** Author could be a user or group */
  author_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  genre?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Games_Min_Fields = {
  __typename?: 'games_min_fields';
  author_id?: Maybe<Scalars['uuid']>;
  /** Author could be a user or group */
  author_type?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  file_id?: Maybe<Scalars['uuid']>;
  genre?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  language?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "games" */
export type Games_Min_Order_By = {
  author_id?: InputMaybe<Order_By>;
  /** Author could be a user or group */
  author_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  genre?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "games" */
export type Games_Mutation_Response = {
  __typename?: 'games_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Games>;
};

/** input type for inserting object relation for remote table "games" */
export type Games_Obj_Rel_Insert_Input = {
  data: Games_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Games_On_Conflict>;
};

/** on_conflict condition type for table "games" */
export type Games_On_Conflict = {
  constraint: Games_Constraint;
  update_columns?: Array<Games_Update_Column>;
  where?: InputMaybe<Games_Bool_Exp>;
};

/** Ordering options when selecting data from "games". */
export type Games_Order_By = {
  author?: InputMaybe<Users_Order_By>;
  author_id?: InputMaybe<Order_By>;
  author_type?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  featured_games_aggregate?: InputMaybe<Featured_Games_Aggregate_Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  game_comments_aggregate?: InputMaybe<Game_Comments_Aggregate_Order_By>;
  game_files_aggregate?: InputMaybe<Game_Files_Aggregate_Order_By>;
  game_flags_aggregate?: InputMaybe<Game_Flags_Aggregate_Order_By>;
  game_genres_aggregate?: InputMaybe<Game_Genres_Aggregate_Order_By>;
  game_ratings_aggregate?: InputMaybe<Game_Ratings_Aggregate_Order_By>;
  game_serving_points_aggregate?: InputMaybe<Game_Serving_Points_Aggregate_Order_By>;
  genre?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  purchases_aggregate?: InputMaybe<Purchases_Aggregate_Order_By>;
  studio?: InputMaybe<Groups_Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploads_aggregate?: InputMaybe<Uploads_Aggregate_Order_By>;
};

/** primary key columns input for table: games */
export type Games_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "games" */
export enum Games_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  AuthorType = 'author_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Genre = 'genre',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "games" */
export type Games_Set_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  /** Author could be a user or group */
  author_type?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  genre?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "games" */
export type Games_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Games_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Games_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  /** Author could be a user or group */
  author_type?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  genre?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  language?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "games" */
export enum Games_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  AuthorType = 'author_type',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileId = 'file_id',
  /** column name */
  Genre = 'genre',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Games_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Games_Set_Input>;
  where: Games_Bool_Exp;
};

/** columns and relationships of "group_flags" */
export type Group_Flags = {
  __typename?: 'group_flags';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  flag?: Maybe<Flags>;
  flag_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  group?: Maybe<Groups>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
};

/** aggregated selection of "group_flags" */
export type Group_Flags_Aggregate = {
  __typename?: 'group_flags_aggregate';
  aggregate?: Maybe<Group_Flags_Aggregate_Fields>;
  nodes: Array<Group_Flags>;
};

/** aggregate fields of "group_flags" */
export type Group_Flags_Aggregate_Fields = {
  __typename?: 'group_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Group_Flags_Max_Fields>;
  min?: Maybe<Group_Flags_Min_Fields>;
};


/** aggregate fields of "group_flags" */
export type Group_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Group_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_flags" */
export type Group_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Group_Flags_Max_Order_By>;
  min?: InputMaybe<Group_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "group_flags" */
export type Group_Flags_Arr_Rel_Insert_Input = {
  data: Array<Group_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Group_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "group_flags". All fields are combined with a logical 'AND'. */
export type Group_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Group_Flags_Bool_Exp>>;
  _not?: InputMaybe<Group_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Group_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_flags" */
export enum Group_Flags_Constraint {
  /** unique or primary key constraint on columns "group_id", "flag_id" */
  GroupFlagsGroupIdFlagIdUnique = 'group_flags_group_id_flag_id_unique',
  /** unique or primary key constraint on columns "id" */
  GroupFlagsPkey = 'group_flags_pkey'
}

/** input type for inserting data into table "group_flags" */
export type Group_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Group_Flags_Max_Fields = {
  __typename?: 'group_flags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "group_flags" */
export type Group_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Group_Flags_Min_Fields = {
  __typename?: 'group_flags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "group_flags" */
export type Group_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "group_flags" */
export type Group_Flags_Mutation_Response = {
  __typename?: 'group_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group_Flags>;
};

/** on_conflict condition type for table "group_flags" */
export type Group_Flags_On_Conflict = {
  constraint: Group_Flags_Constraint;
  update_columns?: Array<Group_Flags_Update_Column>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "group_flags". */
export type Group_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_flags */
export type Group_Flags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "group_flags" */
export enum Group_Flags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id'
}

/** input type for updating data in table "group_flags" */
export type Group_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "group_flags" */
export type Group_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "group_flags" */
export enum Group_Flags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id'
}

export type Group_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Group_Flags_Set_Input>;
  where: Group_Flags_Bool_Exp;
};

/** columns and relationships of "group_members" */
export type Group_Members = {
  __typename?: 'group_members';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  group?: Maybe<Groups>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "group_members" */
export type Group_Members_Aggregate = {
  __typename?: 'group_members_aggregate';
  aggregate?: Maybe<Group_Members_Aggregate_Fields>;
  nodes: Array<Group_Members>;
};

/** aggregate fields of "group_members" */
export type Group_Members_Aggregate_Fields = {
  __typename?: 'group_members_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Group_Members_Max_Fields>;
  min?: Maybe<Group_Members_Min_Fields>;
};


/** aggregate fields of "group_members" */
export type Group_Members_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Group_Members_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "group_members" */
export type Group_Members_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Group_Members_Max_Order_By>;
  min?: InputMaybe<Group_Members_Min_Order_By>;
};

/** input type for inserting array relation for remote table "group_members" */
export type Group_Members_Arr_Rel_Insert_Input = {
  data: Array<Group_Members_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Group_Members_On_Conflict>;
};

/** Boolean expression to filter rows from the table "group_members". All fields are combined with a logical 'AND'. */
export type Group_Members_Bool_Exp = {
  _and?: InputMaybe<Array<Group_Members_Bool_Exp>>;
  _not?: InputMaybe<Group_Members_Bool_Exp>;
  _or?: InputMaybe<Array<Group_Members_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_members" */
export enum Group_Members_Constraint {
  /** unique or primary key constraint on columns "group_id", "user_id" */
  GroupMembersGroupIdUserIdUnique = 'group_members_group_id_user_id_unique',
  /** unique or primary key constraint on columns "id" */
  GroupMembersPkey = 'group_members_pkey'
}

/** input type for inserting data into table "group_members" */
export type Group_Members_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Group_Members_Max_Fields = {
  __typename?: 'group_members_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "group_members" */
export type Group_Members_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Group_Members_Min_Fields = {
  __typename?: 'group_members_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "group_members" */
export type Group_Members_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "group_members" */
export type Group_Members_Mutation_Response = {
  __typename?: 'group_members_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group_Members>;
};

/** on_conflict condition type for table "group_members" */
export type Group_Members_On_Conflict = {
  constraint: Group_Members_Constraint;
  update_columns?: Array<Group_Members_Update_Column>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};

/** Ordering options when selecting data from "group_members". */
export type Group_Members_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_members */
export type Group_Members_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "group_members" */
export enum Group_Members_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "group_members" */
export type Group_Members_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "group_members" */
export type Group_Members_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Members_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Members_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "group_members" */
export enum Group_Members_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type Group_Members_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Group_Members_Set_Input>;
  where: Group_Members_Bool_Exp;
};

/** columns and relationships of "group_permissions" */
export type Group_Permissions = {
  __typename?: 'group_permissions';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  permission_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "group_permissions" */
export type Group_Permissions_Aggregate = {
  __typename?: 'group_permissions_aggregate';
  aggregate?: Maybe<Group_Permissions_Aggregate_Fields>;
  nodes: Array<Group_Permissions>;
};

/** aggregate fields of "group_permissions" */
export type Group_Permissions_Aggregate_Fields = {
  __typename?: 'group_permissions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Group_Permissions_Max_Fields>;
  min?: Maybe<Group_Permissions_Min_Fields>;
};


/** aggregate fields of "group_permissions" */
export type Group_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Group_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "group_permissions". All fields are combined with a logical 'AND'. */
export type Group_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Group_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Group_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Group_Permissions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  permission_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_permissions" */
export enum Group_Permissions_Constraint {
  /** unique or primary key constraint on columns "group_id", "permission_id" */
  GroupPermissionsGroupIdPermissionIdUnique = 'group_permissions_group_id_permission_id_unique',
  /** unique or primary key constraint on columns "id" */
  GroupPermissionsPkey = 'group_permissions_pkey'
}

/** input type for inserting data into table "group_permissions" */
export type Group_Permissions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Group_Permissions_Max_Fields = {
  __typename?: 'group_permissions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Group_Permissions_Min_Fields = {
  __typename?: 'group_permissions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  permission_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "group_permissions" */
export type Group_Permissions_Mutation_Response = {
  __typename?: 'group_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group_Permissions>;
};

/** on_conflict condition type for table "group_permissions" */
export type Group_Permissions_On_Conflict = {
  constraint: Group_Permissions_Constraint;
  update_columns?: Array<Group_Permissions_Update_Column>;
  where?: InputMaybe<Group_Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "group_permissions". */
export type Group_Permissions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_permissions */
export type Group_Permissions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "group_permissions" */
export enum Group_Permissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id'
}

/** input type for updating data in table "group_permissions" */
export type Group_Permissions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "group_permissions" */
export type Group_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Permissions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  permission_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "group_permissions" */
export enum Group_Permissions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  PermissionId = 'permission_id'
}

export type Group_Permissions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Group_Permissions_Set_Input>;
  where: Group_Permissions_Bool_Exp;
};

/** columns and relationships of "group_roles" */
export type Group_Roles = {
  __typename?: 'group_roles';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  role_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "group_roles" */
export type Group_Roles_Aggregate = {
  __typename?: 'group_roles_aggregate';
  aggregate?: Maybe<Group_Roles_Aggregate_Fields>;
  nodes: Array<Group_Roles>;
};

/** aggregate fields of "group_roles" */
export type Group_Roles_Aggregate_Fields = {
  __typename?: 'group_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Group_Roles_Max_Fields>;
  min?: Maybe<Group_Roles_Min_Fields>;
};


/** aggregate fields of "group_roles" */
export type Group_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Group_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "group_roles". All fields are combined with a logical 'AND'. */
export type Group_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Group_Roles_Bool_Exp>>;
  _not?: InputMaybe<Group_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Group_Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "group_roles" */
export enum Group_Roles_Constraint {
  /** unique or primary key constraint on columns "role_id", "group_id" */
  GroupRolesGroupIdRoleIdUnique = 'group_roles_group_id_role_id_unique',
  /** unique or primary key constraint on columns "id" */
  GroupRolesPkey = 'group_roles_pkey'
}

/** input type for inserting data into table "group_roles" */
export type Group_Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Group_Roles_Max_Fields = {
  __typename?: 'group_roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Group_Roles_Min_Fields = {
  __typename?: 'group_roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "group_roles" */
export type Group_Roles_Mutation_Response = {
  __typename?: 'group_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Group_Roles>;
};

/** on_conflict condition type for table "group_roles" */
export type Group_Roles_On_Conflict = {
  constraint: Group_Roles_Constraint;
  update_columns?: Array<Group_Roles_Update_Column>;
  where?: InputMaybe<Group_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "group_roles". */
export type Group_Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: group_roles */
export type Group_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "group_roles" */
export enum Group_Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "group_roles" */
export type Group_Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "group_roles" */
export type Group_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Group_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Group_Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "group_roles" */
export enum Group_Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id'
}

export type Group_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Group_Roles_Set_Input>;
  where: Group_Roles_Bool_Exp;
};

/** Boolean expression to compare columns of type "group_type". All fields are combined with logical 'AND'. */
export type Group_Type_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['group_type']>;
  _gt?: InputMaybe<Scalars['group_type']>;
  _gte?: InputMaybe<Scalars['group_type']>;
  _in?: InputMaybe<Array<Scalars['group_type']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['group_type']>;
  _lte?: InputMaybe<Scalars['group_type']>;
  _neq?: InputMaybe<Scalars['group_type']>;
  _nin?: InputMaybe<Array<Scalars['group_type']>>;
};

/** columns and relationships of "groups" */
export type Groups = {
  __typename?: 'groups';
  avatar?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  /** An array relationship */
  group_flags: Array<Group_Flags>;
  /** An aggregate relationship */
  group_flags_aggregate: Group_Flags_Aggregate;
  /** An array relationship */
  group_members: Array<Group_Members>;
  /** An aggregate relationship */
  group_members_aggregate: Group_Members_Aggregate;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  locale?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  type: Scalars['group_type'];
  updated_at: Scalars['timestamptz'];
  username: Scalars['String'];
  /** An array relationship */
  wallets: Array<Wallets>;
  /** An aggregate relationship */
  wallets_aggregate: Wallets_Aggregate;
};


/** columns and relationships of "groups" */
export type GroupsGroup_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Flags_Order_By>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsGroup_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Flags_Order_By>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsWalletsArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


/** columns and relationships of "groups" */
export type GroupsWallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};

/** aggregated selection of "groups" */
export type Groups_Aggregate = {
  __typename?: 'groups_aggregate';
  aggregate?: Maybe<Groups_Aggregate_Fields>;
  nodes: Array<Groups>;
};

/** aggregate fields of "groups" */
export type Groups_Aggregate_Fields = {
  __typename?: 'groups_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Groups_Max_Fields>;
  min?: Maybe<Groups_Min_Fields>;
};


/** aggregate fields of "groups" */
export type Groups_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Groups_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "groups". All fields are combined with a logical 'AND'. */
export type Groups_Bool_Exp = {
  _and?: InputMaybe<Array<Groups_Bool_Exp>>;
  _not?: InputMaybe<Groups_Bool_Exp>;
  _or?: InputMaybe<Array<Groups_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group_flags?: InputMaybe<Group_Flags_Bool_Exp>;
  group_members?: InputMaybe<Group_Members_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<Group_Type_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  wallets?: InputMaybe<Wallets_Bool_Exp>;
};

/** unique or primary key constraints on table "groups" */
export enum Groups_Constraint {
  /** unique or primary key constraint on columns "id" */
  GroupsPkey = 'groups_pkey',
  /** unique or primary key constraint on columns "username" */
  GroupsUsernameUnique = 'groups_username_unique'
}

/** input type for inserting data into table "groups" */
export type Groups_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_flags?: InputMaybe<Group_Flags_Arr_Rel_Insert_Input>;
  group_members?: InputMaybe<Group_Members_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['group_type']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
  wallets?: InputMaybe<Wallets_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Groups_Max_Fields = {
  __typename?: 'groups_max_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['group_type']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Groups_Min_Fields = {
  __typename?: 'groups_min_fields';
  avatar?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['group_type']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "groups" */
export type Groups_Mutation_Response = {
  __typename?: 'groups_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Groups>;
};

/** input type for inserting object relation for remote table "groups" */
export type Groups_Obj_Rel_Insert_Input = {
  data: Groups_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};

/** on_conflict condition type for table "groups" */
export type Groups_On_Conflict = {
  constraint: Groups_Constraint;
  update_columns?: Array<Groups_Update_Column>;
  where?: InputMaybe<Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "groups". */
export type Groups_Order_By = {
  avatar?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  group_flags_aggregate?: InputMaybe<Group_Flags_Aggregate_Order_By>;
  group_members_aggregate?: InputMaybe<Group_Members_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  username?: InputMaybe<Order_By>;
  wallets_aggregate?: InputMaybe<Wallets_Aggregate_Order_By>;
};

/** primary key columns input for table: groups */
export type Groups_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "groups" */
export enum Groups_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "groups" */
export type Groups_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['group_type']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "groups" */
export type Groups_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Groups_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Groups_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  locale?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['group_type']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "groups" */
export enum Groups_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Groups_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};

/** columns and relationships of "journal_entries" */
export type Journal_Entries = {
  __typename?: 'journal_entries';
  book_id: Scalars['uuid'];
  created_at: Scalars['timestamp'];
  entry_date: Scalars['timestamp'];
  id: Scalars['uuid'];
  memo: Scalars['String'];
  updated_at: Scalars['timestamp'];
  void_reason?: Maybe<Scalars['String']>;
  voided: Scalars['Boolean'];
};

/** aggregated selection of "journal_entries" */
export type Journal_Entries_Aggregate = {
  __typename?: 'journal_entries_aggregate';
  aggregate?: Maybe<Journal_Entries_Aggregate_Fields>;
  nodes: Array<Journal_Entries>;
};

/** aggregate fields of "journal_entries" */
export type Journal_Entries_Aggregate_Fields = {
  __typename?: 'journal_entries_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Journal_Entries_Max_Fields>;
  min?: Maybe<Journal_Entries_Min_Fields>;
};


/** aggregate fields of "journal_entries" */
export type Journal_Entries_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "journal_entries". All fields are combined with a logical 'AND'. */
export type Journal_Entries_Bool_Exp = {
  _and?: InputMaybe<Array<Journal_Entries_Bool_Exp>>;
  _not?: InputMaybe<Journal_Entries_Bool_Exp>;
  _or?: InputMaybe<Array<Journal_Entries_Bool_Exp>>;
  book_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  entry_date?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  memo?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  void_reason?: InputMaybe<String_Comparison_Exp>;
  voided?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "journal_entries" */
export enum Journal_Entries_Constraint {
  /** unique or primary key constraint on columns "id" */
  JournalEntriesIdIdx = 'journal_entries_id_idx',
  /** unique or primary key constraint on columns "id" */
  JournalEntriesPkey = 'journal_entries_pkey'
}

/** input type for inserting data into table "journal_entries" */
export type Journal_Entries_Insert_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  entry_date?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Journal_Entries_Max_Fields = {
  __typename?: 'journal_entries_max_fields';
  book_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  entry_date?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  memo?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  void_reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Journal_Entries_Min_Fields = {
  __typename?: 'journal_entries_min_fields';
  book_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  entry_date?: Maybe<Scalars['timestamp']>;
  id?: Maybe<Scalars['uuid']>;
  memo?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  void_reason?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "journal_entries" */
export type Journal_Entries_Mutation_Response = {
  __typename?: 'journal_entries_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Journal_Entries>;
};

/** on_conflict condition type for table "journal_entries" */
export type Journal_Entries_On_Conflict = {
  constraint: Journal_Entries_Constraint;
  update_columns?: Array<Journal_Entries_Update_Column>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};

/** Ordering options when selecting data from "journal_entries". */
export type Journal_Entries_Order_By = {
  book_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  entry_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
  voided?: InputMaybe<Order_By>;
};

/** primary key columns input for table: journal_entries */
export type Journal_Entries_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "journal_entries" */
export enum Journal_Entries_Select_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntryDate = 'entry_date',
  /** column name */
  Id = 'id',
  /** column name */
  Memo = 'memo',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoidReason = 'void_reason',
  /** column name */
  Voided = 'voided'
}

/** input type for updating data in table "journal_entries" */
export type Journal_Entries_Set_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  entry_date?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided?: InputMaybe<Scalars['Boolean']>;
};

/** Streaming cursor of the table "journal_entries" */
export type Journal_Entries_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Journal_Entries_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Journal_Entries_Stream_Cursor_Value_Input = {
  book_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  entry_date?: InputMaybe<Scalars['timestamp']>;
  id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided?: InputMaybe<Scalars['Boolean']>;
};

/** update columns of table "journal_entries" */
export enum Journal_Entries_Update_Column {
  /** column name */
  BookId = 'book_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EntryDate = 'entry_date',
  /** column name */
  Id = 'id',
  /** column name */
  Memo = 'memo',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoidReason = 'void_reason',
  /** column name */
  Voided = 'voided'
}

export type Journal_Entries_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Journal_Entries_Set_Input>;
  where: Journal_Entries_Bool_Exp;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']>;
  _gt?: InputMaybe<Scalars['json']>;
  _gte?: InputMaybe<Scalars['json']>;
  _in?: InputMaybe<Array<Scalars['json']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['json']>;
  _lte?: InputMaybe<Scalars['json']>;
  _neq?: InputMaybe<Scalars['json']>;
  _nin?: InputMaybe<Array<Scalars['json']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "likes" */
export type Likes = {
  __typename?: 'likes';
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  target_id: Scalars['uuid'];
  target_type?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
};

/** aggregated selection of "likes" */
export type Likes_Aggregate = {
  __typename?: 'likes_aggregate';
  aggregate?: Maybe<Likes_Aggregate_Fields>;
  nodes: Array<Likes>;
};

/** aggregate fields of "likes" */
export type Likes_Aggregate_Fields = {
  __typename?: 'likes_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Likes_Max_Fields>;
  min?: Maybe<Likes_Min_Fields>;
};


/** aggregate fields of "likes" */
export type Likes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Likes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "likes" */
export type Likes_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Likes_Max_Order_By>;
  min?: InputMaybe<Likes_Min_Order_By>;
};

/** input type for inserting array relation for remote table "likes" */
export type Likes_Arr_Rel_Insert_Input = {
  data: Array<Likes_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Likes_On_Conflict>;
};

/** Boolean expression to filter rows from the table "likes". All fields are combined with a logical 'AND'. */
export type Likes_Bool_Exp = {
  _and?: InputMaybe<Array<Likes_Bool_Exp>>;
  _not?: InputMaybe<Likes_Bool_Exp>;
  _or?: InputMaybe<Array<Likes_Bool_Exp>>;
  author_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  target_id?: InputMaybe<Uuid_Comparison_Exp>;
  target_type?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "likes" */
export enum Likes_Constraint {
  /** unique or primary key constraint on columns "id" */
  LikesPkey = 'likes_pkey',
  /** unique or primary key constraint on columns "author_id", "target_id", "target_type" */
  LikesTargetIdTargetTypeAuthorIdUnique = 'likes_target_id_target_type_author_id_unique'
}

/** input type for inserting data into table "likes" */
export type Likes_Insert_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['uuid']>;
  target_type?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Likes_Max_Fields = {
  __typename?: 'likes_max_fields';
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
  target_type?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "likes" */
export type Likes_Max_Order_By = {
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Likes_Min_Fields = {
  __typename?: 'likes_min_fields';
  author_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
  target_type?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "likes" */
export type Likes_Min_Order_By = {
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "likes" */
export type Likes_Mutation_Response = {
  __typename?: 'likes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Likes>;
};

/** on_conflict condition type for table "likes" */
export type Likes_On_Conflict = {
  constraint: Likes_Constraint;
  update_columns?: Array<Likes_Update_Column>;
  where?: InputMaybe<Likes_Bool_Exp>;
};

/** Ordering options when selecting data from "likes". */
export type Likes_Order_By = {
  author_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: likes */
export type Likes_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "likes" */
export enum Likes_Select_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type'
}

/** input type for updating data in table "likes" */
export type Likes_Set_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['uuid']>;
  target_type?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "likes" */
export type Likes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Likes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Likes_Stream_Cursor_Value_Input = {
  author_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['uuid']>;
  target_type?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "likes" */
export enum Likes_Update_Column {
  /** column name */
  AuthorId = 'author_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type'
}

export type Likes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Likes_Set_Input>;
  where: Likes_Bool_Exp;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  addGame?: Maybe<AddGameOutput>;
  addGoogleAccount?: Maybe<AddGoogleAccountOutput>;
  /** 3rd Party App Setup */
  addThirdPartyApp?: Maybe<AddThirdPartyAppOutput>;
  adminLogin?: Maybe<LoginOutput>;
  changePassword?: Maybe<ChangePasswordOutput>;
  confirmPayment?: Maybe<ConfirmPaymentOutput>;
  createWallet?: Maybe<CreateWalletOutput>;
  deleteAccount?: Maybe<DeleteAccountOutput>;
  /** delete data from the table: "audit_log_entries" */
  delete_audit_log_entries?: Maybe<Audit_Log_Entries_Mutation_Response>;
  /** delete single row from the table: "audit_log_entries" */
  delete_audit_log_entries_by_pk?: Maybe<Audit_Log_Entries>;
  /** delete data from the table: "blog_flags" */
  delete_blog_flags?: Maybe<Blog_Flags_Mutation_Response>;
  /** delete single row from the table: "blog_flags" */
  delete_blog_flags_by_pk?: Maybe<Blog_Flags>;
  /** delete data from the table: "blogs" */
  delete_blogs?: Maybe<Blogs_Mutation_Response>;
  /** delete single row from the table: "blogs" */
  delete_blogs_by_pk?: Maybe<Blogs>;
  /** delete data from the table: "books" */
  delete_books?: Maybe<Books_Mutation_Response>;
  /** delete single row from the table: "books" */
  delete_books_by_pk?: Maybe<Books>;
  /** delete data from the table: "channel_members" */
  delete_channel_members?: Maybe<Channel_Members_Mutation_Response>;
  /** delete single row from the table: "channel_members" */
  delete_channel_members_by_pk?: Maybe<Channel_Members>;
  /** delete data from the table: "channels" */
  delete_channels?: Maybe<Channels_Mutation_Response>;
  /** delete single row from the table: "channels" */
  delete_channels_by_pk?: Maybe<Channels>;
  /** delete data from the table: "clients" */
  delete_clients?: Maybe<Clients_Mutation_Response>;
  /** delete single row from the table: "clients" */
  delete_clients_by_pk?: Maybe<Clients>;
  /** delete data from the table: "comment_mentions" */
  delete_comment_mentions?: Maybe<Comment_Mentions_Mutation_Response>;
  /** delete single row from the table: "comment_mentions" */
  delete_comment_mentions_by_pk?: Maybe<Comment_Mentions>;
  /** delete data from the table: "comments" */
  delete_comments?: Maybe<Comments_Mutation_Response>;
  /** delete single row from the table: "comments" */
  delete_comments_by_pk?: Maybe<Comments>;
  /** delete data from the table: "events" */
  delete_events?: Maybe<Events_Mutation_Response>;
  /** delete single row from the table: "events" */
  delete_events_by_pk?: Maybe<Events>;
  /** delete data from the table: "featured_games" */
  delete_featured_games?: Maybe<Featured_Games_Mutation_Response>;
  /** delete single row from the table: "featured_games" */
  delete_featured_games_by_pk?: Maybe<Featured_Games>;
  /** delete data from the table: "file_storage_providers" */
  delete_file_storage_providers?: Maybe<File_Storage_Providers_Mutation_Response>;
  /** delete single row from the table: "file_storage_providers" */
  delete_file_storage_providers_by_pk?: Maybe<File_Storage_Providers>;
  /** delete data from the table: "files" */
  delete_files?: Maybe<Files_Mutation_Response>;
  /** delete single row from the table: "files" */
  delete_files_by_pk?: Maybe<Files>;
  /** delete data from the table: "flags" */
  delete_flags?: Maybe<Flags_Mutation_Response>;
  /** delete single row from the table: "flags" */
  delete_flags_by_pk?: Maybe<Flags>;
  /** delete data from the table: "game_comments" */
  delete_game_comments?: Maybe<Game_Comments_Mutation_Response>;
  /** delete single row from the table: "game_comments" */
  delete_game_comments_by_pk?: Maybe<Game_Comments>;
  /** delete data from the table: "game_files" */
  delete_game_files?: Maybe<Game_Files_Mutation_Response>;
  /** delete single row from the table: "game_files" */
  delete_game_files_by_pk?: Maybe<Game_Files>;
  /** delete data from the table: "game_flags" */
  delete_game_flags?: Maybe<Game_Flags_Mutation_Response>;
  /** delete single row from the table: "game_flags" */
  delete_game_flags_by_pk?: Maybe<Game_Flags>;
  /** delete data from the table: "game_genres" */
  delete_game_genres?: Maybe<Game_Genres_Mutation_Response>;
  /** delete single row from the table: "game_genres" */
  delete_game_genres_by_pk?: Maybe<Game_Genres>;
  /** delete data from the table: "game_ratings" */
  delete_game_ratings?: Maybe<Game_Ratings_Mutation_Response>;
  /** delete single row from the table: "game_ratings" */
  delete_game_ratings_by_pk?: Maybe<Game_Ratings>;
  /** delete data from the table: "game_serving_points" */
  delete_game_serving_points?: Maybe<Game_Serving_Points_Mutation_Response>;
  /** delete single row from the table: "game_serving_points" */
  delete_game_serving_points_by_pk?: Maybe<Game_Serving_Points>;
  /** delete data from the table: "games" */
  delete_games?: Maybe<Games_Mutation_Response>;
  /** delete single row from the table: "games" */
  delete_games_by_pk?: Maybe<Games>;
  /** delete data from the table: "group_flags" */
  delete_group_flags?: Maybe<Group_Flags_Mutation_Response>;
  /** delete single row from the table: "group_flags" */
  delete_group_flags_by_pk?: Maybe<Group_Flags>;
  /** delete data from the table: "group_members" */
  delete_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** delete single row from the table: "group_members" */
  delete_group_members_by_pk?: Maybe<Group_Members>;
  /** delete data from the table: "group_permissions" */
  delete_group_permissions?: Maybe<Group_Permissions_Mutation_Response>;
  /** delete single row from the table: "group_permissions" */
  delete_group_permissions_by_pk?: Maybe<Group_Permissions>;
  /** delete data from the table: "group_roles" */
  delete_group_roles?: Maybe<Group_Roles_Mutation_Response>;
  /** delete single row from the table: "group_roles" */
  delete_group_roles_by_pk?: Maybe<Group_Roles>;
  /** delete data from the table: "groups" */
  delete_groups?: Maybe<Groups_Mutation_Response>;
  /** delete single row from the table: "groups" */
  delete_groups_by_pk?: Maybe<Groups>;
  /** delete data from the table: "journal_entries" */
  delete_journal_entries?: Maybe<Journal_Entries_Mutation_Response>;
  /** delete single row from the table: "journal_entries" */
  delete_journal_entries_by_pk?: Maybe<Journal_Entries>;
  /** delete data from the table: "likes" */
  delete_likes?: Maybe<Likes_Mutation_Response>;
  /** delete single row from the table: "likes" */
  delete_likes_by_pk?: Maybe<Likes>;
  /** delete data from the table: "notification_recipients" */
  delete_notification_recipients?: Maybe<Notification_Recipients_Mutation_Response>;
  /** delete single row from the table: "notification_recipients" */
  delete_notification_recipients_by_pk?: Maybe<Notification_Recipients>;
  /** delete data from the table: "notification_statuses" */
  delete_notification_statuses?: Maybe<Notification_Statuses_Mutation_Response>;
  /** delete single row from the table: "notification_statuses" */
  delete_notification_statuses_by_pk?: Maybe<Notification_Statuses>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "oidc.oidc_payloads" */
  delete_oidc_oidc_payloads?: Maybe<Oidc_Oidc_Payloads_Mutation_Response>;
  /** delete single row from the table: "oidc.oidc_payloads" */
  delete_oidc_oidc_payloads_by_pk?: Maybe<Oidc_Oidc_Payloads>;
  /** delete data from the table: "pages" */
  delete_pages?: Maybe<Pages_Mutation_Response>;
  /** delete single row from the table: "pages" */
  delete_pages_by_pk?: Maybe<Pages>;
  /** delete data from the table: "password_resets" */
  delete_password_resets?: Maybe<Password_Resets_Mutation_Response>;
  /** delete single row from the table: "password_resets" */
  delete_password_resets_by_pk?: Maybe<Password_Resets>;
  /** delete data from the table: "post_authors" */
  delete_post_authors?: Maybe<Post_Authors_Mutation_Response>;
  /** delete single row from the table: "post_authors" */
  delete_post_authors_by_pk?: Maybe<Post_Authors>;
  /** delete data from the table: "post_flags" */
  delete_post_flags?: Maybe<Post_Flags_Mutation_Response>;
  /** delete single row from the table: "post_flags" */
  delete_post_flags_by_pk?: Maybe<Post_Flags>;
  /** delete data from the table: "post_tags" */
  delete_post_tags?: Maybe<Post_Tags_Mutation_Response>;
  /** delete single row from the table: "post_tags" */
  delete_post_tags_by_pk?: Maybe<Post_Tags>;
  /** delete data from the table: "posts" */
  delete_posts?: Maybe<Posts_Mutation_Response>;
  /** delete single row from the table: "posts" */
  delete_posts_by_pk?: Maybe<Posts>;
  /** delete data from the table: "profiles" */
  delete_profiles?: Maybe<Profiles_Mutation_Response>;
  /** delete single row from the table: "profiles" */
  delete_profiles_by_pk?: Maybe<Profiles>;
  /** delete data from the table: "purchases" */
  delete_purchases?: Maybe<Purchases_Mutation_Response>;
  /** delete single row from the table: "purchases" */
  delete_purchases_by_pk?: Maybe<Purchases>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "serving_points" */
  delete_serving_points?: Maybe<Serving_Points_Mutation_Response>;
  /** delete single row from the table: "serving_points" */
  delete_serving_points_by_pk?: Maybe<Serving_Points>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "oidc.oidc_clients" */
  delete_third_party_apps?: Maybe<Third_Party_Apps_Mutation_Response>;
  /** delete single row from the table: "oidc.oidc_clients" */
  delete_third_party_apps_by_pk?: Maybe<Third_Party_Apps>;
  /** delete data from the table: "transactions" */
  delete_transactions?: Maybe<Transactions_Mutation_Response>;
  /** delete single row from the table: "transactions" */
  delete_transactions_by_pk?: Maybe<Transactions>;
  /** delete data from the table: "upload_tags" */
  delete_upload_tags?: Maybe<Upload_Tags_Mutation_Response>;
  /** delete single row from the table: "upload_tags" */
  delete_upload_tags_by_pk?: Maybe<Upload_Tags>;
  /** delete data from the table: "uploads" */
  delete_uploads?: Maybe<Uploads_Mutation_Response>;
  /** delete single row from the table: "uploads" */
  delete_uploads_by_pk?: Maybe<Uploads>;
  /** delete data from the table: "user_flags" */
  delete_user_flags?: Maybe<User_Flags_Mutation_Response>;
  /** delete single row from the table: "user_flags" */
  delete_user_flags_by_pk?: Maybe<User_Flags>;
  /** delete data from the table: "user_group_roles" */
  delete_user_group_roles?: Maybe<User_Group_Roles_Mutation_Response>;
  /** delete single row from the table: "user_group_roles" */
  delete_user_group_roles_by_pk?: Maybe<User_Group_Roles>;
  /** delete data from the table: "user_roles" */
  delete_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** delete single row from the table: "user_roles" */
  delete_user_roles_by_pk?: Maybe<User_Roles>;
  /** delete data from the table: "user_social_logins" */
  delete_user_social_logins?: Maybe<User_Social_Logins_Mutation_Response>;
  /** delete single row from the table: "user_social_logins" */
  delete_user_social_logins_by_pk?: Maybe<User_Social_Logins>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "verifications" */
  delete_verifications?: Maybe<Verifications_Mutation_Response>;
  /** delete single row from the table: "verifications" */
  delete_verifications_by_pk?: Maybe<Verifications>;
  /** delete data from the table: "wallet_transactions" */
  delete_wallet_transactions?: Maybe<Wallet_Transactions_Mutation_Response>;
  /** delete single row from the table: "wallet_transactions" */
  delete_wallet_transactions_by_pk?: Maybe<Wallet_Transactions>;
  /** delete data from the table: "wallets" */
  delete_wallets?: Maybe<Wallets_Mutation_Response>;
  /** delete single row from the table: "wallets" */
  delete_wallets_by_pk?: Maybe<Wallets>;
  generateResetPasswordToken?: Maybe<GenerateResetPasswordTokenOutput>;
  getGAGamesReport?: Maybe<GaGamesReportOutput>;
  getGASessionsReport?: Maybe<GaSessionsReportOutput>;
  googleLogin?: Maybe<GoogleLoginOutput>;
  /** insert data into the table: "audit_log_entries" */
  insert_audit_log_entries?: Maybe<Audit_Log_Entries_Mutation_Response>;
  /** insert a single row into the table: "audit_log_entries" */
  insert_audit_log_entries_one?: Maybe<Audit_Log_Entries>;
  /** insert data into the table: "blog_flags" */
  insert_blog_flags?: Maybe<Blog_Flags_Mutation_Response>;
  /** insert a single row into the table: "blog_flags" */
  insert_blog_flags_one?: Maybe<Blog_Flags>;
  /** insert data into the table: "blogs" */
  insert_blogs?: Maybe<Blogs_Mutation_Response>;
  /** insert a single row into the table: "blogs" */
  insert_blogs_one?: Maybe<Blogs>;
  /** insert data into the table: "books" */
  insert_books?: Maybe<Books_Mutation_Response>;
  /** insert a single row into the table: "books" */
  insert_books_one?: Maybe<Books>;
  /** insert data into the table: "channel_members" */
  insert_channel_members?: Maybe<Channel_Members_Mutation_Response>;
  /** insert a single row into the table: "channel_members" */
  insert_channel_members_one?: Maybe<Channel_Members>;
  /** insert data into the table: "channels" */
  insert_channels?: Maybe<Channels_Mutation_Response>;
  /** insert a single row into the table: "channels" */
  insert_channels_one?: Maybe<Channels>;
  /** insert data into the table: "clients" */
  insert_clients?: Maybe<Clients_Mutation_Response>;
  /** insert a single row into the table: "clients" */
  insert_clients_one?: Maybe<Clients>;
  /** insert data into the table: "comment_mentions" */
  insert_comment_mentions?: Maybe<Comment_Mentions_Mutation_Response>;
  /** insert a single row into the table: "comment_mentions" */
  insert_comment_mentions_one?: Maybe<Comment_Mentions>;
  /** insert data into the table: "comments" */
  insert_comments?: Maybe<Comments_Mutation_Response>;
  /** insert a single row into the table: "comments" */
  insert_comments_one?: Maybe<Comments>;
  /** insert data into the table: "events" */
  insert_events?: Maybe<Events_Mutation_Response>;
  /** insert a single row into the table: "events" */
  insert_events_one?: Maybe<Events>;
  /** insert data into the table: "featured_games" */
  insert_featured_games?: Maybe<Featured_Games_Mutation_Response>;
  /** insert a single row into the table: "featured_games" */
  insert_featured_games_one?: Maybe<Featured_Games>;
  /** insert data into the table: "file_storage_providers" */
  insert_file_storage_providers?: Maybe<File_Storage_Providers_Mutation_Response>;
  /** insert a single row into the table: "file_storage_providers" */
  insert_file_storage_providers_one?: Maybe<File_Storage_Providers>;
  /** insert data into the table: "files" */
  insert_files?: Maybe<Files_Mutation_Response>;
  /** insert a single row into the table: "files" */
  insert_files_one?: Maybe<Files>;
  /** insert data into the table: "flags" */
  insert_flags?: Maybe<Flags_Mutation_Response>;
  /** insert a single row into the table: "flags" */
  insert_flags_one?: Maybe<Flags>;
  /** insert data into the table: "game_comments" */
  insert_game_comments?: Maybe<Game_Comments_Mutation_Response>;
  /** insert a single row into the table: "game_comments" */
  insert_game_comments_one?: Maybe<Game_Comments>;
  /** insert data into the table: "game_files" */
  insert_game_files?: Maybe<Game_Files_Mutation_Response>;
  /** insert a single row into the table: "game_files" */
  insert_game_files_one?: Maybe<Game_Files>;
  /** insert data into the table: "game_flags" */
  insert_game_flags?: Maybe<Game_Flags_Mutation_Response>;
  /** insert a single row into the table: "game_flags" */
  insert_game_flags_one?: Maybe<Game_Flags>;
  /** insert data into the table: "game_genres" */
  insert_game_genres?: Maybe<Game_Genres_Mutation_Response>;
  /** insert a single row into the table: "game_genres" */
  insert_game_genres_one?: Maybe<Game_Genres>;
  /** insert data into the table: "game_ratings" */
  insert_game_ratings?: Maybe<Game_Ratings_Mutation_Response>;
  /** insert a single row into the table: "game_ratings" */
  insert_game_ratings_one?: Maybe<Game_Ratings>;
  /** insert data into the table: "game_serving_points" */
  insert_game_serving_points?: Maybe<Game_Serving_Points_Mutation_Response>;
  /** insert a single row into the table: "game_serving_points" */
  insert_game_serving_points_one?: Maybe<Game_Serving_Points>;
  /** insert data into the table: "games" */
  insert_games?: Maybe<Games_Mutation_Response>;
  /** insert a single row into the table: "games" */
  insert_games_one?: Maybe<Games>;
  /** insert data into the table: "group_flags" */
  insert_group_flags?: Maybe<Group_Flags_Mutation_Response>;
  /** insert a single row into the table: "group_flags" */
  insert_group_flags_one?: Maybe<Group_Flags>;
  /** insert data into the table: "group_members" */
  insert_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** insert a single row into the table: "group_members" */
  insert_group_members_one?: Maybe<Group_Members>;
  /** insert data into the table: "group_permissions" */
  insert_group_permissions?: Maybe<Group_Permissions_Mutation_Response>;
  /** insert a single row into the table: "group_permissions" */
  insert_group_permissions_one?: Maybe<Group_Permissions>;
  /** insert data into the table: "group_roles" */
  insert_group_roles?: Maybe<Group_Roles_Mutation_Response>;
  /** insert a single row into the table: "group_roles" */
  insert_group_roles_one?: Maybe<Group_Roles>;
  /** insert data into the table: "groups" */
  insert_groups?: Maybe<Groups_Mutation_Response>;
  /** insert a single row into the table: "groups" */
  insert_groups_one?: Maybe<Groups>;
  /** insert data into the table: "journal_entries" */
  insert_journal_entries?: Maybe<Journal_Entries_Mutation_Response>;
  /** insert a single row into the table: "journal_entries" */
  insert_journal_entries_one?: Maybe<Journal_Entries>;
  /** insert data into the table: "likes" */
  insert_likes?: Maybe<Likes_Mutation_Response>;
  /** insert a single row into the table: "likes" */
  insert_likes_one?: Maybe<Likes>;
  /** insert data into the table: "notification_recipients" */
  insert_notification_recipients?: Maybe<Notification_Recipients_Mutation_Response>;
  /** insert a single row into the table: "notification_recipients" */
  insert_notification_recipients_one?: Maybe<Notification_Recipients>;
  /** insert data into the table: "notification_statuses" */
  insert_notification_statuses?: Maybe<Notification_Statuses_Mutation_Response>;
  /** insert a single row into the table: "notification_statuses" */
  insert_notification_statuses_one?: Maybe<Notification_Statuses>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "oidc.oidc_payloads" */
  insert_oidc_oidc_payloads?: Maybe<Oidc_Oidc_Payloads_Mutation_Response>;
  /** insert a single row into the table: "oidc.oidc_payloads" */
  insert_oidc_oidc_payloads_one?: Maybe<Oidc_Oidc_Payloads>;
  /** insert data into the table: "pages" */
  insert_pages?: Maybe<Pages_Mutation_Response>;
  /** insert a single row into the table: "pages" */
  insert_pages_one?: Maybe<Pages>;
  /** insert data into the table: "password_resets" */
  insert_password_resets?: Maybe<Password_Resets_Mutation_Response>;
  /** insert a single row into the table: "password_resets" */
  insert_password_resets_one?: Maybe<Password_Resets>;
  /** insert data into the table: "post_authors" */
  insert_post_authors?: Maybe<Post_Authors_Mutation_Response>;
  /** insert a single row into the table: "post_authors" */
  insert_post_authors_one?: Maybe<Post_Authors>;
  /** insert data into the table: "post_flags" */
  insert_post_flags?: Maybe<Post_Flags_Mutation_Response>;
  /** insert a single row into the table: "post_flags" */
  insert_post_flags_one?: Maybe<Post_Flags>;
  /** insert data into the table: "post_tags" */
  insert_post_tags?: Maybe<Post_Tags_Mutation_Response>;
  /** insert a single row into the table: "post_tags" */
  insert_post_tags_one?: Maybe<Post_Tags>;
  /** insert data into the table: "posts" */
  insert_posts?: Maybe<Posts_Mutation_Response>;
  /** insert a single row into the table: "posts" */
  insert_posts_one?: Maybe<Posts>;
  /** insert data into the table: "profiles" */
  insert_profiles?: Maybe<Profiles_Mutation_Response>;
  /** insert a single row into the table: "profiles" */
  insert_profiles_one?: Maybe<Profiles>;
  /** insert data into the table: "purchases" */
  insert_purchases?: Maybe<Purchases_Mutation_Response>;
  /** insert a single row into the table: "purchases" */
  insert_purchases_one?: Maybe<Purchases>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "serving_points" */
  insert_serving_points?: Maybe<Serving_Points_Mutation_Response>;
  /** insert a single row into the table: "serving_points" */
  insert_serving_points_one?: Maybe<Serving_Points>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "oidc.oidc_clients" */
  insert_third_party_apps?: Maybe<Third_Party_Apps_Mutation_Response>;
  /** insert a single row into the table: "oidc.oidc_clients" */
  insert_third_party_apps_one?: Maybe<Third_Party_Apps>;
  /** insert data into the table: "transactions" */
  insert_transactions?: Maybe<Transactions_Mutation_Response>;
  /** insert a single row into the table: "transactions" */
  insert_transactions_one?: Maybe<Transactions>;
  /** insert data into the table: "upload_tags" */
  insert_upload_tags?: Maybe<Upload_Tags_Mutation_Response>;
  /** insert a single row into the table: "upload_tags" */
  insert_upload_tags_one?: Maybe<Upload_Tags>;
  /** insert data into the table: "uploads" */
  insert_uploads?: Maybe<Uploads_Mutation_Response>;
  /** insert a single row into the table: "uploads" */
  insert_uploads_one?: Maybe<Uploads>;
  /** insert data into the table: "user_flags" */
  insert_user_flags?: Maybe<User_Flags_Mutation_Response>;
  /** insert a single row into the table: "user_flags" */
  insert_user_flags_one?: Maybe<User_Flags>;
  /** insert data into the table: "user_group_roles" */
  insert_user_group_roles?: Maybe<User_Group_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_group_roles" */
  insert_user_group_roles_one?: Maybe<User_Group_Roles>;
  /** insert data into the table: "user_roles" */
  insert_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** insert a single row into the table: "user_roles" */
  insert_user_roles_one?: Maybe<User_Roles>;
  /** insert data into the table: "user_social_logins" */
  insert_user_social_logins?: Maybe<User_Social_Logins_Mutation_Response>;
  /** insert a single row into the table: "user_social_logins" */
  insert_user_social_logins_one?: Maybe<User_Social_Logins>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "verifications" */
  insert_verifications?: Maybe<Verifications_Mutation_Response>;
  /** insert a single row into the table: "verifications" */
  insert_verifications_one?: Maybe<Verifications>;
  /** insert data into the table: "wallet_transactions" */
  insert_wallet_transactions?: Maybe<Wallet_Transactions_Mutation_Response>;
  /** insert a single row into the table: "wallet_transactions" */
  insert_wallet_transactions_one?: Maybe<Wallet_Transactions>;
  /** insert data into the table: "wallets" */
  insert_wallets?: Maybe<Wallets_Mutation_Response>;
  /** insert a single row into the table: "wallets" */
  insert_wallets_one?: Maybe<Wallets>;
  login?: Maybe<LoginOutput>;
  makePurchaseRequest?: Maybe<PurchaseOutput>;
  /** Moves generated cowrie to users Celo Wallet */
  offRampCelo?: Maybe<OffRampCeloOutput>;
  resetPassword?: Maybe<ResetPasswordOutput>;
  sendNotification?: Maybe<SendNotificationOutput>;
  sendToCelo?: Maybe<SendToCeloOutput>;
  setupMFA?: Maybe<SetupMfaOutput>;
  signup?: Maybe<SignupOutput>;
  testTopupWallet?: Maybe<TestTopupWalletOutput>;
  topUpJiweWallet: TopUpJiweWalletOutput;
  /** update data of the table: "audit_log_entries" */
  update_audit_log_entries?: Maybe<Audit_Log_Entries_Mutation_Response>;
  /** update single row of the table: "audit_log_entries" */
  update_audit_log_entries_by_pk?: Maybe<Audit_Log_Entries>;
  /** update multiples rows of table: "audit_log_entries" */
  update_audit_log_entries_many?: Maybe<Array<Maybe<Audit_Log_Entries_Mutation_Response>>>;
  /** update data of the table: "blog_flags" */
  update_blog_flags?: Maybe<Blog_Flags_Mutation_Response>;
  /** update single row of the table: "blog_flags" */
  update_blog_flags_by_pk?: Maybe<Blog_Flags>;
  /** update multiples rows of table: "blog_flags" */
  update_blog_flags_many?: Maybe<Array<Maybe<Blog_Flags_Mutation_Response>>>;
  /** update data of the table: "blogs" */
  update_blogs?: Maybe<Blogs_Mutation_Response>;
  /** update single row of the table: "blogs" */
  update_blogs_by_pk?: Maybe<Blogs>;
  /** update multiples rows of table: "blogs" */
  update_blogs_many?: Maybe<Array<Maybe<Blogs_Mutation_Response>>>;
  /** update data of the table: "books" */
  update_books?: Maybe<Books_Mutation_Response>;
  /** update single row of the table: "books" */
  update_books_by_pk?: Maybe<Books>;
  /** update multiples rows of table: "books" */
  update_books_many?: Maybe<Array<Maybe<Books_Mutation_Response>>>;
  /** update data of the table: "channel_members" */
  update_channel_members?: Maybe<Channel_Members_Mutation_Response>;
  /** update single row of the table: "channel_members" */
  update_channel_members_by_pk?: Maybe<Channel_Members>;
  /** update multiples rows of table: "channel_members" */
  update_channel_members_many?: Maybe<Array<Maybe<Channel_Members_Mutation_Response>>>;
  /** update data of the table: "channels" */
  update_channels?: Maybe<Channels_Mutation_Response>;
  /** update single row of the table: "channels" */
  update_channels_by_pk?: Maybe<Channels>;
  /** update multiples rows of table: "channels" */
  update_channels_many?: Maybe<Array<Maybe<Channels_Mutation_Response>>>;
  /** update data of the table: "clients" */
  update_clients?: Maybe<Clients_Mutation_Response>;
  /** update single row of the table: "clients" */
  update_clients_by_pk?: Maybe<Clients>;
  /** update multiples rows of table: "clients" */
  update_clients_many?: Maybe<Array<Maybe<Clients_Mutation_Response>>>;
  /** update data of the table: "comment_mentions" */
  update_comment_mentions?: Maybe<Comment_Mentions_Mutation_Response>;
  /** update single row of the table: "comment_mentions" */
  update_comment_mentions_by_pk?: Maybe<Comment_Mentions>;
  /** update multiples rows of table: "comment_mentions" */
  update_comment_mentions_many?: Maybe<Array<Maybe<Comment_Mentions_Mutation_Response>>>;
  /** update data of the table: "comments" */
  update_comments?: Maybe<Comments_Mutation_Response>;
  /** update single row of the table: "comments" */
  update_comments_by_pk?: Maybe<Comments>;
  /** update multiples rows of table: "comments" */
  update_comments_many?: Maybe<Array<Maybe<Comments_Mutation_Response>>>;
  /** update data of the table: "events" */
  update_events?: Maybe<Events_Mutation_Response>;
  /** update single row of the table: "events" */
  update_events_by_pk?: Maybe<Events>;
  /** update multiples rows of table: "events" */
  update_events_many?: Maybe<Array<Maybe<Events_Mutation_Response>>>;
  /** update data of the table: "featured_games" */
  update_featured_games?: Maybe<Featured_Games_Mutation_Response>;
  /** update single row of the table: "featured_games" */
  update_featured_games_by_pk?: Maybe<Featured_Games>;
  /** update multiples rows of table: "featured_games" */
  update_featured_games_many?: Maybe<Array<Maybe<Featured_Games_Mutation_Response>>>;
  /** update data of the table: "file_storage_providers" */
  update_file_storage_providers?: Maybe<File_Storage_Providers_Mutation_Response>;
  /** update single row of the table: "file_storage_providers" */
  update_file_storage_providers_by_pk?: Maybe<File_Storage_Providers>;
  /** update multiples rows of table: "file_storage_providers" */
  update_file_storage_providers_many?: Maybe<Array<Maybe<File_Storage_Providers_Mutation_Response>>>;
  /** update data of the table: "files" */
  update_files?: Maybe<Files_Mutation_Response>;
  /** update single row of the table: "files" */
  update_files_by_pk?: Maybe<Files>;
  /** update multiples rows of table: "files" */
  update_files_many?: Maybe<Array<Maybe<Files_Mutation_Response>>>;
  /** update data of the table: "flags" */
  update_flags?: Maybe<Flags_Mutation_Response>;
  /** update single row of the table: "flags" */
  update_flags_by_pk?: Maybe<Flags>;
  /** update multiples rows of table: "flags" */
  update_flags_many?: Maybe<Array<Maybe<Flags_Mutation_Response>>>;
  /** update data of the table: "game_comments" */
  update_game_comments?: Maybe<Game_Comments_Mutation_Response>;
  /** update single row of the table: "game_comments" */
  update_game_comments_by_pk?: Maybe<Game_Comments>;
  /** update multiples rows of table: "game_comments" */
  update_game_comments_many?: Maybe<Array<Maybe<Game_Comments_Mutation_Response>>>;
  /** update data of the table: "game_files" */
  update_game_files?: Maybe<Game_Files_Mutation_Response>;
  /** update single row of the table: "game_files" */
  update_game_files_by_pk?: Maybe<Game_Files>;
  /** update multiples rows of table: "game_files" */
  update_game_files_many?: Maybe<Array<Maybe<Game_Files_Mutation_Response>>>;
  /** update data of the table: "game_flags" */
  update_game_flags?: Maybe<Game_Flags_Mutation_Response>;
  /** update single row of the table: "game_flags" */
  update_game_flags_by_pk?: Maybe<Game_Flags>;
  /** update multiples rows of table: "game_flags" */
  update_game_flags_many?: Maybe<Array<Maybe<Game_Flags_Mutation_Response>>>;
  /** update data of the table: "game_genres" */
  update_game_genres?: Maybe<Game_Genres_Mutation_Response>;
  /** update single row of the table: "game_genres" */
  update_game_genres_by_pk?: Maybe<Game_Genres>;
  /** update multiples rows of table: "game_genres" */
  update_game_genres_many?: Maybe<Array<Maybe<Game_Genres_Mutation_Response>>>;
  /** update data of the table: "game_ratings" */
  update_game_ratings?: Maybe<Game_Ratings_Mutation_Response>;
  /** update single row of the table: "game_ratings" */
  update_game_ratings_by_pk?: Maybe<Game_Ratings>;
  /** update multiples rows of table: "game_ratings" */
  update_game_ratings_many?: Maybe<Array<Maybe<Game_Ratings_Mutation_Response>>>;
  /** update data of the table: "game_serving_points" */
  update_game_serving_points?: Maybe<Game_Serving_Points_Mutation_Response>;
  /** update single row of the table: "game_serving_points" */
  update_game_serving_points_by_pk?: Maybe<Game_Serving_Points>;
  /** update multiples rows of table: "game_serving_points" */
  update_game_serving_points_many?: Maybe<Array<Maybe<Game_Serving_Points_Mutation_Response>>>;
  /** update data of the table: "games" */
  update_games?: Maybe<Games_Mutation_Response>;
  /** update single row of the table: "games" */
  update_games_by_pk?: Maybe<Games>;
  /** update multiples rows of table: "games" */
  update_games_many?: Maybe<Array<Maybe<Games_Mutation_Response>>>;
  /** update data of the table: "group_flags" */
  update_group_flags?: Maybe<Group_Flags_Mutation_Response>;
  /** update single row of the table: "group_flags" */
  update_group_flags_by_pk?: Maybe<Group_Flags>;
  /** update multiples rows of table: "group_flags" */
  update_group_flags_many?: Maybe<Array<Maybe<Group_Flags_Mutation_Response>>>;
  /** update data of the table: "group_members" */
  update_group_members?: Maybe<Group_Members_Mutation_Response>;
  /** update single row of the table: "group_members" */
  update_group_members_by_pk?: Maybe<Group_Members>;
  /** update multiples rows of table: "group_members" */
  update_group_members_many?: Maybe<Array<Maybe<Group_Members_Mutation_Response>>>;
  /** update data of the table: "group_permissions" */
  update_group_permissions?: Maybe<Group_Permissions_Mutation_Response>;
  /** update single row of the table: "group_permissions" */
  update_group_permissions_by_pk?: Maybe<Group_Permissions>;
  /** update multiples rows of table: "group_permissions" */
  update_group_permissions_many?: Maybe<Array<Maybe<Group_Permissions_Mutation_Response>>>;
  /** update data of the table: "group_roles" */
  update_group_roles?: Maybe<Group_Roles_Mutation_Response>;
  /** update single row of the table: "group_roles" */
  update_group_roles_by_pk?: Maybe<Group_Roles>;
  /** update multiples rows of table: "group_roles" */
  update_group_roles_many?: Maybe<Array<Maybe<Group_Roles_Mutation_Response>>>;
  /** update data of the table: "groups" */
  update_groups?: Maybe<Groups_Mutation_Response>;
  /** update single row of the table: "groups" */
  update_groups_by_pk?: Maybe<Groups>;
  /** update multiples rows of table: "groups" */
  update_groups_many?: Maybe<Array<Maybe<Groups_Mutation_Response>>>;
  /** update data of the table: "journal_entries" */
  update_journal_entries?: Maybe<Journal_Entries_Mutation_Response>;
  /** update single row of the table: "journal_entries" */
  update_journal_entries_by_pk?: Maybe<Journal_Entries>;
  /** update multiples rows of table: "journal_entries" */
  update_journal_entries_many?: Maybe<Array<Maybe<Journal_Entries_Mutation_Response>>>;
  /** update data of the table: "likes" */
  update_likes?: Maybe<Likes_Mutation_Response>;
  /** update single row of the table: "likes" */
  update_likes_by_pk?: Maybe<Likes>;
  /** update multiples rows of table: "likes" */
  update_likes_many?: Maybe<Array<Maybe<Likes_Mutation_Response>>>;
  /** update data of the table: "notification_recipients" */
  update_notification_recipients?: Maybe<Notification_Recipients_Mutation_Response>;
  /** update single row of the table: "notification_recipients" */
  update_notification_recipients_by_pk?: Maybe<Notification_Recipients>;
  /** update multiples rows of table: "notification_recipients" */
  update_notification_recipients_many?: Maybe<Array<Maybe<Notification_Recipients_Mutation_Response>>>;
  /** update data of the table: "notification_statuses" */
  update_notification_statuses?: Maybe<Notification_Statuses_Mutation_Response>;
  /** update single row of the table: "notification_statuses" */
  update_notification_statuses_by_pk?: Maybe<Notification_Statuses>;
  /** update multiples rows of table: "notification_statuses" */
  update_notification_statuses_many?: Maybe<Array<Maybe<Notification_Statuses_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "oidc.oidc_payloads" */
  update_oidc_oidc_payloads?: Maybe<Oidc_Oidc_Payloads_Mutation_Response>;
  /** update single row of the table: "oidc.oidc_payloads" */
  update_oidc_oidc_payloads_by_pk?: Maybe<Oidc_Oidc_Payloads>;
  /** update multiples rows of table: "oidc.oidc_payloads" */
  update_oidc_oidc_payloads_many?: Maybe<Array<Maybe<Oidc_Oidc_Payloads_Mutation_Response>>>;
  /** update data of the table: "pages" */
  update_pages?: Maybe<Pages_Mutation_Response>;
  /** update single row of the table: "pages" */
  update_pages_by_pk?: Maybe<Pages>;
  /** update multiples rows of table: "pages" */
  update_pages_many?: Maybe<Array<Maybe<Pages_Mutation_Response>>>;
  /** update data of the table: "password_resets" */
  update_password_resets?: Maybe<Password_Resets_Mutation_Response>;
  /** update single row of the table: "password_resets" */
  update_password_resets_by_pk?: Maybe<Password_Resets>;
  /** update multiples rows of table: "password_resets" */
  update_password_resets_many?: Maybe<Array<Maybe<Password_Resets_Mutation_Response>>>;
  /** update data of the table: "post_authors" */
  update_post_authors?: Maybe<Post_Authors_Mutation_Response>;
  /** update single row of the table: "post_authors" */
  update_post_authors_by_pk?: Maybe<Post_Authors>;
  /** update multiples rows of table: "post_authors" */
  update_post_authors_many?: Maybe<Array<Maybe<Post_Authors_Mutation_Response>>>;
  /** update data of the table: "post_flags" */
  update_post_flags?: Maybe<Post_Flags_Mutation_Response>;
  /** update single row of the table: "post_flags" */
  update_post_flags_by_pk?: Maybe<Post_Flags>;
  /** update multiples rows of table: "post_flags" */
  update_post_flags_many?: Maybe<Array<Maybe<Post_Flags_Mutation_Response>>>;
  /** update data of the table: "post_tags" */
  update_post_tags?: Maybe<Post_Tags_Mutation_Response>;
  /** update single row of the table: "post_tags" */
  update_post_tags_by_pk?: Maybe<Post_Tags>;
  /** update multiples rows of table: "post_tags" */
  update_post_tags_many?: Maybe<Array<Maybe<Post_Tags_Mutation_Response>>>;
  /** update data of the table: "posts" */
  update_posts?: Maybe<Posts_Mutation_Response>;
  /** update single row of the table: "posts" */
  update_posts_by_pk?: Maybe<Posts>;
  /** update multiples rows of table: "posts" */
  update_posts_many?: Maybe<Array<Maybe<Posts_Mutation_Response>>>;
  /** update data of the table: "profiles" */
  update_profiles?: Maybe<Profiles_Mutation_Response>;
  /** update single row of the table: "profiles" */
  update_profiles_by_pk?: Maybe<Profiles>;
  /** update multiples rows of table: "profiles" */
  update_profiles_many?: Maybe<Array<Maybe<Profiles_Mutation_Response>>>;
  /** update data of the table: "purchases" */
  update_purchases?: Maybe<Purchases_Mutation_Response>;
  /** update single row of the table: "purchases" */
  update_purchases_by_pk?: Maybe<Purchases>;
  /** update multiples rows of table: "purchases" */
  update_purchases_many?: Maybe<Array<Maybe<Purchases_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "serving_points" */
  update_serving_points?: Maybe<Serving_Points_Mutation_Response>;
  /** update single row of the table: "serving_points" */
  update_serving_points_by_pk?: Maybe<Serving_Points>;
  /** update multiples rows of table: "serving_points" */
  update_serving_points_many?: Maybe<Array<Maybe<Serving_Points_Mutation_Response>>>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  update_tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "oidc.oidc_clients" */
  update_third_party_apps?: Maybe<Third_Party_Apps_Mutation_Response>;
  /** update single row of the table: "oidc.oidc_clients" */
  update_third_party_apps_by_pk?: Maybe<Third_Party_Apps>;
  /** update multiples rows of table: "oidc.oidc_clients" */
  update_third_party_apps_many?: Maybe<Array<Maybe<Third_Party_Apps_Mutation_Response>>>;
  /** update data of the table: "transactions" */
  update_transactions?: Maybe<Transactions_Mutation_Response>;
  /** update single row of the table: "transactions" */
  update_transactions_by_pk?: Maybe<Transactions>;
  /** update multiples rows of table: "transactions" */
  update_transactions_many?: Maybe<Array<Maybe<Transactions_Mutation_Response>>>;
  /** update data of the table: "upload_tags" */
  update_upload_tags?: Maybe<Upload_Tags_Mutation_Response>;
  /** update single row of the table: "upload_tags" */
  update_upload_tags_by_pk?: Maybe<Upload_Tags>;
  /** update multiples rows of table: "upload_tags" */
  update_upload_tags_many?: Maybe<Array<Maybe<Upload_Tags_Mutation_Response>>>;
  /** update data of the table: "uploads" */
  update_uploads?: Maybe<Uploads_Mutation_Response>;
  /** update single row of the table: "uploads" */
  update_uploads_by_pk?: Maybe<Uploads>;
  /** update multiples rows of table: "uploads" */
  update_uploads_many?: Maybe<Array<Maybe<Uploads_Mutation_Response>>>;
  /** update data of the table: "user_flags" */
  update_user_flags?: Maybe<User_Flags_Mutation_Response>;
  /** update single row of the table: "user_flags" */
  update_user_flags_by_pk?: Maybe<User_Flags>;
  /** update multiples rows of table: "user_flags" */
  update_user_flags_many?: Maybe<Array<Maybe<User_Flags_Mutation_Response>>>;
  /** update data of the table: "user_group_roles" */
  update_user_group_roles?: Maybe<User_Group_Roles_Mutation_Response>;
  /** update single row of the table: "user_group_roles" */
  update_user_group_roles_by_pk?: Maybe<User_Group_Roles>;
  /** update multiples rows of table: "user_group_roles" */
  update_user_group_roles_many?: Maybe<Array<Maybe<User_Group_Roles_Mutation_Response>>>;
  /** update data of the table: "user_roles" */
  update_user_roles?: Maybe<User_Roles_Mutation_Response>;
  /** update single row of the table: "user_roles" */
  update_user_roles_by_pk?: Maybe<User_Roles>;
  /** update multiples rows of table: "user_roles" */
  update_user_roles_many?: Maybe<Array<Maybe<User_Roles_Mutation_Response>>>;
  /** update data of the table: "user_social_logins" */
  update_user_social_logins?: Maybe<User_Social_Logins_Mutation_Response>;
  /** update single row of the table: "user_social_logins" */
  update_user_social_logins_by_pk?: Maybe<User_Social_Logins>;
  /** update multiples rows of table: "user_social_logins" */
  update_user_social_logins_many?: Maybe<Array<Maybe<User_Social_Logins_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_id?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "verifications" */
  update_verifications?: Maybe<Verifications_Mutation_Response>;
  /** update single row of the table: "verifications" */
  update_verifications_by_pk?: Maybe<Verifications>;
  /** update multiples rows of table: "verifications" */
  update_verifications_many?: Maybe<Array<Maybe<Verifications_Mutation_Response>>>;
  /** update data of the table: "wallet_transactions" */
  update_wallet_transactions?: Maybe<Wallet_Transactions_Mutation_Response>;
  /** update single row of the table: "wallet_transactions" */
  update_wallet_transactions_by_pk?: Maybe<Wallet_Transactions>;
  /** update multiples rows of table: "wallet_transactions" */
  update_wallet_transactions_many?: Maybe<Array<Maybe<Wallet_Transactions_Mutation_Response>>>;
  /** update data of the table: "wallets" */
  update_wallets?: Maybe<Wallets_Mutation_Response>;
  /** update single row of the table: "wallets" */
  update_wallets_by_pk?: Maybe<Wallets>;
  /** update multiples rows of table: "wallets" */
  update_wallets_many?: Maybe<Array<Maybe<Wallets_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootAddGameArgs = {
  params: AddGameInput;
};


/** mutation root */
export type Mutation_RootAddGoogleAccountArgs = {
  params: AddGoogleAccountInput;
};


/** mutation root */
export type Mutation_RootAddThirdPartyAppArgs = {
  params: AddThirdPartyAppInput;
};


/** mutation root */
export type Mutation_RootAdminLoginArgs = {
  params: LoginInput;
};


/** mutation root */
export type Mutation_RootChangePasswordArgs = {
  params: ChangePasswordInput;
};


/** mutation root */
export type Mutation_RootConfirmPaymentArgs = {
  params: ConfirmPaymentInput;
};


/** mutation root */
export type Mutation_RootCreateWalletArgs = {
  params: CreateWalletInput;
};


/** mutation root */
export type Mutation_RootDeleteAccountArgs = {
  params: DeleteAccountInput;
};


/** mutation root */
export type Mutation_RootDelete_Audit_Log_EntriesArgs = {
  where: Audit_Log_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Audit_Log_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Blog_FlagsArgs = {
  where: Blog_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blog_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BlogsArgs = {
  where: Blogs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Blogs_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_BooksArgs = {
  where: Books_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Books_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Channel_MembersArgs = {
  where: Channel_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channel_Members_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ChannelsArgs = {
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Channels_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ClientsArgs = {
  where: Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Clients_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Comment_MentionsArgs = {
  where: Comment_Mentions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comment_Mentions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_CommentsArgs = {
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_EventsArgs = {
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Events_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Featured_GamesArgs = {
  where: Featured_Games_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Featured_Games_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_File_Storage_ProvidersArgs = {
  where: File_Storage_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_File_Storage_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FilesArgs = {
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_FlagsArgs = {
  where: Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Game_CommentsArgs = {
  where: Game_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Game_FilesArgs = {
  where: Game_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Game_FlagsArgs = {
  where: Game_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Game_GenresArgs = {
  where: Game_Genres_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Genres_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Game_RatingsArgs = {
  where: Game_Ratings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Ratings_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Game_Serving_PointsArgs = {
  where: Game_Serving_Points_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Game_Serving_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GamesArgs = {
  where: Games_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Games_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Group_FlagsArgs = {
  where: Group_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Group_MembersArgs = {
  where: Group_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_Members_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Group_PermissionsArgs = {
  where: Group_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_Permissions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Group_RolesArgs = {
  where: Group_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Group_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_GroupsArgs = {
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Groups_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Journal_EntriesArgs = {
  where: Journal_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Journal_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_LikesArgs = {
  where: Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Likes_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_RecipientsArgs = {
  where: Notification_Recipients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Recipients_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Notification_StatusesArgs = {
  where: Notification_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notification_Statuses_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Oidc_Oidc_PayloadsArgs = {
  where: Oidc_Oidc_Payloads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Oidc_Oidc_Payloads_By_PkArgs = {
  id: Scalars['String'];
  oidc_type: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_PagesArgs = {
  where: Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pages_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Password_ResetsArgs = {
  where: Password_Resets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Password_Resets_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Post_AuthorsArgs = {
  where: Post_Authors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Authors_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Post_FlagsArgs = {
  where: Post_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Post_TagsArgs = {
  where: Post_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Post_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PostsArgs = {
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Posts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_ProfilesArgs = {
  where: Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Profiles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_PurchasesArgs = {
  where: Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Purchases_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Serving_PointsArgs = {
  where: Serving_Points_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Serving_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Third_Party_AppsArgs = {
  where: Third_Party_Apps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Third_Party_Apps_By_PkArgs = {
  id: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_TransactionsArgs = {
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Upload_TagsArgs = {
  where: Upload_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Upload_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UploadsArgs = {
  where: Uploads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Uploads_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_FlagsArgs = {
  where: User_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Group_RolesArgs = {
  where: User_Group_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Group_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_RolesArgs = {
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_User_Social_LoginsArgs = {
  where: User_Social_Logins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Social_Logins_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_VerificationsArgs = {
  where: Verifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Verifications_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Wallet_TransactionsArgs = {
  where: Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_WalletsArgs = {
  where: Wallets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wallets_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootGenerateResetPasswordTokenArgs = {
  params: GenerateResetPasswordTokenInput;
};


/** mutation root */
export type Mutation_RootGetGaGamesReportArgs = {
  params: GaGamesReportInput;
};


/** mutation root */
export type Mutation_RootGetGaSessionsReportArgs = {
  params: GaSessionsReportInput;
};


/** mutation root */
export type Mutation_RootGoogleLoginArgs = {
  params: GoogleLoginInput;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Log_EntriesArgs = {
  objects: Array<Audit_Log_Entries_Insert_Input>;
  on_conflict?: InputMaybe<Audit_Log_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Audit_Log_Entries_OneArgs = {
  object: Audit_Log_Entries_Insert_Input;
  on_conflict?: InputMaybe<Audit_Log_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_FlagsArgs = {
  objects: Array<Blog_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Blog_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blog_Flags_OneArgs = {
  object: Blog_Flags_Insert_Input;
  on_conflict?: InputMaybe<Blog_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BlogsArgs = {
  objects: Array<Blogs_Insert_Input>;
  on_conflict?: InputMaybe<Blogs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Blogs_OneArgs = {
  object: Blogs_Insert_Input;
  on_conflict?: InputMaybe<Blogs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_BooksArgs = {
  objects: Array<Books_Insert_Input>;
  on_conflict?: InputMaybe<Books_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Books_OneArgs = {
  object: Books_Insert_Input;
  on_conflict?: InputMaybe<Books_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_MembersArgs = {
  objects: Array<Channel_Members_Insert_Input>;
  on_conflict?: InputMaybe<Channel_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channel_Members_OneArgs = {
  object: Channel_Members_Insert_Input;
  on_conflict?: InputMaybe<Channel_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ChannelsArgs = {
  objects: Array<Channels_Insert_Input>;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Channels_OneArgs = {
  object: Channels_Insert_Input;
  on_conflict?: InputMaybe<Channels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ClientsArgs = {
  objects: Array<Clients_Insert_Input>;
  on_conflict?: InputMaybe<Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Clients_OneArgs = {
  object: Clients_Insert_Input;
  on_conflict?: InputMaybe<Clients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_MentionsArgs = {
  objects: Array<Comment_Mentions_Insert_Input>;
  on_conflict?: InputMaybe<Comment_Mentions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comment_Mentions_OneArgs = {
  object: Comment_Mentions_Insert_Input;
  on_conflict?: InputMaybe<Comment_Mentions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CommentsArgs = {
  objects: Array<Comments_Insert_Input>;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Comments_OneArgs = {
  object: Comments_Insert_Input;
  on_conflict?: InputMaybe<Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_EventsArgs = {
  objects: Array<Events_Insert_Input>;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Events_OneArgs = {
  object: Events_Insert_Input;
  on_conflict?: InputMaybe<Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Featured_GamesArgs = {
  objects: Array<Featured_Games_Insert_Input>;
  on_conflict?: InputMaybe<Featured_Games_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Featured_Games_OneArgs = {
  object: Featured_Games_Insert_Input;
  on_conflict?: InputMaybe<Featured_Games_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Storage_ProvidersArgs = {
  objects: Array<File_Storage_Providers_Insert_Input>;
  on_conflict?: InputMaybe<File_Storage_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_File_Storage_Providers_OneArgs = {
  object: File_Storage_Providers_Insert_Input;
  on_conflict?: InputMaybe<File_Storage_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FilesArgs = {
  objects: Array<Files_Insert_Input>;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Files_OneArgs = {
  object: Files_Insert_Input;
  on_conflict?: InputMaybe<Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FlagsArgs = {
  objects: Array<Flags_Insert_Input>;
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Flags_OneArgs = {
  object: Flags_Insert_Input;
  on_conflict?: InputMaybe<Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_CommentsArgs = {
  objects: Array<Game_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Game_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Comments_OneArgs = {
  object: Game_Comments_Insert_Input;
  on_conflict?: InputMaybe<Game_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_FilesArgs = {
  objects: Array<Game_Files_Insert_Input>;
  on_conflict?: InputMaybe<Game_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Files_OneArgs = {
  object: Game_Files_Insert_Input;
  on_conflict?: InputMaybe<Game_Files_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_FlagsArgs = {
  objects: Array<Game_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Game_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Flags_OneArgs = {
  object: Game_Flags_Insert_Input;
  on_conflict?: InputMaybe<Game_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_GenresArgs = {
  objects: Array<Game_Genres_Insert_Input>;
  on_conflict?: InputMaybe<Game_Genres_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Genres_OneArgs = {
  object: Game_Genres_Insert_Input;
  on_conflict?: InputMaybe<Game_Genres_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_RatingsArgs = {
  objects: Array<Game_Ratings_Insert_Input>;
  on_conflict?: InputMaybe<Game_Ratings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Ratings_OneArgs = {
  object: Game_Ratings_Insert_Input;
  on_conflict?: InputMaybe<Game_Ratings_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Serving_PointsArgs = {
  objects: Array<Game_Serving_Points_Insert_Input>;
  on_conflict?: InputMaybe<Game_Serving_Points_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Game_Serving_Points_OneArgs = {
  object: Game_Serving_Points_Insert_Input;
  on_conflict?: InputMaybe<Game_Serving_Points_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GamesArgs = {
  objects: Array<Games_Insert_Input>;
  on_conflict?: InputMaybe<Games_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Games_OneArgs = {
  object: Games_Insert_Input;
  on_conflict?: InputMaybe<Games_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_FlagsArgs = {
  objects: Array<Group_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Group_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Flags_OneArgs = {
  object: Group_Flags_Insert_Input;
  on_conflict?: InputMaybe<Group_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_MembersArgs = {
  objects: Array<Group_Members_Insert_Input>;
  on_conflict?: InputMaybe<Group_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Members_OneArgs = {
  object: Group_Members_Insert_Input;
  on_conflict?: InputMaybe<Group_Members_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_PermissionsArgs = {
  objects: Array<Group_Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Group_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Permissions_OneArgs = {
  object: Group_Permissions_Insert_Input;
  on_conflict?: InputMaybe<Group_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_RolesArgs = {
  objects: Array<Group_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Group_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Group_Roles_OneArgs = {
  object: Group_Roles_Insert_Input;
  on_conflict?: InputMaybe<Group_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_GroupsArgs = {
  objects: Array<Groups_Insert_Input>;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Groups_OneArgs = {
  object: Groups_Insert_Input;
  on_conflict?: InputMaybe<Groups_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Journal_EntriesArgs = {
  objects: Array<Journal_Entries_Insert_Input>;
  on_conflict?: InputMaybe<Journal_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Journal_Entries_OneArgs = {
  object: Journal_Entries_Insert_Input;
  on_conflict?: InputMaybe<Journal_Entries_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_LikesArgs = {
  objects: Array<Likes_Insert_Input>;
  on_conflict?: InputMaybe<Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Likes_OneArgs = {
  object: Likes_Insert_Input;
  on_conflict?: InputMaybe<Likes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_RecipientsArgs = {
  objects: Array<Notification_Recipients_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Recipients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Recipients_OneArgs = {
  object: Notification_Recipients_Insert_Input;
  on_conflict?: InputMaybe<Notification_Recipients_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_StatusesArgs = {
  objects: Array<Notification_Statuses_Insert_Input>;
  on_conflict?: InputMaybe<Notification_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notification_Statuses_OneArgs = {
  object: Notification_Statuses_Insert_Input;
  on_conflict?: InputMaybe<Notification_Statuses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Oidc_Oidc_PayloadsArgs = {
  objects: Array<Oidc_Oidc_Payloads_Insert_Input>;
  on_conflict?: InputMaybe<Oidc_Oidc_Payloads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Oidc_Oidc_Payloads_OneArgs = {
  object: Oidc_Oidc_Payloads_Insert_Input;
  on_conflict?: InputMaybe<Oidc_Oidc_Payloads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PagesArgs = {
  objects: Array<Pages_Insert_Input>;
  on_conflict?: InputMaybe<Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pages_OneArgs = {
  object: Pages_Insert_Input;
  on_conflict?: InputMaybe<Pages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_ResetsArgs = {
  objects: Array<Password_Resets_Insert_Input>;
  on_conflict?: InputMaybe<Password_Resets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_Resets_OneArgs = {
  object: Password_Resets_Insert_Input;
  on_conflict?: InputMaybe<Password_Resets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_AuthorsArgs = {
  objects: Array<Post_Authors_Insert_Input>;
  on_conflict?: InputMaybe<Post_Authors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Authors_OneArgs = {
  object: Post_Authors_Insert_Input;
  on_conflict?: InputMaybe<Post_Authors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_FlagsArgs = {
  objects: Array<Post_Flags_Insert_Input>;
  on_conflict?: InputMaybe<Post_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Flags_OneArgs = {
  object: Post_Flags_Insert_Input;
  on_conflict?: InputMaybe<Post_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_TagsArgs = {
  objects: Array<Post_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Post_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Post_Tags_OneArgs = {
  object: Post_Tags_Insert_Input;
  on_conflict?: InputMaybe<Post_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PostsArgs = {
  objects: Array<Posts_Insert_Input>;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Posts_OneArgs = {
  object: Posts_Insert_Input;
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProfilesArgs = {
  objects: Array<Profiles_Insert_Input>;
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Profiles_OneArgs = {
  object: Profiles_Insert_Input;
  on_conflict?: InputMaybe<Profiles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PurchasesArgs = {
  objects: Array<Purchases_Insert_Input>;
  on_conflict?: InputMaybe<Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchases_OneArgs = {
  object: Purchases_Insert_Input;
  on_conflict?: InputMaybe<Purchases_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Serving_PointsArgs = {
  objects: Array<Serving_Points_Insert_Input>;
  on_conflict?: InputMaybe<Serving_Points_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Serving_Points_OneArgs = {
  object: Serving_Points_Insert_Input;
  on_conflict?: InputMaybe<Serving_Points_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Third_Party_AppsArgs = {
  objects: Array<Third_Party_Apps_Insert_Input>;
  on_conflict?: InputMaybe<Third_Party_Apps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Third_Party_Apps_OneArgs = {
  object: Third_Party_Apps_Insert_Input;
  on_conflict?: InputMaybe<Third_Party_Apps_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TransactionsArgs = {
  objects: Array<Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Transactions_OneArgs = {
  object: Transactions_Insert_Input;
  on_conflict?: InputMaybe<Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Upload_TagsArgs = {
  objects: Array<Upload_Tags_Insert_Input>;
  on_conflict?: InputMaybe<Upload_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Upload_Tags_OneArgs = {
  object: Upload_Tags_Insert_Input;
  on_conflict?: InputMaybe<Upload_Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UploadsArgs = {
  objects: Array<Uploads_Insert_Input>;
  on_conflict?: InputMaybe<Uploads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Uploads_OneArgs = {
  object: Uploads_Insert_Input;
  on_conflict?: InputMaybe<Uploads_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_FlagsArgs = {
  objects: Array<User_Flags_Insert_Input>;
  on_conflict?: InputMaybe<User_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Flags_OneArgs = {
  object: User_Flags_Insert_Input;
  on_conflict?: InputMaybe<User_Flags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Group_RolesArgs = {
  objects: Array<User_Group_Roles_Insert_Input>;
  on_conflict?: InputMaybe<User_Group_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Group_Roles_OneArgs = {
  object: User_Group_Roles_Insert_Input;
  on_conflict?: InputMaybe<User_Group_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_RolesArgs = {
  objects: Array<User_Roles_Insert_Input>;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Roles_OneArgs = {
  object: User_Roles_Insert_Input;
  on_conflict?: InputMaybe<User_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Social_LoginsArgs = {
  objects: Array<User_Social_Logins_Insert_Input>;
  on_conflict?: InputMaybe<User_Social_Logins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Social_Logins_OneArgs = {
  object: User_Social_Logins_Insert_Input;
  on_conflict?: InputMaybe<User_Social_Logins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_VerificationsArgs = {
  objects: Array<Verifications_Insert_Input>;
  on_conflict?: InputMaybe<Verifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Verifications_OneArgs = {
  object: Verifications_Insert_Input;
  on_conflict?: InputMaybe<Verifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wallet_TransactionsArgs = {
  objects: Array<Wallet_Transactions_Insert_Input>;
  on_conflict?: InputMaybe<Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wallet_Transactions_OneArgs = {
  object: Wallet_Transactions_Insert_Input;
  on_conflict?: InputMaybe<Wallet_Transactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WalletsArgs = {
  objects: Array<Wallets_Insert_Input>;
  on_conflict?: InputMaybe<Wallets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wallets_OneArgs = {
  object: Wallets_Insert_Input;
  on_conflict?: InputMaybe<Wallets_On_Conflict>;
};


/** mutation root */
export type Mutation_RootLoginArgs = {
  params: LoginInput;
};


/** mutation root */
export type Mutation_RootMakePurchaseRequestArgs = {
  params: PurchaseInput;
};


/** mutation root */
export type Mutation_RootOffRampCeloArgs = {
  params: OffRampCeloInput;
};


/** mutation root */
export type Mutation_RootResetPasswordArgs = {
  params: ResetPasswordInput;
};


/** mutation root */
export type Mutation_RootSendNotificationArgs = {
  params: SendNotificationInput;
};


/** mutation root */
export type Mutation_RootSendToCeloArgs = {
  params: SendToCeloInput;
};


/** mutation root */
export type Mutation_RootSetupMfaArgs = {
  params: SetupMfaInput;
};


/** mutation root */
export type Mutation_RootSignupArgs = {
  params: SignupInput;
};


/** mutation root */
export type Mutation_RootTestTopupWalletArgs = {
  params: TestTopupWalletInput;
};


/** mutation root */
export type Mutation_RootTopUpJiweWalletArgs = {
  params: TopUpJiweWalletInput;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Log_EntriesArgs = {
  _set?: InputMaybe<Audit_Log_Entries_Set_Input>;
  where: Audit_Log_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Log_Entries_By_PkArgs = {
  _set?: InputMaybe<Audit_Log_Entries_Set_Input>;
  pk_columns: Audit_Log_Entries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Audit_Log_Entries_ManyArgs = {
  updates: Array<Audit_Log_Entries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_FlagsArgs = {
  _set?: InputMaybe<Blog_Flags_Set_Input>;
  where: Blog_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Flags_By_PkArgs = {
  _set?: InputMaybe<Blog_Flags_Set_Input>;
  pk_columns: Blog_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blog_Flags_ManyArgs = {
  updates: Array<Blog_Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BlogsArgs = {
  _set?: InputMaybe<Blogs_Set_Input>;
  where: Blogs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Blogs_By_PkArgs = {
  _set?: InputMaybe<Blogs_Set_Input>;
  pk_columns: Blogs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Blogs_ManyArgs = {
  updates: Array<Blogs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_BooksArgs = {
  _append?: InputMaybe<Books_Append_Input>;
  _delete_at_path?: InputMaybe<Books_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Books_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Books_Delete_Key_Input>;
  _prepend?: InputMaybe<Books_Prepend_Input>;
  _set?: InputMaybe<Books_Set_Input>;
  where: Books_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Books_By_PkArgs = {
  _append?: InputMaybe<Books_Append_Input>;
  _delete_at_path?: InputMaybe<Books_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Books_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Books_Delete_Key_Input>;
  _prepend?: InputMaybe<Books_Prepend_Input>;
  _set?: InputMaybe<Books_Set_Input>;
  pk_columns: Books_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Books_ManyArgs = {
  updates: Array<Books_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_MembersArgs = {
  _set?: InputMaybe<Channel_Members_Set_Input>;
  where: Channel_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Members_By_PkArgs = {
  _set?: InputMaybe<Channel_Members_Set_Input>;
  pk_columns: Channel_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channel_Members_ManyArgs = {
  updates: Array<Channel_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ChannelsArgs = {
  _set?: InputMaybe<Channels_Set_Input>;
  where: Channels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_By_PkArgs = {
  _set?: InputMaybe<Channels_Set_Input>;
  pk_columns: Channels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Channels_ManyArgs = {
  updates: Array<Channels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ClientsArgs = {
  _append?: InputMaybe<Clients_Append_Input>;
  _delete_at_path?: InputMaybe<Clients_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clients_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clients_Delete_Key_Input>;
  _prepend?: InputMaybe<Clients_Prepend_Input>;
  _set?: InputMaybe<Clients_Set_Input>;
  where: Clients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Clients_By_PkArgs = {
  _append?: InputMaybe<Clients_Append_Input>;
  _delete_at_path?: InputMaybe<Clients_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Clients_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Clients_Delete_Key_Input>;
  _prepend?: InputMaybe<Clients_Prepend_Input>;
  _set?: InputMaybe<Clients_Set_Input>;
  pk_columns: Clients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Clients_ManyArgs = {
  updates: Array<Clients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_MentionsArgs = {
  _set?: InputMaybe<Comment_Mentions_Set_Input>;
  where: Comment_Mentions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Mentions_By_PkArgs = {
  _set?: InputMaybe<Comment_Mentions_Set_Input>;
  pk_columns: Comment_Mentions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comment_Mentions_ManyArgs = {
  updates: Array<Comment_Mentions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CommentsArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  where: Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_By_PkArgs = {
  _set?: InputMaybe<Comments_Set_Input>;
  pk_columns: Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Comments_ManyArgs = {
  updates: Array<Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_EventsArgs = {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  where: Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Events_By_PkArgs = {
  _inc?: InputMaybe<Events_Inc_Input>;
  _set?: InputMaybe<Events_Set_Input>;
  pk_columns: Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Events_ManyArgs = {
  updates: Array<Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Featured_GamesArgs = {
  _set?: InputMaybe<Featured_Games_Set_Input>;
  where: Featured_Games_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Featured_Games_By_PkArgs = {
  _set?: InputMaybe<Featured_Games_Set_Input>;
  pk_columns: Featured_Games_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Featured_Games_ManyArgs = {
  updates: Array<Featured_Games_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_File_Storage_ProvidersArgs = {
  _set?: InputMaybe<File_Storage_Providers_Set_Input>;
  where: File_Storage_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_File_Storage_Providers_By_PkArgs = {
  _set?: InputMaybe<File_Storage_Providers_Set_Input>;
  pk_columns: File_Storage_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_File_Storage_Providers_ManyArgs = {
  updates: Array<File_Storage_Providers_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FilesArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  where: Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Files_By_PkArgs = {
  _set?: InputMaybe<Files_Set_Input>;
  pk_columns: Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Files_ManyArgs = {
  updates: Array<Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FlagsArgs = {
  _set?: InputMaybe<Flags_Set_Input>;
  where: Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Flags_By_PkArgs = {
  _set?: InputMaybe<Flags_Set_Input>;
  pk_columns: Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Flags_ManyArgs = {
  updates: Array<Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Game_CommentsArgs = {
  _set?: InputMaybe<Game_Comments_Set_Input>;
  where: Game_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Comments_By_PkArgs = {
  _set?: InputMaybe<Game_Comments_Set_Input>;
  pk_columns: Game_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Comments_ManyArgs = {
  updates: Array<Game_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Game_FilesArgs = {
  _set?: InputMaybe<Game_Files_Set_Input>;
  where: Game_Files_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Files_By_PkArgs = {
  _set?: InputMaybe<Game_Files_Set_Input>;
  pk_columns: Game_Files_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Files_ManyArgs = {
  updates: Array<Game_Files_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Game_FlagsArgs = {
  _set?: InputMaybe<Game_Flags_Set_Input>;
  where: Game_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Flags_By_PkArgs = {
  _set?: InputMaybe<Game_Flags_Set_Input>;
  pk_columns: Game_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Flags_ManyArgs = {
  updates: Array<Game_Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Game_GenresArgs = {
  _set?: InputMaybe<Game_Genres_Set_Input>;
  where: Game_Genres_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Genres_By_PkArgs = {
  _set?: InputMaybe<Game_Genres_Set_Input>;
  pk_columns: Game_Genres_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Genres_ManyArgs = {
  updates: Array<Game_Genres_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Game_RatingsArgs = {
  _append?: InputMaybe<Game_Ratings_Append_Input>;
  _delete_at_path?: InputMaybe<Game_Ratings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Game_Ratings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Game_Ratings_Delete_Key_Input>;
  _inc?: InputMaybe<Game_Ratings_Inc_Input>;
  _prepend?: InputMaybe<Game_Ratings_Prepend_Input>;
  _set?: InputMaybe<Game_Ratings_Set_Input>;
  where: Game_Ratings_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Ratings_By_PkArgs = {
  _append?: InputMaybe<Game_Ratings_Append_Input>;
  _delete_at_path?: InputMaybe<Game_Ratings_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Game_Ratings_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Game_Ratings_Delete_Key_Input>;
  _inc?: InputMaybe<Game_Ratings_Inc_Input>;
  _prepend?: InputMaybe<Game_Ratings_Prepend_Input>;
  _set?: InputMaybe<Game_Ratings_Set_Input>;
  pk_columns: Game_Ratings_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Ratings_ManyArgs = {
  updates: Array<Game_Ratings_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Serving_PointsArgs = {
  _set?: InputMaybe<Game_Serving_Points_Set_Input>;
  where: Game_Serving_Points_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Serving_Points_By_PkArgs = {
  _set?: InputMaybe<Game_Serving_Points_Set_Input>;
  pk_columns: Game_Serving_Points_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Game_Serving_Points_ManyArgs = {
  updates: Array<Game_Serving_Points_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GamesArgs = {
  _set?: InputMaybe<Games_Set_Input>;
  where: Games_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Games_By_PkArgs = {
  _set?: InputMaybe<Games_Set_Input>;
  pk_columns: Games_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Games_ManyArgs = {
  updates: Array<Games_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Group_FlagsArgs = {
  _set?: InputMaybe<Group_Flags_Set_Input>;
  where: Group_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Flags_By_PkArgs = {
  _set?: InputMaybe<Group_Flags_Set_Input>;
  pk_columns: Group_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Flags_ManyArgs = {
  updates: Array<Group_Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Group_MembersArgs = {
  _set?: InputMaybe<Group_Members_Set_Input>;
  where: Group_Members_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Members_By_PkArgs = {
  _set?: InputMaybe<Group_Members_Set_Input>;
  pk_columns: Group_Members_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Members_ManyArgs = {
  updates: Array<Group_Members_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Group_PermissionsArgs = {
  _set?: InputMaybe<Group_Permissions_Set_Input>;
  where: Group_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Permissions_By_PkArgs = {
  _set?: InputMaybe<Group_Permissions_Set_Input>;
  pk_columns: Group_Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Permissions_ManyArgs = {
  updates: Array<Group_Permissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Group_RolesArgs = {
  _set?: InputMaybe<Group_Roles_Set_Input>;
  where: Group_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Roles_By_PkArgs = {
  _set?: InputMaybe<Group_Roles_Set_Input>;
  pk_columns: Group_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Group_Roles_ManyArgs = {
  updates: Array<Group_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_GroupsArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  where: Groups_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_By_PkArgs = {
  _set?: InputMaybe<Groups_Set_Input>;
  pk_columns: Groups_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Groups_ManyArgs = {
  updates: Array<Groups_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Journal_EntriesArgs = {
  _set?: InputMaybe<Journal_Entries_Set_Input>;
  where: Journal_Entries_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Journal_Entries_By_PkArgs = {
  _set?: InputMaybe<Journal_Entries_Set_Input>;
  pk_columns: Journal_Entries_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Journal_Entries_ManyArgs = {
  updates: Array<Journal_Entries_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_LikesArgs = {
  _set?: InputMaybe<Likes_Set_Input>;
  where: Likes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Likes_By_PkArgs = {
  _set?: InputMaybe<Likes_Set_Input>;
  pk_columns: Likes_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Likes_ManyArgs = {
  updates: Array<Likes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_RecipientsArgs = {
  _set?: InputMaybe<Notification_Recipients_Set_Input>;
  where: Notification_Recipients_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Recipients_By_PkArgs = {
  _set?: InputMaybe<Notification_Recipients_Set_Input>;
  pk_columns: Notification_Recipients_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Recipients_ManyArgs = {
  updates: Array<Notification_Recipients_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_StatusesArgs = {
  _set?: InputMaybe<Notification_Statuses_Set_Input>;
  where: Notification_Statuses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Statuses_By_PkArgs = {
  _set?: InputMaybe<Notification_Statuses_Set_Input>;
  pk_columns: Notification_Statuses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notification_Statuses_ManyArgs = {
  updates: Array<Notification_Statuses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Oidc_Oidc_PayloadsArgs = {
  _append?: InputMaybe<Oidc_Oidc_Payloads_Append_Input>;
  _delete_at_path?: InputMaybe<Oidc_Oidc_Payloads_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Oidc_Oidc_Payloads_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Oidc_Oidc_Payloads_Delete_Key_Input>;
  _inc?: InputMaybe<Oidc_Oidc_Payloads_Inc_Input>;
  _prepend?: InputMaybe<Oidc_Oidc_Payloads_Prepend_Input>;
  _set?: InputMaybe<Oidc_Oidc_Payloads_Set_Input>;
  where: Oidc_Oidc_Payloads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Oidc_Oidc_Payloads_By_PkArgs = {
  _append?: InputMaybe<Oidc_Oidc_Payloads_Append_Input>;
  _delete_at_path?: InputMaybe<Oidc_Oidc_Payloads_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Oidc_Oidc_Payloads_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Oidc_Oidc_Payloads_Delete_Key_Input>;
  _inc?: InputMaybe<Oidc_Oidc_Payloads_Inc_Input>;
  _prepend?: InputMaybe<Oidc_Oidc_Payloads_Prepend_Input>;
  _set?: InputMaybe<Oidc_Oidc_Payloads_Set_Input>;
  pk_columns: Oidc_Oidc_Payloads_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Oidc_Oidc_Payloads_ManyArgs = {
  updates: Array<Oidc_Oidc_Payloads_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PagesArgs = {
  _append?: InputMaybe<Pages_Append_Input>;
  _delete_at_path?: InputMaybe<Pages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pages_Delete_Key_Input>;
  _prepend?: InputMaybe<Pages_Prepend_Input>;
  _set?: InputMaybe<Pages_Set_Input>;
  where: Pages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pages_By_PkArgs = {
  _append?: InputMaybe<Pages_Append_Input>;
  _delete_at_path?: InputMaybe<Pages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Pages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Pages_Delete_Key_Input>;
  _prepend?: InputMaybe<Pages_Prepend_Input>;
  _set?: InputMaybe<Pages_Set_Input>;
  pk_columns: Pages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Pages_ManyArgs = {
  updates: Array<Pages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Password_ResetsArgs = {
  _set?: InputMaybe<Password_Resets_Set_Input>;
  where: Password_Resets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Resets_By_PkArgs = {
  _set?: InputMaybe<Password_Resets_Set_Input>;
  pk_columns: Password_Resets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Resets_ManyArgs = {
  updates: Array<Password_Resets_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Post_AuthorsArgs = {
  _set?: InputMaybe<Post_Authors_Set_Input>;
  where: Post_Authors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Authors_By_PkArgs = {
  _set?: InputMaybe<Post_Authors_Set_Input>;
  pk_columns: Post_Authors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Authors_ManyArgs = {
  updates: Array<Post_Authors_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Post_FlagsArgs = {
  _set?: InputMaybe<Post_Flags_Set_Input>;
  where: Post_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Flags_By_PkArgs = {
  _set?: InputMaybe<Post_Flags_Set_Input>;
  pk_columns: Post_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Flags_ManyArgs = {
  updates: Array<Post_Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Post_TagsArgs = {
  _set?: InputMaybe<Post_Tags_Set_Input>;
  where: Post_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Tags_By_PkArgs = {
  _set?: InputMaybe<Post_Tags_Set_Input>;
  pk_columns: Post_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Post_Tags_ManyArgs = {
  updates: Array<Post_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PostsArgs = {
  _set?: InputMaybe<Posts_Set_Input>;
  where: Posts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_By_PkArgs = {
  _set?: InputMaybe<Posts_Set_Input>;
  pk_columns: Posts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Posts_ManyArgs = {
  updates: Array<Posts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProfilesArgs = {
  _append?: InputMaybe<Profiles_Append_Input>;
  _delete_at_path?: InputMaybe<Profiles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Profiles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Profiles_Delete_Key_Input>;
  _prepend?: InputMaybe<Profiles_Prepend_Input>;
  _set?: InputMaybe<Profiles_Set_Input>;
  where: Profiles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Profiles_By_PkArgs = {
  _append?: InputMaybe<Profiles_Append_Input>;
  _delete_at_path?: InputMaybe<Profiles_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Profiles_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Profiles_Delete_Key_Input>;
  _prepend?: InputMaybe<Profiles_Prepend_Input>;
  _set?: InputMaybe<Profiles_Set_Input>;
  pk_columns: Profiles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Profiles_ManyArgs = {
  updates: Array<Profiles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PurchasesArgs = {
  _inc?: InputMaybe<Purchases_Inc_Input>;
  _set?: InputMaybe<Purchases_Set_Input>;
  where: Purchases_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Purchases_By_PkArgs = {
  _inc?: InputMaybe<Purchases_Inc_Input>;
  _set?: InputMaybe<Purchases_Set_Input>;
  pk_columns: Purchases_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Purchases_ManyArgs = {
  updates: Array<Purchases_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _inc?: InputMaybe<Roles_Inc_Input>;
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _inc?: InputMaybe<Roles_Inc_Input>;
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Serving_PointsArgs = {
  _set?: InputMaybe<Serving_Points_Set_Input>;
  where: Serving_Points_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Serving_Points_By_PkArgs = {
  _set?: InputMaybe<Serving_Points_Set_Input>;
  pk_columns: Serving_Points_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Serving_Points_ManyArgs = {
  updates: Array<Serving_Points_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_AppsArgs = {
  _append?: InputMaybe<Third_Party_Apps_Append_Input>;
  _delete_at_path?: InputMaybe<Third_Party_Apps_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Third_Party_Apps_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Third_Party_Apps_Delete_Key_Input>;
  _prepend?: InputMaybe<Third_Party_Apps_Prepend_Input>;
  _set?: InputMaybe<Third_Party_Apps_Set_Input>;
  where: Third_Party_Apps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_Apps_By_PkArgs = {
  _append?: InputMaybe<Third_Party_Apps_Append_Input>;
  _delete_at_path?: InputMaybe<Third_Party_Apps_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Third_Party_Apps_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Third_Party_Apps_Delete_Key_Input>;
  _prepend?: InputMaybe<Third_Party_Apps_Prepend_Input>;
  _set?: InputMaybe<Third_Party_Apps_Set_Input>;
  pk_columns: Third_Party_Apps_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Third_Party_Apps_ManyArgs = {
  updates: Array<Third_Party_Apps_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TransactionsArgs = {
  _append?: InputMaybe<Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Transactions_Inc_Input>;
  _prepend?: InputMaybe<Transactions_Prepend_Input>;
  _set?: InputMaybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_By_PkArgs = {
  _append?: InputMaybe<Transactions_Append_Input>;
  _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  _inc?: InputMaybe<Transactions_Inc_Input>;
  _prepend?: InputMaybe<Transactions_Prepend_Input>;
  _set?: InputMaybe<Transactions_Set_Input>;
  pk_columns: Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Transactions_ManyArgs = {
  updates: Array<Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Upload_TagsArgs = {
  _set?: InputMaybe<Upload_Tags_Set_Input>;
  where: Upload_Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Upload_Tags_By_PkArgs = {
  _set?: InputMaybe<Upload_Tags_Set_Input>;
  pk_columns: Upload_Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Upload_Tags_ManyArgs = {
  updates: Array<Upload_Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UploadsArgs = {
  _set?: InputMaybe<Uploads_Set_Input>;
  where: Uploads_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Uploads_By_PkArgs = {
  _set?: InputMaybe<Uploads_Set_Input>;
  pk_columns: Uploads_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Uploads_ManyArgs = {
  updates: Array<Uploads_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_FlagsArgs = {
  _set?: InputMaybe<User_Flags_Set_Input>;
  where: User_Flags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Flags_By_PkArgs = {
  _set?: InputMaybe<User_Flags_Set_Input>;
  pk_columns: User_Flags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Flags_ManyArgs = {
  updates: Array<User_Flags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Group_RolesArgs = {
  _set?: InputMaybe<User_Group_Roles_Set_Input>;
  where: User_Group_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Group_Roles_By_PkArgs = {
  _set?: InputMaybe<User_Group_Roles_Set_Input>;
  pk_columns: User_Group_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Group_Roles_ManyArgs = {
  updates: Array<User_Group_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_RolesArgs = {
  _set?: InputMaybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_By_PkArgs = {
  _set?: InputMaybe<User_Roles_Set_Input>;
  pk_columns: User_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Roles_ManyArgs = {
  updates: Array<User_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Social_LoginsArgs = {
  _set?: InputMaybe<User_Social_Logins_Set_Input>;
  where: User_Social_Logins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Social_Logins_By_PkArgs = {
  _set?: InputMaybe<User_Social_Logins_Set_Input>;
  pk_columns: User_Social_Logins_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Social_Logins_ManyArgs = {
  updates: Array<User_Social_Logins_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_IdArgs = {
  _append?: InputMaybe<Users_Append_Input>;
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  _prepend?: InputMaybe<Users_Prepend_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_VerificationsArgs = {
  _set?: InputMaybe<Verifications_Set_Input>;
  where: Verifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Verifications_By_PkArgs = {
  _set?: InputMaybe<Verifications_Set_Input>;
  pk_columns: Verifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Verifications_ManyArgs = {
  updates: Array<Verifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wallet_TransactionsArgs = {
  _set?: InputMaybe<Wallet_Transactions_Set_Input>;
  where: Wallet_Transactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wallet_Transactions_By_PkArgs = {
  _set?: InputMaybe<Wallet_Transactions_Set_Input>;
  pk_columns: Wallet_Transactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wallet_Transactions_ManyArgs = {
  updates: Array<Wallet_Transactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WalletsArgs = {
  _set?: InputMaybe<Wallets_Set_Input>;
  where: Wallets_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wallets_By_PkArgs = {
  _set?: InputMaybe<Wallets_Set_Input>;
  pk_columns: Wallets_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wallets_ManyArgs = {
  updates: Array<Wallets_Updates>;
};

/** columns and relationships of "notification_recipients" */
export type Notification_Recipients = {
  __typename?: 'notification_recipients';
  /** An object relationship */
  channel: Channels;
  channel_id: Scalars['uuid'];
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  notification: Notifications;
  notification_id: Scalars['uuid'];
};

/** aggregated selection of "notification_recipients" */
export type Notification_Recipients_Aggregate = {
  __typename?: 'notification_recipients_aggregate';
  aggregate?: Maybe<Notification_Recipients_Aggregate_Fields>;
  nodes: Array<Notification_Recipients>;
};

/** aggregate fields of "notification_recipients" */
export type Notification_Recipients_Aggregate_Fields = {
  __typename?: 'notification_recipients_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Recipients_Max_Fields>;
  min?: Maybe<Notification_Recipients_Min_Fields>;
};


/** aggregate fields of "notification_recipients" */
export type Notification_Recipients_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notification_recipients" */
export type Notification_Recipients_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notification_Recipients_Max_Order_By>;
  min?: InputMaybe<Notification_Recipients_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notification_recipients" */
export type Notification_Recipients_Arr_Rel_Insert_Input = {
  data: Array<Notification_Recipients_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Recipients_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notification_recipients". All fields are combined with a logical 'AND'. */
export type Notification_Recipients_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Recipients_Bool_Exp>>;
  _not?: InputMaybe<Notification_Recipients_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Recipients_Bool_Exp>>;
  channel?: InputMaybe<Channels_Bool_Exp>;
  channel_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notification?: InputMaybe<Notifications_Bool_Exp>;
  notification_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_recipients" */
export enum Notification_Recipients_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationRecipientsPkey = 'notification_recipients_pkey'
}

/** input type for inserting data into table "notification_recipients" */
export type Notification_Recipients_Insert_Input = {
  channel?: InputMaybe<Channels_Obj_Rel_Insert_Input>;
  channel_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification?: InputMaybe<Notifications_Obj_Rel_Insert_Input>;
  notification_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Notification_Recipients_Max_Fields = {
  __typename?: 'notification_recipients_max_fields';
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  notification_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "notification_recipients" */
export type Notification_Recipients_Max_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notification_Recipients_Min_Fields = {
  __typename?: 'notification_recipients_min_fields';
  channel_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  notification_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "notification_recipients" */
export type Notification_Recipients_Min_Order_By = {
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notification_recipients" */
export type Notification_Recipients_Mutation_Response = {
  __typename?: 'notification_recipients_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Recipients>;
};

/** on_conflict condition type for table "notification_recipients" */
export type Notification_Recipients_On_Conflict = {
  constraint: Notification_Recipients_Constraint;
  update_columns?: Array<Notification_Recipients_Update_Column>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_recipients". */
export type Notification_Recipients_Order_By = {
  channel?: InputMaybe<Channels_Order_By>;
  channel_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notification?: InputMaybe<Notifications_Order_By>;
  notification_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_recipients */
export type Notification_Recipients_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notification_recipients" */
export enum Notification_Recipients_Select_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id'
}

/** input type for updating data in table "notification_recipients" */
export type Notification_Recipients_Set_Input = {
  channel_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "notification_recipients" */
export type Notification_Recipients_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Recipients_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Recipients_Stream_Cursor_Value_Input = {
  channel_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  notification_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "notification_recipients" */
export enum Notification_Recipients_Update_Column {
  /** column name */
  ChannelId = 'channel_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotificationId = 'notification_id'
}

export type Notification_Recipients_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Recipients_Set_Input>;
  where: Notification_Recipients_Bool_Exp;
};

/** columns and relationships of "notification_statuses" */
export type Notification_Statuses = {
  __typename?: 'notification_statuses';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "notification_statuses" */
export type Notification_StatusesNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "notification_statuses" */
export type Notification_StatusesNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** aggregated selection of "notification_statuses" */
export type Notification_Statuses_Aggregate = {
  __typename?: 'notification_statuses_aggregate';
  aggregate?: Maybe<Notification_Statuses_Aggregate_Fields>;
  nodes: Array<Notification_Statuses>;
};

/** aggregate fields of "notification_statuses" */
export type Notification_Statuses_Aggregate_Fields = {
  __typename?: 'notification_statuses_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notification_Statuses_Max_Fields>;
  min?: Maybe<Notification_Statuses_Min_Fields>;
};


/** aggregate fields of "notification_statuses" */
export type Notification_Statuses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notification_Statuses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "notification_statuses". All fields are combined with a logical 'AND'. */
export type Notification_Statuses_Bool_Exp = {
  _and?: InputMaybe<Array<Notification_Statuses_Bool_Exp>>;
  _not?: InputMaybe<Notification_Statuses_Bool_Exp>;
  _or?: InputMaybe<Array<Notification_Statuses_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notification_statuses" */
export enum Notification_Statuses_Constraint {
  /** unique or primary key constraint on columns "name" */
  NotificationStatusesNameUnique = 'notification_statuses_name_unique',
  /** unique or primary key constraint on columns "id" */
  NotificationStatusesPkey = 'notification_statuses_pkey'
}

/** input type for inserting data into table "notification_statuses" */
export type Notification_Statuses_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notification_Statuses_Max_Fields = {
  __typename?: 'notification_statuses_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Notification_Statuses_Min_Fields = {
  __typename?: 'notification_statuses_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "notification_statuses" */
export type Notification_Statuses_Mutation_Response = {
  __typename?: 'notification_statuses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notification_Statuses>;
};

/** input type for inserting object relation for remote table "notification_statuses" */
export type Notification_Statuses_Obj_Rel_Insert_Input = {
  data: Notification_Statuses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notification_Statuses_On_Conflict>;
};

/** on_conflict condition type for table "notification_statuses" */
export type Notification_Statuses_On_Conflict = {
  constraint: Notification_Statuses_Constraint;
  update_columns?: Array<Notification_Statuses_Update_Column>;
  where?: InputMaybe<Notification_Statuses_Bool_Exp>;
};

/** Ordering options when selecting data from "notification_statuses". */
export type Notification_Statuses_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notification_statuses */
export type Notification_Statuses_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notification_statuses" */
export enum Notification_Statuses_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "notification_statuses" */
export type Notification_Statuses_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notification_statuses" */
export type Notification_Statuses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notification_Statuses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notification_Statuses_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notification_statuses" */
export enum Notification_Statuses_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Value = 'value'
}

export type Notification_Statuses_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notification_Statuses_Set_Input>;
  where: Notification_Statuses_Bool_Exp;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  message: Scalars['String'];
  /** An array relationship */
  notification_recipients: Array<Notification_Recipients>;
  /** An aggregate relationship */
  notification_recipients_aggregate: Notification_Recipients_Aggregate;
  /** An object relationship */
  notification_status?: Maybe<Notification_Statuses>;
  notification_status_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  sender: Users;
  sender_id: Scalars['uuid'];
  type?: Maybe<Scalars['String']>;
};


/** columns and relationships of "notifications" */
export type NotificationsNotification_RecipientsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Recipients_Order_By>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};


/** columns and relationships of "notifications" */
export type NotificationsNotification_Recipients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Recipients_Order_By>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "notifications" */
export type Notifications_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Notifications_Max_Order_By>;
  min?: InputMaybe<Notifications_Min_Order_By>;
};

/** input type for inserting array relation for remote table "notifications" */
export type Notifications_Arr_Rel_Insert_Input = {
  data: Array<Notifications_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  notification_recipients?: InputMaybe<Notification_Recipients_Bool_Exp>;
  notification_status?: InputMaybe<Notification_Statuses_Bool_Exp>;
  notification_status_id?: InputMaybe<Uuid_Comparison_Exp>;
  sender?: InputMaybe<Users_Bool_Exp>;
  sender_id?: InputMaybe<Uuid_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  notification_recipients?: InputMaybe<Notification_Recipients_Arr_Rel_Insert_Input>;
  notification_status?: InputMaybe<Notification_Statuses_Obj_Rel_Insert_Input>;
  notification_status_id?: InputMaybe<Scalars['uuid']>;
  sender?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  notification_status_id?: Maybe<Scalars['uuid']>;
  sender_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "notifications" */
export type Notifications_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  notification_status_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  message?: Maybe<Scalars['String']>;
  notification_status_id?: Maybe<Scalars['uuid']>;
  sender_id?: Maybe<Scalars['uuid']>;
  type?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "notifications" */
export type Notifications_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  notification_status_id?: InputMaybe<Order_By>;
  sender_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** input type for inserting object relation for remote table "notifications" */
export type Notifications_Obj_Rel_Insert_Input = {
  data: Notifications_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  notification_recipients_aggregate?: InputMaybe<Notification_Recipients_Aggregate_Order_By>;
  notification_status?: InputMaybe<Notification_Statuses_Order_By>;
  notification_status_id?: InputMaybe<Order_By>;
  sender?: InputMaybe<Users_Order_By>;
  sender_id?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  NotificationStatusId = 'notification_status_id',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  notification_status_id?: InputMaybe<Scalars['uuid']>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  message?: InputMaybe<Scalars['String']>;
  notification_status_id?: InputMaybe<Scalars['uuid']>;
  sender_id?: InputMaybe<Scalars['uuid']>;
  type?: InputMaybe<Scalars['String']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  NotificationStatusId = 'notification_status_id',
  /** column name */
  SenderId = 'sender_id',
  /** column name */
  Type = 'type'
}

export type Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};

/** columns and relationships of "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads = {
  __typename?: 'oidc_oidc_payloads';
  consumed_at?: Maybe<Scalars['timestamptz']>;
  created_at: Scalars['timestamptz'];
  expires_at?: Maybe<Scalars['timestamptz']>;
  grant_id?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  oidc_type: Scalars['Int'];
  payload: Scalars['jsonb'];
  uid?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  user_code?: Maybe<Scalars['String']>;
};


/** columns and relationships of "oidc.oidc_payloads" */
export type Oidc_Oidc_PayloadsPayloadArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads_Aggregate = {
  __typename?: 'oidc_oidc_payloads_aggregate';
  aggregate?: Maybe<Oidc_Oidc_Payloads_Aggregate_Fields>;
  nodes: Array<Oidc_Oidc_Payloads>;
};

/** aggregate fields of "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads_Aggregate_Fields = {
  __typename?: 'oidc_oidc_payloads_aggregate_fields';
  avg?: Maybe<Oidc_Oidc_Payloads_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Oidc_Oidc_Payloads_Max_Fields>;
  min?: Maybe<Oidc_Oidc_Payloads_Min_Fields>;
  stddev?: Maybe<Oidc_Oidc_Payloads_Stddev_Fields>;
  stddev_pop?: Maybe<Oidc_Oidc_Payloads_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Oidc_Oidc_Payloads_Stddev_Samp_Fields>;
  sum?: Maybe<Oidc_Oidc_Payloads_Sum_Fields>;
  var_pop?: Maybe<Oidc_Oidc_Payloads_Var_Pop_Fields>;
  var_samp?: Maybe<Oidc_Oidc_Payloads_Var_Samp_Fields>;
  variance?: Maybe<Oidc_Oidc_Payloads_Variance_Fields>;
};


/** aggregate fields of "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Oidc_Oidc_Payloads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Oidc_Oidc_Payloads_Append_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Oidc_Oidc_Payloads_Avg_Fields = {
  __typename?: 'oidc_oidc_payloads_avg_fields';
  oidc_type?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "oidc.oidc_payloads". All fields are combined with a logical 'AND'. */
export type Oidc_Oidc_Payloads_Bool_Exp = {
  _and?: InputMaybe<Array<Oidc_Oidc_Payloads_Bool_Exp>>;
  _not?: InputMaybe<Oidc_Oidc_Payloads_Bool_Exp>;
  _or?: InputMaybe<Array<Oidc_Oidc_Payloads_Bool_Exp>>;
  consumed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  grant_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  oidc_type?: InputMaybe<Int_Comparison_Exp>;
  payload?: InputMaybe<Jsonb_Comparison_Exp>;
  uid?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_code?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "oidc.oidc_payloads" */
export enum Oidc_Oidc_Payloads_Constraint {
  /** unique or primary key constraint on columns "oidc_type", "id" */
  OidcPayloadsPkey = 'oidc_payloads_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Oidc_Oidc_Payloads_Delete_At_Path_Input = {
  payload?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Oidc_Oidc_Payloads_Delete_Elem_Input = {
  payload?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Oidc_Oidc_Payloads_Delete_Key_Input = {
  payload?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads_Inc_Input = {
  oidc_type?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads_Insert_Input = {
  consumed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  grant_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  oidc_type?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_code?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Oidc_Oidc_Payloads_Max_Fields = {
  __typename?: 'oidc_oidc_payloads_max_fields';
  consumed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  grant_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  oidc_type?: Maybe<Scalars['Int']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_code?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Oidc_Oidc_Payloads_Min_Fields = {
  __typename?: 'oidc_oidc_payloads_min_fields';
  consumed_at?: Maybe<Scalars['timestamptz']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  grant_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  oidc_type?: Maybe<Scalars['Int']>;
  uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_code?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads_Mutation_Response = {
  __typename?: 'oidc_oidc_payloads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Oidc_Oidc_Payloads>;
};

/** on_conflict condition type for table "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads_On_Conflict = {
  constraint: Oidc_Oidc_Payloads_Constraint;
  update_columns?: Array<Oidc_Oidc_Payloads_Update_Column>;
  where?: InputMaybe<Oidc_Oidc_Payloads_Bool_Exp>;
};

/** Ordering options when selecting data from "oidc.oidc_payloads". */
export type Oidc_Oidc_Payloads_Order_By = {
  consumed_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  grant_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  oidc_type?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  uid?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_code?: InputMaybe<Order_By>;
};

/** primary key columns input for table: oidc.oidc_payloads */
export type Oidc_Oidc_Payloads_Pk_Columns_Input = {
  id: Scalars['String'];
  oidc_type: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Oidc_Oidc_Payloads_Prepend_Input = {
  payload?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "oidc.oidc_payloads" */
export enum Oidc_Oidc_Payloads_Select_Column {
  /** column name */
  ConsumedAt = 'consumed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  GrantId = 'grant_id',
  /** column name */
  Id = 'id',
  /** column name */
  OidcType = 'oidc_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCode = 'user_code'
}

/** input type for updating data in table "oidc.oidc_payloads" */
export type Oidc_Oidc_Payloads_Set_Input = {
  consumed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  grant_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  oidc_type?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_code?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Oidc_Oidc_Payloads_Stddev_Fields = {
  __typename?: 'oidc_oidc_payloads_stddev_fields';
  oidc_type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Oidc_Oidc_Payloads_Stddev_Pop_Fields = {
  __typename?: 'oidc_oidc_payloads_stddev_pop_fields';
  oidc_type?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Oidc_Oidc_Payloads_Stddev_Samp_Fields = {
  __typename?: 'oidc_oidc_payloads_stddev_samp_fields';
  oidc_type?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "oidc_oidc_payloads" */
export type Oidc_Oidc_Payloads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Oidc_Oidc_Payloads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Oidc_Oidc_Payloads_Stream_Cursor_Value_Input = {
  consumed_at?: InputMaybe<Scalars['timestamptz']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  grant_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  oidc_type?: InputMaybe<Scalars['Int']>;
  payload?: InputMaybe<Scalars['jsonb']>;
  uid?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_code?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Oidc_Oidc_Payloads_Sum_Fields = {
  __typename?: 'oidc_oidc_payloads_sum_fields';
  oidc_type?: Maybe<Scalars['Int']>;
};

/** update columns of table "oidc.oidc_payloads" */
export enum Oidc_Oidc_Payloads_Update_Column {
  /** column name */
  ConsumedAt = 'consumed_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  GrantId = 'grant_id',
  /** column name */
  Id = 'id',
  /** column name */
  OidcType = 'oidc_type',
  /** column name */
  Payload = 'payload',
  /** column name */
  Uid = 'uid',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserCode = 'user_code'
}

export type Oidc_Oidc_Payloads_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Oidc_Oidc_Payloads_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Oidc_Oidc_Payloads_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Oidc_Oidc_Payloads_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Oidc_Oidc_Payloads_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Oidc_Oidc_Payloads_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Oidc_Oidc_Payloads_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Oidc_Oidc_Payloads_Set_Input>;
  where: Oidc_Oidc_Payloads_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Oidc_Oidc_Payloads_Var_Pop_Fields = {
  __typename?: 'oidc_oidc_payloads_var_pop_fields';
  oidc_type?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Oidc_Oidc_Payloads_Var_Samp_Fields = {
  __typename?: 'oidc_oidc_payloads_var_samp_fields';
  oidc_type?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Oidc_Oidc_Payloads_Variance_Fields = {
  __typename?: 'oidc_oidc_payloads_variance_fields';
  oidc_type?: Maybe<Scalars['Float']>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "pages" */
export type Pages = {
  __typename?: 'pages';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  metadata: Scalars['jsonb'];
  poster?: Maybe<Scalars['String']>;
  slug: Scalars['String'];
  title: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "pages" */
export type PagesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "pages" */
export type Pages_Aggregate = {
  __typename?: 'pages_aggregate';
  aggregate?: Maybe<Pages_Aggregate_Fields>;
  nodes: Array<Pages>;
};

/** aggregate fields of "pages" */
export type Pages_Aggregate_Fields = {
  __typename?: 'pages_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Pages_Max_Fields>;
  min?: Maybe<Pages_Min_Fields>;
};


/** aggregate fields of "pages" */
export type Pages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Pages_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "pages". All fields are combined with a logical 'AND'. */
export type Pages_Bool_Exp = {
  _and?: InputMaybe<Array<Pages_Bool_Exp>>;
  _not?: InputMaybe<Pages_Bool_Exp>;
  _or?: InputMaybe<Array<Pages_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  poster?: InputMaybe<String_Comparison_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "pages" */
export enum Pages_Constraint {
  /** unique or primary key constraint on columns "id" */
  PagesPkey = 'pages_pkey',
  /** unique or primary key constraint on columns "slug" */
  PagesSlugKey = 'pages_slug_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Pages_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Pages_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Pages_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "pages" */
export type Pages_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  poster?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Pages_Max_Fields = {
  __typename?: 'pages_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  poster?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Pages_Min_Fields = {
  __typename?: 'pages_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  poster?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "pages" */
export type Pages_Mutation_Response = {
  __typename?: 'pages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pages>;
};

/** on_conflict condition type for table "pages" */
export type Pages_On_Conflict = {
  constraint: Pages_Constraint;
  update_columns?: Array<Pages_Update_Column>;
  where?: InputMaybe<Pages_Bool_Exp>;
};

/** Ordering options when selecting data from "pages". */
export type Pages_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  poster?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: pages */
export type Pages_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Pages_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "pages" */
export enum Pages_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Poster = 'poster',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pages" */
export type Pages_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  poster?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "pages" */
export type Pages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pages_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  poster?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "pages" */
export enum Pages_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Poster = 'poster',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Pages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Pages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Pages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Pages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Pages_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Pages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pages_Set_Input>;
  where: Pages_Bool_Exp;
};

/** columns and relationships of "password_resets" */
export type Password_Resets = {
  __typename?: 'password_resets';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  token: Scalars['String'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_email?: Maybe<Scalars['String']>;
};

/** aggregated selection of "password_resets" */
export type Password_Resets_Aggregate = {
  __typename?: 'password_resets_aggregate';
  aggregate?: Maybe<Password_Resets_Aggregate_Fields>;
  nodes: Array<Password_Resets>;
};

/** aggregate fields of "password_resets" */
export type Password_Resets_Aggregate_Fields = {
  __typename?: 'password_resets_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Password_Resets_Max_Fields>;
  min?: Maybe<Password_Resets_Min_Fields>;
};


/** aggregate fields of "password_resets" */
export type Password_Resets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Password_Resets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "password_resets" */
export type Password_Resets_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Password_Resets_Max_Order_By>;
  min?: InputMaybe<Password_Resets_Min_Order_By>;
};

/** input type for inserting array relation for remote table "password_resets" */
export type Password_Resets_Arr_Rel_Insert_Input = {
  data: Array<Password_Resets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Password_Resets_On_Conflict>;
};

/** Boolean expression to filter rows from the table "password_resets". All fields are combined with a logical 'AND'. */
export type Password_Resets_Bool_Exp = {
  _and?: InputMaybe<Array<Password_Resets_Bool_Exp>>;
  _not?: InputMaybe<Password_Resets_Bool_Exp>;
  _or?: InputMaybe<Array<Password_Resets_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_email?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "password_resets" */
export enum Password_Resets_Constraint {
  /** unique or primary key constraint on columns "id" */
  PasswordResetsPkey = 'password_resets_pkey'
}

/** input type for inserting data into table "password_resets" */
export type Password_Resets_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_email?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Password_Resets_Max_Fields = {
  __typename?: 'password_resets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "password_resets" */
export type Password_Resets_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Password_Resets_Min_Fields = {
  __typename?: 'password_resets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  token?: Maybe<Scalars['String']>;
  user_email?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "password_resets" */
export type Password_Resets_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  user_email?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "password_resets" */
export type Password_Resets_Mutation_Response = {
  __typename?: 'password_resets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Password_Resets>;
};

/** on_conflict condition type for table "password_resets" */
export type Password_Resets_On_Conflict = {
  constraint: Password_Resets_Constraint;
  update_columns?: Array<Password_Resets_Update_Column>;
  where?: InputMaybe<Password_Resets_Bool_Exp>;
};

/** Ordering options when selecting data from "password_resets". */
export type Password_Resets_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_email?: InputMaybe<Order_By>;
};

/** primary key columns input for table: password_resets */
export type Password_Resets_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "password_resets" */
export enum Password_Resets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  UserEmail = 'user_email'
}

/** input type for updating data in table "password_resets" */
export type Password_Resets_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "password_resets" */
export type Password_Resets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Password_Resets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Password_Resets_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  token?: InputMaybe<Scalars['String']>;
  user_email?: InputMaybe<Scalars['String']>;
};

/** update columns of table "password_resets" */
export enum Password_Resets_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Token = 'token',
  /** column name */
  UserEmail = 'user_email'
}

export type Password_Resets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Password_Resets_Set_Input>;
  where: Password_Resets_Bool_Exp;
};

/** columns and relationships of "post_authors" */
export type Post_Authors = {
  __typename?: 'post_authors';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  post?: Maybe<Posts>;
  post_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "post_authors" */
export type Post_Authors_Aggregate = {
  __typename?: 'post_authors_aggregate';
  aggregate?: Maybe<Post_Authors_Aggregate_Fields>;
  nodes: Array<Post_Authors>;
};

/** aggregate fields of "post_authors" */
export type Post_Authors_Aggregate_Fields = {
  __typename?: 'post_authors_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Authors_Max_Fields>;
  min?: Maybe<Post_Authors_Min_Fields>;
};


/** aggregate fields of "post_authors" */
export type Post_Authors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Authors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_authors" */
export type Post_Authors_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Authors_Max_Order_By>;
  min?: InputMaybe<Post_Authors_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_authors" */
export type Post_Authors_Arr_Rel_Insert_Input = {
  data: Array<Post_Authors_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Authors_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_authors". All fields are combined with a logical 'AND'. */
export type Post_Authors_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Authors_Bool_Exp>>;
  _not?: InputMaybe<Post_Authors_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Authors_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_authors" */
export enum Post_Authors_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostAuthorsPkey = 'post_authors_pkey',
  /** unique or primary key constraint on columns "user_id", "post_id" */
  PostAuthorsPostIdUserIdUnique = 'post_authors_post_id_user_id_unique'
}

/** input type for inserting data into table "post_authors" */
export type Post_Authors_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Post_Authors_Max_Fields = {
  __typename?: 'post_authors_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "post_authors" */
export type Post_Authors_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Authors_Min_Fields = {
  __typename?: 'post_authors_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "post_authors" */
export type Post_Authors_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_authors" */
export type Post_Authors_Mutation_Response = {
  __typename?: 'post_authors_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Authors>;
};

/** on_conflict condition type for table "post_authors" */
export type Post_Authors_On_Conflict = {
  constraint: Post_Authors_Constraint;
  update_columns?: Array<Post_Authors_Update_Column>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};

/** Ordering options when selecting data from "post_authors". */
export type Post_Authors_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_authors */
export type Post_Authors_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_authors" */
export enum Post_Authors_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "post_authors" */
export type Post_Authors_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "post_authors" */
export type Post_Authors_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Authors_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Authors_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "post_authors" */
export enum Post_Authors_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  UserId = 'user_id'
}

export type Post_Authors_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Authors_Set_Input>;
  where: Post_Authors_Bool_Exp;
};

/** columns and relationships of "post_flags" */
export type Post_Flags = {
  __typename?: 'post_flags';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  flag: Flags;
  flag_id: Scalars['uuid'];
  id: Scalars['uuid'];
  /** An object relationship */
  post: Posts;
  post_id: Scalars['uuid'];
};

/** aggregated selection of "post_flags" */
export type Post_Flags_Aggregate = {
  __typename?: 'post_flags_aggregate';
  aggregate?: Maybe<Post_Flags_Aggregate_Fields>;
  nodes: Array<Post_Flags>;
};

/** aggregate fields of "post_flags" */
export type Post_Flags_Aggregate_Fields = {
  __typename?: 'post_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Flags_Max_Fields>;
  min?: Maybe<Post_Flags_Min_Fields>;
};


/** aggregate fields of "post_flags" */
export type Post_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_flags" */
export type Post_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Flags_Max_Order_By>;
  min?: InputMaybe<Post_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_flags" */
export type Post_Flags_Arr_Rel_Insert_Input = {
  data: Array<Post_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_flags". All fields are combined with a logical 'AND'. */
export type Post_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Flags_Bool_Exp>>;
  _not?: InputMaybe<Post_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_flags" */
export enum Post_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostFlagsPkey = 'post_flags_pkey',
  /** unique or primary key constraint on columns "flag_id", "post_id" */
  PostFlagsPostIdFlagIdUnique = 'post_flags_post_id_flag_id_unique'
}

/** input type for inserting data into table "post_flags" */
export type Post_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Post_Flags_Max_Fields = {
  __typename?: 'post_flags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "post_flags" */
export type Post_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Flags_Min_Fields = {
  __typename?: 'post_flags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "post_flags" */
export type Post_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_flags" */
export type Post_Flags_Mutation_Response = {
  __typename?: 'post_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Flags>;
};

/** on_conflict condition type for table "post_flags" */
export type Post_Flags_On_Conflict = {
  constraint: Post_Flags_Constraint;
  update_columns?: Array<Post_Flags_Update_Column>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "post_flags". */
export type Post_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_flags */
export type Post_Flags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_flags" */
export enum Post_Flags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id'
}

/** input type for updating data in table "post_flags" */
export type Post_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "post_flags" */
export type Post_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "post_flags" */
export enum Post_Flags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id'
}

export type Post_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Flags_Set_Input>;
  where: Post_Flags_Bool_Exp;
};

/** columns and relationships of "post_tags" */
export type Post_Tags = {
  __typename?: 'post_tags';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  post: Posts;
  post_id: Scalars['uuid'];
  /** An object relationship */
  tag: Tags;
  tag_id: Scalars['uuid'];
};

/** aggregated selection of "post_tags" */
export type Post_Tags_Aggregate = {
  __typename?: 'post_tags_aggregate';
  aggregate?: Maybe<Post_Tags_Aggregate_Fields>;
  nodes: Array<Post_Tags>;
};

/** aggregate fields of "post_tags" */
export type Post_Tags_Aggregate_Fields = {
  __typename?: 'post_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Post_Tags_Max_Fields>;
  min?: Maybe<Post_Tags_Min_Fields>;
};


/** aggregate fields of "post_tags" */
export type Post_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Post_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "post_tags" */
export type Post_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Post_Tags_Max_Order_By>;
  min?: InputMaybe<Post_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "post_tags" */
export type Post_Tags_Arr_Rel_Insert_Input = {
  data: Array<Post_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Post_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "post_tags". All fields are combined with a logical 'AND'. */
export type Post_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Post_Tags_Bool_Exp>>;
  _not?: InputMaybe<Post_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Post_Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  post?: InputMaybe<Posts_Bool_Exp>;
  post_id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "post_tags" */
export enum Post_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  PostTagsPkey = 'post_tags_pkey',
  /** unique or primary key constraint on columns "tag_id", "post_id" */
  PostTagsPostIdTagIdUnique = 'post_tags_post_id_tag_id_unique'
}

/** input type for inserting data into table "post_tags" */
export type Post_Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post?: InputMaybe<Posts_Obj_Rel_Insert_Input>;
  post_id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Post_Tags_Max_Fields = {
  __typename?: 'post_tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "post_tags" */
export type Post_Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Post_Tags_Min_Fields = {
  __typename?: 'post_tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  post_id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "post_tags" */
export type Post_Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post_id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "post_tags" */
export type Post_Tags_Mutation_Response = {
  __typename?: 'post_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Post_Tags>;
};

/** on_conflict condition type for table "post_tags" */
export type Post_Tags_On_Conflict = {
  constraint: Post_Tags_Constraint;
  update_columns?: Array<Post_Tags_Update_Column>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "post_tags". */
export type Post_Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  post?: InputMaybe<Posts_Order_By>;
  post_id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: post_tags */
export type Post_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "post_tags" */
export enum Post_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  TagId = 'tag_id'
}

/** input type for updating data in table "post_tags" */
export type Post_Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "post_tags" */
export type Post_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Post_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Post_Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  post_id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "post_tags" */
export enum Post_Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PostId = 'post_id',
  /** column name */
  TagId = 'tag_id'
}

export type Post_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Post_Tags_Set_Input>;
  where: Post_Tags_Bool_Exp;
};

/** columns and relationships of "posts" */
export type Posts = {
  __typename?: 'posts';
  /** An object relationship */
  blog: Blogs;
  blog_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  html?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  image?: Maybe<Scalars['String']>;
  markdown?: Maybe<Scalars['String']>;
  /** An array relationship */
  post_authors: Array<Post_Authors>;
  /** An aggregate relationship */
  post_authors_aggregate: Post_Authors_Aggregate;
  /** An array relationship */
  post_flags: Array<Post_Flags>;
  /** An aggregate relationship */
  post_flags_aggregate: Post_Flags_Aggregate;
  /** An array relationship */
  post_tags: Array<Post_Tags>;
  /** An aggregate relationship */
  post_tags_aggregate: Post_Tags_Aggregate;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "posts" */
export type PostsPost_AuthorsArgs = {
  distinct_on?: InputMaybe<Array<Post_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Authors_Order_By>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPost_Authors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Authors_Order_By>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPost_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Flags_Order_By>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPost_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Flags_Order_By>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPost_TagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Tags_Order_By>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};


/** columns and relationships of "posts" */
export type PostsPost_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Tags_Order_By>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};

/** aggregated selection of "posts" */
export type Posts_Aggregate = {
  __typename?: 'posts_aggregate';
  aggregate?: Maybe<Posts_Aggregate_Fields>;
  nodes: Array<Posts>;
};

/** aggregate fields of "posts" */
export type Posts_Aggregate_Fields = {
  __typename?: 'posts_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Posts_Max_Fields>;
  min?: Maybe<Posts_Min_Fields>;
};


/** aggregate fields of "posts" */
export type Posts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Posts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "posts" */
export type Posts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Posts_Max_Order_By>;
  min?: InputMaybe<Posts_Min_Order_By>;
};

/** input type for inserting array relation for remote table "posts" */
export type Posts_Arr_Rel_Insert_Input = {
  data: Array<Posts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "posts". All fields are combined with a logical 'AND'. */
export type Posts_Bool_Exp = {
  _and?: InputMaybe<Array<Posts_Bool_Exp>>;
  _not?: InputMaybe<Posts_Bool_Exp>;
  _or?: InputMaybe<Array<Posts_Bool_Exp>>;
  blog?: InputMaybe<Blogs_Bool_Exp>;
  blog_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  html?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  image?: InputMaybe<String_Comparison_Exp>;
  markdown?: InputMaybe<String_Comparison_Exp>;
  post_authors?: InputMaybe<Post_Authors_Bool_Exp>;
  post_flags?: InputMaybe<Post_Flags_Bool_Exp>;
  post_tags?: InputMaybe<Post_Tags_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "posts" */
export enum Posts_Constraint {
  /** unique or primary key constraint on columns "blog_id", "slug" */
  PostsBlogIdSlugUnique = 'posts_blog_id_slug_unique',
  /** unique or primary key constraint on columns "id" */
  PostsPkey = 'posts_pkey',
  /** unique or primary key constraint on columns "slug" */
  PostsSlugUnique = 'posts_slug_unique'
}

/** input type for inserting data into table "posts" */
export type Posts_Insert_Input = {
  blog?: InputMaybe<Blogs_Obj_Rel_Insert_Input>;
  blog_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  markdown?: InputMaybe<Scalars['String']>;
  post_authors?: InputMaybe<Post_Authors_Arr_Rel_Insert_Input>;
  post_flags?: InputMaybe<Post_Flags_Arr_Rel_Insert_Input>;
  post_tags?: InputMaybe<Post_Tags_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Posts_Max_Fields = {
  __typename?: 'posts_max_fields';
  blog_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  markdown?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "posts" */
export type Posts_Max_Order_By = {
  blog_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  markdown?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Posts_Min_Fields = {
  __typename?: 'posts_min_fields';
  blog_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  html?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  image?: Maybe<Scalars['String']>;
  markdown?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "posts" */
export type Posts_Min_Order_By = {
  blog_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  markdown?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "posts" */
export type Posts_Mutation_Response = {
  __typename?: 'posts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Posts>;
};

/** input type for inserting object relation for remote table "posts" */
export type Posts_Obj_Rel_Insert_Input = {
  data: Posts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Posts_On_Conflict>;
};

/** on_conflict condition type for table "posts" */
export type Posts_On_Conflict = {
  constraint: Posts_Constraint;
  update_columns?: Array<Posts_Update_Column>;
  where?: InputMaybe<Posts_Bool_Exp>;
};

/** Ordering options when selecting data from "posts". */
export type Posts_Order_By = {
  blog?: InputMaybe<Blogs_Order_By>;
  blog_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  html?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image?: InputMaybe<Order_By>;
  markdown?: InputMaybe<Order_By>;
  post_authors_aggregate?: InputMaybe<Post_Authors_Aggregate_Order_By>;
  post_flags_aggregate?: InputMaybe<Post_Flags_Aggregate_Order_By>;
  post_tags_aggregate?: InputMaybe<Post_Tags_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: posts */
export type Posts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "posts" */
export enum Posts_Select_Column {
  /** column name */
  BlogId = 'blog_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Markdown = 'markdown',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "posts" */
export type Posts_Set_Input = {
  blog_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  markdown?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "posts" */
export type Posts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Posts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Posts_Stream_Cursor_Value_Input = {
  blog_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  html?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  image?: InputMaybe<Scalars['String']>;
  markdown?: InputMaybe<Scalars['String']>;
  slug?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "posts" */
export enum Posts_Update_Column {
  /** column name */
  BlogId = 'blog_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Html = 'html',
  /** column name */
  Id = 'id',
  /** column name */
  Image = 'image',
  /** column name */
  Markdown = 'markdown',
  /** column name */
  Slug = 'slug',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Posts_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Posts_Set_Input>;
  where: Posts_Bool_Exp;
};

/** columns and relationships of "profiles" */
export type Profiles = {
  __typename?: 'profiles';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  esports_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  locale?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  name: Scalars['String'];
  nickname: Scalars['String'];
  phone_number?: Maybe<Scalars['String']>;
  primary_role_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  user_id?: Maybe<Scalars['uuid']>;
};


/** columns and relationships of "profiles" */
export type ProfilesMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "profiles" */
export type Profiles_Aggregate = {
  __typename?: 'profiles_aggregate';
  aggregate?: Maybe<Profiles_Aggregate_Fields>;
  nodes: Array<Profiles>;
};

/** aggregate fields of "profiles" */
export type Profiles_Aggregate_Fields = {
  __typename?: 'profiles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Profiles_Max_Fields>;
  min?: Maybe<Profiles_Min_Fields>;
};


/** aggregate fields of "profiles" */
export type Profiles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Profiles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Profiles_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "profiles". All fields are combined with a logical 'AND'. */
export type Profiles_Bool_Exp = {
  _and?: InputMaybe<Array<Profiles_Bool_Exp>>;
  _not?: InputMaybe<Profiles_Bool_Exp>;
  _or?: InputMaybe<Array<Profiles_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  bio?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  esports_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nickname?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  primary_role_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "profiles" */
export enum Profiles_Constraint {
  /** unique or primary key constraint on columns "nickname" */
  ProfilesNicknameUnique = 'profiles_nickname_unique',
  /** unique or primary key constraint on columns "id" */
  ProfilesPkey = 'profiles_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Profiles_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Profiles_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Profiles_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "profiles" */
export type Profiles_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  esports_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  primary_role_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Profiles_Max_Fields = {
  __typename?: 'profiles_max_fields';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  esports_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  primary_role_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Profiles_Min_Fields = {
  __typename?: 'profiles_min_fields';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  esports_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  primary_role_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "profiles" */
export type Profiles_Mutation_Response = {
  __typename?: 'profiles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Profiles>;
};

/** on_conflict condition type for table "profiles" */
export type Profiles_On_Conflict = {
  constraint: Profiles_Constraint;
  update_columns?: Array<Profiles_Update_Column>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};

/** Ordering options when selecting data from "profiles". */
export type Profiles_Order_By = {
  avatar?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  esports_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  locale?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  nickname?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  primary_role_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: profiles */
export type Profiles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Profiles_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "profiles" */
export enum Profiles_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EsportsId = 'esports_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  Location = 'location',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PrimaryRoleId = 'primary_role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "profiles" */
export type Profiles_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  esports_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  primary_role_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "profiles" */
export type Profiles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Profiles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Profiles_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  esports_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  name?: InputMaybe<Scalars['String']>;
  nickname?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  primary_role_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "profiles" */
export enum Profiles_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EsportsId = 'esports_id',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  Location = 'location',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Nickname = 'nickname',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  PrimaryRoleId = 'primary_role_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Profiles_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Profiles_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Profiles_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Profiles_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Profiles_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Profiles_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Profiles_Set_Input>;
  where: Profiles_Bool_Exp;
};

/** columns and relationships of "purchases" */
export type Purchases = {
  __typename?: 'purchases';
  created_at: Scalars['timestamptz'];
  download_count?: Maybe<Scalars['Int']>;
  /** An object relationship */
  game: Games;
  game_id: Scalars['uuid'];
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  upload: Uploads;
  upload_id: Scalars['uuid'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "purchases" */
export type Purchases_Aggregate = {
  __typename?: 'purchases_aggregate';
  aggregate?: Maybe<Purchases_Aggregate_Fields>;
  nodes: Array<Purchases>;
};

/** aggregate fields of "purchases" */
export type Purchases_Aggregate_Fields = {
  __typename?: 'purchases_aggregate_fields';
  avg?: Maybe<Purchases_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Purchases_Max_Fields>;
  min?: Maybe<Purchases_Min_Fields>;
  stddev?: Maybe<Purchases_Stddev_Fields>;
  stddev_pop?: Maybe<Purchases_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Purchases_Stddev_Samp_Fields>;
  sum?: Maybe<Purchases_Sum_Fields>;
  var_pop?: Maybe<Purchases_Var_Pop_Fields>;
  var_samp?: Maybe<Purchases_Var_Samp_Fields>;
  variance?: Maybe<Purchases_Variance_Fields>;
};


/** aggregate fields of "purchases" */
export type Purchases_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Purchases_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "purchases" */
export type Purchases_Aggregate_Order_By = {
  avg?: InputMaybe<Purchases_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Purchases_Max_Order_By>;
  min?: InputMaybe<Purchases_Min_Order_By>;
  stddev?: InputMaybe<Purchases_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Purchases_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Purchases_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Purchases_Sum_Order_By>;
  var_pop?: InputMaybe<Purchases_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Purchases_Var_Samp_Order_By>;
  variance?: InputMaybe<Purchases_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "purchases" */
export type Purchases_Arr_Rel_Insert_Input = {
  data: Array<Purchases_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Purchases_On_Conflict>;
};

/** aggregate avg on columns */
export type Purchases_Avg_Fields = {
  __typename?: 'purchases_avg_fields';
  download_count?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "purchases" */
export type Purchases_Avg_Order_By = {
  download_count?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "purchases". All fields are combined with a logical 'AND'. */
export type Purchases_Bool_Exp = {
  _and?: InputMaybe<Array<Purchases_Bool_Exp>>;
  _not?: InputMaybe<Purchases_Bool_Exp>;
  _or?: InputMaybe<Array<Purchases_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  download_count?: InputMaybe<Int_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload?: InputMaybe<Uploads_Bool_Exp>;
  upload_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "purchases" */
export enum Purchases_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchasesPkey = 'purchases_pkey',
  /** unique or primary key constraint on columns "game_id", "user_id", "upload_id" */
  PurchasesUserIdGameIdUploadIdUnique = 'purchases_user_id_game_id_upload_id_unique'
}

/** input type for incrementing numeric columns in table "purchases" */
export type Purchases_Inc_Input = {
  download_count?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "purchases" */
export type Purchases_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  download_count?: InputMaybe<Scalars['Int']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upload?: InputMaybe<Uploads_Obj_Rel_Insert_Input>;
  upload_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Purchases_Max_Fields = {
  __typename?: 'purchases_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  download_count?: Maybe<Scalars['Int']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "purchases" */
export type Purchases_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  download_count?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Purchases_Min_Fields = {
  __typename?: 'purchases_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  download_count?: Maybe<Scalars['Int']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  upload_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "purchases" */
export type Purchases_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  download_count?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "purchases" */
export type Purchases_Mutation_Response = {
  __typename?: 'purchases_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Purchases>;
};

/** on_conflict condition type for table "purchases" */
export type Purchases_On_Conflict = {
  constraint: Purchases_Constraint;
  update_columns?: Array<Purchases_Update_Column>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};

/** Ordering options when selecting data from "purchases". */
export type Purchases_Order_By = {
  created_at?: InputMaybe<Order_By>;
  download_count?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload?: InputMaybe<Uploads_Order_By>;
  upload_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: purchases */
export type Purchases_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "purchases" */
export enum Purchases_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DownloadCount = 'download_count',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "purchases" */
export type Purchases_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  download_count?: InputMaybe<Scalars['Int']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upload_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Purchases_Stddev_Fields = {
  __typename?: 'purchases_stddev_fields';
  download_count?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "purchases" */
export type Purchases_Stddev_Order_By = {
  download_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Purchases_Stddev_Pop_Fields = {
  __typename?: 'purchases_stddev_pop_fields';
  download_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "purchases" */
export type Purchases_Stddev_Pop_Order_By = {
  download_count?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Purchases_Stddev_Samp_Fields = {
  __typename?: 'purchases_stddev_samp_fields';
  download_count?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "purchases" */
export type Purchases_Stddev_Samp_Order_By = {
  download_count?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "purchases" */
export type Purchases_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Purchases_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Purchases_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  download_count?: InputMaybe<Scalars['Int']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upload_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate sum on columns */
export type Purchases_Sum_Fields = {
  __typename?: 'purchases_sum_fields';
  download_count?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "purchases" */
export type Purchases_Sum_Order_By = {
  download_count?: InputMaybe<Order_By>;
};

/** update columns of table "purchases" */
export enum Purchases_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DownloadCount = 'download_count',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UploadId = 'upload_id',
  /** column name */
  UserId = 'user_id'
}

export type Purchases_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Purchases_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Purchases_Set_Input>;
  where: Purchases_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Purchases_Var_Pop_Fields = {
  __typename?: 'purchases_var_pop_fields';
  download_count?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "purchases" */
export type Purchases_Var_Pop_Order_By = {
  download_count?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Purchases_Var_Samp_Fields = {
  __typename?: 'purchases_var_samp_fields';
  download_count?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "purchases" */
export type Purchases_Var_Samp_Order_By = {
  download_count?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Purchases_Variance_Fields = {
  __typename?: 'purchases_variance_fields';
  download_count?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "purchases" */
export type Purchases_Variance_Order_By = {
  download_count?: InputMaybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "audit_log_entries" */
  audit_log_entries: Array<Audit_Log_Entries>;
  /** fetch aggregated fields from the table: "audit_log_entries" */
  audit_log_entries_aggregate: Audit_Log_Entries_Aggregate;
  /** fetch data from the table: "audit_log_entries" using primary key columns */
  audit_log_entries_by_pk?: Maybe<Audit_Log_Entries>;
  /** An array relationship */
  blog_flags: Array<Blog_Flags>;
  /** An aggregate relationship */
  blog_flags_aggregate: Blog_Flags_Aggregate;
  /** fetch data from the table: "blog_flags" using primary key columns */
  blog_flags_by_pk?: Maybe<Blog_Flags>;
  /** fetch data from the table: "blogs" */
  blogs: Array<Blogs>;
  /** fetch aggregated fields from the table: "blogs" */
  blogs_aggregate: Blogs_Aggregate;
  /** fetch data from the table: "blogs" using primary key columns */
  blogs_by_pk?: Maybe<Blogs>;
  /** fetch data from the table: "books" */
  books: Array<Books>;
  /** fetch aggregated fields from the table: "books" */
  books_aggregate: Books_Aggregate;
  /** fetch data from the table: "books" using primary key columns */
  books_by_pk?: Maybe<Books>;
  /** An array relationship */
  channel_members: Array<Channel_Members>;
  /** An aggregate relationship */
  channel_members_aggregate: Channel_Members_Aggregate;
  /** fetch data from the table: "channel_members" using primary key columns */
  channel_members_by_pk?: Maybe<Channel_Members>;
  /** fetch data from the table: "channels" */
  channels: Array<Channels>;
  /** fetch aggregated fields from the table: "channels" */
  channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table: "clients" */
  clients: Array<Clients>;
  /** fetch aggregated fields from the table: "clients" */
  clients_aggregate: Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  clients_by_pk?: Maybe<Clients>;
  /** An array relationship */
  comment_mentions: Array<Comment_Mentions>;
  /** An aggregate relationship */
  comment_mentions_aggregate: Comment_Mentions_Aggregate;
  /** fetch data from the table: "comment_mentions" using primary key columns */
  comment_mentions_by_pk?: Maybe<Comment_Mentions>;
  /** An array relationship */
  comments: Array<Comments>;
  /** An aggregate relationship */
  comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  comments_by_pk?: Maybe<Comments>;
  /** fetch data from the table: "daily_comments_count" */
  daily_comments_count: Array<Daily_Comments_Count>;
  /** fetch aggregated fields from the table: "daily_comments_count" */
  daily_comments_count_aggregate: Daily_Comments_Count_Aggregate;
  /** fetch data from the table: "daily_likes_count" */
  daily_likes_count: Array<Daily_Likes_Count>;
  /** fetch aggregated fields from the table: "daily_likes_count" */
  daily_likes_count_aggregate: Daily_Likes_Count_Aggregate;
  /** fetch data from the table: "daily_user_count" */
  daily_user_count: Array<Daily_User_Count>;
  /** fetch aggregated fields from the table: "daily_user_count" */
  daily_user_count_aggregate: Daily_User_Count_Aggregate;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** An array relationship */
  featured_games: Array<Featured_Games>;
  /** An aggregate relationship */
  featured_games_aggregate: Featured_Games_Aggregate;
  /** fetch data from the table: "featured_games" using primary key columns */
  featured_games_by_pk?: Maybe<Featured_Games>;
  /** Fetch wallet transactions */
  fetchWalletTransactions?: Maybe<FetchWalletTransactionsOutput>;
  /** fetch data from the table: "file_storage_providers" */
  file_storage_providers: Array<File_Storage_Providers>;
  /** fetch aggregated fields from the table: "file_storage_providers" */
  file_storage_providers_aggregate: File_Storage_Providers_Aggregate;
  /** fetch data from the table: "file_storage_providers" using primary key columns */
  file_storage_providers_by_pk?: Maybe<File_Storage_Providers>;
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table: "flags" */
  flags: Array<Flags>;
  /** fetch aggregated fields from the table: "flags" */
  flags_aggregate: Flags_Aggregate;
  /** fetch data from the table: "flags" using primary key columns */
  flags_by_pk?: Maybe<Flags>;
  /** An array relationship */
  game_comments: Array<Game_Comments>;
  /** An aggregate relationship */
  game_comments_aggregate: Game_Comments_Aggregate;
  /** fetch data from the table: "game_comments" using primary key columns */
  game_comments_by_pk?: Maybe<Game_Comments>;
  /** An array relationship */
  game_files: Array<Game_Files>;
  /** An aggregate relationship */
  game_files_aggregate: Game_Files_Aggregate;
  /** fetch data from the table: "game_files" using primary key columns */
  game_files_by_pk?: Maybe<Game_Files>;
  /** An array relationship */
  game_flags: Array<Game_Flags>;
  /** An aggregate relationship */
  game_flags_aggregate: Game_Flags_Aggregate;
  /** fetch data from the table: "game_flags" using primary key columns */
  game_flags_by_pk?: Maybe<Game_Flags>;
  /** An array relationship */
  game_genres: Array<Game_Genres>;
  /** An aggregate relationship */
  game_genres_aggregate: Game_Genres_Aggregate;
  /** fetch data from the table: "game_genres" using primary key columns */
  game_genres_by_pk?: Maybe<Game_Genres>;
  /** An array relationship */
  game_ratings: Array<Game_Ratings>;
  /** An aggregate relationship */
  game_ratings_aggregate: Game_Ratings_Aggregate;
  /** fetch data from the table: "game_ratings" using primary key columns */
  game_ratings_by_pk?: Maybe<Game_Ratings>;
  /** An array relationship */
  game_serving_points: Array<Game_Serving_Points>;
  /** An aggregate relationship */
  game_serving_points_aggregate: Game_Serving_Points_Aggregate;
  /** fetch data from the table: "game_serving_points" using primary key columns */
  game_serving_points_by_pk?: Maybe<Game_Serving_Points>;
  /** An array relationship */
  games: Array<Games>;
  /** An aggregate relationship */
  games_aggregate: Games_Aggregate;
  /** fetch data from the table: "games" using primary key columns */
  games_by_pk?: Maybe<Games>;
  generateOTP: GenerateOtpOutput;
  getAuthenticatorQRCode?: Maybe<QrAuthenticatorOutput>;
  /** Get Celo Wallet Balance */
  getCeloBalance?: Maybe<CeloWalletBalanceOutput>;
  /** Get wallet balance */
  getWalletBalance?: Maybe<GetWalletBalanceOutput>;
  /** An array relationship */
  group_flags: Array<Group_Flags>;
  /** An aggregate relationship */
  group_flags_aggregate: Group_Flags_Aggregate;
  /** fetch data from the table: "group_flags" using primary key columns */
  group_flags_by_pk?: Maybe<Group_Flags>;
  /** An array relationship */
  group_members: Array<Group_Members>;
  /** An aggregate relationship */
  group_members_aggregate: Group_Members_Aggregate;
  /** fetch data from the table: "group_members" using primary key columns */
  group_members_by_pk?: Maybe<Group_Members>;
  /** fetch data from the table: "group_permissions" */
  group_permissions: Array<Group_Permissions>;
  /** fetch aggregated fields from the table: "group_permissions" */
  group_permissions_aggregate: Group_Permissions_Aggregate;
  /** fetch data from the table: "group_permissions" using primary key columns */
  group_permissions_by_pk?: Maybe<Group_Permissions>;
  /** fetch data from the table: "group_roles" */
  group_roles: Array<Group_Roles>;
  /** fetch aggregated fields from the table: "group_roles" */
  group_roles_aggregate: Group_Roles_Aggregate;
  /** fetch data from the table: "group_roles" using primary key columns */
  group_roles_by_pk?: Maybe<Group_Roles>;
  /** fetch data from the table: "groups" */
  groups: Array<Groups>;
  /** fetch aggregated fields from the table: "groups" */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table: "journal_entries" */
  journal_entries: Array<Journal_Entries>;
  /** fetch aggregated fields from the table: "journal_entries" */
  journal_entries_aggregate: Journal_Entries_Aggregate;
  /** fetch data from the table: "journal_entries" using primary key columns */
  journal_entries_by_pk?: Maybe<Journal_Entries>;
  /** An array relationship */
  likes: Array<Likes>;
  /** An aggregate relationship */
  likes_aggregate: Likes_Aggregate;
  /** fetch data from the table: "likes" using primary key columns */
  likes_by_pk?: Maybe<Likes>;
  me?: Maybe<MeOutput>;
  /** An array relationship */
  notification_recipients: Array<Notification_Recipients>;
  /** An aggregate relationship */
  notification_recipients_aggregate: Notification_Recipients_Aggregate;
  /** fetch data from the table: "notification_recipients" using primary key columns */
  notification_recipients_by_pk?: Maybe<Notification_Recipients>;
  /** fetch data from the table: "notification_statuses" */
  notification_statuses: Array<Notification_Statuses>;
  /** fetch aggregated fields from the table: "notification_statuses" */
  notification_statuses_aggregate: Notification_Statuses_Aggregate;
  /** fetch data from the table: "notification_statuses" using primary key columns */
  notification_statuses_by_pk?: Maybe<Notification_Statuses>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "oidc.oidc_payloads" */
  oidc_oidc_payloads: Array<Oidc_Oidc_Payloads>;
  /** fetch aggregated fields from the table: "oidc.oidc_payloads" */
  oidc_oidc_payloads_aggregate: Oidc_Oidc_Payloads_Aggregate;
  /** fetch data from the table: "oidc.oidc_payloads" using primary key columns */
  oidc_oidc_payloads_by_pk?: Maybe<Oidc_Oidc_Payloads>;
  /** fetch data from the table: "pages" */
  pages: Array<Pages>;
  /** fetch aggregated fields from the table: "pages" */
  pages_aggregate: Pages_Aggregate;
  /** fetch data from the table: "pages" using primary key columns */
  pages_by_pk?: Maybe<Pages>;
  /** An array relationship */
  password_resets: Array<Password_Resets>;
  /** An aggregate relationship */
  password_resets_aggregate: Password_Resets_Aggregate;
  /** fetch data from the table: "password_resets" using primary key columns */
  password_resets_by_pk?: Maybe<Password_Resets>;
  /** An array relationship */
  post_authors: Array<Post_Authors>;
  /** An aggregate relationship */
  post_authors_aggregate: Post_Authors_Aggregate;
  /** fetch data from the table: "post_authors" using primary key columns */
  post_authors_by_pk?: Maybe<Post_Authors>;
  /** An array relationship */
  post_flags: Array<Post_Flags>;
  /** An aggregate relationship */
  post_flags_aggregate: Post_Flags_Aggregate;
  /** fetch data from the table: "post_flags" using primary key columns */
  post_flags_by_pk?: Maybe<Post_Flags>;
  /** An array relationship */
  post_tags: Array<Post_Tags>;
  /** An aggregate relationship */
  post_tags_aggregate: Post_Tags_Aggregate;
  /** fetch data from the table: "post_tags" using primary key columns */
  post_tags_by_pk?: Maybe<Post_Tags>;
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  /** fetch data from the table: "profiles" */
  profiles: Array<Profiles>;
  /** fetch aggregated fields from the table: "profiles" */
  profiles_aggregate: Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** An array relationship */
  purchases: Array<Purchases>;
  /** An aggregate relationship */
  purchases_aggregate: Purchases_Aggregate;
  /** fetch data from the table: "purchases" using primary key columns */
  purchases_by_pk?: Maybe<Purchases>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table: "serving_points" */
  serving_points: Array<Serving_Points>;
  /** fetch aggregated fields from the table: "serving_points" */
  serving_points_aggregate: Serving_Points_Aggregate;
  /** fetch data from the table: "serving_points" using primary key columns */
  serving_points_by_pk?: Maybe<Serving_Points>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "oidc.oidc_clients" */
  third_party_apps: Array<Third_Party_Apps>;
  /** fetch aggregated fields from the table: "oidc.oidc_clients" */
  third_party_apps_aggregate: Third_Party_Apps_Aggregate;
  /** fetch data from the table: "oidc.oidc_clients" using primary key columns */
  third_party_apps_by_pk?: Maybe<Third_Party_Apps>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** An array relationship */
  upload_tags: Array<Upload_Tags>;
  /** An aggregate relationship */
  upload_tags_aggregate: Upload_Tags_Aggregate;
  /** fetch data from the table: "upload_tags" using primary key columns */
  upload_tags_by_pk?: Maybe<Upload_Tags>;
  /** An array relationship */
  uploads: Array<Uploads>;
  /** An aggregate relationship */
  uploads_aggregate: Uploads_Aggregate;
  /** fetch data from the table: "uploads" using primary key columns */
  uploads_by_pk?: Maybe<Uploads>;
  /** An array relationship */
  user_flags: Array<User_Flags>;
  /** An aggregate relationship */
  user_flags_aggregate: User_Flags_Aggregate;
  /** fetch data from the table: "user_flags" using primary key columns */
  user_flags_by_pk?: Maybe<User_Flags>;
  /** fetch data from the table: "user_group_roles" */
  user_group_roles: Array<User_Group_Roles>;
  /** fetch aggregated fields from the table: "user_group_roles" */
  user_group_roles_aggregate: User_Group_Roles_Aggregate;
  /** fetch data from the table: "user_group_roles" using primary key columns */
  user_group_roles_by_pk?: Maybe<User_Group_Roles>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** An array relationship */
  user_social_logins: Array<User_Social_Logins>;
  /** An aggregate relationship */
  user_social_logins_aggregate: User_Social_Logins_Aggregate;
  /** fetch data from the table: "user_social_logins" using primary key columns */
  user_social_logins_by_pk?: Maybe<User_Social_Logins>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "verifications" */
  verifications: Array<Verifications>;
  /** fetch aggregated fields from the table: "verifications" */
  verifications_aggregate: Verifications_Aggregate;
  /** fetch data from the table: "verifications" using primary key columns */
  verifications_by_pk?: Maybe<Verifications>;
  verifyMFA?: Maybe<VerifyMfaOutput>;
  /** An array relationship */
  wallet_transactions: Array<Wallet_Transactions>;
  /** An aggregate relationship */
  wallet_transactions_aggregate: Wallet_Transactions_Aggregate;
  /** fetch data from the table: "wallet_transactions" using primary key columns */
  wallet_transactions_by_pk?: Maybe<Wallet_Transactions>;
  /** An array relationship */
  wallets: Array<Wallets>;
  /** An aggregate relationship */
  wallets_aggregate: Wallets_Aggregate;
  /** fetch data from the table: "wallets" using primary key columns */
  wallets_by_pk?: Maybe<Wallets>;
};


export type Query_RootAudit_Log_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Log_Entries_Order_By>>;
  where?: InputMaybe<Audit_Log_Entries_Bool_Exp>;
};


export type Query_RootAudit_Log_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Log_Entries_Order_By>>;
  where?: InputMaybe<Audit_Log_Entries_Bool_Exp>;
};


export type Query_RootAudit_Log_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBlog_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Flags_Order_By>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


export type Query_RootBlog_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Flags_Order_By>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


export type Query_RootBlog_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBlogsArgs = {
  distinct_on?: InputMaybe<Array<Blogs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blogs_Order_By>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Query_RootBlogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blogs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blogs_Order_By>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Query_RootBlogs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBooksArgs = {
  distinct_on?: InputMaybe<Array<Books_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Books_Order_By>>;
  where?: InputMaybe<Books_Bool_Exp>;
};


export type Query_RootBooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Books_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Books_Order_By>>;
  where?: InputMaybe<Books_Bool_Exp>;
};


export type Query_RootBooks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootChannel_MembersArgs = {
  distinct_on?: InputMaybe<Array<Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Members_Order_By>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


export type Query_RootChannel_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Members_Order_By>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


export type Query_RootChannel_Members_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Query_RootChannels_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootClientsArgs = {
  distinct_on?: InputMaybe<Array<Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clients_Order_By>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Query_RootClients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clients_Order_By>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Query_RootClients_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootComment_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Mentions_Order_By>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


export type Query_RootComment_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Mentions_Order_By>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


export type Query_RootComment_Mentions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Query_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Query_RootComments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootDaily_Comments_CountArgs = {
  distinct_on?: InputMaybe<Array<Daily_Comments_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Comments_Count_Order_By>>;
  where?: InputMaybe<Daily_Comments_Count_Bool_Exp>;
};


export type Query_RootDaily_Comments_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Comments_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Comments_Count_Order_By>>;
  where?: InputMaybe<Daily_Comments_Count_Bool_Exp>;
};


export type Query_RootDaily_Likes_CountArgs = {
  distinct_on?: InputMaybe<Array<Daily_Likes_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Likes_Count_Order_By>>;
  where?: InputMaybe<Daily_Likes_Count_Bool_Exp>;
};


export type Query_RootDaily_Likes_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Likes_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Likes_Count_Order_By>>;
  where?: InputMaybe<Daily_Likes_Count_Bool_Exp>;
};


export type Query_RootDaily_User_CountArgs = {
  distinct_on?: InputMaybe<Array<Daily_User_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_User_Count_Order_By>>;
  where?: InputMaybe<Daily_User_Count_Bool_Exp>;
};


export type Query_RootDaily_User_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_User_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_User_Count_Order_By>>;
  where?: InputMaybe<Daily_User_Count_Bool_Exp>;
};


export type Query_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Query_RootEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFeatured_GamesArgs = {
  distinct_on?: InputMaybe<Array<Featured_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Featured_Games_Order_By>>;
  where?: InputMaybe<Featured_Games_Bool_Exp>;
};


export type Query_RootFeatured_Games_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Featured_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Featured_Games_Order_By>>;
  where?: InputMaybe<Featured_Games_Bool_Exp>;
};


export type Query_RootFeatured_Games_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFetchWalletTransactionsArgs = {
  params: FetchWalletTransactionsInput;
};


export type Query_RootFile_Storage_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<File_Storage_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Storage_Providers_Order_By>>;
  where?: InputMaybe<File_Storage_Providers_Bool_Exp>;
};


export type Query_RootFile_Storage_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Storage_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Storage_Providers_Order_By>>;
  where?: InputMaybe<File_Storage_Providers_Bool_Exp>;
};


export type Query_RootFile_Storage_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Query_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootFlagsArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Query_RootFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Query_RootFlags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGame_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Game_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Comments_Order_By>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


export type Query_RootGame_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Comments_Order_By>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


export type Query_RootGame_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGame_FilesArgs = {
  distinct_on?: InputMaybe<Array<Game_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Files_Order_By>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


export type Query_RootGame_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Files_Order_By>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


export type Query_RootGame_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGame_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Game_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Flags_Order_By>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


export type Query_RootGame_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Flags_Order_By>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


export type Query_RootGame_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGame_GenresArgs = {
  distinct_on?: InputMaybe<Array<Game_Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Genres_Order_By>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


export type Query_RootGame_Genres_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Genres_Order_By>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


export type Query_RootGame_Genres_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGame_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Ratings_Order_By>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};


export type Query_RootGame_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Ratings_Order_By>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};


export type Query_RootGame_Ratings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGame_Serving_PointsArgs = {
  distinct_on?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Serving_Points_Order_By>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


export type Query_RootGame_Serving_Points_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Serving_Points_Order_By>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


export type Query_RootGame_Serving_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGamesArgs = {
  distinct_on?: InputMaybe<Array<Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Games_Order_By>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


export type Query_RootGames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Games_Order_By>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


export type Query_RootGames_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGetCeloBalanceArgs = {
  params: CeloWalletBalanceInput;
};


export type Query_RootGetWalletBalanceArgs = {
  params: FetchWalletTransactionsInput;
};


export type Query_RootGroup_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Flags_Order_By>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


export type Query_RootGroup_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Flags_Order_By>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


export type Query_RootGroup_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Query_RootGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Query_RootGroup_Members_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroup_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Permissions_Order_By>>;
  where?: InputMaybe<Group_Permissions_Bool_Exp>;
};


export type Query_RootGroup_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Permissions_Order_By>>;
  where?: InputMaybe<Group_Permissions_Bool_Exp>;
};


export type Query_RootGroup_Permissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroup_RolesArgs = {
  distinct_on?: InputMaybe<Array<Group_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Roles_Order_By>>;
  where?: InputMaybe<Group_Roles_Bool_Exp>;
};


export type Query_RootGroup_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Roles_Order_By>>;
  where?: InputMaybe<Group_Roles_Bool_Exp>;
};


export type Query_RootGroup_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Query_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootJournal_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Query_RootJournal_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Query_RootJournal_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootLikesArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Query_RootLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Query_RootLikes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotification_RecipientsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Recipients_Order_By>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};


export type Query_RootNotification_Recipients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Recipients_Order_By>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};


export type Query_RootNotification_Recipients_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotification_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Statuses_Order_By>>;
  where?: InputMaybe<Notification_Statuses_Bool_Exp>;
};


export type Query_RootNotification_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Statuses_Order_By>>;
  where?: InputMaybe<Notification_Statuses_Bool_Exp>;
};


export type Query_RootNotification_Statuses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootOidc_Oidc_PayloadsArgs = {
  distinct_on?: InputMaybe<Array<Oidc_Oidc_Payloads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Oidc_Oidc_Payloads_Order_By>>;
  where?: InputMaybe<Oidc_Oidc_Payloads_Bool_Exp>;
};


export type Query_RootOidc_Oidc_Payloads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Oidc_Oidc_Payloads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Oidc_Oidc_Payloads_Order_By>>;
  where?: InputMaybe<Oidc_Oidc_Payloads_Bool_Exp>;
};


export type Query_RootOidc_Oidc_Payloads_By_PkArgs = {
  id: Scalars['String'];
  oidc_type: Scalars['Int'];
};


export type Query_RootPagesArgs = {
  distinct_on?: InputMaybe<Array<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Query_RootPages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Query_RootPages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPassword_ResetsArgs = {
  distinct_on?: InputMaybe<Array<Password_Resets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Password_Resets_Order_By>>;
  where?: InputMaybe<Password_Resets_Bool_Exp>;
};


export type Query_RootPassword_Resets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Resets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Password_Resets_Order_By>>;
  where?: InputMaybe<Password_Resets_Bool_Exp>;
};


export type Query_RootPassword_Resets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPost_AuthorsArgs = {
  distinct_on?: InputMaybe<Array<Post_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Authors_Order_By>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


export type Query_RootPost_Authors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Authors_Order_By>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


export type Query_RootPost_Authors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPost_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Flags_Order_By>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


export type Query_RootPost_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Flags_Order_By>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


export type Query_RootPost_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPost_TagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Tags_Order_By>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};


export type Query_RootPost_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Tags_Order_By>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};


export type Query_RootPost_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Query_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Query_RootPosts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Query_RootProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Query_RootProfiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootPurchasesArgs = {
  distinct_on?: InputMaybe<Array<Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchases_Order_By>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


export type Query_RootPurchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchases_Order_By>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


export type Query_RootPurchases_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootServing_PointsArgs = {
  distinct_on?: InputMaybe<Array<Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serving_Points_Order_By>>;
  where?: InputMaybe<Serving_Points_Bool_Exp>;
};


export type Query_RootServing_Points_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serving_Points_Order_By>>;
  where?: InputMaybe<Serving_Points_Bool_Exp>;
};


export type Query_RootServing_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootThird_Party_AppsArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Apps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Third_Party_Apps_Order_By>>;
  where?: InputMaybe<Third_Party_Apps_Bool_Exp>;
};


export type Query_RootThird_Party_Apps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Apps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Third_Party_Apps_Order_By>>;
  where?: InputMaybe<Third_Party_Apps_Bool_Exp>;
};


export type Query_RootThird_Party_Apps_By_PkArgs = {
  id: Scalars['String'];
};


export type Query_RootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Query_RootTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUpload_TagsArgs = {
  distinct_on?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upload_Tags_Order_By>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};


export type Query_RootUpload_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upload_Tags_Order_By>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};


export type Query_RootUpload_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUploadsArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};


export type Query_RootUploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};


export type Query_RootUploads_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_FlagsArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};


export type Query_RootUser_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};


export type Query_RootUser_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_Group_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Group_Roles_Order_By>>;
  where?: InputMaybe<User_Group_Roles_Bool_Exp>;
};


export type Query_RootUser_Group_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Group_Roles_Order_By>>;
  where?: InputMaybe<User_Group_Roles_Bool_Exp>;
};


export type Query_RootUser_Group_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Query_RootUser_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUser_Social_LoginsArgs = {
  distinct_on?: InputMaybe<Array<User_Social_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Social_Logins_Order_By>>;
  where?: InputMaybe<User_Social_Logins_Bool_Exp>;
};


export type Query_RootUser_Social_Logins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Social_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Social_Logins_Order_By>>;
  where?: InputMaybe<User_Social_Logins_Bool_Exp>;
};


export type Query_RootUser_Social_Logins_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVerificationsArgs = {
  distinct_on?: InputMaybe<Array<Verifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verifications_Order_By>>;
  where?: InputMaybe<Verifications_Bool_Exp>;
};


export type Query_RootVerifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verifications_Order_By>>;
  where?: InputMaybe<Verifications_Bool_Exp>;
};


export type Query_RootVerifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootVerifyMfaArgs = {
  params: VerifyMfaInput;
};


export type Query_RootWallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type Query_RootWallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type Query_RootWallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootWalletsArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Query_RootWallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Query_RootWallets_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name: Scalars['String'];
  power: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  avg?: Maybe<Roles_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
  stddev?: Maybe<Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Roles_Sum_Fields>;
  var_pop?: Maybe<Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Roles_Var_Samp_Fields>;
  variance?: Maybe<Roles_Variance_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Roles_Avg_Fields = {
  __typename?: 'roles_avg_fields';
  power?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  power?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "name" */
  RolesNameUnique = 'roles_name_unique',
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey'
}

/** input type for incrementing numeric columns in table "roles" */
export type Roles_Inc_Input = {
  power?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  power?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  power?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Power = 'power',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Roles_Stddev_Fields = {
  __typename?: 'roles_stddev_fields';
  power?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Roles_Stddev_Pop_Fields = {
  __typename?: 'roles_stddev_pop_fields';
  power?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Roles_Stddev_Samp_Fields = {
  __typename?: 'roles_stddev_samp_fields';
  power?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  power?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Roles_Sum_Fields = {
  __typename?: 'roles_sum_fields';
  power?: Maybe<Scalars['Int']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Power = 'power',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Roles_Var_Pop_Fields = {
  __typename?: 'roles_var_pop_fields';
  power?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Roles_Var_Samp_Fields = {
  __typename?: 'roles_var_samp_fields';
  power?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Roles_Variance_Fields = {
  __typename?: 'roles_variance_fields';
  power?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "serving_points" */
export type Serving_Points = {
  __typename?: 'serving_points';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  game_serving_points: Array<Game_Serving_Points>;
  /** An aggregate relationship */
  game_serving_points_aggregate: Game_Serving_Points_Aggregate;
  id: Scalars['uuid'];
  is_active: Scalars['Boolean'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  uploads: Array<Uploads>;
  /** An aggregate relationship */
  uploads_aggregate: Uploads_Aggregate;
  value: Scalars['String'];
};


/** columns and relationships of "serving_points" */
export type Serving_PointsGame_Serving_PointsArgs = {
  distinct_on?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Serving_Points_Order_By>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


/** columns and relationships of "serving_points" */
export type Serving_PointsGame_Serving_Points_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Serving_Points_Order_By>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


/** columns and relationships of "serving_points" */
export type Serving_PointsUploadsArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};


/** columns and relationships of "serving_points" */
export type Serving_PointsUploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};

/** aggregated selection of "serving_points" */
export type Serving_Points_Aggregate = {
  __typename?: 'serving_points_aggregate';
  aggregate?: Maybe<Serving_Points_Aggregate_Fields>;
  nodes: Array<Serving_Points>;
};

/** aggregate fields of "serving_points" */
export type Serving_Points_Aggregate_Fields = {
  __typename?: 'serving_points_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Serving_Points_Max_Fields>;
  min?: Maybe<Serving_Points_Min_Fields>;
};


/** aggregate fields of "serving_points" */
export type Serving_Points_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Serving_Points_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "serving_points". All fields are combined with a logical 'AND'. */
export type Serving_Points_Bool_Exp = {
  _and?: InputMaybe<Array<Serving_Points_Bool_Exp>>;
  _not?: InputMaybe<Serving_Points_Bool_Exp>;
  _or?: InputMaybe<Array<Serving_Points_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  game_serving_points?: InputMaybe<Game_Serving_Points_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  uploads?: InputMaybe<Uploads_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "serving_points" */
export enum Serving_Points_Constraint {
  /** unique or primary key constraint on columns "name" */
  ServingPointsNameUnique = 'serving_points_name_unique',
  /** unique or primary key constraint on columns "id" */
  ServingPointsPkey = 'serving_points_pkey'
}

/** input type for inserting data into table "serving_points" */
export type Serving_Points_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  game_serving_points?: InputMaybe<Game_Serving_Points_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  uploads?: InputMaybe<Uploads_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Serving_Points_Max_Fields = {
  __typename?: 'serving_points_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Serving_Points_Min_Fields = {
  __typename?: 'serving_points_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "serving_points" */
export type Serving_Points_Mutation_Response = {
  __typename?: 'serving_points_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Serving_Points>;
};

/** input type for inserting object relation for remote table "serving_points" */
export type Serving_Points_Obj_Rel_Insert_Input = {
  data: Serving_Points_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Serving_Points_On_Conflict>;
};

/** on_conflict condition type for table "serving_points" */
export type Serving_Points_On_Conflict = {
  constraint: Serving_Points_Constraint;
  update_columns?: Array<Serving_Points_Update_Column>;
  where?: InputMaybe<Serving_Points_Bool_Exp>;
};

/** Ordering options when selecting data from "serving_points". */
export type Serving_Points_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  game_serving_points_aggregate?: InputMaybe<Game_Serving_Points_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  uploads_aggregate?: InputMaybe<Uploads_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: serving_points */
export type Serving_Points_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "serving_points" */
export enum Serving_Points_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "serving_points" */
export type Serving_Points_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "serving_points" */
export type Serving_Points_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Serving_Points_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Serving_Points_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "serving_points" */
export enum Serving_Points_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Serving_Points_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Serving_Points_Set_Input>;
  where: Serving_Points_Bool_Exp;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "audit_log_entries" */
  audit_log_entries: Array<Audit_Log_Entries>;
  /** fetch aggregated fields from the table: "audit_log_entries" */
  audit_log_entries_aggregate: Audit_Log_Entries_Aggregate;
  /** fetch data from the table: "audit_log_entries" using primary key columns */
  audit_log_entries_by_pk?: Maybe<Audit_Log_Entries>;
  /** fetch data from the table in a streaming manner : "audit_log_entries" */
  audit_log_entries_stream: Array<Audit_Log_Entries>;
  /** An array relationship */
  blog_flags: Array<Blog_Flags>;
  /** An aggregate relationship */
  blog_flags_aggregate: Blog_Flags_Aggregate;
  /** fetch data from the table: "blog_flags" using primary key columns */
  blog_flags_by_pk?: Maybe<Blog_Flags>;
  /** fetch data from the table in a streaming manner : "blog_flags" */
  blog_flags_stream: Array<Blog_Flags>;
  /** fetch data from the table: "blogs" */
  blogs: Array<Blogs>;
  /** fetch aggregated fields from the table: "blogs" */
  blogs_aggregate: Blogs_Aggregate;
  /** fetch data from the table: "blogs" using primary key columns */
  blogs_by_pk?: Maybe<Blogs>;
  /** fetch data from the table in a streaming manner : "blogs" */
  blogs_stream: Array<Blogs>;
  /** fetch data from the table: "books" */
  books: Array<Books>;
  /** fetch aggregated fields from the table: "books" */
  books_aggregate: Books_Aggregate;
  /** fetch data from the table: "books" using primary key columns */
  books_by_pk?: Maybe<Books>;
  /** fetch data from the table in a streaming manner : "books" */
  books_stream: Array<Books>;
  /** An array relationship */
  channel_members: Array<Channel_Members>;
  /** An aggregate relationship */
  channel_members_aggregate: Channel_Members_Aggregate;
  /** fetch data from the table: "channel_members" using primary key columns */
  channel_members_by_pk?: Maybe<Channel_Members>;
  /** fetch data from the table in a streaming manner : "channel_members" */
  channel_members_stream: Array<Channel_Members>;
  /** fetch data from the table: "channels" */
  channels: Array<Channels>;
  /** fetch aggregated fields from the table: "channels" */
  channels_aggregate: Channels_Aggregate;
  /** fetch data from the table: "channels" using primary key columns */
  channels_by_pk?: Maybe<Channels>;
  /** fetch data from the table in a streaming manner : "channels" */
  channels_stream: Array<Channels>;
  /** fetch data from the table: "clients" */
  clients: Array<Clients>;
  /** fetch aggregated fields from the table: "clients" */
  clients_aggregate: Clients_Aggregate;
  /** fetch data from the table: "clients" using primary key columns */
  clients_by_pk?: Maybe<Clients>;
  /** fetch data from the table in a streaming manner : "clients" */
  clients_stream: Array<Clients>;
  /** An array relationship */
  comment_mentions: Array<Comment_Mentions>;
  /** An aggregate relationship */
  comment_mentions_aggregate: Comment_Mentions_Aggregate;
  /** fetch data from the table: "comment_mentions" using primary key columns */
  comment_mentions_by_pk?: Maybe<Comment_Mentions>;
  /** fetch data from the table in a streaming manner : "comment_mentions" */
  comment_mentions_stream: Array<Comment_Mentions>;
  /** An array relationship */
  comments: Array<Comments>;
  /** An aggregate relationship */
  comments_aggregate: Comments_Aggregate;
  /** fetch data from the table: "comments" using primary key columns */
  comments_by_pk?: Maybe<Comments>;
  /** fetch data from the table in a streaming manner : "comments" */
  comments_stream: Array<Comments>;
  /** fetch data from the table: "daily_comments_count" */
  daily_comments_count: Array<Daily_Comments_Count>;
  /** fetch aggregated fields from the table: "daily_comments_count" */
  daily_comments_count_aggregate: Daily_Comments_Count_Aggregate;
  /** fetch data from the table in a streaming manner : "daily_comments_count" */
  daily_comments_count_stream: Array<Daily_Comments_Count>;
  /** fetch data from the table: "daily_likes_count" */
  daily_likes_count: Array<Daily_Likes_Count>;
  /** fetch aggregated fields from the table: "daily_likes_count" */
  daily_likes_count_aggregate: Daily_Likes_Count_Aggregate;
  /** fetch data from the table in a streaming manner : "daily_likes_count" */
  daily_likes_count_stream: Array<Daily_Likes_Count>;
  /** fetch data from the table: "daily_user_count" */
  daily_user_count: Array<Daily_User_Count>;
  /** fetch aggregated fields from the table: "daily_user_count" */
  daily_user_count_aggregate: Daily_User_Count_Aggregate;
  /** fetch data from the table in a streaming manner : "daily_user_count" */
  daily_user_count_stream: Array<Daily_User_Count>;
  /** fetch data from the table: "events" */
  events: Array<Events>;
  /** fetch aggregated fields from the table: "events" */
  events_aggregate: Events_Aggregate;
  /** fetch data from the table: "events" using primary key columns */
  events_by_pk?: Maybe<Events>;
  /** fetch data from the table in a streaming manner : "events" */
  events_stream: Array<Events>;
  /** An array relationship */
  featured_games: Array<Featured_Games>;
  /** An aggregate relationship */
  featured_games_aggregate: Featured_Games_Aggregate;
  /** fetch data from the table: "featured_games" using primary key columns */
  featured_games_by_pk?: Maybe<Featured_Games>;
  /** fetch data from the table in a streaming manner : "featured_games" */
  featured_games_stream: Array<Featured_Games>;
  /** fetch data from the table: "file_storage_providers" */
  file_storage_providers: Array<File_Storage_Providers>;
  /** fetch aggregated fields from the table: "file_storage_providers" */
  file_storage_providers_aggregate: File_Storage_Providers_Aggregate;
  /** fetch data from the table: "file_storage_providers" using primary key columns */
  file_storage_providers_by_pk?: Maybe<File_Storage_Providers>;
  /** fetch data from the table in a streaming manner : "file_storage_providers" */
  file_storage_providers_stream: Array<File_Storage_Providers>;
  /** An array relationship */
  files: Array<Files>;
  /** An aggregate relationship */
  files_aggregate: Files_Aggregate;
  /** fetch data from the table: "files" using primary key columns */
  files_by_pk?: Maybe<Files>;
  /** fetch data from the table in a streaming manner : "files" */
  files_stream: Array<Files>;
  /** fetch data from the table: "flags" */
  flags: Array<Flags>;
  /** fetch aggregated fields from the table: "flags" */
  flags_aggregate: Flags_Aggregate;
  /** fetch data from the table: "flags" using primary key columns */
  flags_by_pk?: Maybe<Flags>;
  /** fetch data from the table in a streaming manner : "flags" */
  flags_stream: Array<Flags>;
  /** An array relationship */
  game_comments: Array<Game_Comments>;
  /** An aggregate relationship */
  game_comments_aggregate: Game_Comments_Aggregate;
  /** fetch data from the table: "game_comments" using primary key columns */
  game_comments_by_pk?: Maybe<Game_Comments>;
  /** fetch data from the table in a streaming manner : "game_comments" */
  game_comments_stream: Array<Game_Comments>;
  /** An array relationship */
  game_files: Array<Game_Files>;
  /** An aggregate relationship */
  game_files_aggregate: Game_Files_Aggregate;
  /** fetch data from the table: "game_files" using primary key columns */
  game_files_by_pk?: Maybe<Game_Files>;
  /** fetch data from the table in a streaming manner : "game_files" */
  game_files_stream: Array<Game_Files>;
  /** An array relationship */
  game_flags: Array<Game_Flags>;
  /** An aggregate relationship */
  game_flags_aggregate: Game_Flags_Aggregate;
  /** fetch data from the table: "game_flags" using primary key columns */
  game_flags_by_pk?: Maybe<Game_Flags>;
  /** fetch data from the table in a streaming manner : "game_flags" */
  game_flags_stream: Array<Game_Flags>;
  /** An array relationship */
  game_genres: Array<Game_Genres>;
  /** An aggregate relationship */
  game_genres_aggregate: Game_Genres_Aggregate;
  /** fetch data from the table: "game_genres" using primary key columns */
  game_genres_by_pk?: Maybe<Game_Genres>;
  /** fetch data from the table in a streaming manner : "game_genres" */
  game_genres_stream: Array<Game_Genres>;
  /** An array relationship */
  game_ratings: Array<Game_Ratings>;
  /** An aggregate relationship */
  game_ratings_aggregate: Game_Ratings_Aggregate;
  /** fetch data from the table: "game_ratings" using primary key columns */
  game_ratings_by_pk?: Maybe<Game_Ratings>;
  /** fetch data from the table in a streaming manner : "game_ratings" */
  game_ratings_stream: Array<Game_Ratings>;
  /** An array relationship */
  game_serving_points: Array<Game_Serving_Points>;
  /** An aggregate relationship */
  game_serving_points_aggregate: Game_Serving_Points_Aggregate;
  /** fetch data from the table: "game_serving_points" using primary key columns */
  game_serving_points_by_pk?: Maybe<Game_Serving_Points>;
  /** fetch data from the table in a streaming manner : "game_serving_points" */
  game_serving_points_stream: Array<Game_Serving_Points>;
  /** An array relationship */
  games: Array<Games>;
  /** An aggregate relationship */
  games_aggregate: Games_Aggregate;
  /** fetch data from the table: "games" using primary key columns */
  games_by_pk?: Maybe<Games>;
  /** fetch data from the table in a streaming manner : "games" */
  games_stream: Array<Games>;
  /** An array relationship */
  group_flags: Array<Group_Flags>;
  /** An aggregate relationship */
  group_flags_aggregate: Group_Flags_Aggregate;
  /** fetch data from the table: "group_flags" using primary key columns */
  group_flags_by_pk?: Maybe<Group_Flags>;
  /** fetch data from the table in a streaming manner : "group_flags" */
  group_flags_stream: Array<Group_Flags>;
  /** An array relationship */
  group_members: Array<Group_Members>;
  /** An aggregate relationship */
  group_members_aggregate: Group_Members_Aggregate;
  /** fetch data from the table: "group_members" using primary key columns */
  group_members_by_pk?: Maybe<Group_Members>;
  /** fetch data from the table in a streaming manner : "group_members" */
  group_members_stream: Array<Group_Members>;
  /** fetch data from the table: "group_permissions" */
  group_permissions: Array<Group_Permissions>;
  /** fetch aggregated fields from the table: "group_permissions" */
  group_permissions_aggregate: Group_Permissions_Aggregate;
  /** fetch data from the table: "group_permissions" using primary key columns */
  group_permissions_by_pk?: Maybe<Group_Permissions>;
  /** fetch data from the table in a streaming manner : "group_permissions" */
  group_permissions_stream: Array<Group_Permissions>;
  /** fetch data from the table: "group_roles" */
  group_roles: Array<Group_Roles>;
  /** fetch aggregated fields from the table: "group_roles" */
  group_roles_aggregate: Group_Roles_Aggregate;
  /** fetch data from the table: "group_roles" using primary key columns */
  group_roles_by_pk?: Maybe<Group_Roles>;
  /** fetch data from the table in a streaming manner : "group_roles" */
  group_roles_stream: Array<Group_Roles>;
  /** fetch data from the table: "groups" */
  groups: Array<Groups>;
  /** fetch aggregated fields from the table: "groups" */
  groups_aggregate: Groups_Aggregate;
  /** fetch data from the table: "groups" using primary key columns */
  groups_by_pk?: Maybe<Groups>;
  /** fetch data from the table in a streaming manner : "groups" */
  groups_stream: Array<Groups>;
  /** fetch data from the table: "journal_entries" */
  journal_entries: Array<Journal_Entries>;
  /** fetch aggregated fields from the table: "journal_entries" */
  journal_entries_aggregate: Journal_Entries_Aggregate;
  /** fetch data from the table: "journal_entries" using primary key columns */
  journal_entries_by_pk?: Maybe<Journal_Entries>;
  /** fetch data from the table in a streaming manner : "journal_entries" */
  journal_entries_stream: Array<Journal_Entries>;
  /** An array relationship */
  likes: Array<Likes>;
  /** An aggregate relationship */
  likes_aggregate: Likes_Aggregate;
  /** fetch data from the table: "likes" using primary key columns */
  likes_by_pk?: Maybe<Likes>;
  /** fetch data from the table in a streaming manner : "likes" */
  likes_stream: Array<Likes>;
  /** An array relationship */
  notification_recipients: Array<Notification_Recipients>;
  /** An aggregate relationship */
  notification_recipients_aggregate: Notification_Recipients_Aggregate;
  /** fetch data from the table: "notification_recipients" using primary key columns */
  notification_recipients_by_pk?: Maybe<Notification_Recipients>;
  /** fetch data from the table in a streaming manner : "notification_recipients" */
  notification_recipients_stream: Array<Notification_Recipients>;
  /** fetch data from the table: "notification_statuses" */
  notification_statuses: Array<Notification_Statuses>;
  /** fetch aggregated fields from the table: "notification_statuses" */
  notification_statuses_aggregate: Notification_Statuses_Aggregate;
  /** fetch data from the table: "notification_statuses" using primary key columns */
  notification_statuses_by_pk?: Maybe<Notification_Statuses>;
  /** fetch data from the table in a streaming manner : "notification_statuses" */
  notification_statuses_stream: Array<Notification_Statuses>;
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner : "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "oidc.oidc_payloads" */
  oidc_oidc_payloads: Array<Oidc_Oidc_Payloads>;
  /** fetch aggregated fields from the table: "oidc.oidc_payloads" */
  oidc_oidc_payloads_aggregate: Oidc_Oidc_Payloads_Aggregate;
  /** fetch data from the table: "oidc.oidc_payloads" using primary key columns */
  oidc_oidc_payloads_by_pk?: Maybe<Oidc_Oidc_Payloads>;
  /** fetch data from the table in a streaming manner : "oidc.oidc_payloads" */
  oidc_oidc_payloads_stream: Array<Oidc_Oidc_Payloads>;
  /** fetch data from the table: "pages" */
  pages: Array<Pages>;
  /** fetch aggregated fields from the table: "pages" */
  pages_aggregate: Pages_Aggregate;
  /** fetch data from the table: "pages" using primary key columns */
  pages_by_pk?: Maybe<Pages>;
  /** fetch data from the table in a streaming manner : "pages" */
  pages_stream: Array<Pages>;
  /** An array relationship */
  password_resets: Array<Password_Resets>;
  /** An aggregate relationship */
  password_resets_aggregate: Password_Resets_Aggregate;
  /** fetch data from the table: "password_resets" using primary key columns */
  password_resets_by_pk?: Maybe<Password_Resets>;
  /** fetch data from the table in a streaming manner : "password_resets" */
  password_resets_stream: Array<Password_Resets>;
  /** An array relationship */
  post_authors: Array<Post_Authors>;
  /** An aggregate relationship */
  post_authors_aggregate: Post_Authors_Aggregate;
  /** fetch data from the table: "post_authors" using primary key columns */
  post_authors_by_pk?: Maybe<Post_Authors>;
  /** fetch data from the table in a streaming manner : "post_authors" */
  post_authors_stream: Array<Post_Authors>;
  /** An array relationship */
  post_flags: Array<Post_Flags>;
  /** An aggregate relationship */
  post_flags_aggregate: Post_Flags_Aggregate;
  /** fetch data from the table: "post_flags" using primary key columns */
  post_flags_by_pk?: Maybe<Post_Flags>;
  /** fetch data from the table in a streaming manner : "post_flags" */
  post_flags_stream: Array<Post_Flags>;
  /** An array relationship */
  post_tags: Array<Post_Tags>;
  /** An aggregate relationship */
  post_tags_aggregate: Post_Tags_Aggregate;
  /** fetch data from the table: "post_tags" using primary key columns */
  post_tags_by_pk?: Maybe<Post_Tags>;
  /** fetch data from the table in a streaming manner : "post_tags" */
  post_tags_stream: Array<Post_Tags>;
  /** An array relationship */
  posts: Array<Posts>;
  /** An aggregate relationship */
  posts_aggregate: Posts_Aggregate;
  /** fetch data from the table: "posts" using primary key columns */
  posts_by_pk?: Maybe<Posts>;
  /** fetch data from the table in a streaming manner : "posts" */
  posts_stream: Array<Posts>;
  /** fetch data from the table: "profiles" */
  profiles: Array<Profiles>;
  /** fetch aggregated fields from the table: "profiles" */
  profiles_aggregate: Profiles_Aggregate;
  /** fetch data from the table: "profiles" using primary key columns */
  profiles_by_pk?: Maybe<Profiles>;
  /** fetch data from the table in a streaming manner : "profiles" */
  profiles_stream: Array<Profiles>;
  /** An array relationship */
  purchases: Array<Purchases>;
  /** An aggregate relationship */
  purchases_aggregate: Purchases_Aggregate;
  /** fetch data from the table: "purchases" using primary key columns */
  purchases_by_pk?: Maybe<Purchases>;
  /** fetch data from the table in a streaming manner : "purchases" */
  purchases_stream: Array<Purchases>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner : "roles" */
  roles_stream: Array<Roles>;
  /** fetch data from the table: "serving_points" */
  serving_points: Array<Serving_Points>;
  /** fetch aggregated fields from the table: "serving_points" */
  serving_points_aggregate: Serving_Points_Aggregate;
  /** fetch data from the table: "serving_points" using primary key columns */
  serving_points_by_pk?: Maybe<Serving_Points>;
  /** fetch data from the table in a streaming manner : "serving_points" */
  serving_points_stream: Array<Serving_Points>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner : "tags" */
  tags_stream: Array<Tags>;
  /** fetch data from the table: "oidc.oidc_clients" */
  third_party_apps: Array<Third_Party_Apps>;
  /** fetch aggregated fields from the table: "oidc.oidc_clients" */
  third_party_apps_aggregate: Third_Party_Apps_Aggregate;
  /** fetch data from the table: "oidc.oidc_clients" using primary key columns */
  third_party_apps_by_pk?: Maybe<Third_Party_Apps>;
  /** fetch data from the table in a streaming manner : "oidc.oidc_clients" */
  third_party_apps_stream: Array<Third_Party_Apps>;
  /** fetch data from the table: "transactions" */
  transactions: Array<Transactions>;
  /** fetch aggregated fields from the table: "transactions" */
  transactions_aggregate: Transactions_Aggregate;
  /** fetch data from the table: "transactions" using primary key columns */
  transactions_by_pk?: Maybe<Transactions>;
  /** fetch data from the table in a streaming manner : "transactions" */
  transactions_stream: Array<Transactions>;
  /** An array relationship */
  upload_tags: Array<Upload_Tags>;
  /** An aggregate relationship */
  upload_tags_aggregate: Upload_Tags_Aggregate;
  /** fetch data from the table: "upload_tags" using primary key columns */
  upload_tags_by_pk?: Maybe<Upload_Tags>;
  /** fetch data from the table in a streaming manner : "upload_tags" */
  upload_tags_stream: Array<Upload_Tags>;
  /** An array relationship */
  uploads: Array<Uploads>;
  /** An aggregate relationship */
  uploads_aggregate: Uploads_Aggregate;
  /** fetch data from the table: "uploads" using primary key columns */
  uploads_by_pk?: Maybe<Uploads>;
  /** fetch data from the table in a streaming manner : "uploads" */
  uploads_stream: Array<Uploads>;
  /** An array relationship */
  user_flags: Array<User_Flags>;
  /** An aggregate relationship */
  user_flags_aggregate: User_Flags_Aggregate;
  /** fetch data from the table: "user_flags" using primary key columns */
  user_flags_by_pk?: Maybe<User_Flags>;
  /** fetch data from the table in a streaming manner : "user_flags" */
  user_flags_stream: Array<User_Flags>;
  /** fetch data from the table: "user_group_roles" */
  user_group_roles: Array<User_Group_Roles>;
  /** fetch aggregated fields from the table: "user_group_roles" */
  user_group_roles_aggregate: User_Group_Roles_Aggregate;
  /** fetch data from the table: "user_group_roles" using primary key columns */
  user_group_roles_by_pk?: Maybe<User_Group_Roles>;
  /** fetch data from the table in a streaming manner : "user_group_roles" */
  user_group_roles_stream: Array<User_Group_Roles>;
  /** fetch data from the table: "user_roles" */
  user_roles: Array<User_Roles>;
  /** fetch aggregated fields from the table: "user_roles" */
  user_roles_aggregate: User_Roles_Aggregate;
  /** fetch data from the table: "user_roles" using primary key columns */
  user_roles_by_pk?: Maybe<User_Roles>;
  /** fetch data from the table in a streaming manner : "user_roles" */
  user_roles_stream: Array<User_Roles>;
  /** An array relationship */
  user_social_logins: Array<User_Social_Logins>;
  /** An aggregate relationship */
  user_social_logins_aggregate: User_Social_Logins_Aggregate;
  /** fetch data from the table: "user_social_logins" using primary key columns */
  user_social_logins_by_pk?: Maybe<User_Social_Logins>;
  /** fetch data from the table in a streaming manner : "user_social_logins" */
  user_social_logins_stream: Array<User_Social_Logins>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner : "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "verifications" */
  verifications: Array<Verifications>;
  /** fetch aggregated fields from the table: "verifications" */
  verifications_aggregate: Verifications_Aggregate;
  /** fetch data from the table: "verifications" using primary key columns */
  verifications_by_pk?: Maybe<Verifications>;
  /** fetch data from the table in a streaming manner : "verifications" */
  verifications_stream: Array<Verifications>;
  /** An array relationship */
  wallet_transactions: Array<Wallet_Transactions>;
  /** An aggregate relationship */
  wallet_transactions_aggregate: Wallet_Transactions_Aggregate;
  /** fetch data from the table: "wallet_transactions" using primary key columns */
  wallet_transactions_by_pk?: Maybe<Wallet_Transactions>;
  /** fetch data from the table in a streaming manner : "wallet_transactions" */
  wallet_transactions_stream: Array<Wallet_Transactions>;
  /** An array relationship */
  wallets: Array<Wallets>;
  /** An aggregate relationship */
  wallets_aggregate: Wallets_Aggregate;
  /** fetch data from the table: "wallets" using primary key columns */
  wallets_by_pk?: Maybe<Wallets>;
  /** fetch data from the table in a streaming manner : "wallets" */
  wallets_stream: Array<Wallets>;
};


export type Subscription_RootAudit_Log_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Log_Entries_Order_By>>;
  where?: InputMaybe<Audit_Log_Entries_Bool_Exp>;
};


export type Subscription_RootAudit_Log_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Audit_Log_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Audit_Log_Entries_Order_By>>;
  where?: InputMaybe<Audit_Log_Entries_Bool_Exp>;
};


export type Subscription_RootAudit_Log_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAudit_Log_Entries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Audit_Log_Entries_Stream_Cursor_Input>>;
  where?: InputMaybe<Audit_Log_Entries_Bool_Exp>;
};


export type Subscription_RootBlog_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Flags_Order_By>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


export type Subscription_RootBlog_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blog_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blog_Flags_Order_By>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


export type Subscription_RootBlog_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBlog_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Blog_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Blog_Flags_Bool_Exp>;
};


export type Subscription_RootBlogsArgs = {
  distinct_on?: InputMaybe<Array<Blogs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blogs_Order_By>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Subscription_RootBlogs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Blogs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Blogs_Order_By>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Subscription_RootBlogs_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBlogs_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Blogs_Stream_Cursor_Input>>;
  where?: InputMaybe<Blogs_Bool_Exp>;
};


export type Subscription_RootBooksArgs = {
  distinct_on?: InputMaybe<Array<Books_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Books_Order_By>>;
  where?: InputMaybe<Books_Bool_Exp>;
};


export type Subscription_RootBooks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Books_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Books_Order_By>>;
  where?: InputMaybe<Books_Bool_Exp>;
};


export type Subscription_RootBooks_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBooks_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Books_Stream_Cursor_Input>>;
  where?: InputMaybe<Books_Bool_Exp>;
};


export type Subscription_RootChannel_MembersArgs = {
  distinct_on?: InputMaybe<Array<Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Members_Order_By>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


export type Subscription_RootChannel_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Members_Order_By>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


export type Subscription_RootChannel_Members_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootChannel_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Channel_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


export type Subscription_RootChannelsArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channels_Order_By>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootChannels_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootChannels_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Channels_Stream_Cursor_Input>>;
  where?: InputMaybe<Channels_Bool_Exp>;
};


export type Subscription_RootClientsArgs = {
  distinct_on?: InputMaybe<Array<Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clients_Order_By>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Subscription_RootClients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Clients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Clients_Order_By>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Subscription_RootClients_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootClients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Clients_Stream_Cursor_Input>>;
  where?: InputMaybe<Clients_Bool_Exp>;
};


export type Subscription_RootComment_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Mentions_Order_By>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


export type Subscription_RootComment_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Mentions_Order_By>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


export type Subscription_RootComment_Mentions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootComment_Mentions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Comment_Mentions_Stream_Cursor_Input>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


export type Subscription_RootCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootComments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootComments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


export type Subscription_RootDaily_Comments_CountArgs = {
  distinct_on?: InputMaybe<Array<Daily_Comments_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Comments_Count_Order_By>>;
  where?: InputMaybe<Daily_Comments_Count_Bool_Exp>;
};


export type Subscription_RootDaily_Comments_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Comments_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Comments_Count_Order_By>>;
  where?: InputMaybe<Daily_Comments_Count_Bool_Exp>;
};


export type Subscription_RootDaily_Comments_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Daily_Comments_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Comments_Count_Bool_Exp>;
};


export type Subscription_RootDaily_Likes_CountArgs = {
  distinct_on?: InputMaybe<Array<Daily_Likes_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Likes_Count_Order_By>>;
  where?: InputMaybe<Daily_Likes_Count_Bool_Exp>;
};


export type Subscription_RootDaily_Likes_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Likes_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Likes_Count_Order_By>>;
  where?: InputMaybe<Daily_Likes_Count_Bool_Exp>;
};


export type Subscription_RootDaily_Likes_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Daily_Likes_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_Likes_Count_Bool_Exp>;
};


export type Subscription_RootDaily_User_CountArgs = {
  distinct_on?: InputMaybe<Array<Daily_User_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_User_Count_Order_By>>;
  where?: InputMaybe<Daily_User_Count_Bool_Exp>;
};


export type Subscription_RootDaily_User_Count_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_User_Count_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_User_Count_Order_By>>;
  where?: InputMaybe<Daily_User_Count_Bool_Exp>;
};


export type Subscription_RootDaily_User_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Daily_User_Count_Stream_Cursor_Input>>;
  where?: InputMaybe<Daily_User_Count_Bool_Exp>;
};


export type Subscription_RootEventsArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Events_Order_By>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootEvents_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootEvents_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Events_Bool_Exp>;
};


export type Subscription_RootFeatured_GamesArgs = {
  distinct_on?: InputMaybe<Array<Featured_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Featured_Games_Order_By>>;
  where?: InputMaybe<Featured_Games_Bool_Exp>;
};


export type Subscription_RootFeatured_Games_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Featured_Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Featured_Games_Order_By>>;
  where?: InputMaybe<Featured_Games_Bool_Exp>;
};


export type Subscription_RootFeatured_Games_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFeatured_Games_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Featured_Games_Stream_Cursor_Input>>;
  where?: InputMaybe<Featured_Games_Bool_Exp>;
};


export type Subscription_RootFile_Storage_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<File_Storage_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Storage_Providers_Order_By>>;
  where?: InputMaybe<File_Storage_Providers_Bool_Exp>;
};


export type Subscription_RootFile_Storage_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<File_Storage_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<File_Storage_Providers_Order_By>>;
  where?: InputMaybe<File_Storage_Providers_Bool_Exp>;
};


export type Subscription_RootFile_Storage_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFile_Storage_Providers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<File_Storage_Providers_Stream_Cursor_Input>>;
  where?: InputMaybe<File_Storage_Providers_Bool_Exp>;
};


export type Subscription_RootFilesArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Files_Order_By>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Files_Bool_Exp>;
};


export type Subscription_RootFlagsArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Subscription_RootFlags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Flags_Order_By>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Subscription_RootFlags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootFlags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Flags_Bool_Exp>;
};


export type Subscription_RootGame_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Game_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Comments_Order_By>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


export type Subscription_RootGame_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Comments_Order_By>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


export type Subscription_RootGame_Comments_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGame_Comments_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Game_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Game_Comments_Bool_Exp>;
};


export type Subscription_RootGame_FilesArgs = {
  distinct_on?: InputMaybe<Array<Game_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Files_Order_By>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


export type Subscription_RootGame_Files_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Files_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Files_Order_By>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


export type Subscription_RootGame_Files_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGame_Files_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Game_Files_Stream_Cursor_Input>>;
  where?: InputMaybe<Game_Files_Bool_Exp>;
};


export type Subscription_RootGame_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Game_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Flags_Order_By>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


export type Subscription_RootGame_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Flags_Order_By>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


export type Subscription_RootGame_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGame_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Game_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Game_Flags_Bool_Exp>;
};


export type Subscription_RootGame_GenresArgs = {
  distinct_on?: InputMaybe<Array<Game_Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Genres_Order_By>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


export type Subscription_RootGame_Genres_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Genres_Order_By>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


export type Subscription_RootGame_Genres_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGame_Genres_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Game_Genres_Stream_Cursor_Input>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


export type Subscription_RootGame_RatingsArgs = {
  distinct_on?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Ratings_Order_By>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};


export type Subscription_RootGame_Ratings_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Ratings_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Ratings_Order_By>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};


export type Subscription_RootGame_Ratings_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGame_Ratings_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Game_Ratings_Stream_Cursor_Input>>;
  where?: InputMaybe<Game_Ratings_Bool_Exp>;
};


export type Subscription_RootGame_Serving_PointsArgs = {
  distinct_on?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Serving_Points_Order_By>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


export type Subscription_RootGame_Serving_Points_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Serving_Points_Order_By>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


export type Subscription_RootGame_Serving_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGame_Serving_Points_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Game_Serving_Points_Stream_Cursor_Input>>;
  where?: InputMaybe<Game_Serving_Points_Bool_Exp>;
};


export type Subscription_RootGamesArgs = {
  distinct_on?: InputMaybe<Array<Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Games_Order_By>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


export type Subscription_RootGames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Games_Order_By>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


export type Subscription_RootGames_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGames_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Games_Stream_Cursor_Input>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


export type Subscription_RootGroup_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Flags_Order_By>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


export type Subscription_RootGroup_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Flags_Order_By>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


export type Subscription_RootGroup_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroup_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Group_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Group_Flags_Bool_Exp>;
};


export type Subscription_RootGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootGroup_Members_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroup_Members_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Group_Members_Stream_Cursor_Input>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


export type Subscription_RootGroup_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Permissions_Order_By>>;
  where?: InputMaybe<Group_Permissions_Bool_Exp>;
};


export type Subscription_RootGroup_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Permissions_Order_By>>;
  where?: InputMaybe<Group_Permissions_Bool_Exp>;
};


export type Subscription_RootGroup_Permissions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroup_Permissions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Group_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Group_Permissions_Bool_Exp>;
};


export type Subscription_RootGroup_RolesArgs = {
  distinct_on?: InputMaybe<Array<Group_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Roles_Order_By>>;
  where?: InputMaybe<Group_Roles_Bool_Exp>;
};


export type Subscription_RootGroup_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Roles_Order_By>>;
  where?: InputMaybe<Group_Roles_Bool_Exp>;
};


export type Subscription_RootGroup_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroup_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Group_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Group_Roles_Bool_Exp>;
};


export type Subscription_RootGroupsArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Groups_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Groups_Order_By>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootGroups_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootGroups_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Groups_Stream_Cursor_Input>>;
  where?: InputMaybe<Groups_Bool_Exp>;
};


export type Subscription_RootJournal_EntriesArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Subscription_RootJournal_Entries_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Journal_Entries_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Journal_Entries_Order_By>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Subscription_RootJournal_Entries_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootJournal_Entries_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Journal_Entries_Stream_Cursor_Input>>;
  where?: InputMaybe<Journal_Entries_Bool_Exp>;
};


export type Subscription_RootLikesArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Subscription_RootLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Subscription_RootLikes_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootLikes_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Likes_Stream_Cursor_Input>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


export type Subscription_RootNotification_RecipientsArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Recipients_Order_By>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};


export type Subscription_RootNotification_Recipients_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Recipients_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Recipients_Order_By>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};


export type Subscription_RootNotification_Recipients_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotification_Recipients_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notification_Recipients_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Recipients_Bool_Exp>;
};


export type Subscription_RootNotification_StatusesArgs = {
  distinct_on?: InputMaybe<Array<Notification_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Statuses_Order_By>>;
  where?: InputMaybe<Notification_Statuses_Bool_Exp>;
};


export type Subscription_RootNotification_Statuses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notification_Statuses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notification_Statuses_Order_By>>;
  where?: InputMaybe<Notification_Statuses_Bool_Exp>;
};


export type Subscription_RootNotification_Statuses_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotification_Statuses_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notification_Statuses_Stream_Cursor_Input>>;
  where?: InputMaybe<Notification_Statuses_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootOidc_Oidc_PayloadsArgs = {
  distinct_on?: InputMaybe<Array<Oidc_Oidc_Payloads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Oidc_Oidc_Payloads_Order_By>>;
  where?: InputMaybe<Oidc_Oidc_Payloads_Bool_Exp>;
};


export type Subscription_RootOidc_Oidc_Payloads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Oidc_Oidc_Payloads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Oidc_Oidc_Payloads_Order_By>>;
  where?: InputMaybe<Oidc_Oidc_Payloads_Bool_Exp>;
};


export type Subscription_RootOidc_Oidc_Payloads_By_PkArgs = {
  id: Scalars['String'];
  oidc_type: Scalars['Int'];
};


export type Subscription_RootOidc_Oidc_Payloads_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Oidc_Oidc_Payloads_Stream_Cursor_Input>>;
  where?: InputMaybe<Oidc_Oidc_Payloads_Bool_Exp>;
};


export type Subscription_RootPagesArgs = {
  distinct_on?: InputMaybe<Array<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Subscription_RootPages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Pages_Order_By>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Subscription_RootPages_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPages_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Pages_Stream_Cursor_Input>>;
  where?: InputMaybe<Pages_Bool_Exp>;
};


export type Subscription_RootPassword_ResetsArgs = {
  distinct_on?: InputMaybe<Array<Password_Resets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Password_Resets_Order_By>>;
  where?: InputMaybe<Password_Resets_Bool_Exp>;
};


export type Subscription_RootPassword_Resets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Resets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Password_Resets_Order_By>>;
  where?: InputMaybe<Password_Resets_Bool_Exp>;
};


export type Subscription_RootPassword_Resets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPassword_Resets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Password_Resets_Stream_Cursor_Input>>;
  where?: InputMaybe<Password_Resets_Bool_Exp>;
};


export type Subscription_RootPost_AuthorsArgs = {
  distinct_on?: InputMaybe<Array<Post_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Authors_Order_By>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


export type Subscription_RootPost_Authors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Authors_Order_By>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


export type Subscription_RootPost_Authors_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPost_Authors_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Authors_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


export type Subscription_RootPost_FlagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Flags_Order_By>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


export type Subscription_RootPost_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Flags_Order_By>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


export type Subscription_RootPost_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPost_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Flags_Bool_Exp>;
};


export type Subscription_RootPost_TagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Tags_Order_By>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};


export type Subscription_RootPost_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Tags_Order_By>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};


export type Subscription_RootPost_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPost_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Post_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};


export type Subscription_RootPostsArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootPosts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Posts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Posts_Order_By>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootPosts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPosts_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Posts_Stream_Cursor_Input>>;
  where?: InputMaybe<Posts_Bool_Exp>;
};


export type Subscription_RootProfilesArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProfiles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Profiles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Profiles_Order_By>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootProfiles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootProfiles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Profiles_Stream_Cursor_Input>>;
  where?: InputMaybe<Profiles_Bool_Exp>;
};


export type Subscription_RootPurchasesArgs = {
  distinct_on?: InputMaybe<Array<Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchases_Order_By>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


export type Subscription_RootPurchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchases_Order_By>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


export type Subscription_RootPurchases_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootPurchases_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Purchases_Stream_Cursor_Input>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootServing_PointsArgs = {
  distinct_on?: InputMaybe<Array<Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serving_Points_Order_By>>;
  where?: InputMaybe<Serving_Points_Bool_Exp>;
};


export type Subscription_RootServing_Points_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Serving_Points_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Serving_Points_Order_By>>;
  where?: InputMaybe<Serving_Points_Bool_Exp>;
};


export type Subscription_RootServing_Points_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootServing_Points_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Serving_Points_Stream_Cursor_Input>>;
  where?: InputMaybe<Serving_Points_Bool_Exp>;
};


export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootThird_Party_AppsArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Apps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Third_Party_Apps_Order_By>>;
  where?: InputMaybe<Third_Party_Apps_Bool_Exp>;
};


export type Subscription_RootThird_Party_Apps_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Third_Party_Apps_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Third_Party_Apps_Order_By>>;
  where?: InputMaybe<Third_Party_Apps_Bool_Exp>;
};


export type Subscription_RootThird_Party_Apps_By_PkArgs = {
  id: Scalars['String'];
};


export type Subscription_RootThird_Party_Apps_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Third_Party_Apps_Stream_Cursor_Input>>;
  where?: InputMaybe<Third_Party_Apps_Bool_Exp>;
};


export type Subscription_RootTransactionsArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Transactions_Order_By>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootTransactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootTransactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};


export type Subscription_RootUpload_TagsArgs = {
  distinct_on?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upload_Tags_Order_By>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};


export type Subscription_RootUpload_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upload_Tags_Order_By>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};


export type Subscription_RootUpload_Tags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUpload_Tags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Upload_Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};


export type Subscription_RootUploadsArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};


export type Subscription_RootUploads_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Uploads_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Uploads_Order_By>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};


export type Subscription_RootUploads_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUploads_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Uploads_Stream_Cursor_Input>>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};


export type Subscription_RootUser_FlagsArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};


export type Subscription_RootUser_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};


export type Subscription_RootUser_Flags_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Flags_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Flags_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};


export type Subscription_RootUser_Group_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Group_Roles_Order_By>>;
  where?: InputMaybe<User_Group_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Group_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Group_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Group_Roles_Order_By>>;
  where?: InputMaybe<User_Group_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Group_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Group_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Group_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Group_Roles_Bool_Exp>;
};


export type Subscription_RootUser_RolesArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Roles_Order_By>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Roles_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};


export type Subscription_RootUser_Social_LoginsArgs = {
  distinct_on?: InputMaybe<Array<User_Social_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Social_Logins_Order_By>>;
  where?: InputMaybe<User_Social_Logins_Bool_Exp>;
};


export type Subscription_RootUser_Social_Logins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Social_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Social_Logins_Order_By>>;
  where?: InputMaybe<User_Social_Logins_Bool_Exp>;
};


export type Subscription_RootUser_Social_Logins_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUser_Social_Logins_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<User_Social_Logins_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Social_Logins_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootVerificationsArgs = {
  distinct_on?: InputMaybe<Array<Verifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verifications_Order_By>>;
  where?: InputMaybe<Verifications_Bool_Exp>;
};


export type Subscription_RootVerifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Verifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Verifications_Order_By>>;
  where?: InputMaybe<Verifications_Bool_Exp>;
};


export type Subscription_RootVerifications_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootVerifications_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Verifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Verifications_Bool_Exp>;
};


export type Subscription_RootWallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootWallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootWallet_Transactions_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWallet_Transactions_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wallet_Transactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


export type Subscription_RootWalletsArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Subscription_RootWallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


export type Subscription_RootWallets_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootWallets_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<InputMaybe<Wallets_Stream_Cursor_Input>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'tags';
  created_at: Scalars['timestamptz'];
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  game_genres: Array<Game_Genres>;
  /** An aggregate relationship */
  game_genres_aggregate: Game_Genres_Aggregate;
  id: Scalars['uuid'];
  name: Scalars['String'];
  /** An array relationship */
  post_tags: Array<Post_Tags>;
  /** An aggregate relationship */
  post_tags_aggregate: Post_Tags_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  upload_tags: Array<Upload_Tags>;
  /** An aggregate relationship */
  upload_tags_aggregate: Upload_Tags_Aggregate;
  value?: Maybe<Scalars['String']>;
};


/** columns and relationships of "tags" */
export type TagsGame_GenresArgs = {
  distinct_on?: InputMaybe<Array<Game_Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Genres_Order_By>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsGame_Genres_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Game_Genres_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Game_Genres_Order_By>>;
  where?: InputMaybe<Game_Genres_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsPost_TagsArgs = {
  distinct_on?: InputMaybe<Array<Post_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Tags_Order_By>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsPost_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Tags_Order_By>>;
  where?: InputMaybe<Post_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsUpload_TagsArgs = {
  distinct_on?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upload_Tags_Order_By>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsUpload_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upload_Tags_Order_By>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
};


/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  game_genres?: InputMaybe<Game_Genres_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  post_tags?: InputMaybe<Post_Tags_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_tags?: InputMaybe<Upload_Tags_Bool_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "name" */
  TagsNameUnique = 'tags_name_unique',
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey',
  /** unique or primary key constraint on columns "value" */
  TagsValueUnique = 'tags_value_unique'
}

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  game_genres?: InputMaybe<Game_Genres_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  post_tags?: InputMaybe<Post_Tags_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upload_tags?: InputMaybe<Upload_Tags_Arr_Rel_Insert_Input>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  game_genres_aggregate?: InputMaybe<Game_Genres_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  post_tags_aggregate?: InputMaybe<Post_Tags_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_tags_aggregate?: InputMaybe<Upload_Tags_Aggregate_Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

export type Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};

/** columns and relationships of "oidc.oidc_clients" */
export type Third_Party_Apps = {
  __typename?: 'third_party_apps';
  application_type: Scalars['String'];
  client_name: Scalars['String'];
  client_salt: Scalars['String'];
  client_secret: Scalars['String'];
  client_uri: Scalars['String'];
  contacts: Scalars['_text'];
  created_at: Scalars['timestamptz'];
  grant_types: Scalars['_text'];
  id: Scalars['String'];
  is_active: Scalars['Boolean'];
  metadata: Scalars['jsonb'];
  redirect_uris: Scalars['_text'];
  scope: Scalars['_text'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "oidc.oidc_clients" */
export type Third_Party_AppsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "oidc.oidc_clients" */
export type Third_Party_Apps_Aggregate = {
  __typename?: 'third_party_apps_aggregate';
  aggregate?: Maybe<Third_Party_Apps_Aggregate_Fields>;
  nodes: Array<Third_Party_Apps>;
};

/** aggregate fields of "oidc.oidc_clients" */
export type Third_Party_Apps_Aggregate_Fields = {
  __typename?: 'third_party_apps_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Third_Party_Apps_Max_Fields>;
  min?: Maybe<Third_Party_Apps_Min_Fields>;
};


/** aggregate fields of "oidc.oidc_clients" */
export type Third_Party_Apps_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Third_Party_Apps_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Third_Party_Apps_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "oidc.oidc_clients". All fields are combined with a logical 'AND'. */
export type Third_Party_Apps_Bool_Exp = {
  _and?: InputMaybe<Array<Third_Party_Apps_Bool_Exp>>;
  _not?: InputMaybe<Third_Party_Apps_Bool_Exp>;
  _or?: InputMaybe<Array<Third_Party_Apps_Bool_Exp>>;
  application_type?: InputMaybe<String_Comparison_Exp>;
  client_name?: InputMaybe<String_Comparison_Exp>;
  client_salt?: InputMaybe<String_Comparison_Exp>;
  client_secret?: InputMaybe<String_Comparison_Exp>;
  client_uri?: InputMaybe<String_Comparison_Exp>;
  contacts?: InputMaybe<_Text_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  grant_types?: InputMaybe<_Text_Comparison_Exp>;
  id?: InputMaybe<String_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  redirect_uris?: InputMaybe<_Text_Comparison_Exp>;
  scope?: InputMaybe<_Text_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "oidc.oidc_clients" */
export enum Third_Party_Apps_Constraint {
  /** unique or primary key constraint on columns "id" */
  OidcClientsPkey = 'oidc_clients_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Third_Party_Apps_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Third_Party_Apps_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Third_Party_Apps_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "oidc.oidc_clients" */
export type Third_Party_Apps_Insert_Input = {
  application_type?: InputMaybe<Scalars['String']>;
  client_name?: InputMaybe<Scalars['String']>;
  client_salt?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  client_uri?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Scalars['_text']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  grant_types?: InputMaybe<Scalars['_text']>;
  id?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  redirect_uris?: InputMaybe<Scalars['_text']>;
  scope?: InputMaybe<Scalars['_text']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Third_Party_Apps_Max_Fields = {
  __typename?: 'third_party_apps_max_fields';
  application_type?: Maybe<Scalars['String']>;
  client_name?: Maybe<Scalars['String']>;
  client_salt?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  client_uri?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Third_Party_Apps_Min_Fields = {
  __typename?: 'third_party_apps_min_fields';
  application_type?: Maybe<Scalars['String']>;
  client_name?: Maybe<Scalars['String']>;
  client_salt?: Maybe<Scalars['String']>;
  client_secret?: Maybe<Scalars['String']>;
  client_uri?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "oidc.oidc_clients" */
export type Third_Party_Apps_Mutation_Response = {
  __typename?: 'third_party_apps_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Third_Party_Apps>;
};

/** on_conflict condition type for table "oidc.oidc_clients" */
export type Third_Party_Apps_On_Conflict = {
  constraint: Third_Party_Apps_Constraint;
  update_columns?: Array<Third_Party_Apps_Update_Column>;
  where?: InputMaybe<Third_Party_Apps_Bool_Exp>;
};

/** Ordering options when selecting data from "oidc.oidc_clients". */
export type Third_Party_Apps_Order_By = {
  application_type?: InputMaybe<Order_By>;
  client_name?: InputMaybe<Order_By>;
  client_salt?: InputMaybe<Order_By>;
  client_secret?: InputMaybe<Order_By>;
  client_uri?: InputMaybe<Order_By>;
  contacts?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  grant_types?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  redirect_uris?: InputMaybe<Order_By>;
  scope?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: oidc.oidc_clients */
export type Third_Party_Apps_Pk_Columns_Input = {
  id: Scalars['String'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Third_Party_Apps_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "oidc.oidc_clients" */
export enum Third_Party_Apps_Select_Column {
  /** column name */
  ApplicationType = 'application_type',
  /** column name */
  ClientName = 'client_name',
  /** column name */
  ClientSalt = 'client_salt',
  /** column name */
  ClientSecret = 'client_secret',
  /** column name */
  ClientUri = 'client_uri',
  /** column name */
  Contacts = 'contacts',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GrantTypes = 'grant_types',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RedirectUris = 'redirect_uris',
  /** column name */
  Scope = 'scope',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "oidc.oidc_clients" */
export type Third_Party_Apps_Set_Input = {
  application_type?: InputMaybe<Scalars['String']>;
  client_name?: InputMaybe<Scalars['String']>;
  client_salt?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  client_uri?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Scalars['_text']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  grant_types?: InputMaybe<Scalars['_text']>;
  id?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  redirect_uris?: InputMaybe<Scalars['_text']>;
  scope?: InputMaybe<Scalars['_text']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "third_party_apps" */
export type Third_Party_Apps_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Third_Party_Apps_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Third_Party_Apps_Stream_Cursor_Value_Input = {
  application_type?: InputMaybe<Scalars['String']>;
  client_name?: InputMaybe<Scalars['String']>;
  client_salt?: InputMaybe<Scalars['String']>;
  client_secret?: InputMaybe<Scalars['String']>;
  client_uri?: InputMaybe<Scalars['String']>;
  contacts?: InputMaybe<Scalars['_text']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  grant_types?: InputMaybe<Scalars['_text']>;
  id?: InputMaybe<Scalars['String']>;
  is_active?: InputMaybe<Scalars['Boolean']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  redirect_uris?: InputMaybe<Scalars['_text']>;
  scope?: InputMaybe<Scalars['_text']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "oidc.oidc_clients" */
export enum Third_Party_Apps_Update_Column {
  /** column name */
  ApplicationType = 'application_type',
  /** column name */
  ClientName = 'client_name',
  /** column name */
  ClientSalt = 'client_salt',
  /** column name */
  ClientSecret = 'client_secret',
  /** column name */
  ClientUri = 'client_uri',
  /** column name */
  Contacts = 'contacts',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GrantTypes = 'grant_types',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  RedirectUris = 'redirect_uris',
  /** column name */
  Scope = 'scope',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Third_Party_Apps_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Third_Party_Apps_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Third_Party_Apps_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Third_Party_Apps_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Third_Party_Apps_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Third_Party_Apps_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Third_Party_Apps_Set_Input>;
  where: Third_Party_Apps_Bool_Exp;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "transactions" */
export type Transactions = {
  __typename?: 'transactions';
  account: Scalars['_text'];
  account_path: Scalars['String'];
  book_id: Scalars['uuid'];
  created_at: Scalars['timestamp'];
  credit: Scalars['float8'];
  debit: Scalars['float8'];
  id: Scalars['uuid'];
  journal_id: Scalars['uuid'];
  memo: Scalars['String'];
  metadata?: Maybe<Scalars['jsonb']>;
  original_journal_id?: Maybe<Scalars['uuid']>;
  transaction_date: Scalars['timestamp'];
  updated_at: Scalars['timestamp'];
  void_reason?: Maybe<Scalars['String']>;
  voided: Scalars['Boolean'];
};


/** columns and relationships of "transactions" */
export type TransactionsMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "transactions" */
export type Transactions_Aggregate = {
  __typename?: 'transactions_aggregate';
  aggregate?: Maybe<Transactions_Aggregate_Fields>;
  nodes: Array<Transactions>;
};

/** aggregate fields of "transactions" */
export type Transactions_Aggregate_Fields = {
  __typename?: 'transactions_aggregate_fields';
  avg?: Maybe<Transactions_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Transactions_Max_Fields>;
  min?: Maybe<Transactions_Min_Fields>;
  stddev?: Maybe<Transactions_Stddev_Fields>;
  stddev_pop?: Maybe<Transactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Transactions_Stddev_Samp_Fields>;
  sum?: Maybe<Transactions_Sum_Fields>;
  var_pop?: Maybe<Transactions_Var_Pop_Fields>;
  var_samp?: Maybe<Transactions_Var_Samp_Fields>;
  variance?: Maybe<Transactions_Variance_Fields>;
};


/** aggregate fields of "transactions" */
export type Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** aggregate avg on columns */
export type Transactions_Avg_Fields = {
  __typename?: 'transactions_avg_fields';
  credit?: Maybe<Scalars['Float']>;
  debit?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "transactions". All fields are combined with a logical 'AND'. */
export type Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Transactions_Bool_Exp>>;
  _not?: InputMaybe<Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Transactions_Bool_Exp>>;
  account?: InputMaybe<_Text_Comparison_Exp>;
  account_path?: InputMaybe<String_Comparison_Exp>;
  book_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  credit?: InputMaybe<Float8_Comparison_Exp>;
  debit?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  journal_id?: InputMaybe<Uuid_Comparison_Exp>;
  memo?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  original_journal_id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_date?: InputMaybe<Timestamp_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  void_reason?: InputMaybe<String_Comparison_Exp>;
  voided?: InputMaybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "transactions" */
export enum Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  TransactionsPkey = 'transactions_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Transactions_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Transactions_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Transactions_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "transactions" */
export type Transactions_Inc_Input = {
  credit?: InputMaybe<Scalars['float8']>;
  debit?: InputMaybe<Scalars['float8']>;
};

/** input type for inserting data into table "transactions" */
export type Transactions_Insert_Input = {
  account?: InputMaybe<Scalars['_text']>;
  account_path?: InputMaybe<Scalars['String']>;
  book_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  credit?: InputMaybe<Scalars['float8']>;
  debit?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  journal_id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  original_journal_id?: InputMaybe<Scalars['uuid']>;
  transaction_date?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate max on columns */
export type Transactions_Max_Fields = {
  __typename?: 'transactions_max_fields';
  account_path?: Maybe<Scalars['String']>;
  book_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  credit?: Maybe<Scalars['float8']>;
  debit?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  journal_id?: Maybe<Scalars['uuid']>;
  memo?: Maybe<Scalars['String']>;
  original_journal_id?: Maybe<Scalars['uuid']>;
  transaction_date?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  void_reason?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Transactions_Min_Fields = {
  __typename?: 'transactions_min_fields';
  account_path?: Maybe<Scalars['String']>;
  book_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamp']>;
  credit?: Maybe<Scalars['float8']>;
  debit?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  journal_id?: Maybe<Scalars['uuid']>;
  memo?: Maybe<Scalars['String']>;
  original_journal_id?: Maybe<Scalars['uuid']>;
  transaction_date?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  void_reason?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "transactions" */
export type Transactions_Mutation_Response = {
  __typename?: 'transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Transactions>;
};

/** on_conflict condition type for table "transactions" */
export type Transactions_On_Conflict = {
  constraint: Transactions_Constraint;
  update_columns?: Array<Transactions_Update_Column>;
  where?: InputMaybe<Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "transactions". */
export type Transactions_Order_By = {
  account?: InputMaybe<Order_By>;
  account_path?: InputMaybe<Order_By>;
  book_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  credit?: InputMaybe<Order_By>;
  debit?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  journal_id?: InputMaybe<Order_By>;
  memo?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  original_journal_id?: InputMaybe<Order_By>;
  transaction_date?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  void_reason?: InputMaybe<Order_By>;
  voided?: InputMaybe<Order_By>;
};

/** primary key columns input for table: transactions */
export type Transactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Transactions_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "transactions" */
export enum Transactions_Select_Column {
  /** column name */
  Account = 'account',
  /** column name */
  AccountPath = 'account_path',
  /** column name */
  BookId = 'book_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Credit = 'credit',
  /** column name */
  Debit = 'debit',
  /** column name */
  Id = 'id',
  /** column name */
  JournalId = 'journal_id',
  /** column name */
  Memo = 'memo',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OriginalJournalId = 'original_journal_id',
  /** column name */
  TransactionDate = 'transaction_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoidReason = 'void_reason',
  /** column name */
  Voided = 'voided'
}

/** input type for updating data in table "transactions" */
export type Transactions_Set_Input = {
  account?: InputMaybe<Scalars['_text']>;
  account_path?: InputMaybe<Scalars['String']>;
  book_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  credit?: InputMaybe<Scalars['float8']>;
  debit?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  journal_id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  original_journal_id?: InputMaybe<Scalars['uuid']>;
  transaction_date?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate stddev on columns */
export type Transactions_Stddev_Fields = {
  __typename?: 'transactions_stddev_fields';
  credit?: Maybe<Scalars['Float']>;
  debit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Transactions_Stddev_Pop_Fields = {
  __typename?: 'transactions_stddev_pop_fields';
  credit?: Maybe<Scalars['Float']>;
  debit?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Transactions_Stddev_Samp_Fields = {
  __typename?: 'transactions_stddev_samp_fields';
  credit?: Maybe<Scalars['Float']>;
  debit?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "transactions" */
export type Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Transactions_Stream_Cursor_Value_Input = {
  account?: InputMaybe<Scalars['_text']>;
  account_path?: InputMaybe<Scalars['String']>;
  book_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamp']>;
  credit?: InputMaybe<Scalars['float8']>;
  debit?: InputMaybe<Scalars['float8']>;
  id?: InputMaybe<Scalars['uuid']>;
  journal_id?: InputMaybe<Scalars['uuid']>;
  memo?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  original_journal_id?: InputMaybe<Scalars['uuid']>;
  transaction_date?: InputMaybe<Scalars['timestamp']>;
  updated_at?: InputMaybe<Scalars['timestamp']>;
  void_reason?: InputMaybe<Scalars['String']>;
  voided?: InputMaybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type Transactions_Sum_Fields = {
  __typename?: 'transactions_sum_fields';
  credit?: Maybe<Scalars['float8']>;
  debit?: Maybe<Scalars['float8']>;
};

/** update columns of table "transactions" */
export enum Transactions_Update_Column {
  /** column name */
  Account = 'account',
  /** column name */
  AccountPath = 'account_path',
  /** column name */
  BookId = 'book_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Credit = 'credit',
  /** column name */
  Debit = 'debit',
  /** column name */
  Id = 'id',
  /** column name */
  JournalId = 'journal_id',
  /** column name */
  Memo = 'memo',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  OriginalJournalId = 'original_journal_id',
  /** column name */
  TransactionDate = 'transaction_date',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VoidReason = 'void_reason',
  /** column name */
  Voided = 'voided'
}

export type Transactions_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Transactions_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Transactions_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Transactions_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Transactions_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Transactions_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Transactions_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Transactions_Set_Input>;
  where: Transactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Transactions_Var_Pop_Fields = {
  __typename?: 'transactions_var_pop_fields';
  credit?: Maybe<Scalars['Float']>;
  debit?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Transactions_Var_Samp_Fields = {
  __typename?: 'transactions_var_samp_fields';
  credit?: Maybe<Scalars['Float']>;
  debit?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Transactions_Variance_Fields = {
  __typename?: 'transactions_variance_fields';
  credit?: Maybe<Scalars['Float']>;
  debit?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "upload_tags" */
export type Upload_Tags = {
  __typename?: 'upload_tags';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  /** An object relationship */
  tag?: Maybe<Tags>;
  tag_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  upload?: Maybe<Uploads>;
  upload_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "upload_tags" */
export type Upload_Tags_Aggregate = {
  __typename?: 'upload_tags_aggregate';
  aggregate?: Maybe<Upload_Tags_Aggregate_Fields>;
  nodes: Array<Upload_Tags>;
};

/** aggregate fields of "upload_tags" */
export type Upload_Tags_Aggregate_Fields = {
  __typename?: 'upload_tags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Upload_Tags_Max_Fields>;
  min?: Maybe<Upload_Tags_Min_Fields>;
};


/** aggregate fields of "upload_tags" */
export type Upload_Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "upload_tags" */
export type Upload_Tags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Upload_Tags_Max_Order_By>;
  min?: InputMaybe<Upload_Tags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "upload_tags" */
export type Upload_Tags_Arr_Rel_Insert_Input = {
  data: Array<Upload_Tags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Upload_Tags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "upload_tags". All fields are combined with a logical 'AND'. */
export type Upload_Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Upload_Tags_Bool_Exp>>;
  _not?: InputMaybe<Upload_Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Upload_Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  tag?: InputMaybe<Tags_Bool_Exp>;
  tag_id?: InputMaybe<Uuid_Comparison_Exp>;
  upload?: InputMaybe<Uploads_Bool_Exp>;
  upload_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "upload_tags" */
export enum Upload_Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  UploadTagsPkey = 'upload_tags_pkey'
}

/** input type for inserting data into table "upload_tags" */
export type Upload_Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  upload?: InputMaybe<Uploads_Obj_Rel_Insert_Input>;
  upload_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Upload_Tags_Max_Fields = {
  __typename?: 'upload_tags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  upload_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "upload_tags" */
export type Upload_Tags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Upload_Tags_Min_Fields = {
  __typename?: 'upload_tags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  tag_id?: Maybe<Scalars['uuid']>;
  upload_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "upload_tags" */
export type Upload_Tags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "upload_tags" */
export type Upload_Tags_Mutation_Response = {
  __typename?: 'upload_tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Upload_Tags>;
};

/** on_conflict condition type for table "upload_tags" */
export type Upload_Tags_On_Conflict = {
  constraint: Upload_Tags_Constraint;
  update_columns?: Array<Upload_Tags_Update_Column>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "upload_tags". */
export type Upload_Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  tag?: InputMaybe<Tags_Order_By>;
  tag_id?: InputMaybe<Order_By>;
  upload?: InputMaybe<Uploads_Order_By>;
  upload_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: upload_tags */
export type Upload_Tags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "upload_tags" */
export enum Upload_Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UploadId = 'upload_id'
}

/** input type for updating data in table "upload_tags" */
export type Upload_Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  upload_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "upload_tags" */
export type Upload_Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Upload_Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Upload_Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  tag_id?: InputMaybe<Scalars['uuid']>;
  upload_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "upload_tags" */
export enum Upload_Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  UploadId = 'upload_id'
}

export type Upload_Tags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Upload_Tags_Set_Input>;
  where: Upload_Tags_Bool_Exp;
};

/** columns and relationships of "uploads" */
export type Uploads = {
  __typename?: 'uploads';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  file?: Maybe<Files>;
  file_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  game?: Maybe<Games>;
  game_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  serving_point?: Maybe<Serving_Points>;
  serving_point_id?: Maybe<Scalars['uuid']>;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  upload_tags: Array<Upload_Tags>;
  /** An aggregate relationship */
  upload_tags_aggregate: Upload_Tags_Aggregate;
};


/** columns and relationships of "uploads" */
export type UploadsUpload_TagsArgs = {
  distinct_on?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upload_Tags_Order_By>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};


/** columns and relationships of "uploads" */
export type UploadsUpload_Tags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Upload_Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Upload_Tags_Order_By>>;
  where?: InputMaybe<Upload_Tags_Bool_Exp>;
};

/** aggregated selection of "uploads" */
export type Uploads_Aggregate = {
  __typename?: 'uploads_aggregate';
  aggregate?: Maybe<Uploads_Aggregate_Fields>;
  nodes: Array<Uploads>;
};

/** aggregate fields of "uploads" */
export type Uploads_Aggregate_Fields = {
  __typename?: 'uploads_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Uploads_Max_Fields>;
  min?: Maybe<Uploads_Min_Fields>;
};


/** aggregate fields of "uploads" */
export type Uploads_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Uploads_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "uploads" */
export type Uploads_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Uploads_Max_Order_By>;
  min?: InputMaybe<Uploads_Min_Order_By>;
};

/** input type for inserting array relation for remote table "uploads" */
export type Uploads_Arr_Rel_Insert_Input = {
  data: Array<Uploads_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Uploads_On_Conflict>;
};

/** Boolean expression to filter rows from the table "uploads". All fields are combined with a logical 'AND'. */
export type Uploads_Bool_Exp = {
  _and?: InputMaybe<Array<Uploads_Bool_Exp>>;
  _not?: InputMaybe<Uploads_Bool_Exp>;
  _or?: InputMaybe<Array<Uploads_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  file?: InputMaybe<Files_Bool_Exp>;
  file_id?: InputMaybe<Uuid_Comparison_Exp>;
  game?: InputMaybe<Games_Bool_Exp>;
  game_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  serving_point?: InputMaybe<Serving_Points_Bool_Exp>;
  serving_point_id?: InputMaybe<Uuid_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  upload_tags?: InputMaybe<Upload_Tags_Bool_Exp>;
};

/** unique or primary key constraints on table "uploads" */
export enum Uploads_Constraint {
  /** unique or primary key constraint on columns "id" */
  UploadsPkey = 'uploads_pkey'
}

/** input type for inserting data into table "uploads" */
export type Uploads_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file?: InputMaybe<Files_Obj_Rel_Insert_Input>;
  file_id?: InputMaybe<Scalars['uuid']>;
  game?: InputMaybe<Games_Obj_Rel_Insert_Input>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serving_point?: InputMaybe<Serving_Points_Obj_Rel_Insert_Input>;
  serving_point_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  upload_tags?: InputMaybe<Upload_Tags_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Uploads_Max_Fields = {
  __typename?: 'uploads_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  serving_point_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "uploads" */
export type Uploads_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serving_point_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Uploads_Min_Fields = {
  __typename?: 'uploads_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  file_id?: Maybe<Scalars['uuid']>;
  game_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  serving_point_id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "uploads" */
export type Uploads_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file_id?: InputMaybe<Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serving_point_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "uploads" */
export type Uploads_Mutation_Response = {
  __typename?: 'uploads_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Uploads>;
};

/** input type for inserting object relation for remote table "uploads" */
export type Uploads_Obj_Rel_Insert_Input = {
  data: Uploads_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Uploads_On_Conflict>;
};

/** on_conflict condition type for table "uploads" */
export type Uploads_On_Conflict = {
  constraint: Uploads_Constraint;
  update_columns?: Array<Uploads_Update_Column>;
  where?: InputMaybe<Uploads_Bool_Exp>;
};

/** Ordering options when selecting data from "uploads". */
export type Uploads_Order_By = {
  created_at?: InputMaybe<Order_By>;
  file?: InputMaybe<Files_Order_By>;
  file_id?: InputMaybe<Order_By>;
  game?: InputMaybe<Games_Order_By>;
  game_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  serving_point?: InputMaybe<Serving_Points_Order_By>;
  serving_point_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  upload_tags_aggregate?: InputMaybe<Upload_Tags_Aggregate_Order_By>;
};

/** primary key columns input for table: uploads */
export type Uploads_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "uploads" */
export enum Uploads_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServingPointId = 'serving_point_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "uploads" */
export type Uploads_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serving_point_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "uploads" */
export type Uploads_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Uploads_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Uploads_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  file_id?: InputMaybe<Scalars['uuid']>;
  game_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  serving_point_id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "uploads" */
export enum Uploads_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FileId = 'file_id',
  /** column name */
  GameId = 'game_id',
  /** column name */
  Id = 'id',
  /** column name */
  ServingPointId = 'serving_point_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Uploads_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Uploads_Set_Input>;
  where: Uploads_Bool_Exp;
};

/** columns and relationships of "user_flags" */
export type User_Flags = {
  __typename?: 'user_flags';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  flag?: Maybe<Flags>;
  flag_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_flags" */
export type User_Flags_Aggregate = {
  __typename?: 'user_flags_aggregate';
  aggregate?: Maybe<User_Flags_Aggregate_Fields>;
  nodes: Array<User_Flags>;
};

/** aggregate fields of "user_flags" */
export type User_Flags_Aggregate_Fields = {
  __typename?: 'user_flags_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Flags_Max_Fields>;
  min?: Maybe<User_Flags_Min_Fields>;
};


/** aggregate fields of "user_flags" */
export type User_Flags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Flags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_flags" */
export type User_Flags_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Flags_Max_Order_By>;
  min?: InputMaybe<User_Flags_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_flags" */
export type User_Flags_Arr_Rel_Insert_Input = {
  data: Array<User_Flags_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Flags_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_flags". All fields are combined with a logical 'AND'. */
export type User_Flags_Bool_Exp = {
  _and?: InputMaybe<Array<User_Flags_Bool_Exp>>;
  _not?: InputMaybe<User_Flags_Bool_Exp>;
  _or?: InputMaybe<Array<User_Flags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  flag?: InputMaybe<Flags_Bool_Exp>;
  flag_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_flags" */
export enum User_Flags_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserFlagsPkey = 'user_flags_pkey',
  /** unique or primary key constraint on columns "user_id", "flag_id" */
  UserFlagsUserIdFlagIdUnique = 'user_flags_user_id_flag_id_unique'
}

/** input type for inserting data into table "user_flags" */
export type User_Flags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag?: InputMaybe<Flags_Obj_Rel_Insert_Input>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Flags_Max_Fields = {
  __typename?: 'user_flags_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_flags" */
export type User_Flags_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Flags_Min_Fields = {
  __typename?: 'user_flags_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  flag_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_flags" */
export type User_Flags_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_flags" */
export type User_Flags_Mutation_Response = {
  __typename?: 'user_flags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Flags>;
};

/** on_conflict condition type for table "user_flags" */
export type User_Flags_On_Conflict = {
  constraint: User_Flags_Constraint;
  update_columns?: Array<User_Flags_Update_Column>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};

/** Ordering options when selecting data from "user_flags". */
export type User_Flags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  flag?: InputMaybe<Flags_Order_By>;
  flag_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_flags */
export type User_Flags_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_flags" */
export enum User_Flags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_flags" */
export type User_Flags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_flags" */
export type User_Flags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Flags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Flags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  flag_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_flags" */
export enum User_Flags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FlagId = 'flag_id',
  /** column name */
  Id = 'id',
  /** column name */
  UserId = 'user_id'
}

export type User_Flags_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Flags_Set_Input>;
  where: User_Flags_Bool_Exp;
};

/** columns and relationships of "user_group_roles" */
export type User_Group_Roles = {
  __typename?: 'user_group_roles';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  /** An object relationship */
  role?: Maybe<Roles>;
  role_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_group_roles" */
export type User_Group_Roles_Aggregate = {
  __typename?: 'user_group_roles_aggregate';
  aggregate?: Maybe<User_Group_Roles_Aggregate_Fields>;
  nodes: Array<User_Group_Roles>;
};

/** aggregate fields of "user_group_roles" */
export type User_Group_Roles_Aggregate_Fields = {
  __typename?: 'user_group_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Group_Roles_Max_Fields>;
  min?: Maybe<User_Group_Roles_Min_Fields>;
};


/** aggregate fields of "user_group_roles" */
export type User_Group_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Group_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_group_roles". All fields are combined with a logical 'AND'. */
export type User_Group_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Group_Roles_Bool_Exp>>;
  _not?: InputMaybe<User_Group_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Group_Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_group_roles" */
export enum User_Group_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserGroupRolesPkey = 'user_group_roles_pkey',
  /** unique or primary key constraint on columns "role_id", "group_id", "user_id" */
  UserGroupRolesUserIdGroupIdRoleIdUnique = 'user_group_roles_user_id_group_id_role_id_unique'
}

/** input type for inserting data into table "user_group_roles" */
export type User_Group_Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['uuid']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Group_Roles_Max_Fields = {
  __typename?: 'user_group_roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Group_Roles_Min_Fields = {
  __typename?: 'user_group_roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_group_roles" */
export type User_Group_Roles_Mutation_Response = {
  __typename?: 'user_group_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Group_Roles>;
};

/** on_conflict condition type for table "user_group_roles" */
export type User_Group_Roles_On_Conflict = {
  constraint: User_Group_Roles_Constraint;
  update_columns?: Array<User_Group_Roles_Update_Column>;
  where?: InputMaybe<User_Group_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_group_roles". */
export type User_Group_Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_group_roles */
export type User_Group_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_group_roles" */
export enum User_Group_Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_group_roles" */
export type User_Group_Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_group_roles" */
export type User_Group_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Group_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Group_Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_group_roles" */
export enum User_Group_Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

export type User_Group_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Group_Roles_Set_Input>;
  where: User_Group_Roles_Bool_Exp;
};

/** columns and relationships of "user_roles" */
export type User_Roles = {
  __typename?: 'user_roles';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "user_roles" */
export type User_Roles_Aggregate = {
  __typename?: 'user_roles_aggregate';
  aggregate?: Maybe<User_Roles_Aggregate_Fields>;
  nodes: Array<User_Roles>;
};

/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_Fields = {
  __typename?: 'user_roles_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Roles_Max_Fields>;
  min?: Maybe<User_Roles_Min_Fields>;
};


/** aggregate fields of "user_roles" */
export type User_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "user_roles". All fields are combined with a logical 'AND'. */
export type User_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  _not?: InputMaybe<User_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<User_Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role_id?: InputMaybe<Uuid_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_roles" */
export enum User_Roles_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserRolesPkey = 'user_roles_pkey',
  /** unique or primary key constraint on columns "role_id", "user_id" */
  UserRolesUserIdRoleIdUnique = 'user_roles_user_id_role_id_unique'
}

/** input type for inserting data into table "user_roles" */
export type User_Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Roles_Max_Fields = {
  __typename?: 'user_roles_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type User_Roles_Min_Fields = {
  __typename?: 'user_roles_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "user_roles" */
export type User_Roles_Mutation_Response = {
  __typename?: 'user_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Roles>;
};

/** on_conflict condition type for table "user_roles" */
export type User_Roles_On_Conflict = {
  constraint: User_Roles_Constraint;
  update_columns?: Array<User_Roles_Update_Column>;
  where?: InputMaybe<User_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "user_roles". */
export type User_Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_roles */
export type User_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_roles" */
export enum User_Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_roles" */
export type User_Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_roles" */
export type User_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  role_id?: InputMaybe<Scalars['uuid']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_roles" */
export enum User_Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  UserId = 'user_id'
}

export type User_Roles_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Roles_Set_Input>;
  where: User_Roles_Bool_Exp;
};

/** columns and relationships of "user_social_logins" */
export type User_Social_Logins = {
  __typename?: 'user_social_logins';
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  meta?: Maybe<Scalars['json']>;
  provider: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['uuid'];
};


/** columns and relationships of "user_social_logins" */
export type User_Social_LoginsMetaArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "user_social_logins" */
export type User_Social_Logins_Aggregate = {
  __typename?: 'user_social_logins_aggregate';
  aggregate?: Maybe<User_Social_Logins_Aggregate_Fields>;
  nodes: Array<User_Social_Logins>;
};

/** aggregate fields of "user_social_logins" */
export type User_Social_Logins_Aggregate_Fields = {
  __typename?: 'user_social_logins_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<User_Social_Logins_Max_Fields>;
  min?: Maybe<User_Social_Logins_Min_Fields>;
};


/** aggregate fields of "user_social_logins" */
export type User_Social_Logins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Social_Logins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_social_logins" */
export type User_Social_Logins_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Social_Logins_Max_Order_By>;
  min?: InputMaybe<User_Social_Logins_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_social_logins" */
export type User_Social_Logins_Arr_Rel_Insert_Input = {
  data: Array<User_Social_Logins_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Social_Logins_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_social_logins". All fields are combined with a logical 'AND'. */
export type User_Social_Logins_Bool_Exp = {
  _and?: InputMaybe<Array<User_Social_Logins_Bool_Exp>>;
  _not?: InputMaybe<User_Social_Logins_Bool_Exp>;
  _or?: InputMaybe<Array<User_Social_Logins_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  meta?: InputMaybe<Json_Comparison_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_social_logins" */
export enum User_Social_Logins_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserSocialLoginsPkey = 'user_social_logins_pkey',
  /** unique or primary key constraint on columns "provider", "user_id" */
  UserSocialLoginsUserIdProviderUnique = 'user_social_logins_user_id_provider_unique'
}

/** input type for inserting data into table "user_social_logins" */
export type User_Social_Logins_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['json']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Social_Logins_Max_Fields = {
  __typename?: 'user_social_logins_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_social_logins" */
export type User_Social_Logins_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Social_Logins_Min_Fields = {
  __typename?: 'user_social_logins_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_social_logins" */
export type User_Social_Logins_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_social_logins" */
export type User_Social_Logins_Mutation_Response = {
  __typename?: 'user_social_logins_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Social_Logins>;
};

/** on_conflict condition type for table "user_social_logins" */
export type User_Social_Logins_On_Conflict = {
  constraint: User_Social_Logins_Constraint;
  update_columns?: Array<User_Social_Logins_Update_Column>;
  where?: InputMaybe<User_Social_Logins_Bool_Exp>;
};

/** Ordering options when selecting data from "user_social_logins". */
export type User_Social_Logins_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta?: InputMaybe<Order_By>;
  provider?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_social_logins */
export type User_Social_Logins_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "user_social_logins" */
export enum User_Social_Logins_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_social_logins" */
export type User_Social_Logins_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['json']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "user_social_logins" */
export type User_Social_Logins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Social_Logins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Social_Logins_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  meta?: InputMaybe<Scalars['json']>;
  provider?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_social_logins" */
export enum User_Social_Logins_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Meta = 'meta',
  /** column name */
  Provider = 'provider',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Social_Logins_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Social_Logins_Set_Input>;
  where: User_Social_Logins_Bool_Exp;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  /** An array relationship */
  channel_members: Array<Channel_Members>;
  /** An aggregate relationship */
  channel_members_aggregate: Channel_Members_Aggregate;
  /** An array relationship */
  comment_mentions: Array<Comment_Mentions>;
  /** An aggregate relationship */
  comment_mentions_aggregate: Comment_Mentions_Aggregate;
  /** An array relationship */
  comments: Array<Comments>;
  /** An aggregate relationship */
  comments_aggregate: Comments_Aggregate;
  created_at: Scalars['timestamptz'];
  email: Scalars['String'];
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  games: Array<Games>;
  /** An aggregate relationship */
  games_aggregate: Games_Aggregate;
  /** An array relationship */
  group_members: Array<Group_Members>;
  /** An aggregate relationship */
  group_members_aggregate: Group_Members_Aggregate;
  id: Scalars['uuid'];
  /** An array relationship */
  likes: Array<Likes>;
  /** An aggregate relationship */
  likes_aggregate: Likes_Aggregate;
  locale?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['jsonb']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  /** An array relationship */
  notifications: Array<Notifications>;
  /** An aggregate relationship */
  notifications_aggregate: Notifications_Aggregate;
  password?: Maybe<Scalars['String']>;
  /** An array relationship */
  password_resets: Array<Password_Resets>;
  /** An aggregate relationship */
  password_resets_aggregate: Password_Resets_Aggregate;
  phone_number?: Maybe<Scalars['String']>;
  /** An array relationship */
  post_authors: Array<Post_Authors>;
  /** An aggregate relationship */
  post_authors_aggregate: Post_Authors_Aggregate;
  profile_id?: Maybe<Scalars['uuid']>;
  /** An array relationship */
  purchases: Array<Purchases>;
  /** An aggregate relationship */
  purchases_aggregate: Purchases_Aggregate;
  remember_token?: Maybe<Scalars['String']>;
  salt: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  user_flags: Array<User_Flags>;
  /** An aggregate relationship */
  user_flags_aggregate: User_Flags_Aggregate;
  /** An array relationship */
  user_social_logins: Array<User_Social_Logins>;
  /** An aggregate relationship */
  user_social_logins_aggregate: User_Social_Logins_Aggregate;
  username: Scalars['String'];
  /** An array relationship */
  wallets: Array<Wallets>;
  /** An aggregate relationship */
  wallets_aggregate: Wallets_Aggregate;
};


/** columns and relationships of "users" */
export type UsersChannel_MembersArgs = {
  distinct_on?: InputMaybe<Array<Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Members_Order_By>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersChannel_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Channel_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Channel_Members_Order_By>>;
  where?: InputMaybe<Channel_Members_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersComment_MentionsArgs = {
  distinct_on?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Mentions_Order_By>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersComment_Mentions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comment_Mentions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comment_Mentions_Order_By>>;
  where?: InputMaybe<Comment_Mentions_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCommentsArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersComments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Comments_Order_By>>;
  where?: InputMaybe<Comments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersGamesArgs = {
  distinct_on?: InputMaybe<Array<Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Games_Order_By>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersGames_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Games_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Games_Order_By>>;
  where?: InputMaybe<Games_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersGroup_MembersArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersGroup_Members_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Group_Members_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Group_Members_Order_By>>;
  where?: InputMaybe<Group_Members_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLikesArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLikes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Likes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Likes_Order_By>>;
  where?: InputMaybe<Likes_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersMetadataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "users" */
export type UsersNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPassword_ResetsArgs = {
  distinct_on?: InputMaybe<Array<Password_Resets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Password_Resets_Order_By>>;
  where?: InputMaybe<Password_Resets_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPassword_Resets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Resets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Password_Resets_Order_By>>;
  where?: InputMaybe<Password_Resets_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPost_AuthorsArgs = {
  distinct_on?: InputMaybe<Array<Post_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Authors_Order_By>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPost_Authors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Post_Authors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Post_Authors_Order_By>>;
  where?: InputMaybe<Post_Authors_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPurchasesArgs = {
  distinct_on?: InputMaybe<Array<Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchases_Order_By>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPurchases_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Purchases_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Purchases_Order_By>>;
  where?: InputMaybe<Purchases_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_FlagsArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Flags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Flags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Flags_Order_By>>;
  where?: InputMaybe<User_Flags_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Social_LoginsArgs = {
  distinct_on?: InputMaybe<Array<User_Social_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Social_Logins_Order_By>>;
  where?: InputMaybe<User_Social_Logins_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Social_Logins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Social_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Social_Logins_Order_By>>;
  where?: InputMaybe<User_Social_Logins_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersWalletsArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersWallets_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallets_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallets_Order_By>>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Users_Append_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  avatar?: InputMaybe<String_Comparison_Exp>;
  bio?: InputMaybe<String_Comparison_Exp>;
  channel_members?: InputMaybe<Channel_Members_Bool_Exp>;
  comment_mentions?: InputMaybe<Comment_Mentions_Bool_Exp>;
  comments?: InputMaybe<Comments_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  games?: InputMaybe<Games_Bool_Exp>;
  group_members?: InputMaybe<Group_Members_Bool_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  likes?: InputMaybe<Likes_Bool_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  location?: InputMaybe<String_Comparison_Exp>;
  metadata?: InputMaybe<Jsonb_Comparison_Exp>;
  mfa_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  notifications?: InputMaybe<Notifications_Bool_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  password_resets?: InputMaybe<Password_Resets_Bool_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  post_authors?: InputMaybe<Post_Authors_Bool_Exp>;
  profile_id?: InputMaybe<Uuid_Comparison_Exp>;
  purchases?: InputMaybe<Purchases_Bool_Exp>;
  remember_token?: InputMaybe<String_Comparison_Exp>;
  salt?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_flags?: InputMaybe<User_Flags_Bool_Exp>;
  user_social_logins?: InputMaybe<User_Social_Logins_Bool_Exp>;
  username?: InputMaybe<String_Comparison_Exp>;
  wallets?: InputMaybe<Wallets_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "email" */
  UsersEmailUnique = 'users_email_unique',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey',
  /** unique or primary key constraint on columns "username" */
  UsersUsernameUnique = 'users_username_unique'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Users_Delete_At_Path_Input = {
  metadata?: InputMaybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Users_Delete_Elem_Input = {
  metadata?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Users_Delete_Key_Input = {
  metadata?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  channel_members?: InputMaybe<Channel_Members_Arr_Rel_Insert_Input>;
  comment_mentions?: InputMaybe<Comment_Mentions_Arr_Rel_Insert_Input>;
  comments?: InputMaybe<Comments_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified_at?: InputMaybe<Scalars['timestamptz']>;
  games?: InputMaybe<Games_Arr_Rel_Insert_Input>;
  group_members?: InputMaybe<Group_Members_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['uuid']>;
  likes?: InputMaybe<Likes_Arr_Rel_Insert_Input>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  notifications?: InputMaybe<Notifications_Arr_Rel_Insert_Input>;
  password?: InputMaybe<Scalars['String']>;
  password_resets?: InputMaybe<Password_Resets_Arr_Rel_Insert_Input>;
  phone_number?: InputMaybe<Scalars['String']>;
  post_authors?: InputMaybe<Post_Authors_Arr_Rel_Insert_Input>;
  profile_id?: InputMaybe<Scalars['uuid']>;
  purchases?: InputMaybe<Purchases_Arr_Rel_Insert_Input>;
  remember_token?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_flags?: InputMaybe<User_Flags_Arr_Rel_Insert_Input>;
  user_social_logins?: InputMaybe<User_Social_Logins_Arr_Rel_Insert_Input>;
  username?: InputMaybe<Scalars['String']>;
  wallets?: InputMaybe<Wallets_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['uuid']>;
  remember_token?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  avatar?: Maybe<Scalars['String']>;
  bio?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  email?: Maybe<Scalars['String']>;
  email_verified_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  locale?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  profile_id?: Maybe<Scalars['uuid']>;
  remember_token?: Maybe<Scalars['String']>;
  salt?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  username?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  avatar?: InputMaybe<Order_By>;
  bio?: InputMaybe<Order_By>;
  channel_members_aggregate?: InputMaybe<Channel_Members_Aggregate_Order_By>;
  comment_mentions_aggregate?: InputMaybe<Comment_Mentions_Aggregate_Order_By>;
  comments_aggregate?: InputMaybe<Comments_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified_at?: InputMaybe<Order_By>;
  games_aggregate?: InputMaybe<Games_Aggregate_Order_By>;
  group_members_aggregate?: InputMaybe<Group_Members_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  likes_aggregate?: InputMaybe<Likes_Aggregate_Order_By>;
  locale?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  metadata?: InputMaybe<Order_By>;
  mfa_enabled?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  notifications_aggregate?: InputMaybe<Notifications_Aggregate_Order_By>;
  password?: InputMaybe<Order_By>;
  password_resets_aggregate?: InputMaybe<Password_Resets_Aggregate_Order_By>;
  phone_number?: InputMaybe<Order_By>;
  post_authors_aggregate?: InputMaybe<Post_Authors_Aggregate_Order_By>;
  profile_id?: InputMaybe<Order_By>;
  purchases_aggregate?: InputMaybe<Purchases_Aggregate_Order_By>;
  remember_token?: InputMaybe<Order_By>;
  salt?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_flags_aggregate?: InputMaybe<User_Flags_Aggregate_Order_By>;
  user_social_logins_aggregate?: InputMaybe<User_Social_Logins_Aggregate_Order_By>;
  username?: InputMaybe<Order_By>;
  wallets_aggregate?: InputMaybe<Wallets_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Users_Prepend_Input = {
  metadata?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  Location = 'location',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  RememberToken = 'remember_token',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['uuid']>;
  remember_token?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  avatar?: InputMaybe<Scalars['String']>;
  bio?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  email?: InputMaybe<Scalars['String']>;
  email_verified_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  locale?: InputMaybe<Scalars['String']>;
  location?: InputMaybe<Scalars['String']>;
  metadata?: InputMaybe<Scalars['jsonb']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  password?: InputMaybe<Scalars['String']>;
  phone_number?: InputMaybe<Scalars['String']>;
  profile_id?: InputMaybe<Scalars['uuid']>;
  remember_token?: InputMaybe<Scalars['String']>;
  salt?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  username?: InputMaybe<Scalars['String']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Avatar = 'avatar',
  /** column name */
  Bio = 'bio',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  Id = 'id',
  /** column name */
  Locale = 'locale',
  /** column name */
  Location = 'location',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfileId = 'profile_id',
  /** column name */
  RememberToken = 'remember_token',
  /** column name */
  Salt = 'salt',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Username = 'username'
}

export type Users_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Users_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Users_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Users_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Users_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Users_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "verifications" */
export type Verifications = {
  __typename?: 'verifications';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  target_id: Scalars['uuid'];
  target_type: Scalars['uuid'];
  verified_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "verifications" */
export type Verifications_Aggregate = {
  __typename?: 'verifications_aggregate';
  aggregate?: Maybe<Verifications_Aggregate_Fields>;
  nodes: Array<Verifications>;
};

/** aggregate fields of "verifications" */
export type Verifications_Aggregate_Fields = {
  __typename?: 'verifications_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Verifications_Max_Fields>;
  min?: Maybe<Verifications_Min_Fields>;
};


/** aggregate fields of "verifications" */
export type Verifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Verifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "verifications". All fields are combined with a logical 'AND'. */
export type Verifications_Bool_Exp = {
  _and?: InputMaybe<Array<Verifications_Bool_Exp>>;
  _not?: InputMaybe<Verifications_Bool_Exp>;
  _or?: InputMaybe<Array<Verifications_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  target_id?: InputMaybe<Uuid_Comparison_Exp>;
  target_type?: InputMaybe<Uuid_Comparison_Exp>;
  verified_by_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "verifications" */
export enum Verifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  VerificationsPkey = 'verifications_pkey',
  /** unique or primary key constraint on columns "target_id", "target_type" */
  VerificationsTargetIdTargetTypeUnique = 'verifications_target_id_target_type_unique'
}

/** input type for inserting data into table "verifications" */
export type Verifications_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['uuid']>;
  target_type?: InputMaybe<Scalars['uuid']>;
  verified_by_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Verifications_Max_Fields = {
  __typename?: 'verifications_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
  target_type?: Maybe<Scalars['uuid']>;
  verified_by_id?: Maybe<Scalars['uuid']>;
};

/** aggregate min on columns */
export type Verifications_Min_Fields = {
  __typename?: 'verifications_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  target_id?: Maybe<Scalars['uuid']>;
  target_type?: Maybe<Scalars['uuid']>;
  verified_by_id?: Maybe<Scalars['uuid']>;
};

/** response of any mutation on the table "verifications" */
export type Verifications_Mutation_Response = {
  __typename?: 'verifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Verifications>;
};

/** on_conflict condition type for table "verifications" */
export type Verifications_On_Conflict = {
  constraint: Verifications_Constraint;
  update_columns?: Array<Verifications_Update_Column>;
  where?: InputMaybe<Verifications_Bool_Exp>;
};

/** Ordering options when selecting data from "verifications". */
export type Verifications_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  verified_by_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: verifications */
export type Verifications_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "verifications" */
export enum Verifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  VerifiedById = 'verified_by_id'
}

/** input type for updating data in table "verifications" */
export type Verifications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['uuid']>;
  target_type?: InputMaybe<Scalars['uuid']>;
  verified_by_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "verifications" */
export type Verifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Verifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Verifications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  target_id?: InputMaybe<Scalars['uuid']>;
  target_type?: InputMaybe<Scalars['uuid']>;
  verified_by_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "verifications" */
export enum Verifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  VerifiedById = 'verified_by_id'
}

export type Verifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Verifications_Set_Input>;
  where: Verifications_Bool_Exp;
};

/** columns and relationships of "wallet_transactions" */
export type Wallet_Transactions = {
  __typename?: 'wallet_transactions';
  created_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['uuid'];
  transaction_id?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  wallet?: Maybe<Wallets>;
  wallet_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "wallet_transactions" */
export type Wallet_Transactions_Aggregate = {
  __typename?: 'wallet_transactions_aggregate';
  aggregate?: Maybe<Wallet_Transactions_Aggregate_Fields>;
  nodes: Array<Wallet_Transactions>;
};

/** aggregate fields of "wallet_transactions" */
export type Wallet_Transactions_Aggregate_Fields = {
  __typename?: 'wallet_transactions_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wallet_Transactions_Max_Fields>;
  min?: Maybe<Wallet_Transactions_Min_Fields>;
};


/** aggregate fields of "wallet_transactions" */
export type Wallet_Transactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wallet_transactions" */
export type Wallet_Transactions_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wallet_Transactions_Max_Order_By>;
  min?: InputMaybe<Wallet_Transactions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "wallet_transactions" */
export type Wallet_Transactions_Arr_Rel_Insert_Input = {
  data: Array<Wallet_Transactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallet_Transactions_On_Conflict>;
};

/** Boolean expression to filter rows from the table "wallet_transactions". All fields are combined with a logical 'AND'. */
export type Wallet_Transactions_Bool_Exp = {
  _and?: InputMaybe<Array<Wallet_Transactions_Bool_Exp>>;
  _not?: InputMaybe<Wallet_Transactions_Bool_Exp>;
  _or?: InputMaybe<Array<Wallet_Transactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  transaction_id?: InputMaybe<Uuid_Comparison_Exp>;
  wallet?: InputMaybe<Wallets_Bool_Exp>;
  wallet_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "wallet_transactions" */
export enum Wallet_Transactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  WalletTransactionsPkey = 'wallet_transactions_pkey',
  /** unique or primary key constraint on columns "transaction_id", "wallet_id" */
  WalletTransactionsWalletIdTransactionIdUnique = 'wallet_transactions_wallet_id_transaction_id_unique'
}

/** input type for inserting data into table "wallet_transactions" */
export type Wallet_Transactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  wallet?: InputMaybe<Wallets_Obj_Rel_Insert_Input>;
  wallet_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Wallet_Transactions_Max_Fields = {
  __typename?: 'wallet_transactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  wallet_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  wallet_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wallet_Transactions_Min_Fields = {
  __typename?: 'wallet_transactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  transaction_id?: Maybe<Scalars['uuid']>;
  wallet_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "wallet_transactions" */
export type Wallet_Transactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  wallet_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "wallet_transactions" */
export type Wallet_Transactions_Mutation_Response = {
  __typename?: 'wallet_transactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallet_Transactions>;
};

/** on_conflict condition type for table "wallet_transactions" */
export type Wallet_Transactions_On_Conflict = {
  constraint: Wallet_Transactions_Constraint;
  update_columns?: Array<Wallet_Transactions_Update_Column>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};

/** Ordering options when selecting data from "wallet_transactions". */
export type Wallet_Transactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  transaction_id?: InputMaybe<Order_By>;
  wallet?: InputMaybe<Wallets_Order_By>;
  wallet_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: wallet_transactions */
export type Wallet_Transactions_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "wallet_transactions" */
export enum Wallet_Transactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  WalletId = 'wallet_id'
}

/** input type for updating data in table "wallet_transactions" */
export type Wallet_Transactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  wallet_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "wallet_transactions" */
export type Wallet_Transactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wallet_Transactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wallet_Transactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  transaction_id?: InputMaybe<Scalars['uuid']>;
  wallet_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "wallet_transactions" */
export enum Wallet_Transactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  TransactionId = 'transaction_id',
  /** column name */
  WalletId = 'wallet_id'
}

export type Wallet_Transactions_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wallet_Transactions_Set_Input>;
  where: Wallet_Transactions_Bool_Exp;
};

/** columns and relationships of "wallets" */
export type Wallets = {
  __typename?: 'wallets';
  created_at: Scalars['timestamptz'];
  /** An object relationship */
  group?: Maybe<Groups>;
  group_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  is_shared: Scalars['Boolean'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
  /** An array relationship */
  wallet_transactions: Array<Wallet_Transactions>;
  /** An aggregate relationship */
  wallet_transactions_aggregate: Wallet_Transactions_Aggregate;
};


/** columns and relationships of "wallets" */
export type WalletsWallet_TransactionsArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};


/** columns and relationships of "wallets" */
export type WalletsWallet_Transactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Wallet_Transactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Wallet_Transactions_Order_By>>;
  where?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};

/** aggregated selection of "wallets" */
export type Wallets_Aggregate = {
  __typename?: 'wallets_aggregate';
  aggregate?: Maybe<Wallets_Aggregate_Fields>;
  nodes: Array<Wallets>;
};

/** aggregate fields of "wallets" */
export type Wallets_Aggregate_Fields = {
  __typename?: 'wallets_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Wallets_Max_Fields>;
  min?: Maybe<Wallets_Min_Fields>;
};


/** aggregate fields of "wallets" */
export type Wallets_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Wallets_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wallets" */
export type Wallets_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Wallets_Max_Order_By>;
  min?: InputMaybe<Wallets_Min_Order_By>;
};

/** input type for inserting array relation for remote table "wallets" */
export type Wallets_Arr_Rel_Insert_Input = {
  data: Array<Wallets_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallets_On_Conflict>;
};

/** Boolean expression to filter rows from the table "wallets". All fields are combined with a logical 'AND'. */
export type Wallets_Bool_Exp = {
  _and?: InputMaybe<Array<Wallets_Bool_Exp>>;
  _not?: InputMaybe<Wallets_Bool_Exp>;
  _or?: InputMaybe<Array<Wallets_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  group?: InputMaybe<Groups_Bool_Exp>;
  group_id?: InputMaybe<Uuid_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_shared?: InputMaybe<Boolean_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
  wallet_transactions?: InputMaybe<Wallet_Transactions_Bool_Exp>;
};

/** unique or primary key constraints on table "wallets" */
export enum Wallets_Constraint {
  /** unique or primary key constraint on columns "id" */
  WalletsPkey = 'wallets_pkey'
}

/** input type for inserting data into table "wallets" */
export type Wallets_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group?: InputMaybe<Groups_Obj_Rel_Insert_Input>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_shared?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
  wallet_transactions?: InputMaybe<Wallet_Transactions_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Wallets_Max_Fields = {
  __typename?: 'wallets_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "wallets" */
export type Wallets_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Wallets_Min_Fields = {
  __typename?: 'wallets_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  group_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "wallets" */
export type Wallets_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "wallets" */
export type Wallets_Mutation_Response = {
  __typename?: 'wallets_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wallets>;
};

/** input type for inserting object relation for remote table "wallets" */
export type Wallets_Obj_Rel_Insert_Input = {
  data: Wallets_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Wallets_On_Conflict>;
};

/** on_conflict condition type for table "wallets" */
export type Wallets_On_Conflict = {
  constraint: Wallets_Constraint;
  update_columns?: Array<Wallets_Update_Column>;
  where?: InputMaybe<Wallets_Bool_Exp>;
};

/** Ordering options when selecting data from "wallets". */
export type Wallets_Order_By = {
  created_at?: InputMaybe<Order_By>;
  group?: InputMaybe<Groups_Order_By>;
  group_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_shared?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
  wallet_transactions_aggregate?: InputMaybe<Wallet_Transactions_Aggregate_Order_By>;
};

/** primary key columns input for table: wallets */
export type Wallets_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "wallets" */
export enum Wallets_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsShared = 'is_shared',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "wallets" */
export type Wallets_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_shared?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** Streaming cursor of the table "wallets" */
export type Wallets_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wallets_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wallets_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  group_id?: InputMaybe<Scalars['uuid']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_shared?: InputMaybe<Scalars['Boolean']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "wallets" */
export enum Wallets_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GroupId = 'group_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsShared = 'is_shared',
  /** column name */
  UserId = 'user_id'
}

export type Wallets_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Wallets_Set_Input>;
  where: Wallets_Bool_Exp;
};

export type CommentFragment = { __typename: 'comments', id: any, content: string, author_id?: any | null, created_at: any, updated_at: any };

export type FlagFragment = { __typename: 'flags', id: any, name: string, value: string };

export type GameFragment = { __typename: 'games', id: any, language?: string | null, author_id?: any | null, author_type?: any | null, description: string, created_at: any, genre: string, title: string, updated_at: any };

export type GameAttrsFragment = { __typename?: 'games', game_posters: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string } }>, game_downloads: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string, file_type?: any | null } }>, game_genres: Array<{ __typename?: 'game_genres', id: any, tag: { __typename?: 'tags', id: any, name: string, value?: string | null, description?: string | null } }> };

export type UserFragment = { __typename: 'users', avatar?: string | null, bio?: string | null, created_at: any, id: any, locale?: string | null, location?: string | null, name: string, username: string };

export type ChangePasswordMutationVariables = Exact<{
  input: ChangePasswordInput;
}>;


export type ChangePasswordMutation = { __typename?: 'mutation_root', changePassword?: { __typename?: 'ChangePasswordOutput', message?: string | null } | null };

export type CreateCommentMutationVariables = Exact<{
  input: Comments_Insert_Input;
}>;


export type CreateCommentMutation = { __typename?: 'mutation_root', insert_comments_one?: { __typename: 'comments', id: any, author_id?: any | null } | null };

export type CreateCowrieWalletMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
}>;


export type CreateCowrieWalletMutation = { __typename?: 'mutation_root', createWallet?: { __typename?: 'CreateWalletOutput', message: string, testWalletId?: string | null, walletId?: string | null } | null };

export type CreateGameRatingMutationVariables = Exact<{
  input: Game_Ratings_Insert_Input;
}>;


export type CreateGameRatingMutation = { __typename?: 'mutation_root', insert_game_ratings_one?: { __typename: 'game_ratings', id: any, comment: { __typename: 'comments', id: any, content: string, author_id?: any | null, created_at: any, updated_at: any }, game: { __typename: 'games', id: any, language?: string | null, author_id?: any | null, author_type?: any | null, description: string, created_at: any, genre: string, title: string, updated_at: any } } | null };

export type AddThirdPartyAppMutationVariables = Exact<{
  params: AddThirdPartyAppInput;
}>;


export type AddThirdPartyAppMutation = { __typename?: 'mutation_root', addThirdPartyApp?: { __typename?: 'AddThirdPartyAppOutput', message: string, walletName?: string | null, clientSecret?: string | null } | null };

export type DeleteAccountMutationVariables = Exact<{
  input: DeleteAccountInput;
}>;


export type DeleteAccountMutation = { __typename?: 'mutation_root', deleteAccount?: { __typename?: 'DeleteAccountOutput', message?: string | null } | null };

export type GenerateResetPasswordTokenMutationVariables = Exact<{
  input: GenerateResetPasswordTokenInput;
}>;


export type GenerateResetPasswordTokenMutation = { __typename?: 'mutation_root', generateResetPasswordToken?: { __typename?: 'GenerateResetPasswordTokenOutput', resetToken?: string | null } | null };

export type GoogleLoginMutationVariables = Exact<{
  input: GoogleLoginInput;
}>;


export type GoogleLoginMutation = { __typename?: 'mutation_root', googleLogin?: { __typename?: 'GoogleLoginOutput', message?: string | null, type?: string | null, id?: string | null, token: string } | null };

export type LikeEntityMutationVariables = Exact<{
  input: Likes_Insert_Input;
}>;


export type LikeEntityMutation = { __typename?: 'mutation_root', insert_likes_one?: { __typename?: 'likes', id: any, author_id?: any | null } | null };

export type LoginMutationVariables = Exact<{
  input: LoginInput;
}>;


export type LoginMutation = { __typename?: 'mutation_root', login?: { __typename?: 'LoginOutput', accessToken: string, message?: string | null, type?: string | null, id?: string | null } | null };

export type OffRampCeloMutationVariables = Exact<{
  params: SendToCeloInput;
}>;


export type OffRampCeloMutation = { __typename?: 'mutation_root', sendToCelo?: { __typename?: 'SendToCeloOutput', message: string, walletBalance?: { __typename?: 'GetWalletBalanceOutput', balance?: any | null, debitBalance?: any | null } | null } | null };

export type ResetPasswordMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPasswordMutation = { __typename?: 'mutation_root', resetPassword?: { __typename?: 'ResetPasswordOutput', message: string } | null };

export type SignUpMutationVariables = Exact<{
  input: SignupInput;
}>;


export type SignUpMutation = { __typename?: 'mutation_root', signup?: { __typename?: 'SignupOutput', data?: string | null, message?: string | null, type?: string | null } | null };

export type TopUpCowrieMutationVariables = Exact<{
  params: TopUpJiweWalletInput;
}>;


export type TopUpCowrieMutation = { __typename?: 'mutation_root', topUpJiweWallet: { __typename?: 'TopUpJiweWalletOutput', message: string, walletBalance?: { __typename?: 'GetWalletBalanceOutput', balance?: any | null } | null } };

export type UpdateProfileMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Profiles_Set_Input;
}>;


export type UpdateProfileMutation = { __typename?: 'mutation_root', update_profiles?: { __typename?: 'profiles_mutation_response', returning: Array<{ __typename: 'profiles', id: any }> } | null };

export type UpdateProfileMetadataMutationVariables = Exact<{
  profileId: Scalars['uuid'];
  metadata: Scalars['jsonb'];
}>;


export type UpdateProfileMetadataMutation = { __typename?: 'mutation_root', update_profiles_by_pk?: { __typename?: 'profiles', id: any } | null };

export type UpdateUserMutationVariables = Exact<{
  id: Scalars['uuid'];
  input: Users_Set_Input;
}>;


export type UpdateUserMutation = { __typename?: 'mutation_root', update_users?: { __typename?: 'users_mutation_response', returning: Array<{ __typename: 'users', avatar?: string | null, bio?: string | null, created_at: any, id: any, locale?: string | null, location?: string | null, name: string, username: string }> } | null };

export type GetEntityLikesCountSubscriptionVariables = Exact<{
  target_id: Scalars['uuid'];
}>;


export type GetEntityLikesCountSubscription = { __typename?: 'subscription_root', likes: { __typename?: 'likes_aggregate', aggregate?: { __typename?: 'likes_aggregate_fields', count: number } | null } };

export type GetAllFlagsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllFlagsQuery = { __typename?: 'query_root', flags: Array<{ __typename: 'flags', id: any, name: string, value: string }> };

export type GetAllGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAllGamesQuery = { __typename?: 'query_root', games: Array<{ __typename: 'games', id: any, language?: string | null, author_id?: any | null, author_type?: any | null, description: string, created_at: any, genre: string, title: string, updated_at: any, author?: { __typename: 'users', avatar?: string | null, bio?: string | null, created_at: any, id: any, locale?: string | null, location?: string | null, name: string, username: string } | null, game_posters: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string } }>, game_downloads: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string, file_type?: any | null } }>, game_genres: Array<{ __typename?: 'game_genres', id: any, tag: { __typename?: 'tags', id: any, name: string, value?: string | null, description?: string | null } }> }> };

export type GetMyAppsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMyAppsQuery = { __typename?: 'query_root', third_party_apps: Array<{ __typename?: 'third_party_apps', id: string, client_name: string, client_secret: string, metadata: any, is_active: boolean, created_at: any }> };

export type GetCeloWalletBalanceQueryVariables = Exact<{
  params: CeloWalletBalanceInput;
}>;


export type GetCeloWalletBalanceQuery = { __typename?: 'query_root', getCeloBalance?: { __typename?: 'CeloWalletBalanceOutput', cUSDBalance: string, celoBalance: string, cEURBalance: string, celoWalletAddress: string, testMode: boolean } | null };

export type GetFeaturedGamesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetFeaturedGamesQuery = { __typename?: 'query_root', featured_games: Array<{ __typename: 'featured_games', id: any, updated_at: any, created_at: any, game: { __typename: 'games', id: any, language?: string | null, author_id?: any | null, author_type?: any | null, description: string, created_at: any, genre: string, title: string, updated_at: any, game_serving_points: Array<{ __typename?: 'game_serving_points', id: any, serving_point?: { __typename?: 'serving_points', id: any, name: string, value: string } | null }> } }> };

export type GetGameQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetGameQuery = { __typename?: 'query_root', game?: { __typename: 'games', author_id?: any | null, id: any, language?: string | null, author_type?: any | null, description: string, created_at: any, genre: string, title: string, updated_at: any, game_serving_points: Array<{ __typename?: 'game_serving_points', id: any, serving_point?: { __typename?: 'serving_points', id: any, name: string, value: string } | null }>, file?: { __typename?: 'files', id: any, path: string } | null, author?: { __typename: 'users', avatar?: string | null, bio?: string | null, created_at: any, id: any, locale?: string | null, location?: string | null, name: string, username: string } | null, game_posters: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string } }>, game_downloads: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string, file_type?: any | null } }>, game_genres: Array<{ __typename?: 'game_genres', id: any, tag: { __typename?: 'tags', id: any, name: string, value?: string | null, description?: string | null } }> } | null };

export type GetGameRatingsSubscriptionVariables = Exact<{
  game_id: Scalars['uuid'];
}>;


export type GetGameRatingsSubscription = { __typename?: 'subscription_root', game_ratings: Array<{ __typename?: 'game_ratings', id: any, rating: number, additional_ratings?: any | null, created_at: any, comment: { __typename: 'comments', id: any, content: string, author_id?: any | null, created_at: any, updated_at: any, author?: { __typename: 'users', avatar?: string | null, bio?: string | null, created_at: any, id: any, locale?: string | null, location?: string | null, name: string, username: string } | null } }> };

export type GetGameRatingsQueryQueryVariables = Exact<{
  game_id: Scalars['uuid'];
}>;


export type GetGameRatingsQueryQuery = { __typename?: 'query_root', game_ratings: Array<{ __typename?: 'game_ratings', id: any, rating: number, additional_ratings?: any | null, created_at: any, comment: { __typename: 'comments', id: any, content: string, author_id?: any | null, created_at: any, updated_at: any, author?: { __typename: 'users', avatar?: string | null, bio?: string | null, created_at: any, id: any, locale?: string | null, location?: string | null, name: string, username: string } | null } }> };

export type GetGamesByGenreQueryVariables = Exact<{
  genreId: Scalars['uuid'];
}>;


export type GetGamesByGenreQuery = { __typename?: 'query_root', game_genres: Array<{ __typename?: 'game_genres', id: any, game: { __typename: 'games', author_id?: any | null, id: any, language?: string | null, author_type?: any | null, description: string, created_at: any, genre: string, title: string, updated_at: any, game_serving_points: Array<{ __typename?: 'game_serving_points', id: any, serving_point?: { __typename?: 'serving_points', id: any, name: string, value: string } | null }>, file?: { __typename?: 'files', id: any, path: string } | null, game_posters: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string } }>, game_downloads: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string, file_type?: any | null } }>, game_genres: Array<{ __typename?: 'game_genres', id: any, tag: { __typename?: 'tags', id: any, name: string, value?: string | null, description?: string | null } }> } }> };

export type GetGenresQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGenresQuery = { __typename?: 'query_root', genres: Array<{ __typename?: 'tags', id: any, name: string, value?: string | null, description?: string | null }> };

export type GetMeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMeQuery = { __typename?: 'query_root', me?: { __typename?: 'MeOutput', id: any, avatar?: string | null, name?: string | null, username?: string | null, email?: string | null, phone_number?: string | null, locale?: string | null, location?: string | null, profile_id?: any | null } | null };

export type GetNotificationsSubscriptionVariables = Exact<{
  user_id: Scalars['uuid'];
}>;


export type GetNotificationsSubscription = { __typename?: 'subscription_root', notifications: Array<{ __typename?: 'notifications', id: any, created_at?: any | null, message: string, sender: { __typename?: 'users', id: any, name: string, username: string, avatar?: string | null } }> };

export type GetPageQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type GetPageQuery = { __typename?: 'query_root', pages: Array<{ __typename?: 'pages', id: any, slug: string, title: string, poster?: string | null, description?: string | null, metadata: any, created_at: any, updated_at: any }> };

export type GetProfileQueryVariables = Exact<{
  profileId: Scalars['uuid'];
}>;


export type GetProfileQuery = { __typename?: 'query_root', profiles_by_pk?: { __typename?: 'profiles', id: any, name: string, nickname: string, bio?: string | null, avatar?: string | null, locale?: string | null, location?: string | null, esports_id?: any | null, metadata?: any | null } | null };

export type GetUserQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type GetUserQuery = { __typename?: 'query_root', user?: { __typename: 'users', avatar?: string | null, bio?: string | null, created_at: any, id: any, locale?: string | null, location?: string | null, name: string, username: string } | null };

export type GetUsersQueryVariables = Exact<{
  where?: InputMaybe<Users_Bool_Exp>;
}>;


export type GetUsersQuery = { __typename?: 'query_root', users: Array<{ __typename: 'users', avatar?: string | null, bio?: string | null, created_at: any, id: any, locale?: string | null, location?: string | null, name: string, username: string, games: Array<{ __typename: 'games', author_id?: any | null, id: any, language?: string | null, author_type?: any | null, description: string, created_at: any, genre: string, title: string, updated_at: any, game_serving_points: Array<{ __typename?: 'game_serving_points', id: any, serving_point?: { __typename?: 'serving_points', id: any, name: string, value: string } | null }>, file?: { __typename?: 'files', id: any, path: string } | null, game_posters: Array<{ __typename?: 'game_files', id: any, file: { __typename?: 'files', id: any, path: string } }> }> }> };

export type GetWalletTransactionsQueryVariables = Exact<{
  params: FetchWalletTransactionsInput;
}>;


export type GetWalletTransactionsQuery = { __typename?: 'query_root', fetchWalletTransactions?: { __typename?: 'FetchWalletTransactionsOutput', transactions: any } | null, getWalletBalance?: { __typename?: 'GetWalletBalanceOutput', balance?: any | null, creditBalance?: any | null, debitBalance?: any | null, creditTotal?: any | null, debitTotal?: any | null, transactionCount?: any | null } | null };

export const CommentFragmentDoc = gql`
    fragment comment on comments {
  __typename
  id
  content
  author_id
  created_at
  updated_at
}
    `;
export const FlagFragmentDoc = gql`
    fragment flag on flags {
  __typename
  id
  name
  value
}
    `;
export const GameFragmentDoc = gql`
    fragment game on games {
  __typename
  id
  language
  author_id
  author_type
  description
  created_at
  genre
  title
  updated_at
}
    `;
export const GameAttrsFragmentDoc = gql`
    fragment gameAttrs on games {
  game_posters: game_files(where: {file: {file_type: {_eq: "game_poster"}}}) {
    id
    file {
      id
      path
    }
  }
  game_downloads: game_files(where: {file: {file_type: {_in: ["exe", "apk"]}}}) {
    id
    file {
      id
      path
      file_type
    }
  }
  game_genres {
    id
    tag {
      id
      name
      value
      description
    }
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment user on users {
  __typename
  avatar
  bio
  created_at
  id
  locale
  location
  name
  username
}
    `;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($input: ChangePasswordInput!) {
  changePassword(params: $input) {
    message
  }
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateCommentDocument = gql`
    mutation CreateComment($input: comments_insert_input!) {
  insert_comments_one(object: $input) {
    __typename
    id
    author_id
  }
}
    `;
export type CreateCommentMutationFn = Apollo.MutationFunction<CreateCommentMutation, CreateCommentMutationVariables>;

/**
 * __useCreateCommentMutation__
 *
 * To run a mutation, you first call `useCreateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCommentMutation, { data, loading, error }] = useCreateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateCommentMutation, CreateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCommentMutation, CreateCommentMutationVariables>(CreateCommentDocument, options);
      }
export type CreateCommentMutationHookResult = ReturnType<typeof useCreateCommentMutation>;
export type CreateCommentMutationResult = Apollo.MutationResult<CreateCommentMutation>;
export type CreateCommentMutationOptions = Apollo.BaseMutationOptions<CreateCommentMutation, CreateCommentMutationVariables>;
export const CreateCowrieWalletDocument = gql`
    mutation CreateCowrieWallet($name: String = "") {
  createWallet(params: {name: $name}) {
    message
    testWalletId
    walletId
  }
}
    `;
export type CreateCowrieWalletMutationFn = Apollo.MutationFunction<CreateCowrieWalletMutation, CreateCowrieWalletMutationVariables>;

/**
 * __useCreateCowrieWalletMutation__
 *
 * To run a mutation, you first call `useCreateCowrieWalletMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCowrieWalletMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCowrieWalletMutation, { data, loading, error }] = useCreateCowrieWalletMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateCowrieWalletMutation(baseOptions?: Apollo.MutationHookOptions<CreateCowrieWalletMutation, CreateCowrieWalletMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateCowrieWalletMutation, CreateCowrieWalletMutationVariables>(CreateCowrieWalletDocument, options);
      }
export type CreateCowrieWalletMutationHookResult = ReturnType<typeof useCreateCowrieWalletMutation>;
export type CreateCowrieWalletMutationResult = Apollo.MutationResult<CreateCowrieWalletMutation>;
export type CreateCowrieWalletMutationOptions = Apollo.BaseMutationOptions<CreateCowrieWalletMutation, CreateCowrieWalletMutationVariables>;
export const CreateGameRatingDocument = gql`
    mutation CreateGameRating($input: game_ratings_insert_input!) {
  insert_game_ratings_one(object: $input) {
    __typename
    id
    comment {
      ...comment
    }
    game {
      ...game
    }
  }
}
    ${CommentFragmentDoc}
${GameFragmentDoc}`;
export type CreateGameRatingMutationFn = Apollo.MutationFunction<CreateGameRatingMutation, CreateGameRatingMutationVariables>;

/**
 * __useCreateGameRatingMutation__
 *
 * To run a mutation, you first call `useCreateGameRatingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGameRatingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGameRatingMutation, { data, loading, error }] = useCreateGameRatingMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateGameRatingMutation(baseOptions?: Apollo.MutationHookOptions<CreateGameRatingMutation, CreateGameRatingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateGameRatingMutation, CreateGameRatingMutationVariables>(CreateGameRatingDocument, options);
      }
export type CreateGameRatingMutationHookResult = ReturnType<typeof useCreateGameRatingMutation>;
export type CreateGameRatingMutationResult = Apollo.MutationResult<CreateGameRatingMutation>;
export type CreateGameRatingMutationOptions = Apollo.BaseMutationOptions<CreateGameRatingMutation, CreateGameRatingMutationVariables>;
export const AddThirdPartyAppDocument = gql`
    mutation addThirdPartyApp($params: AddThirdPartyAppInput!) {
  addThirdPartyApp(params: $params) {
    message
    walletName
    clientSecret: walletClientSecret
  }
}
    `;
export type AddThirdPartyAppMutationFn = Apollo.MutationFunction<AddThirdPartyAppMutation, AddThirdPartyAppMutationVariables>;

/**
 * __useAddThirdPartyAppMutation__
 *
 * To run a mutation, you first call `useAddThirdPartyAppMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddThirdPartyAppMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addThirdPartyAppMutation, { data, loading, error }] = useAddThirdPartyAppMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useAddThirdPartyAppMutation(baseOptions?: Apollo.MutationHookOptions<AddThirdPartyAppMutation, AddThirdPartyAppMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddThirdPartyAppMutation, AddThirdPartyAppMutationVariables>(AddThirdPartyAppDocument, options);
      }
export type AddThirdPartyAppMutationHookResult = ReturnType<typeof useAddThirdPartyAppMutation>;
export type AddThirdPartyAppMutationResult = Apollo.MutationResult<AddThirdPartyAppMutation>;
export type AddThirdPartyAppMutationOptions = Apollo.BaseMutationOptions<AddThirdPartyAppMutation, AddThirdPartyAppMutationVariables>;
export const DeleteAccountDocument = gql`
    mutation DeleteAccount($input: DeleteAccountInput!) {
  deleteAccount(params: $input) {
    message
  }
}
    `;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteAccountMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
      }
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<DeleteAccountMutation, DeleteAccountMutationVariables>;
export const GenerateResetPasswordTokenDocument = gql`
    mutation GenerateResetPasswordToken($input: GenerateResetPasswordTokenInput!) {
  generateResetPasswordToken(params: $input) {
    resetToken
  }
}
    `;
export type GenerateResetPasswordTokenMutationFn = Apollo.MutationFunction<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>;

/**
 * __useGenerateResetPasswordTokenMutation__
 *
 * To run a mutation, you first call `useGenerateResetPasswordTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateResetPasswordTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateResetPasswordTokenMutation, { data, loading, error }] = useGenerateResetPasswordTokenMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGenerateResetPasswordTokenMutation(baseOptions?: Apollo.MutationHookOptions<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>(GenerateResetPasswordTokenDocument, options);
      }
export type GenerateResetPasswordTokenMutationHookResult = ReturnType<typeof useGenerateResetPasswordTokenMutation>;
export type GenerateResetPasswordTokenMutationResult = Apollo.MutationResult<GenerateResetPasswordTokenMutation>;
export type GenerateResetPasswordTokenMutationOptions = Apollo.BaseMutationOptions<GenerateResetPasswordTokenMutation, GenerateResetPasswordTokenMutationVariables>;
export const GoogleLoginDocument = gql`
    mutation GoogleLogin($input: GoogleLoginInput!) {
  googleLogin(params: $input) {
    token: accessToken
    message
    type
    id
  }
}
    `;
export type GoogleLoginMutationFn = Apollo.MutationFunction<GoogleLoginMutation, GoogleLoginMutationVariables>;

/**
 * __useGoogleLoginMutation__
 *
 * To run a mutation, you first call `useGoogleLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGoogleLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [googleLoginMutation, { data, loading, error }] = useGoogleLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGoogleLoginMutation(baseOptions?: Apollo.MutationHookOptions<GoogleLoginMutation, GoogleLoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GoogleLoginMutation, GoogleLoginMutationVariables>(GoogleLoginDocument, options);
      }
export type GoogleLoginMutationHookResult = ReturnType<typeof useGoogleLoginMutation>;
export type GoogleLoginMutationResult = Apollo.MutationResult<GoogleLoginMutation>;
export type GoogleLoginMutationOptions = Apollo.BaseMutationOptions<GoogleLoginMutation, GoogleLoginMutationVariables>;
export const LikeEntityDocument = gql`
    mutation LikeEntity($input: likes_insert_input!) {
  insert_likes_one(object: $input) {
    id
    author_id
  }
}
    `;
export type LikeEntityMutationFn = Apollo.MutationFunction<LikeEntityMutation, LikeEntityMutationVariables>;

/**
 * __useLikeEntityMutation__
 *
 * To run a mutation, you first call `useLikeEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLikeEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [likeEntityMutation, { data, loading, error }] = useLikeEntityMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLikeEntityMutation(baseOptions?: Apollo.MutationHookOptions<LikeEntityMutation, LikeEntityMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LikeEntityMutation, LikeEntityMutationVariables>(LikeEntityDocument, options);
      }
export type LikeEntityMutationHookResult = ReturnType<typeof useLikeEntityMutation>;
export type LikeEntityMutationResult = Apollo.MutationResult<LikeEntityMutation>;
export type LikeEntityMutationOptions = Apollo.BaseMutationOptions<LikeEntityMutation, LikeEntityMutationVariables>;
export const LoginDocument = gql`
    mutation Login($input: LoginInput!) {
  login(params: $input) {
    accessToken
    message
    type
    id
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const OffRampCeloDocument = gql`
    mutation OffRampCelo($params: SendToCeloInput!) {
  sendToCelo(params: $params) {
    message
    walletBalance {
      balance
      debitBalance
    }
  }
}
    `;
export type OffRampCeloMutationFn = Apollo.MutationFunction<OffRampCeloMutation, OffRampCeloMutationVariables>;

/**
 * __useOffRampCeloMutation__
 *
 * To run a mutation, you first call `useOffRampCeloMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useOffRampCeloMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [offRampCeloMutation, { data, loading, error }] = useOffRampCeloMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useOffRampCeloMutation(baseOptions?: Apollo.MutationHookOptions<OffRampCeloMutation, OffRampCeloMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<OffRampCeloMutation, OffRampCeloMutationVariables>(OffRampCeloDocument, options);
      }
export type OffRampCeloMutationHookResult = ReturnType<typeof useOffRampCeloMutation>;
export type OffRampCeloMutationResult = Apollo.MutationResult<OffRampCeloMutation>;
export type OffRampCeloMutationOptions = Apollo.BaseMutationOptions<OffRampCeloMutation, OffRampCeloMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($input: ResetPasswordInput!) {
  resetPassword(params: $input) {
    message
  }
}
    `;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const SignUpDocument = gql`
    mutation SignUp($input: SignupInput!) {
  signup(params: $input) {
    data
    message
    type
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const TopUpCowrieDocument = gql`
    mutation TopUpCowrie($params: TopUpJiweWalletInput!) {
  topUpJiweWallet(params: $params) {
    message
    walletBalance {
      balance
    }
  }
}
    `;
export type TopUpCowrieMutationFn = Apollo.MutationFunction<TopUpCowrieMutation, TopUpCowrieMutationVariables>;

/**
 * __useTopUpCowrieMutation__
 *
 * To run a mutation, you first call `useTopUpCowrieMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTopUpCowrieMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [topUpCowrieMutation, { data, loading, error }] = useTopUpCowrieMutation({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useTopUpCowrieMutation(baseOptions?: Apollo.MutationHookOptions<TopUpCowrieMutation, TopUpCowrieMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TopUpCowrieMutation, TopUpCowrieMutationVariables>(TopUpCowrieDocument, options);
      }
export type TopUpCowrieMutationHookResult = ReturnType<typeof useTopUpCowrieMutation>;
export type TopUpCowrieMutationResult = Apollo.MutationResult<TopUpCowrieMutation>;
export type TopUpCowrieMutationOptions = Apollo.BaseMutationOptions<TopUpCowrieMutation, TopUpCowrieMutationVariables>;
export const UpdateProfileDocument = gql`
    mutation UpdateProfile($id: uuid!, $input: profiles_set_input!) {
  update_profiles(_set: $input, where: {id: {_eq: $id}}) {
    returning {
      __typename
      id
    }
  }
}
    `;
export type UpdateProfileMutationFn = Apollo.MutationFunction<UpdateProfileMutation, UpdateProfileMutationVariables>;

/**
 * __useUpdateProfileMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMutation, { data, loading, error }] = useUpdateProfileMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMutation, UpdateProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMutation, UpdateProfileMutationVariables>(UpdateProfileDocument, options);
      }
export type UpdateProfileMutationHookResult = ReturnType<typeof useUpdateProfileMutation>;
export type UpdateProfileMutationResult = Apollo.MutationResult<UpdateProfileMutation>;
export type UpdateProfileMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMutation, UpdateProfileMutationVariables>;
export const UpdateProfileMetadataDocument = gql`
    mutation UpdateProfileMetadata($profileId: uuid!, $metadata: jsonb!) {
  update_profiles_by_pk(
    pk_columns: {id: $profileId}
    _append: {metadata: $metadata}
  ) {
    id
  }
}
    `;
export type UpdateProfileMetadataMutationFn = Apollo.MutationFunction<UpdateProfileMetadataMutation, UpdateProfileMetadataMutationVariables>;

/**
 * __useUpdateProfileMetadataMutation__
 *
 * To run a mutation, you first call `useUpdateProfileMetadataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProfileMetadataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProfileMetadataMutation, { data, loading, error }] = useUpdateProfileMetadataMutation({
 *   variables: {
 *      profileId: // value for 'profileId'
 *      metadata: // value for 'metadata'
 *   },
 * });
 */
export function useUpdateProfileMetadataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProfileMetadataMutation, UpdateProfileMetadataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProfileMetadataMutation, UpdateProfileMetadataMutationVariables>(UpdateProfileMetadataDocument, options);
      }
export type UpdateProfileMetadataMutationHookResult = ReturnType<typeof useUpdateProfileMetadataMutation>;
export type UpdateProfileMetadataMutationResult = Apollo.MutationResult<UpdateProfileMetadataMutation>;
export type UpdateProfileMetadataMutationOptions = Apollo.BaseMutationOptions<UpdateProfileMetadataMutation, UpdateProfileMetadataMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($id: uuid!, $input: users_set_input!) {
  update_users(_set: $input, where: {id: {_eq: $id}}) {
    returning {
      __typename
      ...user
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const GetEntityLikesCountDocument = gql`
    subscription GetEntityLikesCount($target_id: uuid!) {
  likes: likes_aggregate(where: {target_id: {_eq: $target_id}}) {
    aggregate {
      count
    }
  }
}
    `;

/**
 * __useGetEntityLikesCountSubscription__
 *
 * To run a query within a React component, call `useGetEntityLikesCountSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetEntityLikesCountSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEntityLikesCountSubscription({
 *   variables: {
 *      target_id: // value for 'target_id'
 *   },
 * });
 */
export function useGetEntityLikesCountSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetEntityLikesCountSubscription, GetEntityLikesCountSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetEntityLikesCountSubscription, GetEntityLikesCountSubscriptionVariables>(GetEntityLikesCountDocument, options);
      }
export type GetEntityLikesCountSubscriptionHookResult = ReturnType<typeof useGetEntityLikesCountSubscription>;
export type GetEntityLikesCountSubscriptionResult = Apollo.SubscriptionResult<GetEntityLikesCountSubscription>;
export const GetAllFlagsDocument = gql`
    query GetAllFlags {
  flags {
    ...flag
  }
}
    ${FlagFragmentDoc}`;

/**
 * __useGetAllFlagsQuery__
 *
 * To run a query within a React component, call `useGetAllFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllFlagsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllFlagsQuery(baseOptions?: Apollo.QueryHookOptions<GetAllFlagsQuery, GetAllFlagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllFlagsQuery, GetAllFlagsQueryVariables>(GetAllFlagsDocument, options);
      }
export function useGetAllFlagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllFlagsQuery, GetAllFlagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllFlagsQuery, GetAllFlagsQueryVariables>(GetAllFlagsDocument, options);
        }
export type GetAllFlagsQueryHookResult = ReturnType<typeof useGetAllFlagsQuery>;
export type GetAllFlagsLazyQueryHookResult = ReturnType<typeof useGetAllFlagsLazyQuery>;
export type GetAllFlagsQueryResult = Apollo.QueryResult<GetAllFlagsQuery, GetAllFlagsQueryVariables>;
export const GetAllGamesDocument = gql`
    query GetAllGames {
  games {
    ...game
    author {
      ...user
    }
    ...gameAttrs
  }
}
    ${GameFragmentDoc}
${UserFragmentDoc}
${GameAttrsFragmentDoc}`;

/**
 * __useGetAllGamesQuery__
 *
 * To run a query within a React component, call `useGetAllGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllGamesQuery(baseOptions?: Apollo.QueryHookOptions<GetAllGamesQuery, GetAllGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAllGamesQuery, GetAllGamesQueryVariables>(GetAllGamesDocument, options);
      }
export function useGetAllGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAllGamesQuery, GetAllGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAllGamesQuery, GetAllGamesQueryVariables>(GetAllGamesDocument, options);
        }
export type GetAllGamesQueryHookResult = ReturnType<typeof useGetAllGamesQuery>;
export type GetAllGamesLazyQueryHookResult = ReturnType<typeof useGetAllGamesLazyQuery>;
export type GetAllGamesQueryResult = Apollo.QueryResult<GetAllGamesQuery, GetAllGamesQueryVariables>;
export const GetMyAppsDocument = gql`
    query GetMyApps {
  third_party_apps {
    id
    client_name
    client_secret
    metadata
    is_active
    created_at
  }
}
    `;

/**
 * __useGetMyAppsQuery__
 *
 * To run a query within a React component, call `useGetMyAppsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyAppsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyAppsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMyAppsQuery(baseOptions?: Apollo.QueryHookOptions<GetMyAppsQuery, GetMyAppsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyAppsQuery, GetMyAppsQueryVariables>(GetMyAppsDocument, options);
      }
export function useGetMyAppsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyAppsQuery, GetMyAppsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyAppsQuery, GetMyAppsQueryVariables>(GetMyAppsDocument, options);
        }
export type GetMyAppsQueryHookResult = ReturnType<typeof useGetMyAppsQuery>;
export type GetMyAppsLazyQueryHookResult = ReturnType<typeof useGetMyAppsLazyQuery>;
export type GetMyAppsQueryResult = Apollo.QueryResult<GetMyAppsQuery, GetMyAppsQueryVariables>;
export const GetCeloWalletBalanceDocument = gql`
    query GetCeloWalletBalance($params: CeloWalletBalanceInput!) {
  getCeloBalance(params: $params) {
    cUSDBalance
    celoBalance
    cEURBalance
    celoWalletAddress
    testMode
  }
}
    `;

/**
 * __useGetCeloWalletBalanceQuery__
 *
 * To run a query within a React component, call `useGetCeloWalletBalanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCeloWalletBalanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCeloWalletBalanceQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetCeloWalletBalanceQuery(baseOptions: Apollo.QueryHookOptions<GetCeloWalletBalanceQuery, GetCeloWalletBalanceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCeloWalletBalanceQuery, GetCeloWalletBalanceQueryVariables>(GetCeloWalletBalanceDocument, options);
      }
export function useGetCeloWalletBalanceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCeloWalletBalanceQuery, GetCeloWalletBalanceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCeloWalletBalanceQuery, GetCeloWalletBalanceQueryVariables>(GetCeloWalletBalanceDocument, options);
        }
export type GetCeloWalletBalanceQueryHookResult = ReturnType<typeof useGetCeloWalletBalanceQuery>;
export type GetCeloWalletBalanceLazyQueryHookResult = ReturnType<typeof useGetCeloWalletBalanceLazyQuery>;
export type GetCeloWalletBalanceQueryResult = Apollo.QueryResult<GetCeloWalletBalanceQuery, GetCeloWalletBalanceQueryVariables>;
export const GetFeaturedGamesDocument = gql`
    query GetFeaturedGames {
  featured_games(where: {is_active: {_eq: true}}) {
    __typename
    id
    game {
      ...game
      game_serving_points {
        id
        serving_point {
          id
          name
          value
        }
      }
    }
    id
    updated_at
    created_at
  }
}
    ${GameFragmentDoc}`;

/**
 * __useGetFeaturedGamesQuery__
 *
 * To run a query within a React component, call `useGetFeaturedGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFeaturedGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFeaturedGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFeaturedGamesQuery(baseOptions?: Apollo.QueryHookOptions<GetFeaturedGamesQuery, GetFeaturedGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFeaturedGamesQuery, GetFeaturedGamesQueryVariables>(GetFeaturedGamesDocument, options);
      }
export function useGetFeaturedGamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFeaturedGamesQuery, GetFeaturedGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFeaturedGamesQuery, GetFeaturedGamesQueryVariables>(GetFeaturedGamesDocument, options);
        }
export type GetFeaturedGamesQueryHookResult = ReturnType<typeof useGetFeaturedGamesQuery>;
export type GetFeaturedGamesLazyQueryHookResult = ReturnType<typeof useGetFeaturedGamesLazyQuery>;
export type GetFeaturedGamesQueryResult = Apollo.QueryResult<GetFeaturedGamesQuery, GetFeaturedGamesQueryVariables>;
export const GetGameDocument = gql`
    query GetGame($id: uuid!) {
  game: games_by_pk(id: $id) {
    ...game
    game_serving_points {
      id
      serving_point {
        id
        name
        value
      }
    }
    file {
      id
      path
    }
    author_id
    author {
      ...user
    }
    ...gameAttrs
  }
}
    ${GameFragmentDoc}
${UserFragmentDoc}
${GameAttrsFragmentDoc}`;

/**
 * __useGetGameQuery__
 *
 * To run a query within a React component, call `useGetGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameQuery(baseOptions: Apollo.QueryHookOptions<GetGameQuery, GetGameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, options);
      }
export function useGetGameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameQuery, GetGameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, options);
        }
export type GetGameQueryHookResult = ReturnType<typeof useGetGameQuery>;
export type GetGameLazyQueryHookResult = ReturnType<typeof useGetGameLazyQuery>;
export type GetGameQueryResult = Apollo.QueryResult<GetGameQuery, GetGameQueryVariables>;
export const GetGameRatingsDocument = gql`
    subscription GetGameRatings($game_id: uuid!) {
  game_ratings(where: {game_id: {_eq: $game_id}}) {
    id
    rating
    additional_ratings
    comment {
      ...comment
      author: user {
        ...user
      }
    }
    created_at
  }
}
    ${CommentFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useGetGameRatingsSubscription__
 *
 * To run a query within a React component, call `useGetGameRatingsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetGameRatingsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameRatingsSubscription({
 *   variables: {
 *      game_id: // value for 'game_id'
 *   },
 * });
 */
export function useGetGameRatingsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetGameRatingsSubscription, GetGameRatingsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetGameRatingsSubscription, GetGameRatingsSubscriptionVariables>(GetGameRatingsDocument, options);
      }
export type GetGameRatingsSubscriptionHookResult = ReturnType<typeof useGetGameRatingsSubscription>;
export type GetGameRatingsSubscriptionResult = Apollo.SubscriptionResult<GetGameRatingsSubscription>;
export const GetGameRatingsQueryDocument = gql`
    query GetGameRatingsQuery($game_id: uuid!) {
  game_ratings(where: {game_id: {_eq: $game_id}}) {
    id
    rating
    additional_ratings
    comment {
      ...comment
      author: user {
        ...user
      }
    }
    created_at
  }
}
    ${CommentFragmentDoc}
${UserFragmentDoc}`;

/**
 * __useGetGameRatingsQueryQuery__
 *
 * To run a query within a React component, call `useGetGameRatingsQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameRatingsQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameRatingsQueryQuery({
 *   variables: {
 *      game_id: // value for 'game_id'
 *   },
 * });
 */
export function useGetGameRatingsQueryQuery(baseOptions: Apollo.QueryHookOptions<GetGameRatingsQueryQuery, GetGameRatingsQueryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGameRatingsQueryQuery, GetGameRatingsQueryQueryVariables>(GetGameRatingsQueryDocument, options);
      }
export function useGetGameRatingsQueryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGameRatingsQueryQuery, GetGameRatingsQueryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGameRatingsQueryQuery, GetGameRatingsQueryQueryVariables>(GetGameRatingsQueryDocument, options);
        }
export type GetGameRatingsQueryQueryHookResult = ReturnType<typeof useGetGameRatingsQueryQuery>;
export type GetGameRatingsQueryLazyQueryHookResult = ReturnType<typeof useGetGameRatingsQueryLazyQuery>;
export type GetGameRatingsQueryQueryResult = Apollo.QueryResult<GetGameRatingsQueryQuery, GetGameRatingsQueryQueryVariables>;
export const GetGamesByGenreDocument = gql`
    query GetGamesByGenre($genreId: uuid!) {
  game_genres(where: {tag_id: {_eq: $genreId}}) {
    id
    game {
      ...game
      game_serving_points {
        id
        serving_point {
          id
          name
          value
        }
      }
      file {
        id
        path
      }
      author_id
      ...gameAttrs
    }
  }
}
    ${GameFragmentDoc}
${GameAttrsFragmentDoc}`;

/**
 * __useGetGamesByGenreQuery__
 *
 * To run a query within a React component, call `useGetGamesByGenreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesByGenreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesByGenreQuery({
 *   variables: {
 *      genreId: // value for 'genreId'
 *   },
 * });
 */
export function useGetGamesByGenreQuery(baseOptions: Apollo.QueryHookOptions<GetGamesByGenreQuery, GetGamesByGenreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGamesByGenreQuery, GetGamesByGenreQueryVariables>(GetGamesByGenreDocument, options);
      }
export function useGetGamesByGenreLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGamesByGenreQuery, GetGamesByGenreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGamesByGenreQuery, GetGamesByGenreQueryVariables>(GetGamesByGenreDocument, options);
        }
export type GetGamesByGenreQueryHookResult = ReturnType<typeof useGetGamesByGenreQuery>;
export type GetGamesByGenreLazyQueryHookResult = ReturnType<typeof useGetGamesByGenreLazyQuery>;
export type GetGamesByGenreQueryResult = Apollo.QueryResult<GetGamesByGenreQuery, GetGamesByGenreQueryVariables>;
export const GetGenresDocument = gql`
    query GetGenres {
  genres: tags {
    id
    name
    value
    description
  }
}
    `;

/**
 * __useGetGenresQuery__
 *
 * To run a query within a React component, call `useGetGenresQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGenresQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGenresQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGenresQuery(baseOptions?: Apollo.QueryHookOptions<GetGenresQuery, GetGenresQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGenresQuery, GetGenresQueryVariables>(GetGenresDocument, options);
      }
export function useGetGenresLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGenresQuery, GetGenresQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGenresQuery, GetGenresQueryVariables>(GetGenresDocument, options);
        }
export type GetGenresQueryHookResult = ReturnType<typeof useGetGenresQuery>;
export type GetGenresLazyQueryHookResult = ReturnType<typeof useGetGenresLazyQuery>;
export type GetGenresQueryResult = Apollo.QueryResult<GetGenresQuery, GetGenresQueryVariables>;
export const GetMeDocument = gql`
    query GetMe {
  me {
    id
    avatar
    name
    username
    email
    phone_number
    locale
    location
    profile_id
  }
}
    `;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
      }
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
        }
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetNotificationsDocument = gql`
    subscription GetNotifications($user_id: uuid!) {
  notifications(
    where: {notification_recipients: {channel: {channel_members: {user_id: {_eq: $user_id}}}}}
    order_by: {created_at: desc}
  ) {
    id
    created_at
    message
    sender {
      id
      name
      username
      avatar
    }
  }
}
    `;

/**
 * __useGetNotificationsSubscription__
 *
 * To run a query within a React component, call `useGetNotificationsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useGetNotificationsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNotificationsSubscription({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useGetNotificationsSubscription(baseOptions: Apollo.SubscriptionHookOptions<GetNotificationsSubscription, GetNotificationsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<GetNotificationsSubscription, GetNotificationsSubscriptionVariables>(GetNotificationsDocument, options);
      }
export type GetNotificationsSubscriptionHookResult = ReturnType<typeof useGetNotificationsSubscription>;
export type GetNotificationsSubscriptionResult = Apollo.SubscriptionResult<GetNotificationsSubscription>;
export const GetPageDocument = gql`
    query GetPage($slug: String!) {
  pages(where: {slug: {_eq: $slug}}) {
    id
    slug
    title
    poster
    description
    metadata
    created_at
    updated_at
  }
}
    `;

/**
 * __useGetPageQuery__
 *
 * To run a query within a React component, call `useGetPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPageQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useGetPageQuery(baseOptions: Apollo.QueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
      }
export function useGetPageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPageQuery, GetPageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPageQuery, GetPageQueryVariables>(GetPageDocument, options);
        }
export type GetPageQueryHookResult = ReturnType<typeof useGetPageQuery>;
export type GetPageLazyQueryHookResult = ReturnType<typeof useGetPageLazyQuery>;
export type GetPageQueryResult = Apollo.QueryResult<GetPageQuery, GetPageQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile($profileId: uuid!) {
  profiles_by_pk(id: $profileId) {
    id
    name
    nickname
    bio
    avatar
    locale
    location
    esports_id
    metadata
  }
}
    `;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      profileId: // value for 'profileId'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const GetUserDocument = gql`
    query GetUser($id: uuid!) {
  user: users_by_pk(id: $id) {
    ...user
  }
}
    ${UserFragmentDoc}`;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserQuery(baseOptions: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUsersDocument = gql`
    query GetUsers($where: users_bool_exp) {
  users(where: $where) {
    ...user
    games {
      ...game
      game_serving_points {
        id
        serving_point {
          id
          name
          value
        }
      }
      file {
        id
        path
      }
      game_posters: game_files(where: {file: {file_type: {_eq: "game_poster"}}}) {
        id
        file {
          id
          path
        }
      }
      author_id
    }
  }
}
    ${UserFragmentDoc}
${GameFragmentDoc}`;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetWalletTransactionsDocument = gql`
    query GetWalletTransactions($params: FetchWalletTransactionsInput!) {
  fetchWalletTransactions(params: $params) {
    transactions
  }
  getWalletBalance(params: $params) {
    balance
    creditBalance
    debitBalance
    creditTotal
    debitTotal
    transactionCount
  }
}
    `;

/**
 * __useGetWalletTransactionsQuery__
 *
 * To run a query within a React component, call `useGetWalletTransactionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWalletTransactionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWalletTransactionsQuery({
 *   variables: {
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetWalletTransactionsQuery(baseOptions: Apollo.QueryHookOptions<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>(GetWalletTransactionsDocument, options);
      }
export function useGetWalletTransactionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>(GetWalletTransactionsDocument, options);
        }
export type GetWalletTransactionsQueryHookResult = ReturnType<typeof useGetWalletTransactionsQuery>;
export type GetWalletTransactionsLazyQueryHookResult = ReturnType<typeof useGetWalletTransactionsLazyQuery>;
export type GetWalletTransactionsQueryResult = Apollo.QueryResult<GetWalletTransactionsQuery, GetWalletTransactionsQueryVariables>;

      export interface PossibleTypesResultData {
        possibleTypes: {
          [key: string]: string[]
        }
      }
      const result: PossibleTypesResultData = {
  "possibleTypes": {}
};
      export default result;
    