import { RegularButton } from "components/buttons/button";
import { useState } from "react";
import Modal from "react-modal";
import { GetProfileDocument, useCreateCowrieWalletMutation } from "@graphql";
import * as toast from "./toast";
import styled from "@emotion/styled";

const ContentWrapper = styled.div`
  padding: 3rem 0;
  width: 90%;
  margin: auto;

  h1 {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  button {
    display: block;
    margin: 1rem auto;
    cursor: pointer;
  }
`;

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    width: "550px",
    height: "250px",
    marginRight: "-50%",
    borderRadius: "1rem",
    transform: "translate(-50%, -50%)",
  },
};

const NoJiweWallet: React.FC<{ profileId: string }> = ({ profileId }) => {
  const [modalIsOpen, setIsOpen] = useState(true);
  const [createWallet] = useCreateCowrieWalletMutation();

  const handleCreateWallet = async () => {
    await createWallet({
      onCompleted: () => {
        toast.success("Wallet created successfully");
        setTimeout(() => {
          document.location.reload();
        }, 200);
      },
      refetchQueries: [
        {
          query: GetProfileDocument,
          variables: {
            profileId,
          },
        },
      ],
    });
  };

  return (
    <Modal
      isOpen={modalIsOpen}
      onRequestClose={() => setIsOpen(false)}
      style={customStyles}
      contentLabel='Example Modal'
    >
      <ContentWrapper>
        <h1>Setup your Jiwe wallet</h1>
        <RegularButton onClick={() => handleCreateWallet()}>
          Okay!
        </RegularButton>
      </ContentWrapper>
    </Modal>
  );
};

export default NoJiweWallet;
