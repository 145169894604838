import React, { useContext, useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";

import Footer from "./Footer";
import Header from "./Header";
import useAuth from "pages/auth/useAuth";
import { LoggedInUserContext } from "contexts/user";
import { Helmet } from "react-helmet";
import styled from "@emotion/styled";

const EarlyAccessPageContainer = styled.div`
  * {
    color: #fff;
  }

  h3 {
    font-weight: bold;
  }

  .container {
    max-width: 1300px;
    margin: 0 auto;
    padding: 0 30px;
  }

  .back-img {
    height: 2500px;
    background: url(/bg-details.png);
  }

  .hero-section {
    width: 100%;
    margin-top: 130px;
  }

  .hero-section .container {
    text-align: center;
    margin-top: 20px;
  }

  .hero-section h1 {
    color: #ccdfff;
    font-weight: bold;
  }

  .hero-section p {
    margin-top: 20px;
  }

  .main-section {
    width: 100%;
  }

  .main-section .container {
    margin-top: 150px;
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .main-child {
    margin-bottom: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .content-img {
    background: rgba(141, 157, 192, 0.41);
    border: 2px solid #ffffff;
    filter: blur(0.5px);
    border-radius: 19px;
    width: 300px;
    display: flex;
    padding: 10px;
  }

  .content-img img {
    width: 270px;
    margin: 0 auto;
    align-items: center;
  }

  .content-text {
    width: 50%;
  }

  .content-text p {
    margin-top: 10px;
  }

  .gas-logo {
    margin-left: 250px;
  }

  .footer-section {
    width: 100%;
  }

  .footer-section .container {
    width: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .footer-section .container h3 {
    margin-top: 20px;
  }

  .partner-logos {
    margin-top: 50px;
    margin-bottom: 50px;
    display: flex;
  }

  .partner-logos img {
    margin-left: 20px;
    width: 100px;
  }

  @media (max-width: 30em) {
    /* HERO SECTION */
    .hero-section {
      width: 100%;
      margin-top: 130px;
    }
    .hero-section h1 {
      margin-bottom: 15px;
    }
    /* CONTENT SECTION */
    .main-section .container {
      margin-top: 100px;
      width: 70%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .main-child {
      margin-bottom: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
    }
    .reverese {
      display: flex;
      flex-direction: column-reverse;
    }
    .content-img {
      border-radius: 15px;
      width: 200px;
    }
    .content-img img {
      width: 170px;
    }
    .content-text {
      margin-top: 20px;
      width: 100%;
      text-align: center;
    }
    .gas-logo {
      margin-left: 0px;
    }
    /* FOOTER SECTION */
    .footer-section .container h1 {
      font-size: 20px;
      text-align: center;
    }
    .footer-section .container h3 {
      margin-top: 20px;
      font-size: 15px;
    }
    .partner-logos {
      margin-top: 50px;
      margin-bottom: 50px;
    }
    .partner-logos img {
      margin-left: 20px;
      width: 100px;
    }
  }
`;

const EarlyAccessPage: React.FC<RouteComponentProps> = ({ history }) => {
  const { isAuthenticated } = useAuth();
  const loggedInUser = useContext(LoggedInUserContext);

  useEffect(() => {
    document.getElementsByTagName("body")[0].className = "bg-black";

    return () => {
      document.getElementsByTagName("body")[0].className = "";
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Homepage | Jiwe IO</title>
      </Helmet>
      <Header
        isAuthenticated={isAuthenticated}
        userId={loggedInUser?.id}
        floated
        isHomeHeader={true}
      />
      <EarlyAccessPageContainer>
        <section className='back-img'>
          <section className='hero-section'>
            <div className='container'>
              <h1>
                Join The Future Of Gaming with Jiwe Wallet API Early Access
              </h1>
              <p>Leverage Web 3.0 technology to monetize your game faster</p>
            </div>
          </section>
          <section className='main-section'>
            <div className='container'>
              <div className='main-child reverese'>
                <div className='content-text'>
                  <h3>Simple yet Powerful APIs for Game Developers</h3>
                  <p>
                    The easiest way to add rewards to your game. Jiwe takes care
                    of wallet creation, transactions, and gas fees under the
                    hood.
                  </p>
                </div>
                <div className='content-img'>
                  <img src='/api-img.png' alt='' />
                </div>
              </div>
              <div className='main-child'>
                <div className='content-img'>
                  <img src='/celo-img.png' alt='' />
                </div>
                <div className='content-text'>
                  <h3>Built on the Celo Blockchain</h3>
                  <p>
                    We have built on the Celo blockchain so all payouts and
                    top-ups to your wallet can easily be withdrawn to Mpesa
                    through Kotanipay.
                  </p>
                </div>
              </div>
              <div className='main-child reverese'>
                <div className='content-text'>
                  <h3>On-board gamers with no friction</h3>
                  <p>
                    Once you integrate the Jiwe Wallet SDK, users can sign in
                    with their Jiwe IO account to purchase assets and earn
                    rewards with no friction
                  </p>
                </div>
                <div className='content-img'>
                  <img src='/gaming-img.png' alt='' />
                </div>
              </div>
              <div className='main-child'>
                <div className='content-img'>
                  <img src='/game-dev-img.png' alt='' />
                </div>
                <div className='content-text'>
                  <h3>Manage your game</h3>
                  <p>
                    Create, and manage your API keys through your dashboard with
                    ease and track transactions per game
                  </p>
                </div>
              </div>
              <div className='main-child reverese'>
                <div className='content-text'>
                  <h3>Gas fees</h3>
                  <p>No gas fees while we are in beta</p>
                </div>
                <div className='content-img gas-logo'>
                  <img src='/gas-logo.png' alt='' />
                </div>
              </div>
            </div>
          </section>
          <section className='footer-section'>
            <div className='container'>
              <h1>
                Level up your game and start earning directly and building
                in-game economies!
              </h1>
              <h3>Ready to take the best next step?</h3>
              <div className='partner-logos'>
                <img src='/unity-logo.png' alt='' />
                <img src='unreal-logo.png' alt='' />
              </div>
              <h3>Reach out to us to join the program</h3>
              <h3 style={{ marginBottom: "20px" }}>rock@jiwe.io</h3>
            </div>
          </section>
        </section>
      </EarlyAccessPageContainer>
    </>
  );
};

export default EarlyAccessPage;
