import { Link, Redirect } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useLoginMutation } from "@graphql";
import useAuth from "pages/auth/useAuth";
import { createClient } from "lib/apollo";
import {
  LoginContainer,
  Left,
  Right,
  Welcome,
  LoginTitle,
  Signup,
} from "./components";
import LoginForm from "./LoginForm";

const loginClient = createClient(true)

export default function Login(props: any) {
  const { isAuthenticated, login } = useAuth();

  const [loginMutation, { loading }] = useLoginMutation({
    onCompleted: (res) => {
      if (res?.login?.type === "SUCCESS") {
        login({
          partyId: res?.login?.id,
          accessToken: res?.login?.accessToken,
          next: props.location.state ? props.location.state.from.pathname : '/'
        });
      }
    },
    onError: (error) => {
      console.error({
        description: error.message,
        variant: "error",
      });
    },
    client: loginClient
  });

  const handleSubmit = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    await loginMutation({
      variables: {
        input: { usernameOrEmail: email, password },
      },
    });
  };

  return isAuthenticated ? (
    <Redirect
      to={{
        pathname: props.location.state
          ? props.location.state.from.pathname
          : "/",
        // state: { from: location },
      }}
    />
  ) : (
    <LoginContainer>
      <Helmet>
        <title>Login | Jiwe IO</title>
      </Helmet>
      <Left>
        <Welcome>
          <h3>Welcome back</h3>
        </Welcome>
      </Left>
      <Right>
        <LoginTitle>Log In</LoginTitle>
        <Signup>
          Do not have an account?{" "}
          <Link to='/signup' style={{ color: "blue", cursor: "pointer" }}>
            Sign up
          </Link>
        </Signup>
        <LoginForm handleSubmit={handleSubmit} loading={loading} />
      </Right>
    </LoginContainer>
  );
}
