import styled from "@emotion/styled";

export const SignupContainer = styled.div`
    display: flex;
    flex-direction: row;
    border: 3px solid rgb(0, 0, 0); 
    width: 100%;
    height: 100vh;
  
`;

export const Left = styled.div`
    flex: 1 1 0px;
    background: url("/login-bg.jpg") no-repeat;
    @media(max-width: 768px) {
        display: none;
    }
`;

export const Text = styled.div`
    color: white;
    margin-top: 90vh;
    margin-left: 15%;
    width: 150px;
`;

export const Right = styled.div`
    flex: 1 1 0px;
    background-color: #FFFF;
    padding: 40px;
    color: #636363;
`;

export const SignupTitle = styled.h1`
    font-size: 70px;
`;

export const Login = styled.span`
    color: #636363;
    font-size: 20px;
    margin-bottom: 10px;
`;

export const Label =  styled.h4`
    color: black;
    font-size: 20px;
    margin-bottom: 2%;
    width: 100%;
`;

export const Input = styled.input`
    width: 100%;
    border-radius: 1rem;
    height: 55px;
    padding: 10px;
    margin-bottom: 2%;
    border: 2px solid;
    
`;

export const Line = styled.div`
    width: 100%;
    height: 10px;
    margin-top: 5%;
    margin-bottom: 5%;
    border-top: 1px solid #E7E7E7;
`;