import {
  split,
  ApolloClient,
  InMemoryCache,
  HttpLink,
  NormalizedCacheObject,
} from "@apollo/client";
import { getMainDefinition } from "@apollo/client/utilities";
import { WebSocketLink } from "@apollo/client/link/ws";

/* Local */
import fragments from "@graphql/fragments";
import { storageKey } from "pages/auth/useAuth";

// ----------------------------------------------------------------------------

export const createClient = (noHeaders?: boolean): ApolloClient<NormalizedCacheObject> => {
  // Match up fragments
  const cache = new InMemoryCache({ possibleTypes: fragments.possibleTypes });

  let token;

  try {
    token = JSON.parse(localStorage.getItem(storageKey)!).accessToken;
  } catch (error) {
    token = undefined
  }

  const headers =
    Boolean(token) && !noHeaders
      ? {
          Authorization: `Bearer ${token}`,
        }
      : {};

  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL_URL,
    headers: {
      "content-type": "application/json",
      ...headers,
    },
  });

  const wsLink = new WebSocketLink({
    uri: process.env.REACT_APP_GRAPHQL_URL?.replace(/^https?/, "wss") || "",
    options: {
      reconnect: true,
      lazy: true,
      connectionParams: {
        headers: {
          "content-type": "application/json",
          ...headers,
        },
      },
    },
  });

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  return new ApolloClient({
    link: splitLink,
    cache,
    defaultOptions: { watchQuery: { fetchPolicy: "cache-and-network" } },
  });
};
