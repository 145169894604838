export function countDecimals(num: number) {
  let text = num.toString();
  if (text.indexOf("e-") > -1) {
    let [base, trail] = text.split("e-");
    let elen = parseInt(trail, 10);
    let idx = base.indexOf(".");
    return idx === -1 ? 0 + elen : base.length - idx - 1 + elen;
  }
  let index = text.indexOf(".");
  return index === -1 ? 0 : text.length - index - 1;
}

export function isJson(str: string) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}