import styled from "@emotion/styled";


export const Top = styled.div`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    // border: 5px solid;
    margin-bottom: 3%;
    @media(max-width: 1800px) {
        display: block;
        text-align: center;
    }
`;

export const Title = styled.h2`
    font-size: 30px;
    font-weight: bold;
    @media(max-width: 1000px) {
        font-size: 20px
        transition: 3s;
    }
`;

export const Label = styled.h4`
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 1%;
    color: black;

`;

export const Row = styled.div`
    /* first breakpoint*/
    --w1:100%;
    --n:2;
    /* second breakpoint*/
    --w2:400px;
    --m:2;

    display:grid;
    grid-template-columns:
    repeat(auto-fill,
        minmax(clamp(clamp(
            100%/(var(--n) + 1) + 0.1%, 
        (var(--w1) - 100vw)*1000,
            100%/(var(--m) + 1) + 0.1%), 
        (var(--w2) - 100vw)*1000,
            100%), 1fr));
    gap:10px;
    @media(max-width: 1800px) {
        display: flex;
        flex-wrap: wrap;
        text-align: center;
        justify-content: center;
        margin-bottom: 2%;
    }
`;

export const Graph = styled.div`
    width: 45rem;
    height: 30rem;
    border: 3px solid #CECECE;
    border-radius: 1rem;
    @media(max-width: 1000px) {
        width: 90%;
        height: 300px;
    }

`;

export const Sections = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 50rem;
    @media(max-width: 1000px) {
        margin-bottom: 3%;
    }
`;

export const SubContainer = styled.div`
    width: 20rem;
    height: 14.5rem;
    padding: -3%;
    border: 3px solid #CECECE;
    border-radius: 1rem;
    margin-left: 3%;
    margin-bottom: 3%;
    // margin-top: 3.5%;
    @media(max-width: 1000px) {
        width: 45%;
        height: 200px;
        text-align: center;
    }
`;
export const CowrieShell = styled.img`
    width: 50px;
    height: 60px;
    margin-left: 2%;
    @media(max-width: 1000px) {
        width:  30px
        height: 10px;
    }
`;

export const Flex = styled.div`
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    color: black;
    margin-top: 5%;
    margin-bottom: 1%;
    // border: 2px solid red;
    align-items: center;
    font-size: 20px;
    @media(max-width: 1000px) {
        font-size: 15px
    }
`

export const Chartline = styled.div`
    font-size: 40px;
    @media(max-width: 1000px) {
        display: none
    }
`;

export const Large = styled.div`
    display: block;
    font-size: 70px;
    // border: 3px solid #E7E7E7;
    color: black;
    font-weight: bold;
    @media(max-width: 1000px) {
        font-size: 40px
    }
`;

export const Medium = styled.div`
    display: block;
    font-size: 30px;
    // border: 3px solid #E7E7E7;
    color: black;
    font-weight: bold;
    @media(max-width: 1000px) {
        font-size: 20px
    }

`;

export const GamerImage = styled.img`
    border-radius: 50%;
    width: 70px;
    @media(max-width: 1000px) {
       width: 50px;
    }
`;

export const Vertical = styled.h3`
    transform: rotate(-90deg);
    border: 3px solid red;
    width: 100px;
`;