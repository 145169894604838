import React, { useState } from "react";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { Field, Form, Formik } from "formik";

import { useLoginMutation, useResetPasswordMutation } from "@graphql";
import useAuth from "pages/auth/useAuth";
import {
  Left,
  LoginContainer,
  LoginTitle,
  Right,
  Welcome,
} from "pages/login/components";
import GenerateAuthTokenForm, {
  FormFieldWrapper,
} from "./GenerateAuthTokenForm";
import Message from "components/shared/Message";
import { RegularButton } from "components/buttons/button";

interface ResetPasswordFormValues {
  email: string;
  password: string;
  confirmPassword: string;
}

const initialValues = {
  email: "",
  password: "",
  confirmPassword: "",
};

const ResetPassword: React.FC<
  RouteComponentProps<{}, any, BaseLocationState>
> = ({ history }) => {
  const { login } = useAuth();
  const [message, setMessage] = useState<{
    text: string;
    success: boolean;
  } | null>(null);

  // Fetch generated token from query params
  const query = new URLSearchParams(useLocation().search);
  const token = query.get("token");

  // Mutations
  const [resetPasswordMutation, { loading }] = useResetPasswordMutation();
  const [
    loginMutation,
    { data: loginData, loading: loginLoading, error: loginError },
  ] = useLoginMutation();

  // Validation Util
  const eitherFieldIsEmpty = (values: ResetPasswordFormValues) =>
    values.password === "" || values.confirmPassword === "";

  const validate = (values: ResetPasswordFormValues) => {
    const errors: { confirmPassword?: string; password?: string } = {};
    if (values.confirmPassword && values.password) {
      if (values.password !== values.confirmPassword) {
        Object.assign(errors, { confirmPassword: "Password do not match" });
      }
      if (values.password.length < 6) {
        Object.assign(errors, { password: "Password too short" });
      }
    }
    return errors;
  };

  const onSubmit = (values: ResetPasswordFormValues) => {
    const isEmpty = eitherFieldIsEmpty(values);
    if (!isEmpty && token) {
      resetPasswordMutation({
        variables: {
          input: {
            usernameOrEmail: values.email,
            newPassword: values.password,
            token,
          },
        },
      })
        .then((res) => {
          console.log({ res });
          setMessage({ text: "Password updated successfully", success: true });
          return loginMutation({
            variables: {
              input: {
                usernameOrEmail: values.email,
                password: values.password,
              },
            },
          });
        })
        .catch((err) => {
          console.error(err);
          setMessage({
            text: loginError?.message || "Password updated failed",
            success: false,
          });
        });
    }
  };

  if (loginData?.login && loginData.login.id) {
    login({
      accessToken: loginData.login.accessToken,
      partyId: loginData.login.id,
    });
  }

  return (
    <LoginContainer>
      <Left>
        <Welcome>
          <h3>Reset password</h3>
        </Welcome>
      </Left>
      <Right style={{ overflowX: "scroll" }}>
        <LoginTitle>{token ? "Change Password" : "Reset Password"}</LoginTitle>
        {token ? (
          <FormFieldWrapper
            style={{ display: "flex", gap: "1rem", flexDirection: "column" }}
            className='change-password'
          >
            <Formik
              initialValues={initialValues}
              onSubmit={onSubmit}
              validate={validate}
            >
              {({ values, errors }) => (
                <Form>
                  {message && (
                    <Message message={message.text} success={message.success} />
                  )}
                  {errors && errors.password && (
                    <Message message={errors.password} success={false} />
                  )}
                  {errors && errors.confirmPassword && (
                    <Message message={errors.confirmPassword} success={false} />
                  )}
                  <Field
                    placeholder='Confirm your email address'
                    type='email'
                    name='email'
                    label='Email'
                    className='input-box'
                  />
                  <br />
                  <Field
                    placeholder='New password'
                    type='password'
                    name='password'
                    label='New password'
                    className='input-box'
                  />
                  <br />
                  <Field
                    placeholder='Confirm password'
                    type='password'
                    name='confirmPassword'
                    label='Confirm password'
                    className='input-box'
                  />
                  <br />
                  <RegularButton
                    type='submit'
                    disabled={
                      loading || loginLoading || eitherFieldIsEmpty(values)
                    }
                  >
                    Submit New Password
                  </RegularButton>
                </Form>
              )}
            </Formik>
          </FormFieldWrapper>
        ) : (
          <>
            <GenerateAuthTokenForm />
          </>
        )}
      </Right>
    </LoginContainer>
  );
};

export default ResetPassword;
