import { useState } from "react";
import CategoryList from "../../components/CategoryList/CategoryList";
import { Game } from "../../types";
import {
  CategorylistContainer,
  GamesContainer,
  Image,
  GameDetail,
  GameName,
  GameCategory,
} from "../../components/gameslist/components";
import {
  GetGamesByGenreQuery,
  useGetAllGamesQuery,
  useGetGamesByGenreLazyQuery,
  useGetGenresQuery,
} from "@graphql";
import { Link } from "react-router-dom";
import useAuth from "pages/auth/useAuth";
import { Helmet } from "react-helmet";

export default function GamesList() {
  const { logout } = useAuth();
  const [selected, setSelected] = useState("all");
  const { data, loading, error } = useGetAllGamesQuery({
    fetchPolicy: "cache-first",
  });
  const { data: genres, loading: gettingGenres } = useGetGenresQuery({
    fetchPolicy: "cache-first",
  });
  const [getGamesByGenre, { data: filteredGames }] =
    useGetGamesByGenreLazyQuery({
      fetchPolicy: "cache-first"
    });

  if (error?.message === "Authentication hook unauthorized this request") {
    logout()
  }

  const list =
    !gettingGenres && genres
      ? genres.genres.map(({ id, value, name }) => ({ id, title: value, name }))
      : [];

  const handleGenreSelection = async (genreId: string) => {
    setSelected(genreId);
    if (genreId !== "all") {
      await getGamesByGenre({ variables: { genreId } });
    }
  };

  let games = loading
    ? []
    : data?.games?.filter((game) => game.game_posters?.length > 0);

  if (
    filteredGames &&
    filteredGames.game_genres.length > 0 &&
    selected !== "all"
  ) {
    games = filteredGames?.game_genres
      .map(({ game }) => game)
      .filter((game) => game.game_posters?.length > 0);
  }

  return (
    <>
      <Helmet>
        <title>Games | Jiwe IO</title>
      </Helmet>

      <CategorylistContainer>
        {[{ id: "all", title: "All" }, ...list].map((item) => (
          <CategoryList
            title={item.title!}
            active={selected === item.id}
            setSelected={handleGenreSelection}
            id={item.id}
            key={`genres-${item.id}`}
          />
        ))}
      </CategorylistContainer>
      <GamesContainer>
        {games &&
          games.map((game) => (
            <GameDetail key={game.id}>
              <Link to={`/games/${game.id}`}>
                <Image src={game.game_posters[0].file.path} />
                <div style={{ marginBottom: "40px" }}>
                  <GameName>{game.title}</GameName>
                  <GameCategory>
                    {game.game_genres.length > 0 &&
                      `#${game.game_genres
                        .map(({ tag }) => tag.value)
                        .join(" #")}`}
                  </GameCategory>
                </div>
              </Link>
            </GameDetail>
          ))}
      </GamesContainer>
    </>
  );
}
