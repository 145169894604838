import { MiniContractKit } from "@celo/contractkit/lib/mini-kit";
import { BigNumber } from "@ethersproject/bignumber";
import { GetProfileQuery, MeOutput, OffRampCeloMutationFn, TopUpCowrieMutationFn } from "@graphql";
import { isEmpty } from "lodash";
import { countDecimals } from "./shared";

const refetchQueries = ["GetCeloWalletBalance", "GetWalletTransactions"];

type TopupWalletType = {
  toast: any;
  performActions: (
    ...operations: ((kit: MiniContractKit) => unknown)[]
  ) => Promise<unknown[]>;
  jiweWalletAddress: {
    test: string;
    live: string;
  };
  topUpJiweWallet: TopUpCowrieMutationFn;
  walletMode: "test" | "live";
  loggedInUser: MeOutput & { profile: GetProfileQuery["profiles_by_pk"] };
};

export const topUpWallet = async ({
  toast,
  performActions,
  jiweWalletAddress,
  topUpJiweWallet,
  walletMode,
  loggedInUser,
}: TopupWalletType) => {
  try {
    const amt = prompt("Top up amount?");
    if (isEmpty(amt)) {
      toast.warning("Cancelled wallet topup");
      return;
    }
    let amnt = parseFloat(amt || "0");
    const decimals = countDecimals(amnt);
    amnt = amnt * 10 ** decimals;

    let transactionAmount = BigNumber.from(
      BigInt(amnt) * BigInt(1e18 / 10 ** decimals)
    );

    await performActions(async (kit) => {
      const cUSD = await kit.contracts.getStableToken();
      const result = await cUSD
        .transfer(jiweWalletAddress[walletMode], transactionAmount.toString())
        .sendAndWaitForReceipt();
      await topUpJiweWallet({
        variables: {
          params: {
            walletId: loggedInUser.profile?.metadata[walletMode].walletId,
            amount: parseFloat(amt || "0"),
            celoTxnData: result,
            testMode: walletMode === 'test'
          },
        },
        refetchQueries,
      });
      toast.success("Wallet successfully topped up");
    });
  } catch (err) {
    console.error(err);
    toast.error((err as Error).message);
  }
};

export const sendToCelo = async ({
  address,
  toast,
  offRamp,
  loggedInUser,
  walletMode,
}: {
  address: string
  walletMode: string
  toast: any;
  offRamp: OffRampCeloMutationFn;
  loggedInUser: MeOutput & { profile: GetProfileQuery["profiles_by_pk"] };
}) => {
  try {
    const amt = prompt("Enter amount to send");
    if (isEmpty(amt)) {
      toast.warning("Cancelled sending to celo");
      return;
    }
    const amount = parseFloat(amt || "0");
    if (amount === 0) {
      toast.error("Please enter amount greater than zero");
      return;
    }
    offRamp({
      variables: {
        params: {
          idempotencyKey: `${amount}:${
            loggedInUser.profile?.metadata.walletId
          }:${Date.now()}`,
          amount,
          description: `Withdraw ${amount} on ${new Date().toString()}`,
          walletId: loggedInUser.profile?.metadata[walletMode].walletId,
          testMode: walletMode === 'test',
          celoWalletAddress: address,
        },
      },
      onError: (err) => toast.error(err.message),
      onCompleted: () =>
        toast.success(`cUSD ${amount} successfully sent to your Valora wallet`),
      refetchQueries,
    });
  } catch (err) {
    console.error(err);
    toast.error((err as Error).message);
  }
};
