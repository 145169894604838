import useAuth from "pages/auth/useAuth";
import { Link, useHistory } from "react-router-dom";
import { Item, ItemContainer, Menu, SidebarContainer } from "./components";

export default function Sidebar() {
  const { logout } = useAuth();
  const history = useHistory()

  return (
    <SidebarContainer>
      <Menu>
        <ItemContainer onClick={() => history.push("/games")}>
          <Item>
            <Link to='/games'>
              <i className='fa-solid fa-gamepad' />
            </Link>
          </Item>
          <Item>
            <Link to='/games'>Games</Link>
          </Item>
        </ItemContainer>
        {/* <ItemContainer>
          <Item>
            <i className='fa-solid fa-cloud-arrow-down' />
          </Item>
          <Item>Downloads</Item>
        </ItemContainer> */}
        <ItemContainer onClick={() => history.push("/profile/wallet")}>
          <Item>
            <Link to='/profile/wallet'>
              <i className='fa-solid fa-wallet' />
            </Link>
          </Item>
          <Item>
            <Link to='/profile/wallet'>Wallet</Link>
          </Item>
        </ItemContainer>
        {/* <ItemContainer>
          <Item>
            <i className='fa-solid fa-chart-line' />
          </Item>
          <Item>Statistics</Item>
        </ItemContainer> */}
      </Menu>
      <Menu>
        <ItemContainer onClick={() => history.push("/profile")}>
          <Item>
            <Link to='/profile'>
              <i className='fa-solid fa-user' />
            </Link>
          </Item>
          <Item>
            <Link to='/profile'>Profile</Link>
          </Item>
        </ItemContainer>
        <ItemContainer onClick={() => logout()}>
          <Item onClick={() => logout()}>
            <i className='fa-solid fa-arrow-right-from-bracket' />
          </Item>
          <Item onClick={() => logout()}>Logout</Item>
        </ItemContainer>
      </Menu>
    </SidebarContainer>
  );
}
