import { Connector } from "@celo/react-celo/lib/types";
import { RegularButton } from "components/buttons/button";
import { Flex } from "./components";

function ConnectWalletInstructions({
  walletMode,
  connect,
}: {
  walletMode: "test" | "live";
  connect: () => Promise<Connector>;
}) {
  return (
    <>
      <div style={{ width: "calc(100% - 1060px)", textAlign: "center" }}>
        <p
          style={{
            color: "mediumseagreen",
            fontSize: "1.25rem",
            marginBottom: "1rem",
          }}
        >
          Step 1: Download{" "}
          {walletMode === "test" ? "Alfajores" : "Valora"}
        </p>
        {walletMode === "test" && (
          <>
            <small>(Android)</small>
            <img
              src='/alfajores-android.png'
              alt='valora-scan-to-download-android'
              width={150}
              style={{ margin: "auto", marginBottom: "1.5rem" }}
            />
            <small>(iOS)</small>
            <img
              src='/alfajores-ios.png'
              alt='valora-scan-to-download-ios'
              width={150}
              style={{ margin: "auto", marginBottom: "1.5rem" }}
            />
          </>
        )}
        {walletMode === "live" && (
          <>
            <img
              src='/valora-scan-to-download.svg'
              alt='valora-scan-to-download-android'
              width={150}
              style={{ margin: "auto", marginBottom: "1.5rem" }}
            />
            <Flex style={{ justifyContent: "center", gap: "1rem" }}>
              <img
                src='/valora-logo-mark.png'
                alt='valora-logo-mark'
                width={50}
              />
              <img src='/valora-logo.svg' alt='logo' />
            </Flex>
          </>
        )}
        {walletMode === "test" && (
          <small>
            As this is still in beta testing please note that this is the
            Alfajores Testnet using the Celo Mobile wallet and you can request
            for us to top up your wallet through <b>email (rock@jiwe.io)</b> or{" "}
            <b>WhatsApp +254773754444</b> (include your wallet address). Or go
            directly to the{" "}
            <a href='https://celo.org/developers/faucet'>Celo Faucet</a>.
            <br />
            You can use your Celo Dollars on from the Alfajores testnet to test
            your game before going into production.
          </small>
        )}
      </div>
      <div style={{ width: "calc(100% - 900px)", textAlign: "center" }}>
        <p
          style={{
            color: "mediumseagreen",
            fontSize: "1.25rem",
            marginBottom: "1rem",
          }}
        >
          Step 2: Connect to Jiwe Wallet
        </p>
        <img
          src={require("assets/logo/logo-black.png").default}
          alt='logo'
          style={{ marginBottom: "1.5rem" }}
        />
        <RegularButton onClick={connect}>
          Connect {walletMode === "test" ? "Alfajores" : "Valora"} Wallet
        </RegularButton>
        <br />
        <br />
        <small>
          Please select Valora option when asked and scan the QR code shown with
          your {walletMode === "test" ? "Alfajores" : "Valora"} App
        </small>
      </div>
    </>
  );
}

export default ConnectWalletInstructions;
