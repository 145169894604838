import { Theme } from "@emotion/react";
import { createTheme } from "@material-ui/core/styles";

export interface IButtonProps {
  outline?: boolean;
  width?: string;
  color?: VariantType;
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  heavy?: boolean;
  padding?: "compact" | "wide";
  loading?: boolean;
}

export const theme = createTheme({
  // @ts-ignore
  colors: {
    accent: "#ED2C7F", // pink
    dark: "#191F2D", // really dark blue
    light: "#CCDFFF", // light blue
    primary: "#26324C", // dark blue
    secondary: "#718096", // dark grey

    pinkish: "#FFEAF3", //
    grey: "#A0AEC0", // grey
    greyish: "#E2E8F0", // light grey
    bg1: "#F2F5FA",
    bg2: "#ECF1FE",
    blueish: "#CCDFFF", // grey bluish
    white: "#FFFFFF", //
    green: "#04862C",
    red: "#D50000",
  },
});

export const buttonColor = (
  props: { theme: Theme } & Partial<IButtonProps>
): string => {
  if (props.outline) {
    switch (props.color) {
      case "greyish":
        return props.theme.colors.primary;
      case "accent":
        return props.theme.colors.white;
      case "light":
        return props.theme.colors.dark;
      default:
        return props.theme.colors[props!.color || "primary"];
    }
  } else {
    switch (props.color) {
      case "primary":
      case "dark":
        return props.theme.colors.blueish;
      case "greyish":
      case "grey":
      case "white":
      case "bg1":
        return props.theme.colors.primary;
      case "accent":
        return props.theme.colors.white;
      default:
        return props.theme.colors[props!.color || "primary"];
    }
  }
};

export const buttonPadding = (padding?: "compact" | "wide"): string => {
  switch (padding) {
    case "wide":
      return "12px 16px";
    case "compact":
      return "6px 12px";
    default:
      return "10px 16px";
  }
};
