import { useContext } from "react";
import { useChangePasswordMutation } from "@graphql";
import { Field, Form, Formik } from "formik";
import { RegularButton } from "../buttons/button";

import {
  Buttons,
  Container,
  Label,
  ProfileContainer,
  Details,
} from "../profile/components";
import { LoggedInUserContext } from "contexts/user";
import * as toast from "../jiwewallet/toast";
import ProfileHeader from "components/profile/ProfileHeader";

export default function Profile() {
  const loggedInUser = useContext(LoggedInUserContext);

  const [changePasswordMutation] = useChangePasswordMutation();

  const user = loggedInUser?.profile_id;
  const handleSubmit = async (values: any, helpers: any) => {
    if (values.oldPassword !== values.newPassword) {
      toast.error("Password must match");
    }

    changePasswordMutation({
      variables: {
        input: {
          usernameOrEmail: user?.email,
          ...values,
        },
      },
    });
    toast.success("Password changed successfully");
    helpers.setSubmitting(false);
  };

  const initialValues = {};

  return (
    <ProfileContainer>
      <ProfileHeader activeSection="password" />
      <Container>
        <Formik onSubmit={handleSubmit} initialValues={initialValues}>
          {({ handleSubmit, isSubmitting, values, dirty }) => (
            <Form onSubmit={handleSubmit}>
              <div style={{ marginBottom: "3%", display: "none" }}>
                Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                Ducimus eius natus magnam aspernatur iusto officia sint ipsum
                accusantium quaerat consequatur aliquam quos, harum dolorum
                labore, adipisci quo repudiandae eos nihil? Lorem ipsum dolor,
                sit amet consectetur adipisicing elit. Fugiat, quo esse magni
                nam accusantium molestias consequatur nulla voluptate facilis
                labore id dolores. Eum cumque vero eius minima ducimus ad
                accusamus.
              </div>
              <Details>
                <div>
                  <Label>Current Password</Label>
                  <Field compact name='oldPassword' type='password' required />
                </div>
                <div>
                  <Label>New Pasword</Label>
                  <Field compact name='newPassword' type='password' required />
                </div>
              </Details>
              <Buttons style={{ marginTop: "3%", marginBottom: " 3%" }}>
                {/* <OutlineButton style={{ marginRight: '1rem' }}>Cancel</OutlineButton> */}
                <RegularButton>Update Password</RegularButton>
              </Buttons>
            </Form>
          )}
        </Formik>

        {/* <DeleteContainer>
          <DeleteTitle>Delete Account</DeleteTitle>
          <DeleteDesc>
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Asperiores
            iure, quo dolorum, inventore harum ipsa perferendis deserunt nobis
            ipsum illum ut ipsam esse consectetur nesciunt sunt, illo sed!
            Rerum, illo. Lorem ipsum dolor sit amet consectetur adipisicing
            elit. Blanditiis adipisci minima, ea quia quae, tempora accusamus
            optio perferendis et doloremque reprehenderit unde error tempore
            placeat quo nemo! Ab, quos explicabo.
          </DeleteDesc>
          <OutlineButton
            style={{ border: "2px solid #BA4646", marginTop: "2%" }}
          >
            Delete Account
          </OutlineButton>
        </DeleteContainer> */}
      </Container>
    </ProfileContainer>
  );
}
