import React from "react";
import {
  Redirect,
  Route,
  RouteComponentProps,
  RouteProps,
} from "react-router-dom";

import useAuth from "./useAuth";

const ProtectedRoute: React.FC<
  RouteProps & {
    component?: React.ComponentType;
    children?: (props: RouteComponentProps) => React.ReactElement;
  }
> = ({ component: Component, children, ...restOfProps }) => {
  const { isAuthenticated } = useAuth();

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: `/auth/login`,
          state: { from: restOfProps.location },
        }}
      />
    );
  }

  if (Component) {
    return (
      <Route {...restOfProps} render={(props) => <Component {...props} />} />
    );
  }

  return <Route render={(props) => children && children(props)} />;
};

export default ProtectedRoute;
