import { useRef } from 'react';
import PropTypes from 'prop-types';
import { DragAndDropArea } from './components';
import UploadImage from '../../assets/images/cloud-upload-regular-240.png'


export default function FilesDragAndDrop({ onUpload: { } }) {
    const drop = useRef(null);

    // useEffect(() => {
    //     drop.current.addEventListener('dragover', handleDragOver);
    //     drop.current.addEventListener('drop', handleDrop);
      
    //     return () => {
    //       drop.current.removeEventListener('dragover', handleDragOver);
    //       drop.current.removeEventListener('drop', handleDrop);
    //     };
    //   }, []);
    return (
        <DragAndDropArea  ref={drop}>
            Upload Cover Image
            <img src={UploadImage} width="100px" height="150px" alt="cloudUploadImage" />
            <p>Browse files and drop them here or</p>
            <a href="#">Click here to upload</a>
        </DragAndDropArea>
    );
}

FilesDragAndDrop.propTypes = {
    onUpload: PropTypes.func.isRequired,
};