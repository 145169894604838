import { Form, Field, Formik } from "formik";
import { object, string } from "yup";
import Checkbox from "@mui/material/Checkbox";
import {
  InputFormField,
  Line,
} from "./components";
import { RegularButton } from "../../components/buttons/button";
import { Link } from "react-router-dom";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const loginSchema = object().shape({
  email: string().email("Invalid email").required(),
  password: string().min(6).required(),
});

export type HandleLoginFn = (params: {
  email: string;
  password: string;
}) => void;

const LoginForm: React.FC<{
  handleSubmit: HandleLoginFn;
  loading: boolean;
}> = ({ handleSubmit, loading }) => {
  const initialFormState = {
    email: "",
    password: "",
  };

  return (
    <Formik
      initialValues={initialFormState}
      onSubmit={handleSubmit}
      validationSchema={loginSchema}
    >
      {({ isValid, errors }) => (
        <Form>
          <div style={{ marginTop: "100px", justifyContent: "space-between" }}>
            <Field
              component={InputFormField}
              type='email'
              label='Email'
              name='email'
              placeholder='example@email.com'
            />
            <Field
              component={InputFormField}
              type='password'
              label='Password'
              name='password'
              placeholder='password'
            />
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "3%",
              }}
            >
              {/* <Checkbox {...label} defaultChecked /> */}
              {/* <h2 style={{ fontSize: "12px", fontWeight: "300" }}>
                    Remember me?
                  </h2> */}
              <Link to='/auth/reset-password' style={{ marginLeft: "70%", color: "blue" }}>
                Forgot password?
              </Link>
            </div>
            <div style={{ textAlign: "center" }}>
              <RegularButton
                style={{ width: "100%" }}
                type='submit'
                disabled={loading || !isValid}
              >
                Sign In
              </RegularButton>
              <Line />
              {/* <OutlineButton style={{ width: "100%" }}>
                    Login With Google
                  </OutlineButton> */}
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default LoginForm;
