import React from "react";
import ReactDOM from "react-dom";
import ReactGA from "react-ga";
import { BrowserRouter as Router } from "react-router-dom";
import { createBrowserHistory } from "history";
import { Toaster } from "react-hot-toast";
import { ApolloProvider } from "@apollo/client";
import { CeloProvider, Alfajores, SupportedProviders } from "@celo/react-celo";
import { Global, ThemeProvider } from "@emotion/react";
import { createClient } from "lib/apollo";

import App from "./App";
import "@celo/react-celo/lib/styles.css";
import { globalStyles } from "styles";

import { theme } from "./theme";

const history = createBrowserHistory();
ReactGA.initialize("G-2H2RE2BTBN");
history.listen(({location}) => {
  ReactGA.set({ page: location.pathname });
  ReactGA.pageview(location.pathname);
});

const client = createClient();

ReactDOM.render(
  <React.StrictMode>
    <CeloProvider
      dapp={{
        icon: "",
        name: "Jiwe IO",
        description: "Game platform",
        url: "https://jiwe.io",
      }}
      network={Alfajores}
      connectModal={{
        providersOptions: {
          hideFromDefaults: [
            SupportedProviders.MetaMask,
            SupportedProviders.PrivateKey,
            SupportedProviders.CeloExtensionWallet,
            // SupportedProvider.Valora,
          ],
        },
      }}
    >
      <Toaster
        position='top-center'
        toastOptions={{
          className: "w-72 md:w-96",
          style: {
            padding: "0px",
          },
        }}
      />
      <Global styles={globalStyles} />
      <ThemeProvider theme={theme}>

      <ApolloProvider client={client}>
        <Router>
          <App />
        </Router>
      </ApolloProvider>
      </ThemeProvider>
    </CeloProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
