import React from "react";
import styled from "@emotion/styled";

import { Games } from "@graphql";

const Label = styled("span")<{ color: VariantType }>`
  border-radius: 4px;
  background: transparent;
  color: ${(props) => props.theme.colors[props.color]};
  padding: 6px 8px;
  font-size: 16px;
  font-weight: 600;
`;

const CardWrapper = styled.div<{ img: string }>`
  border-radius: 8px;
  display: flex;
  align-items: flex-end;
  background: url(${(props) => props.img}) no-repeat center;
  background-size: cover;
  width: 100%;
  height: 100%;
  > div {
    opacity: 0;
  }
  :hover {
    > div {
      transition: all ease-in-out 0.5s 0.5s;
      opacity: 1;
    }
  }
`;

const CardOverlay = styled.div`
  padding: 1rem 1.5rem;
  width: 100%;
  border-radius: 8px;
  background: linear-gradient(
    180deg,
    rgba(101, 105, 114, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
  color: ${(props) => props.theme.colors.white};
  > b {
    display: block;
    font-size: 1.4rem;
    font-weight: 900;
    margin-bottom: 5px;
  }
  > small {
    display: block;
    font-weight: 300;
    font-size: 1rem;
    margin-bottom: 5px;
  }
  > span {
    :first-of-type {
      padding-left: 0;
    }
    :last-of-type {
      padding-right: 0;
    }
  }
`;

const Card: React.FC<{ game: Partial<Games> }> = ({ game }) => {
  const g: any = game;
  const gamePoster = g.game_posters![0]!.file!.path;

  return (
    <CardWrapper img={gamePoster}>
      <CardOverlay>
        <b>{game.title}</b>
        <small>By {game.author?.name}</small>
        <Label color='white'>#{game.genre}</Label>
      </CardOverlay>
    </CardWrapper>
  );
};

export default Card;
