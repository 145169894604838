import { Link } from "react-router-dom";

import styled from "@emotion/styled";

import Container from "./Container";
import FlexWrapper from "./FlexWrapper";

import { ReactComponent as FacebookIcon } from "assets/icons/facebook.svg";
import { ReactComponent as InstagramIcon } from "assets/icons/instagram.svg";


const Divider = styled.div<{ color?: VariantType; hidden?: boolean }>`
  margin: 1.5em 0;
  display: block;
  ${({ hidden, color = "grey", theme }) =>
    `
    border-bottom: solid 1px ${!hidden ? theme.colors[color] : "transparent"}`};
`;

const Logo = styled("img")<{ height?: number }>`
  height: ${(props) => props.height || "50"}px;
  @media (max-width: 480px) {
    height: 40px;
  }
`;

const FooterWrapper = styled.footer`
  background: ${(props) => props.theme.colors.dark};
  padding: 5rem 0;
  color: ${(props) => props.theme.colors.greyish};
  font-size: 14px;
  font-weight: bold;

  @media (max-width: 720px) {
    padding-top: 4em;

    > div > div {
      display: block;
      text-align: center;

      img {
        display: block;
        margin: auto;
        margin-bottom: 2em; 
      }
    }
  }

`;

const FooterItem = styled(Link)`
  text-decoration: none;
  color: ${(props) => props.theme.colors.greyish};
  padding: 9px 0;
  font-size: 14px;
  :first-of-type {
    margin-right: 1rem;
  }
  :last-of-type {
    margin-left: 1rem;
  }
`;

const SocialMediaIcon = styled.div`
  display: flex;
  color: ${(props) => props.theme.colors.greyish};
  > a {
    text-decoration: none;
    margin-left: 2rem;
    display: inherit;
    color: ${(props) => props.theme.colors.greyish};
    > i {
      width: 20px;
      height: 20px;
    }
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <Container>
        <FlexWrapper justify="space-between">
          <Logo src={require("assets/logo/logo-white.png").default} />
          <div>
            <FooterItem to="">Terms of services</FooterItem>
            &bull;
            <FooterItem to="/privacy-policy">Privacy policy</FooterItem>
          </div>
        </FlexWrapper>
        <Divider color="greyish" />
        <FlexWrapper justify="space-between">
          <div>
            <span
              style={{
                fontSize: "1.5em",
                verticalAlign: "bottom",
                display: "inline-block",
                marginBottom: "-4px",
                marginRight: "5px",
              }}
            >
              &copy;{" "}
            </span>
            <span>{new Date().getFullYear()}</span> Jiwe Interactive
          </div>
          <SocialMediaIcon>
            <a href="https://twitter.com/jiwe_io" target="_black">
              <FacebookIcon />
            </a>
            <a href="https://www.instagram.com/jiwe.io/" target="_black">
              <InstagramIcon />
            </a>
            {/* <a href="http://" target="_black">
              <LinkedInIcon />
            </a> */}
          </SocialMediaIcon>
        </FlexWrapper>
      </Container>
    </FooterWrapper>
  );
};

export default Footer;
