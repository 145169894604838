import styled from "@emotion/styled";
import { FieldProps } from "formik";
import React, { ChangeEvent } from "react";

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: row;
  // border: 3px solid rgb(0, 0, 0);
  width: 100%;
  height: 100vh;
  // overflow: hidden;

  input {
    border: 1px solid #888;
    height: unset;
    padding: 1rem;
  }

  .change-password {
    margin-top: 2rem;
    input {
      width: 80%;
      border-radius: 1rem;
      margin-bottom: 2rem;
    }
  }
`;

export const Left = styled.div`
  flex: 1 1 0px;
  background: url("/login-bg.jpg") no-repeat;
  @media(max-width: 768px) {
    display: none;
}
`;

export const Welcome = styled.div`
  color: white;
  margin-top: 90vh;
  margin-left: 15%;
`;
export const Right = styled.div`
  flex: 1 1 0px;
  background-color: #ffff;
  padding: 40px;
  color: #636363;
`;

export const LoginTitle = styled.h1`
  font-size: 70px;
`;

export const Signup = styled.span`
  color: #636363;
  font-size: 20px;
  margin-bottom: 10px;
`;

export const Label = styled.h4`
  color: black;
  font-size: 20px;
  margin-bottom: 2%;
  width: 100%;
`;

export const Input = styled.input`
  width: 100%;
  border-radius: 1rem;
  height: 40px;
  padding: 10px;
  margin-bottom: 2%;
`;

export const Textarea = styled.textarea`
  background-color: #ffffff;
  border-radius: 1rem;
  font-family: "Montserrat";
  width: 90%;
  height: 90px;
  padding: 20px;
  border: 1px solid #d2d2d2;
`;

export const Line = styled.div`
  width: 100%;
  height: 10px;
  margin-top: 5%;
  margin-bottom: 5%;
  border-top: 1px solid #e7e7e7;
`;

export const InputError = styled("p")`
  color: #d50000;
  font-size: 0.75rem;
  font-style: italic;
  margin-top: 0.375rem;
`;

interface InputFormFieldProps {
  type: "text" | "email" | "number";
  label?: string;
  icon?: React.ReactNode;
}

export const InputFormField: React.FC<InputFormFieldProps & FieldProps> = ({
  type = "text",
  field,
  form: { touched, errors },
  label,
  ...props
}) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <Input type={type} {...field} {...props} />
      {touched[field.name] && errors[field.name] && <InputError />}
    </>
  );
};

export const TextAreaFormField: React.FC<
  {
    label?: string;
    value?: string;
    onChange: (event: ChangeEvent<HTMLTextAreaElement>) => void;
  } & FieldProps
> = ({ field, form: { touched, errors }, label, ...props }) => {
  return (
    <>
      {label && <Label>{label}</Label>}
      <Textarea {...field} {...props} />
      {touched[field.name] && errors[field.name] && <InputError />}
    </>
  );
};
