import styled from "@emotion/styled";


export const SidebarContainer = styled.div`
    width: 15%;
    color: white;
    margin-top: 2%;
    height: 100%;
    @media(max-width: 1100px) {
       display: none;
    }   
`;

export const Menu = styled.div`
    margin-bottom: 70%;
    justify-content: center;
`;
 
export const ItemContainer = styled.ul`
    // border: 5px solid blue;
    width: 100%;
    height: 50px;
    text-align: center;
    align-items: center;
    font-size: 20px;
    display: flex;
    &:hover{
        color: #C59D24;
        transition: 500ms;
        background-color: #231F20;
        border-left: 5px solid #C59D24;
        cursor: pointer;
    }
`;
 
export const Item = styled.li`
  list-style: none;
  // border: 1px solid #C59D24;
  margin-left: 10%;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      color: #c59d24;
    }
  }
`;